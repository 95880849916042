import { Component, ViewChild, Input, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import Cropper from "cropperjs";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-photo-crop',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './photo-crop.component.html',
  styleUrls: ['./photo-crop.component.scss']
})

export class PhotoCropComponent implements AfterViewInit {
  @ViewChild("image", { static: false }) imageElement!: ElementRef

  @Input() imageSource!: string | undefined
  @Input() photoContainerDimensions!: { width: number, height: number }

  @Output() userCroppedImageEvent = new EventEmitter()
  @Output() cropDetailsEvent = new EventEmitter()

  cropDetails!: Cropper.Data
  x!: number
  y!: number
  height!: number
  width!: number 

  givenName!: string

  imageDestination!: string

  destinationImage!: string
  private cropper!: Cropper

  imageNames:[x: number, y: number, height: number, width: number, givenName: string][] = []
  allImageNames=[]

  constructor() {}

  ngAfterViewInit() {
    console.log('photo-crop')
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: (event) => {
        this.imageDestination = this.cropper.getCroppedCanvas().toDataURL("image/png");

        this.userCroppedImageEvent.emit(this.imageDestination)

        this.cropDetails = event.detail
        this.x = Math.floor(this.cropDetails.x)
        this.y = Math.floor(this.cropDetails.y)
        this.height = Math.floor(this.cropDetails.height)
        this.width = Math.floor(this.cropDetails.width)

        this.cropDetailsEvent.emit({height: this.height, width: this.width, x: this.x, y: this.y})
      }
    })
  }

  name(x: number, y: number, height: number, width: number, givenName: string){
    this.imageNames = [[x,y,height,width,givenName]]
    //this.allImageNames = this.allImageNames.concat(this.imageNames)
    console.log(this.allImageNames)
  }
}