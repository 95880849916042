import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

interface LocationHistory {
  previousUrl: string | null;
  currentUrl: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private locationHistory: LocationHistory = {
    previousUrl: null,
    currentUrl: null,
  };

  constructor(
    private router: Router
  ) { 
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
          this.locationHistory.previousUrl =
              this.locationHistory.currentUrl;
          this.locationHistory.currentUrl = event.url;
      }
    });
  }

  public getPreviousUrl(): string | null {
    return this.locationHistory.previousUrl;
  }
}
