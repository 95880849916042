
<div formGroupName="birthdate">
    <h2 i18n="Title for the birth date form@@BIRTHDATE">Birth date</h2>
    <div class="fields">
        <fieldset class="field">
            <label for="day-select" class="sublabel" i18n="Label for the day selector in the birth date form@@DAY">Day</label>
            <select name="day" id="day-select" formControlName="birthDayCtrl" (click)="onClick($event)">
                <option value="" i18n="Placeholder in the day selector of the birth date form@@SELECT_DAY">-- Select a day --</option>
                @for(d of days; track d){
                    <option [value]="d">{{d}}</option>
                }
            </select>
        </fieldset>
        
        <fieldset class="field">
            <label for="month-select" class="sublabel" i18n="Label for the month selector in the birth date form@@MONTH">Month</label>
            <select name="month" id="month-select" formControlName="birthMonthCtrl" (click)="onClick($event)">
                <option value="" i18n="Placeholder in the month selector of the birth date form@@SELECT_MONTH">-- Select a month --</option>
                @for(m of months; track m.id) {
                    <option [value]="m.id" class="month">{{m.label | titlecase}}</option>
                }
            </select>
        </fieldset>
    
        <fieldset class="field">
            <label for="year-select" class="sublabel" i18n="Label for the year selector in the birth date form@@YEAR">Year</label>
            <select name="year" id="year-select" formControlName="birthYearCtrl" (click)="onClick($event)">
                <option value="" i18n="Placeholder in the year selector of the birth date form@@SELECT_YEAR">-- Select a year --</option>
                @for(y of years; track y){
                    <option [value]="y">{{y}}</option>
                }
            </select>
        </fieldset>    
    </div>
</div>
