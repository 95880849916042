import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toolbar-top-actions',
  standalone: true,
  imports: [],
  templateUrl: './toolbar-top-actions.component.html',
  styleUrl: './toolbar-top-actions.component.scss'
})
export class ToolbarTopActionsComponent {
  //buttons which close/ confirm actions in the tool bar menus
  @Input() showDelete = false
  @Output() cancel = new EventEmitter<void>()
  @Output() confirm = new EventEmitter<void>()

  constructor() { }
}
