<h3 i18n="Title for the conversation deletion dialog@@TEXT_DELETE_CONVERSATION">Are you sure you want to delete this conversation?</h3>

<p class="description" i18n="Description for the conversation deletion dialog@@TEXT2_DELETE_CONVERSATION">This conversation will no longer be available</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()" i18n="Text for the button to cancel the action in progress@@CANCEL">Cancel</button>
  <button class="submit" (click)="dialogRef.close(true)">
    <img src="assets/icons/icon_chat_delete.svg" alt="" class="delete-icon">
    <ng-container i18n="Text for the button to confirm the deletion@@DELETE">Delete</ng-container> 
  </button>
</div>
