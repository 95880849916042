
<app-toolbar-top-actions (cancel)="closeOverlay()" (confirm)="selectEmoji()"></app-toolbar-top-actions>

@if(menuType === 'stickers'){
  <div class="tab-menu">
      <button
          class="tab-button"
          (click)="subMenuType = 'fun'"
          [class.selected]="subMenuType === 'fun'"
      >
          <img src="assets/emoticons/emojis/thumb/emoji_1004.png" alt="Fun" class="button-image" title="Fun">
      </button>

      <button
          class="tab-button"
          (click)="subMenuType = 'animals'"
          [class.selected]="subMenuType === 'animals'"
      >
          <img src="assets/emoticons/emojis/thumb/emoji_1068.png" alt="Animals" class="button-image" title="Animals">
      </button>
  </div>

  <div class="emojis-group-holder">
      @for(img of subMenuType === 'fun' ? emojis.fun : emojis.animals; track img){
          <button #visual class="emojis-button" (click)="changeEmoji({src: 'assets/emoticons/emojis/thumb/' + img + '.png', type: 'visual', height: 40, width: 40})">
              <img [src]="'assets/emoticons/emojis/thumb/' + img + '.png'" alt=""/>
          </button>
      }
  </div> 
}

@if(menuType ==='emojis'){
  <div class="tab-menu">
      <button
          class="tab-button"
          (click)="subMenuType = 'emoticons'"
          [class.selected]="subMenuType === 'emoticons'"
      >
          <img src="assets/emoticons/emojis/thumb/emoji_53.png" alt="Emoticons" class="button-image emoticon" title="Emoticons">
      </button>
      <button
          class="tab-button"
          (click)="subMenuType = 'hands'"
          [class.selected]="subMenuType === 'hands'"
      >
          <img src="assets/emoticons/emojis/thumb/emoji_84.png" alt="Hands" class="button-image" title="Hands">
      </button>
  </div>

  <div class="emojis-group-holder" (scroll)="onScroll($event)">
      @for(img of subMenuType === 'emoticons' ? emojis.emoticons : emojis.hands; track img){
          <div  #visual class="emojis-group-holder-emoji">
            <button type="button" (click)="changeEmoji({src: 'assets/emoticons/emojis/thumb/' + img + '.png', type: 'visual', height: 40, width: 40})">
              <img [src]="'assets/emoticons/emojis/thumb/' + img + '.png'" alt="" [class.rotate]="img === 'emoji_53'"/>
            </button>
          </div>
      }
  </div>
}

@if(menuType === 'masks'){
    <div class="emojis-group-holder">
        @for(img of masks.masks; track img){
            <div class="emojis-group-holder-emoji">
              <button type="button" (click)="changeEmoji({src: 'assets/masks/thumb/' + img + '.png' , type: 'visual',height: 40, width: 40})">
                <img [src]="'assets/masks/thumb/' + img + '.png'" alt=""/>
              </button>
            </div>
        }
    </div>
}




