import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewChecked,
  Inject,
  OnDestroy,
} from '@angular/core';
import { Observable, Subscription, map, throwError } from 'rxjs';
import { Router, RouterOutlet, RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CommonModule, Location, DOCUMENT } from '@angular/common';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { environment } from '../../environments/environment';

import { AuthService } from '../auth.service';
import { AppService } from '../app.service';
import { PhotoService } from '../photo/photo.service';
import { StoriesService } from '../stories.service';
import { StoryService } from '../create-story/story.service';
import { HomeService } from './home.service';

import { LoaderComponent } from '../shared/loader/loader.component';
import { CreateStoryComponent } from '../create-story/create-story.component';
import { LoggedInHeaderComponent } from '../logged-in-header/logged-in-header.component';
import { FooterComponent } from '../footer/footer.component';
import { InfoDialogComponent } from '../dialog/info-dialog/info-dialog.component';
import { StoryComponent } from '../story/story.component';
import { PaginatorComponent } from '../shared/paginator/paginator.component';

import { User } from '../shared/interfaces/user';
import { Story } from '../shared/interfaces/story';
import { StoryImage } from '../shared/interfaces/story-image';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    RouterOutlet,
    LoaderComponent,
    CreateStoryComponent,
    LoggedInHeaderComponent,
    FooterComponent,
    RouterModule,
    InfoDialogComponent,
    StoryComponent,
    PaginatorComponent,
    CommonModule,
    MatSidenavModule,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewChecked, OnDestroy {
  @HostListener('window:scroll', ['$event']) private onScrollWindow() {
    this.windowScrolling();
  }

  @ViewChild('mainHolder') holder!: ElementRef;
  @ViewChild('fileUpload') fileUpload!: ElementRef;
  @ViewChild('mobileFileUpload') mobileFileUpload!: ElementRef;  
  @ViewChild('sidenav') sidenav!: MatSidenav
  @ViewChild('photos') photosContainer!: ElementRef

  private window: (Window & typeof globalThis) | null

  firstname!: string;
  user: User | null = null;
  photo!: string;

  savedImageUrl = '';
  loading = false;
  stories: Story[] = [];

  storyImages: StoryImage[] = [];

  showAddStoryDialog = false;
  showOverlay = false;
  showInfoDialog = false;
  currentPage = 1;
  totalPages = 1;

  encounters: User[] = [];
  infoDialogType = '';

  storiesLoading = true;
  desktopDevice = false;
  serviceName = environment.serviceName;

  pageDisplayed = 'home';
  loadingMore = false
  noMoreToLoad = false

  sidenavToggled = false
  storyWidth!: number

  loaderSubscription!: Subscription
  toggleSubscription!: Subscription
  pageSubscription!: Subscription
  count = 0

  constructor(
    public router: Router,
    private appService: AppService,
    private authService: AuthService,
    private photoService: PhotoService,
    private storiesService: StoriesService,
    private storyService: StoryService,
    private http: HttpClient,
    private homeService: HomeService,
    private location: Location,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.appService.setPage('service');
    this.window = this.document.defaultView

    if(this.window){
      this.window.addEventListener('popstate', (event) => {
        const popStateTarget = event.currentTarget as Window
        const urlSegmentsArr = popStateTarget.location.pathname.split('/')

        switch (popStateTarget.location.pathname) {
          case '/home':
            this.homeService.toggleSidenav.next(false)
            this.homeService.pageDisplayed.next('home')
            break;

          case '/login':
              this.homeService.toggleSidenav.next(false)
              this.homeService.pageDisplayed.next('home')
              break;

          default:
            this.homeService.pageDisplayed.next(urlSegmentsArr[urlSegmentsArr.length-1])
            break;
        }
      }, false);
    }
  }

  ngOnInit() {
    this.pageSubscription = this.homeService.pageDisplayed.subscribe(res => {
      this.pageDisplayed = res
    })

    // permet de réinitialiser l'url au refresh
    this.location.replaceState('/home')
    
    this.loaderSubscription = this.appService.loader.subscribe((res) => (this.loading = res));
    this.storyService.storyImages.subscribe((res) => (this.storyImages = res));
    this.toggleSubscription = this.homeService.toggleSidenav.subscribe(res => {
      this.sidenavToggled = res
      if(this.sidenav) {
        if(this.sidenavToggled){
          this.sidenav.open()
        } else {
          this.sidenav.close()
        }
      }
    })

    this.user = JSON.parse(this.authService.get('user'));
    const userStoredLocally = !!this.user;
    let id: number | undefined;

    // user stored locally
    // no user (even though token), redirection to login page

    if (userStoredLocally) {
      id = this.user?.id;

      if (id) {
        this.authService.getProfile(id).subscribe({
          next: (res: User | null) => {
            this.appService.setLoader(true);
            this.user = res;

            this.authService.authMe$.next(this.user)

            this.authService.remove('user');
            this.authService.set('user', JSON.stringify(res));

            this.user?.main_photo
              ? (this.photo = this.user?.main_photo.url)
              : this.router.navigate(['/upload-photo']);

            this.appService.setLoader(false);
          },
          error: (err) => {
            this.appService.setLoader(false);
            return throwError(() => err);
          },
        });
        // } else {
        //   if(!this.me) {
        //     this.authService.remove('token')
        //     this.authService.remove('user')
        //     this.router.navigate(['/login'])
        //   }
      }

      this.storiesService.getStories(this.currentPage, 9).subscribe({
        next: (res) => {
          this.storiesLoading = true
          this.stories = res.stories
          this.storiesLoading = false
        },
        error: (err) => {
          this.storiesLoading = false
          return throwError(() => err)
        },
      });

      //this.storyService.storyImages.next(this.storiesService.tempStoryFiles)
    } else {
      this.authService.authMe.subscribe((res: User | null) => {
        if (!res) {
          this.authService.remove('token');
          this.authService.remove('user');
          this.router.navigate(['/login']);
        }

        this.user = res;
      });
    }

    // récupération de fake encounters : pour les tests
    this.getEncounters().subscribe((res) => {
      this.encounters = res;
    });

    if (window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true;
    }
    this.desktopDevice = this.appService.desktopDevice;
  }

  ngAfterViewChecked(){
    if(this.count > 0) return

    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }
    this.desktopDevice = this.appService.desktopDevice

    setTimeout(() => {
      const bounds = this.photosContainer.nativeElement.getBoundingClientRect()
      const photosContainerWidth = Number.parseFloat((bounds.right - bounds.left).toFixed(2))
  
      const gapWidth = 16
      let numberOfItemsPerRow = 2
  
      if(this.desktopDevice) numberOfItemsPerRow = 5
  
      this.storyWidth = Math.floor((photosContainerWidth - (numberOfItemsPerRow - 1) * gapWidth) / numberOfItemsPerRow)
      
      this.count++
    }, 100)
  }

  getStyles(){
    return {
      'width': this.storyWidth + 'px',
      'height': `${this.storyWidth * 1.4}px`,
    }
  }

  toggleSidenav(open: boolean){
    this.homeService.toggleSidenav.next(open)
    if(!open) this.homeService.pageDisplayed.next('')
  }

  windowScrolling() {
    const scrollValue = window.scrollY;
    const content = this.holder.nativeElement as HTMLElement;
    const bottom = scrollValue > content.clientHeight - window.innerHeight - 200;

    if(bottom && !this.loadingMore && !this.noMoreToLoad) {
      this.loadingMore = true
      this.storiesLoading = true
      this.currentPage++

      this.getStories()
    }
  }

  getStories() {
    this.storiesService.getStories(this.currentPage, 10).subscribe({
      next: (res) => {
        this.storiesLoading = true
        this.stories = this.stories.concat(res.stories)

        if(res.stories.length < 10) this.noMoreToLoad = true

        this.storiesLoading = false

        if(!this.loadingMore) {
          window.scrollTo(0, 0);
        }
        this.loadingMore = false;
      },
      error: (err) => {
        this.storiesLoading = false
        return throwError(() => err)
      },
    });
  }

  // récupération de fake encounters : pour les tests
  getEncounters(): Observable<User[]> {
    return this.http.get<User[]>('./assets/encounters.json').pipe(
      map((res) => {
        return res;
      })
    );
  }

  deletePhoto() {
    const id = this.user?.main_photo?.id;
    if (id) {
      this.photoService.deletePhoto(id).subscribe(() => {
        this.authService.authMe$.subscribe((res) => (this.user = res));

        const jsonString = this.authService.get('user');
        const userStoredLocally = JSON.parse(jsonString);
        userStoredLocally.main_photo = null;
        this.authService.set('user', JSON.stringify(userStoredLocally));
      });
    }
  }

  // ajout d'image via l'explorateur
  onPhotoOrVideoInputChange(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const files = element.files;

    Array.prototype.forEach.call(files, (file: File) => {
      const fileBlob = URL.createObjectURL(file);

      this.storyService.storyImages.next([
        {
          id: this.storyImages.length,
          value: fileBlob,
          processed: false,
        },
      ]);
    });

    if (this.storyImages.length > 0 && this.mobileFileUpload) {
      //this.storiesService.tempStoryFiles = this.storyImages
      this.router.navigate(['create-story']);
    }
  }

  displayAddStoryDialog() {
    this.showAddStoryDialog = true;
    this.showOverlay = true;
  }

  hideAddStoryDialog() {
    this.showAddStoryDialog = false;
    this.showOverlay = false;
  }

  openMobileCamera() {
    this.mobileFileUpload.nativeElement.click();
  }

  closeDialog() {
    console.log('closeDialog');
    this.showAddStoryDialog = false;
    this.showOverlay = false;

    //this.appService.warningPopup.next(true)
  }

  openFileInput() {
    this.fileUpload.nativeElement.click();
  }

  drop(ev: DragEvent) {
    if (!ev.dataTransfer) return;
    //stops browser from opening the file
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (const item of ev.dataTransfer.items) {
        if (item.kind !== 'file') {
          return;
        }

        const file = item.getAsFile();

        if (!file) return;
        const fileBlob = URL.createObjectURL(file);

        this.storyService.storyImages.next([
          {
            id: this.storyImages.length,
            value: fileBlob,
            processed: false,
          },
        ]);
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (const file of ev.dataTransfer.files) {
        const fileBlob = URL.createObjectURL(file);
        this.storyService.storyImages.next([
          {
            id: this.storyImages.length,
            value: fileBlob,
            processed: false,
          },
        ]);
      }
    }
  }

  allowDrop(ev: DragEvent) {
    ev.preventDefault();
  }

  logOut() {
    this.authService.logged$.next(false);
    this.router.navigate(['/login']);
    this.authService.authDisconnect();
  }

  closeInfoDialog(event: boolean) {
    this.showInfoDialog = !event;
  }

  requestStories($event: 'previous' | 'next') {
    if (!$event) return;

    if (this.currentPage === 1 && $event === 'previous') return;

    const page =
      $event === 'next' ? this.currentPage + 1 : this.currentPage - 1;
    const pagesNumber = page === 1 ? 9 : 10;

    this.storiesService.getStories(page, pagesNumber).subscribe({
      next: (res) => {
        console.log(res);
        this.stories = res.stories;
        this.currentPage = page;
        this.totalPages = res.total + 1; // res.total commence à 0
      },
      error: (err) => {
        if (err.error.message) {
          // this.errorTitle = "Login details are incorrect"
          // this.errorMessage = "Please check and re-enter your data."
          // this.openDialog = true
        }

        this.appService.setLoader(false);
      },
    });
  }

  getInfoDialogType(event: string) {
    console.log(event);
    this.infoDialogType = event;
    this.showInfoDialog = true;
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
    this.toggleSubscription.unsubscribe()
    this.pageSubscription.unsubscribe()
  }

  // scrolling($event: Event) {
  //   const container = $event.target as HTMLElement;
  //   const content = this.holder.nativeElement as HTMLElement;
  //   const bottom = container.scrollHeight - container.scrollTop - 100 < container.clientHeight;

  //   console.log(
  //     window.scrollY,
  //     'container.scrollHeight',
  //     container.scrollHeight,
  //     'container.scrollTop',
  //     container.scrollTop,
  //     'container.clientHeight',
  //     container.clientHeight
  //   );
  //   // const bottom = scrollValue > container.clientHeight - window.innerHeight - 200;
  //   if (bottom && !this.loadingMore && !this.noMoreToLoad) {
  //     this.loadingMore = true;
  //     this.pager.page += 1;
  //     this.tinyLoader = true;
  //     this.getFeedsByCriterias();
  //   }
  // }

  // sendMessage(){
  //   this.showInfoDialog = true
  //   this.infoDialogType = 'send-message'
  // }

  // likeProfile(){
  //   this.showInfoDialog = true
  //   this.infoDialogType = 'like-profile'
  // }

  // getUser(){
  //   this.appService.setLoader(true)
  //   const id = this.user?.id

  //   if(id){
  //     this.authService.getProfile(id).subscribe(res => {
  //       this.appService.setLoader(false)
  //       console.log(res)
  //     })
  //   }
  // }
}
