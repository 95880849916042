@if(loading){
    <app-loader></app-loader>
}

<section class="my-stories">
  <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>  
  
  <div class="top" #top>
      <h3><span class="regular">My</span> Stories</h3>
  </div>

  @if(!stories.length){
    <div class="no-stories-container" [style.height.px]="(viewportHeight * 70 / 100)">
      <p class="no-stories">No stories for the moment.</p>
    </div>
  } @else {
    <div class="stories-container" #storiesContainer>
      @for(s of stories; track s){
          <div class="photo-container" [ngStyle]="getStyles()">
            <img [src]="s.image" alt="" class="cover">
            <button type="button" (click)="deleteStory(s.id)">
              <img src="assets/icons/icon_delete.svg" alt="" class="delete-icon"> 
            </button>
        </div>
      }
    </div>

    <app-paginator [currentPage]="currentPage" [totalPages]="totalPages"></app-paginator>
  }

  @if(stories.length){
    <app-top-button (scrollRequest)="getScrollRequest($event)"></app-top-button>
  }

  <app-footer></app-footer>
</section>
