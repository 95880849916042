import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location, AsyncPipe } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, RouterModule, RouterOutlet } from '@angular/router';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomeService } from '../../home/home.service';
import { AuthService } from '../../auth.service';
import { LoaderService } from '../../loader.service';
import { LayoutService } from '../../layout.service';

import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';
import { LoaderComponent } from '../../shared/loader/loader.component';

import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-my-account',
  standalone: true,
  imports: [GoBackButtonComponent, LoaderComponent, FormsModule, ReactiveFormsModule, RouterOutlet, RouterModule, AsyncPipe],
  templateUrl: './my-account.component.html',
  styleUrl: './my-account.component.scss'
})
export class MyAccountComponent implements OnInit, OnDestroy {
  loading$ = this.loaderService.loading$
  showPassword = true
  user: User | null = null
  editEmailMode = false
  editPasswordMode = false
  deleteAccountMode = false
  emailFormGroup!: FormGroup
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop

  constructor(
    private location: Location,
    private homeService: HomeService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private layoutService: LayoutService
  ){}

  ngOnInit(){
    this.authService.authMe.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      this.user = res
    })

    if(!this.user){
      this.route.data.pipe(
        takeUntil(this._destroyed)
      ).subscribe(data => this.user = data['user'])
    }
  }

  goBack(){
    if(this.isDesktop()) {
      this.homeService.pageDisplayed.next('')
      this.location.back()
    } else {
      this.homeService.pageDisplayed.next('')
    }
  }

  getClickNature(clickNature: string){
    console.log(clickNature)
    // if(clickNature === 'anywhere' && this.openDialog === true){
    //   this.openDialog = false
    // }
  }

  showHidePassword() {
    this.showPassword = !this.showPassword
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
