import { Injectable } from '@angular/core';
import { User } from '../shared/interfaces/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profile = new BehaviorSubject<User | null>(null)
  private _profileLiked = new BehaviorSubject<User | null>(null)
  public profileLiked$ = this._profileLiked.asObservable().pipe()
  profileId = new BehaviorSubject<number | null>(null)

  constructor() { }
  
  setProfileLiked(value: User | null) {
    this._profileLiked.next(value)
  }
}
