import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../../app.service';

import { Story } from '../../shared/interfaces/story';
import { User } from '../../shared/interfaces/user';

export interface GetCoachesResponse {
  code: number,
  coachs: User[],
  moderation: {
    firstname: boolean,
    photo: boolean
  },
  user: {
    boosters: number
  }
}

export interface GetCoachStoriesResponse {
  code: number,
  stories: Story[]
}

@Injectable({
  providedIn: 'root'
})
export class CoachService {
  coachId!: number
  coach!: User
  stories: Story[] = []

  constructor(
    private http: HttpClient,
    private appService: AppService,
  ) { }

  getCoaches(): Observable<User[]> {
    this.appService.setLoader(true)

    return this.http
      .get<GetCoachesResponse>(`${this.appService.API}/api/relationship/coachs/1/10`)
      .pipe(
        map((res) => {
          this.appService.setLoader(false)
          return res.coachs
        }),
        catchError((error) => {
          return throwError(() => error)
        })
      );
  }

  getCoachStories(): Observable<Story[]>{
    if(!this.coachId){
      const result = new Observable<Story[]>((observer) => {
        observer.next();
        observer.complete();
      });

      return result
    }

    this.appService.setLoader(true)

    return this.http.get<GetCoachStoriesResponse>(`${this.appService.API}/api/story/user/${this.coachId}/1/10`)
      .pipe(
        map((res) => {
          console.log(res)
          this.appService.setLoader(false)
          return res.stories
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      )
  }
}
