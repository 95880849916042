<section class="profile-space">
    @if(profileLoading){
      <app-loader></app-loader>
    }
    
    <div class="left">
      @if(profile){
        <div class="user-information mobile">
          <div class="avatar-container">
            @if(profile.main_photo){
              <img [src]="profile.main_photo.url" alt="">
            } @else {
              <img src="../../../assets/icons/icon_user_400X400.png" alt="" class="no-photo">
            }
          </div>
          <div class="right">
            <!-- <h3 [routerLink]="['/profile/' + profile.id]">{{profile.firstname}}</h3> -->
            <h3>{{profile.firstname}}</h3>
            <p>{{userAge}} years - {{profile.city?.name}}</p>
            <div class="searching-container">
                <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
                {{profile.attraction | titlecase}}
            </div>
          </div>
        </div>
           
        @if(profile.photos) {          
          @if(profile.photos.length > 1){
            <div class="carousel-component-container">
              <carousel
                  [infinite]="true"
                  [autoPlay]="true"
                  [autoPlayAtStart]="true"
                  [autoPlayInterval]="2500"
                  [displayAutoPlayControls]="false"
                  [arrows]="false"
                  [counter]="false"
                  [slideToShow]="1"
                  [gapBetweenSlides]="6"
                  [slideMaxWidth]="400"
                  [responsive]="true"
              >
                @for(p of profile.photos; track $index){
                  <div class="carousel-slide">
                      <img [src]="p.url" alt="" class="photo">
                  </div>
                }
              </carousel>
            </div>
          } @else {
            <div class="photo-container">
              @if(profile.photos.length){
                <img [src]="profile.photos[0].url" alt="">
              } @else {
                <img src="../../../assets/icons/icon_user_400X400.png" alt="" class="no-photo">
              }
            </div>
          }
        } 

        @if(relationshipStatus === 'notfriend' || relationshipStatus === 'pending'){
          <div class="actions">
            <button 
                alt="Close this profile" 
                class="close-container"
                (click)="rejectProfile(profile.id)"
            >
                <span class="material-symbols-rounded close">close</span>
            </button>
  
            <button 
                alt="Like this profile" 
                class="like-container"
                (click)="likeProfile(profile.id)"
            >
                <img src="assets/icons/icon_love_32.svg" alt="" class="like">
            </button>
  
            <button 
                alt="Send a message" 
                class="message-container"
                (click)="contactProfile(profile.id)"
            >
                <img src="assets/icons/icon_message.png" alt="" class="message">
            </button>
          </div>
        } @else if(relationshipStatus === 'waiting' || showWaitingStatus) {
          <div class="waiting-status">
            <img src="assets/icons/icon_magical_wand.png" alt="" class="wand-icon">

            <div class="text">
              <p>You already liked or sent a message to this profile!</p>
              <p>Now you just have to wait for {{profile.sex === "man" ? 'his' : 'her'}} answer.</p>
              <p>If the attraction is mutual, let the magic begin...</p>
            </div>
          </div>
        } @else if(relationshipStatus === 'friend' || futureRelationshipStatus === "friend"){
          <div class="actions-validated">
            <button alt="Send a message" (click)="contactProfile(profile.id)">
              <img src="assets/icons/icon_chat_with.svg" alt="" class="chat-icon">
            </button>
            <button type="button" class="contextual-menu closed show" #contextualMenuClosed (click)="openProfileDialog($event)">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </button>
          </div>
        }
      }
    </div>
        
    @if(profile){
      <div class="right">
        <div class="user-information desktop">
          <!-- <h3 [routerLink]="['/profile/' + profile.id]">{{profile.firstname}}</h3> -->
          <h3>{{profile.firstname}}</h3>
          <p>{{userAge}} years - {{profile.city?.name}}</p>
          <div class="searching-container">
              <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
              {{profile.attraction | titlecase}}
          </div>
        </div>
          
          <h4>Description</h4>
          <p class="description" #description>
            @if(profile.description){
              {{profile.description}}
            } @else {
              Passport to Love: Seeking an Adventure Partner! I don't follow guidebooks; I forge my own path. Local experiences and cultural immersion are my souvenirs. Authenticity is key; I want to experience the world as it truly is. My ideal travel partner shares this insatiable curiosity, this desire to dive deep into the heart of every destination.
            }
          </p>
  
          <h4>Hobbies</h4>
          <div class="hobbies-container" #hobbiesContainer>
              @for (h of profile.topics; track $index) {
                  <div class="hobby" 
                  [ngStyle]="getStyles()"
                  >
                      {{h.name}}
                  </div>
              }
          </div>
      </div>
    }

    <div class="overlay" [ngStyle]="{'display': infoDialogType !== '' ? 'block' : 'none'}"></div>
    <app-info-dialog [infoDialogType]="infoDialogType" [ngStyle]="{'display': infoDialogType !== '' ? 'flex' : 'none'}" (closeInfoDialog)="closeDialog($event)"></app-info-dialog>

    @if(match){
      <app-match-dialog></app-match-dialog>
    }
</section>