import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core';
import { Visual } from '../../shared/interfaces/visual';
import { StoryService } from '../story.service';
import { CommonModule } from '@angular/common';

import { TextInputComponent } from '../text-input/text-input.component';
import { StoryContent } from '../../shared/interfaces/story-content';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-contents-container',
  standalone: true,
  imports: [CommonModule, TextInputComponent],
  templateUrl: './contents-container.component.html',
  styleUrl: './contents-container.component.scss'
})
export class ContentsContainerComponent implements OnInit {
  @Input() bounds!: DOMRect

  @Output() textEditionActivated = new EventEmitter<boolean>(false)

  @ViewChild('textInput', {read: ElementRef}) textInput!: ElementRef
  @ViewChild('visual', {read: ElementRef}) visual!: ElementRef

  visuals: Visual[] = []
  visualTransformData: {
    width: number, 
    height: number,
    angle: number
    x: string,
    y: string
  } = {
    width: 70, 
    height: 70,
    angle: 0,
    x: 'calc(50% - 35px)',
    y: 'calc(50% - 35px)'
  }
  textTransformData: {
    size: number, 
    angle: number
    x: string,
    y: string
  } = {
    size: 20,
    angle: 0,
    x: 'calc(50% - 100px)',
    y: 'calc(50% - 24px)'
  }
  textToolSelected = false
  visualToolSelected = false

  mobileTouchStartPosition:{ x: number, y: number } = { x: 0, y:0 }

  inputSize: { height: number, width: number } = { height: 48, width: 200 }

  textSelected = false

  selectedContent: StoryContent | null = null

  contents: StoryContent[] = []

  currentInput: StoryContent | null = null

  desktopDevice = false

  constructor(
    private storyService: StoryService,
    private appService: AppService
  ){}

  ngOnInit(){
    this.storyService.visuals.subscribe(res => this.visuals = res )
    this.storyService.textToolSelected.subscribe(res => this.textToolSelected = res)
    this.storyService.visualToolSelected.subscribe(res => this.visualToolSelected = res)
    this.storyService.contents.subscribe(res => {
      this.contents = res
    })    
    this.storyService.currentInput.subscribe(res => this.currentInput = res)

    this.desktopDevice = this.appService.desktopDevice
  }

  selectStoryContent(item: StoryContent){
    this.storyService.currentInput.next(item)

    if(this.currentInput?.type === 'text') {
      this.textEditionActivated.emit(true)
      this.storyService.textToolSelected.next(true)}
  }

  getTextDeleted(){
    console.log('getTextDeleted')   
    this.contents.forEach((c, index) => {
      if(c.type === 'text') {
        this.contents.splice(index, 1)
      }
    })

    this.storyService.contents.next(this.contents)
    this.storyService.textToolSelected.next(true)
  }

  getResizeValue(value: string){
    if(this.currentInput?.type === 'text'){
      this.resizeText(value)
    } else {
      this.resizeVisual(value)
    }
  }

  resizeText(value: string){
    console.log('resizeText')
    this.textTransformData.size = Math.floor(parseInt(value)/3)

    if(!this.currentInput) return
    this.currentInput.newData = {
      ...this.currentInput?.newData, 
      size: this.textTransformData.size
    }

    this.contents.forEach(c => {
      if(c.id == this.currentInput?.id){
        c.newData = this.currentInput.newData
      }
    })

    this.storyService.currentInput.next(this.currentInput)
    this.storyService.contents.next(this.contents)
  }

  resizeVisual(value: string){
    this.visualTransformData.width = parseInt(value)

    if(this.currentInput?.initialData.height === this.currentInput?.initialData.width){
      this.visualTransformData.height = parseInt(value)
    } else {
      if(!this.currentInput?.initialData?.width || !this.currentInput.initialData.height) return
      
      const ratio = this.currentInput.initialData.height / this.currentInput?.initialData?.width
      this.visualTransformData.height = parseInt(value) * ratio
    }
    
    if(!this.currentInput) return
    this.currentInput.newData = {
      ...this.currentInput?.newData, 
      width: this.visualTransformData.width, 
      height: this.visualTransformData.height,
    }
  }

  getAngleValue(value: string){
    this.textTransformData.angle = parseInt(value)

    if(!this.currentInput) return
    this.currentInput.newData = {...this.currentInput?.newData, angle: this.textTransformData.angle}
  }

  deleteVisual(){
    this.contents.forEach((c, index) => {
      if(c.id === this.currentInput?.id) {
        this.contents.splice(index, 1)
      }
    })

    this.storyService.contents.next(this.contents)
  }

  // selectVisual(visual: StoryContent){
  //   console.log('selectVisual')
  //   console.log(visual)
  //   this.selectedContentId.next(visual.id)
  //   this.storyService.visualToolSelected.next(true)
  // }

  /* gestion de déplacement du visuel en mobile */
  onTouchStart(event: TouchEvent){
    this.mobileTouchStartPosition.x = event.changedTouches[0].clientX
    this.mobileTouchStartPosition.y = event.changedTouches[0].clientY
  }

  onTouchMove(){}

 /*  onTouchEnd(event: any){
    if(this.mobileTouchStartPosition.x === event.changedTouches[0].clientX && this.mobileTouchStartPosition.y === event.changedTouches[0].clientY) {
      return
    }

    if(Math.abs(event.changedTouches[0].clientX - this.mobileTouchStartPosition.x) < 5 && Math.abs(event.changedTouches[0].clientY - this.mobileTouchStartPosition.y) < 5) {
      return
    }

    this.visualTransformData.x = `${Math.floor(event.changedTouches[0].clientX - this.visualTransformData.width / 2)}px`
    this.visualTransformData.y = `${Math.floor(event.changedTouches[0].clientY - this.visualTransformData.height / 2)}px`

    this.contents.forEach(c => {
      if(c.id == event.target.id){
        c.newData = {
          ...c.newData,
          x: this.visualTransformData.x,
          y: this.visualTransformData.y
        }
      }
    })

    this.storyService.contents.next(this.contents)
  } */
  /* gestion de déplacement du visuel en mobile */

  /* gestion de déplacement du visuel en desktop */
  onDragStart() {}

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent) {
    console.log('ondrop contents container')

    this.visualTransformData.x = `${event.clientX - (this.visualTransformData.width / 2 + this.bounds.left)}px`
    this.visualTransformData.y = `${event.clientY - (this.visualTransformData.height / 2 + this.bounds.top)}px`

    if(!this.currentInput) return

    this.currentInput.newData = {
      x: this.visualTransformData.x,
      y: this.visualTransformData.y
    }

    this.contents.forEach((v) => {
      if(v.id === this.currentInput?.id){
        v.newData = {
          x: this.visualTransformData.x,
          y: this.visualTransformData.y
        }
      }
    })
    this.storyService.contents.next(this.contents)
  }
  /* gestion de déplacement du visuel en desktop */









}
