import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppService } from '../../app.service';

import { City } from '../interfaces/city';

@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  constructor(
    private http: HttpClient, 
    private appService: AppService,
  ) {}

  getCitiesByName(value: string): Observable<City[]> {
    const header = {
      headers: new HttpHeaders ({
        'Accept': 'application/x.nda.v5+json'
      })
    };

    return this.http
      .get<{ datas: { cities: City[] } }>(`${this.appService.API}/api/cities/name/${value}/fr/fr`, header) // /fr/fr: /lang/country
      .pipe(
        map((res) => {
          return res.datas.cities;
        }),
        catchError((error) => {
          return throwError(() => error)
        }),
      );
  }
}
