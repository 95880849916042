import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppService } from '../../app.service';

import { ErrorReturn } from '../interfaces/errorReturn';

@Component({
  selector: 'app-popup-warning',
  standalone: true,
  imports: [],
  templateUrl: './popup-warning.component.html',
  styleUrl: './popup-warning.component.scss'
})
export class PopupWarningComponent implements OnInit {
  @Input() errorValue!: ErrorReturn | null;
  
  token = null;

  constructor(
    private appService: AppService, 
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params)
      //this.token = params.SBtoken;
    });
  }

  closeError() {
    this.appService.setPopin(null);
  }
}
