import { Component, ElementRef, ViewChild, OnInit, ViewChildren, QueryList, OnDestroy, AfterViewChecked } from '@angular/core';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, Location, SlicePipe, TitleCasePipe } from '@angular/common';
import { FormsModule, Validators, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';

import { AppService } from '../../app.service';
import { AuthService } from '../../auth.service';
import { PhotoService } from '../../photo/photo.service';
import { HomeService } from '../../home/home.service';
import { MySpaceService } from '../my-space.service';

import { LoaderComponent } from '../../shared/loader/loader.component';
import { PhotoCropComponent } from '../../shared/photo-crop/photo-crop.component';
import { BirthdateInputComponent } from '../../shared/birthdate-input/birthdate-input.component';
import { AttractionInputComponent } from '../../shared/attraction-input/attraction-input.component';
import { CityInputComponent } from '../../shared/city-input/city-input.component';
import { FooterComponent } from '../../footer/footer.component';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { TopButtonComponent } from '../../shared/top-button/top-button.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { ClickAnywhereDirective } from '../../click-anywhere.directive';

import { User } from '../../shared/interfaces/user';
import { City } from '../../shared/interfaces/city';
import { Photo } from '../../shared/interfaces/photo';
import { Gender } from '../../shared/enum/Gender';
import { firstnameValidator } from '../../shared/validators/firstnameValidator';
import { Hobby } from '../../shared/interfaces/hobby';

@Component({
  selector: 'app-my-profile',
  standalone: true,
  imports: [
    LoaderComponent, 
    PhotoCropComponent, 
    TitleCasePipe, 
    FormsModule, 
    ReactiveFormsModule, 
    CityInputComponent, 
    BirthdateInputComponent, 
    AttractionInputComponent, 
    RouterModule,
    SlicePipe,
    CommonModule,
    FooterComponent,
    ErrorDialogComponent,
    ClickAnywhereDirective,
    TopButtonComponent,
    GoBackButtonComponent
  ],
  templateUrl: './my-profile.component.html',
  styleUrl: './my-profile.component.scss'
})
export class MyProfileComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('fileUpload') fileUpload!: ElementRef
  @ViewChildren('contextualMenuClosed') contextualMenusClosed!: QueryList<ElementRef>
  @ViewChildren('contextualMenuOpened') contextualMenusOpened!: QueryList<ElementRef>
  @ViewChild('cropper') cropper!: ElementRef
  @ViewChild('top') top!: ElementRef
  @ViewChild('cropperContainer') cropperContainer!: ElementRef
  @ViewChild('hobbiesListContainer') hobbiesListContainer!: ElementRef
  
  loading = false
  deleting = false
  me!: User
  user!: User
  photos: (Photo | null)[] = []

  fileList: FileList | null = null
  tempCropperPhotoInBase64!: string
  showCropper!: { 
    data: File,
    temp?: string,
    size: { height: number; width: number; }
  } | null
  croppedImage: string = ''
  cropData!: { x: number; y: number; height: number; width: number; preview: string; }

  editInformationMode = false

  editProfileInformationFormGroup!: FormGroup
  displayCreatingLoader = false
  userAge!: number
  newPhotoIndex!: number
  selectedAttraction = 'woman'
  selectedCity: City | null = null

  day = ''
  month = ''
  year = ''

  firstnameError = false
  dateError = false
  errorMessage: string | null = null
  errorTitle: string | null = null

  photoContainerDimensions!: {width: number, height: number}
  loaderSubscription!: Subscription
  desktopDevice = false

  hobbies: Hobby[] = []
  hobbyWidth!: number

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private imageCompress: NgxImageCompressService,
    private photoService: PhotoService,
    private router: Router,
    private location: Location,
    private homeService: HomeService,
    private mySpaceService: MySpaceService
  ){}

  ngOnInit(){
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    this.desktopDevice = this.appService.desktopDevice

    const userStoredLocally = this.authService.get('user')

    if(userStoredLocally) {
      this.user = JSON.parse(this.authService.get('user'))

      for (let i = 0; i < 6; i++) {
        if(!this.user.photos?.[i]){
          this.photos.push(null)
        } else {
          this.photos.push(this.user.photos[i])
        }
      }

      this.authService.getProfile(this.user.id).subscribe(res => {
        this.user = res

        if(this.user.birthdate){
          const [y,m,d] = this.user.birthdate.split('-')
          const date = `${y}-${m}-${d}`
          const birthDate = dayjs(date)
    
          this.userAge = dayjs().diff(birthDate, 'year')
        }
      })

      this.day = this.user.birthdate.substring(8)
      this.month = this.user.birthdate.substring(5,7)
      this.year = this.user.birthdate.substring(0,4)

      this.editProfileInformationFormGroup = new FormGroup({
        firstnameCtrl: new FormControl(this.user.firstname || '', [Validators.required, Validators.minLength(2), Validators.maxLength(25), firstnameValidator()]),
        birthDayCtrl: new FormControl(this.day || '', [Validators.required]),
        birthMonthCtrl: new FormControl(this.month || '', [Validators.required]),
        birthYearCtrl: new FormControl(this.year || '', [Validators.required]),
        attractionCtrl: new FormControl(this.user.attraction || this.user.sex === 'man' ? Gender.woman : Gender.man || '', [Validators.required]),
        descriptionCtrl: new FormControl(this.user.description || '', [Validators.required])
      })

      this.editProfileInformationFormGroup.get('firstnameCtrl')?.statusChanges.subscribe(() => {
        this.firstnameError = false
  
        if(!this.editProfileInformationFormGroup.get('firstnameCtrl')?.errors) {
          console.log('pas d erreur')
        }
  
        if(this.editProfileInformationFormGroup.get('firstnameCtrl')?.errors){
          this.errorTitle = 'Invalid first name'
          this.errorMessage = 'Your first name should contain neither number, space nor special characters.'
          this.firstnameError = true
        }
      })
    }

    // récupération des hobbies depuis le service
    this.hobbies = this.mySpaceService.selectedHobbies
  }

  ngAfterViewChecked(){
    if(this.desktopDevice && this.hobbiesListContainer){
      const bounds = this.hobbiesListContainer.nativeElement.getBoundingClientRect()
      const hobbiesContainerWidth = bounds.right - bounds.left

      this.hobbyWidth = hobbiesContainerWidth / 5 - 16
    } else {
      const screenWidth = window.screen.width

      this.hobbyWidth = (screenWidth-20) / 3 - 16
    }
  }

  getStyles() {
    return {
      'width': this.hobbyWidth + 'px',
      'height': this.hobbyWidth + 'px',
    }
  }

  editHobbies(){
    this.router.navigate(['home/my-space/my-profile/my-hobbies'])
  }

  // ngAfterViewInit(){
  //   const bounds = this.cropperContainer.nativeElement.getBoundingClientRect()

  //   console.log(bounds)

  //   this.photoContainerDimensions = {
  //     width: bounds.width,
  //     height: bounds.height
  //   }
  // }

  goBack(){
    if(this.desktopDevice) {
      this.homeService.pageDisplayed.next('')
      this.location.back()
    } else {
      this.homeService.pageDisplayed.next('')
    }
  }

  openFileInput(index: number){
    this.newPhotoIndex = index
    this.fileUpload.nativeElement.click()
  }

  onPhotoInputChange(event: Event){
    console.log('onPhotoInputChange')
    const element = event.currentTarget as HTMLInputElement;
    this.fileList = element.files;

    if(element.files && element.files.length) {
      const file = element.files[0] // File
      const reader = new FileReader()

      reader.onload = () => {
        const img = new Image()
   
        img.onload = () => {
          // redimensionne l'image temporaire si sa taille est supérieure à 1024px
          if(img.width > 1024 || img.height > 1024){
            this.imageCompress.compressFile(img.src, DOC_ORIENTATION.Default, undefined, undefined, 1024, 1024).then((r)=>{
              this.tempCropperPhotoInBase64 = r

              this.showCropper = { 
                data: file,
                temp: this.tempCropperPhotoInBase64,
                size: { height: 400, width: 400 }
              }

              this.cropper.nativeElement.scrollIntoView()
            })
          } else {
            this.tempCropperPhotoInBase64 = img.src

            this.showCropper = { 
              data: file,
              temp: this.tempCropperPhotoInBase64,
              size: { height: 400, width: 400 }
            }

              this.cropper.nativeElement.scrollIntoView()
          }
        }
       
        img.src = reader.result as string
        this.photoService.photoToCropInBase64 = img.src
      }

      reader.onloadend = () => {this.router.navigate(['/home/my-space/my-profile/crop'])}

      reader.readAsDataURL(file)
      
      // reset l'event
      element.value = ''
    }
  }

  openMenu(index: number){
    // fermer tous les contextual menus avant d'ouvrir celui demandé => à revoir
    this.contextualMenusOpened.forEach(m => {
      m.nativeElement.classList.remove('show', 'hide')
    })

    this.contextualMenusClosed.get(index)?.nativeElement.classList.add('hide')
    this.contextualMenusOpened.get(index)?.nativeElement.classList.add('show')
  }

  discardPhoto(id?: number, index?: number){
    this.deleting = true

    if(index){
      this.contextualMenusClosed.get(index)?.nativeElement.classList.add('show')
      this.contextualMenusOpened.get(index)?.nativeElement.classList.add('hide')
    }

    this.fileList = null
    this.photoService.cropper();
    this.showCropper = null;

    if(!id) return

    this.photoService.deletePhoto(id).subscribe(res => {
      this.user = res
      this.photos = []

      for (let i = 0; i < 6; i++) {
        if(!res.photos?.[i]){
          this.photos.push(null)
        }else{
          this.photos.push(res.photos[i])
        }
      }

      this.deleting = false
    }) 
  }

  getUserCroppedImage(event: string){
    this.croppedImage = event
  }

  getClickNature(clickNature: string){
    if(clickNature === 'anywhere' && (this.firstnameError === true || this.dateError === true)){
      this.firstnameError = false
      this.dateError = false
    }
  }

  getScrollRequest(request: string){
    if(request === 'scrollToTop') this.top.nativeElement.scrollIntoView()
  }

  getBirthdateError(error: {errorTitle: string; errorMessage: string;} | null){
    if(error) {
      this.dateError = true
      this.errorTitle = error.errorTitle
      this.errorMessage = error.errorMessage
    }
  }

  getCity(city: City | null){
    this.selectedCity = city
  }
  
  getCropDetails(event: { x: number; y: number; height: number; width: number; preview: string; }){
    this.cropData = event
  }

  saveCropData() {
    console.log('saveCropData de my profile')
    const tempCropperPhotoInBase64Arr = this.tempCropperPhotoInBase64.split(',')

    if(!this.showCropper?.temp){ throw new Error('Source image is missing') }

    this.appService.setLoader(true)
    this.photoService.cropper()

    // étape 2 : envoyer la photo en base64
    const data: {
      origin: string //image destination en base64
    } = {
      origin: tempCropperPhotoInBase64Arr[1]
    }

    // crop image on front
    const img = new Image()
    img.src = this.tempCropperPhotoInBase64
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if(img && ctx){
      img.onload = () => {
        ctx.drawImage(img,this.cropData.x,this.cropData.y,this.cropData.width,this.cropData.height,0,0,50,50)
       
        //const croppedImg = canvas.toDataURL('image/jpeg')
      }
    }
    // crop image on front

    const token = this.authService.get('token')

    this.photoService.saveOriginalPhoto(data, token).subscribe(async(res) => {
      if(res.photo.id){
        // étape 3 : cropper la photo
        const croppedImageArr = this.croppedImage.split(',')
        const photoId = res.photo.id

        this.photoService.cropPhoto(data.origin, croppedImageArr[1], this.cropData,photoId).subscribe(res => {
          if(!res) return
         
          this.appService.setLoader(false)

          this.authService.getProfile(this.user.id).subscribe(async(res) => {
            this.user = res

            this.photos = []

            for (let i = 0; i < 6; i++) {
              if(!res.photos?.[i]){
                this.photos.push(null)
              } else {
                this.photos.push(res.photos[i])
              }
            }

            this.authService.setAuthMe(this.user)

            this.top.nativeElement.scrollIntoView()
          })

          this.showCropper = null
        })
      } else if(res.error?.message) {
        // this.errorTitle = 'Subscription failed'
        // this.errorMessage = res.error?.message
        // this.openDialog = true
      } 
    })
  }

  editProfile(){
    this.editInformationMode = true
  }

  onSubmit() {
    console.log('onsubmit my profile')
    const data = this.editProfileInformationFormGroup.value
    // format de birthdate : 2005-12-08
    const dateString = `${data.birthdate.birthYearCtrl.toString()}-${data.birthdate.birthMonthCtrl}-${data.birthdate.birthDayCtrl < 10 ? '0' + data.birthdate.birthDayCtrl : data.birthdate.birthDayCtrl}`
    
    this.authService.setSubscriptionFormData('firstname', data.firstnameCtrl)
    this.authService.setSubscriptionFormData('birthdate', dateString)
    this.authService.setSubscriptionFormData('city', data.cityCtrl)
    this.authService.setSubscriptionFormData('sex', this.selectedAttraction)
    this.authService.setSubscriptionFormData('description', data.descriptionCtrl)
    if(this.selectedCity){
      this.authService.setSubscriptionFormData('city', this.selectedCity.id)
    }
    
    // this.authService.set('sex', data.attractionCtrl)
    // this.authService.set('birthdate', dateString)

    // mettre à jour le user
    this.authService.authUpdate().subscribe(res => {
      this.user = res
      this.editInformationMode = false
    })
  }

  getCitySet($event: City | { id: number | null; name: string | null; }){
    console.log($event)
    this.editProfileInformationFormGroup.controls['locationCtrl'].setValue($event.id)
  }

  cancel($event: Event){
    $event.stopPropagation()
    console.log('cancel')
    this.editInformationMode = false
    
    /* réinitialise le formulaire avec ses valeurs initiales */
    this.editProfileInformationFormGroup.controls['firstnameCtrl'].setValue(this.user.firstname || '')
    this.editProfileInformationFormGroup.controls['birthDayCtrl'].setValue(this.day || '')
    this.editProfileInformationFormGroup.controls['birthMonthCtrl'].setValue(this.month || '')
    this.editProfileInformationFormGroup.controls['birthYearCtrl'].setValue(this.year || '')
    this.editProfileInformationFormGroup.controls['attractionCtrl'].setValue(this.user.attraction || this.user.sex === 'man' ? Gender.woman : Gender.man || '')
    this.editProfileInformationFormGroup.controls['descriptionCtrl'].setValue(this.user.description || '')
  }

  // getBirthdate($event: {day: string, month: string, year: string}){
  //   console.log($event)
  // }

  getAttraction(gender: Gender.man | Gender.woman){
    this.selectedAttraction = gender
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
