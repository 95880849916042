<button class="text-btn" (click)="dialogRef.close('flag')">
  <img src="assets/icons/icon_flag.svg" alt="" class="icon">
  <ng-container i18n="Text for the item to flage the story in the story player menu@@FLAG_STORY">Flag this story</ng-container>
</button>
<button class="text-btn" (click)="dialogRef.close('block')">
  <img src="assets/icons/icon_block.svg" alt="" class="icon">
  <ng-container i18n="Text for the item to block the user in the story player menu@@BLOCK_USER">Block the user</ng-container>
</button>
<button class="text-btn" (click)="dialogRef.close('report')">
  <img src="assets/icons/icon_report_block.svg" alt="" class="icon">
  <ng-container i18n="Text for the item to block and report the user in the story player menu@@BLOCK_REPORT_USER">Block and Report the user</ng-container>
</button>
