import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { AuthService } from '../../auth.service';
import { PhotoService } from '../photo.service';
import { LoaderService } from '../../loader.service';

import { HeaderComponent } from '../../header/header.component';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { ProgressBarComponent } from '../../shared/progress-bar/progress-bar.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-upload-photo',
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent, LoaderComponent, ProgressBarComponent, GoBackButtonComponent],
  providers: [NgxImageCompressService],
  templateUrl: './upload-photo.component.html',
  styleUrl: './upload-photo.component.scss'
})
export class UploadPhotoComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() selector = { value: 0, name: 'account' }
  @Input() flow!: string
  
  @ViewChild('fileUpload') fileUpload!: ElementRef
  @ViewChild('photoContainer') photoContainer!: ElementRef

  loading$ = this.loaderService.loading$
  menu = ['account', 'photos', 'contacts', 'legal']
  fileList: FileList | null = null
  showCropper!: { 
    data: File,
    temp?: string,
    size: { height: number; width: number; }
  } | null
  tempCropperPhotoInBase64!: string
  user!: User | null
  firstname = ''
  userId!: number
  hideSaveButton = false

  photoUrl!: string | undefined

  showHelp = false
  helpIsOpened = false
  subscriptionInProgress = false

  hightlightedSteps: {
    first: boolean, 
    second?: boolean, 
    third?: boolean, 
    fourth?: boolean
  } = { first: true, second: true, third: true, fourth: true }

  photoContainerDimensions!: {width: number, height: number}
  private readonly _destroyed = new Subject<void>()

  constructor( 
    private authService: AuthService,
    private imageCompress: NgxImageCompressService,
    public router: Router,
    private photoService: PhotoService,
    private route: ActivatedRoute,
    private loaderService: LoaderService
  ){
    this.authService.authMe$.subscribe(res => {
      this.user = res
    })

    this.firstname = this.authService.get('firstname')
  }

  ngOnInit(){
    console.log('upload photo')
    this.loaderService.show()

    this.photoService.cropperState().pipe(
      takeUntil(this._destroyed)
    ).subscribe((res) => {
      this.showCropper = res
    })

    if(!this.authService.authMe$.value && this.authService.get('user')){
      const userStoredLocally = JSON.parse(this.authService.get('user'))
      if(userStoredLocally) this.userId = userStoredLocally.id
    }

    this.route.url.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      if(res[0].path === 'subscribe-step-four'){
        this.subscriptionInProgress = true
      }
    })
  }

  ngAfterViewChecked(){
    if(!this.photoContainer.nativeElement) return
      
    this.photoContainer.nativeElement.getBoundingClientRect()
    // this.photoContainerDimensions = {
    //   width: bounds.width,
    //   height: bounds.height
    // }

    this.loaderService.hide()
  }

  openFileInput(){
    this.fileUpload.nativeElement.click()
  }

  onPhotoInputChange(event: Event){
    console.log('onPhotoInputChange')
    const element = event.currentTarget as HTMLInputElement
    this.fileList = element.files

    if(element.files && element.files.length) {
      const file = element.files[0] // File
      const reader = new FileReader()

      reader.onload = () => {
        const img = new Image()
   
        img.onload = () => {
          // redimensionne l'image temporaire si sa taille est supérieure à 1024px
          if(img.width > 1024 || img.height > 1024){
            this.imageCompress.compressFile(img.src, DOC_ORIENTATION.Default, undefined, undefined, 1024, 1024).then((r)=>{
              this.tempCropperPhotoInBase64 = r

              this.showCropper = { 
                data: file,
                temp: this.tempCropperPhotoInBase64,
                size: { height: 340, width: 340 }
              }

              this.photoService.photoToCropInBase64 = r
              this.photoService.showCropper = this.showCropper
              if(this.router.url.includes('subscribe')){
                this.router.navigate(['/subscribe-step-four/crop'])
              } else {
                this.router.navigate(['/upload-photo/crop'])
              }
            })
          } else {
            this.tempCropperPhotoInBase64 = img.src

            this.showCropper = { 
              data: file,
              temp: this.tempCropperPhotoInBase64,
              size: { height: 340, width: 340 }
            }

            this.photoService.showCropper = this.showCropper
            this.photoService.photoToCropInBase64 = this.tempCropperPhotoInBase64
            if(this.router.url.includes('subscribe')){
              this.router.navigate(['/subscribe-step-four/crop'])
            } else {
              this.router.navigate(['/upload-photo/crop'])
            }
          }

          this.hideSaveButton = true
        }
       
        img.src = reader.result as string
      }

      reader.readAsDataURL(file)
      
      // reset l'event
      element.value = ''
    }
  }

  // getUserCroppedImage(imageInBase64: string){
  //   this.croppedImage = imageInBase64
  // }
  
  // getCropDetails(cropData: {height: number, width: number, x: number, y: number, preview?: string}){
  //   console.log('getCropDetails', cropData)
  //   this.cropData = cropData
  // }

  // saveCropData() {
  //   console.log('saveCropData', this.cropData)
  //   this.loaderService.show()
  //   const tempCropperPhotoInBase64Arr = this.tempCropperPhotoInBase64.split(',')

  //   // /!\ this.showCropper.temp n'est pas défini
  //   if(!this.showCropper?.temp){ throw new Error('Source image is missing') }

  //   this.photoService.cropper()

  //   // étape 2 : envoyer la photo en base64
  //   const data: {
  //     origin: string //image destination en base64
  //   } = {
  //     origin: tempCropperPhotoInBase64Arr[1]
  //   }

  //   // crop image on front
  //   const img = new Image()
    
  //   const canvas = document.getElementById('canvas') as HTMLCanvasElement
  //   if(!canvas) return
  //   const ctx = canvas.getContext('2d')

  //   if(img && ctx){
  //     console.log(img)
  //     img.onload = () => {
  //       //ctx.drawImage(img, 23, 23, canvas.width, canvas.height, 0, 0, 200, 200)
  //       ctx.drawImage(img,this.cropData.x,this.cropData.y,this.cropData.width,this.cropData.height,0,0,200,200)
       
  //       //const croppedImg = canvas.toDataURL('image/jpeg');
  //     }

  //     img.src = this.tempCropperPhotoInBase64
  //   }
  //   // crop image on front

  //   const token = this.authService.get('token')

  //   this.photoService.saveOriginalPhoto(data, token).pipe(
  //     takeUntil(this._destroyed)
  //   ).subscribe(res => {
  //     if(res.photo.id){
  //       // étape 3 : cropper la photo
  //       const croppedImageArr = this.croppedImage.split(',')
  //       const photoId = res.photo.id

  //       console.log(this.cropData)
  //       this.photoService.cropPhoto(data.origin, croppedImageArr[1], this.cropData,photoId).pipe(
  //         takeUntil(this._destroyed)
  //       ).subscribe((res: Photo | undefined) => {
  //         //this.photoService.croppedPhotoUrl.subscribe(res => this.photoUrl = res)

  //         if(!res) return
  //         console.log(res)
  //         this.photoService.croppedPhotoUrl.next(res.url)

  //         this.photoUrl = res.url
  //         this.photoService.photoCropped.next(true)
  //         this.authService.savedImageUrl$.next(res.url)
  //         this.loaderService.hide()

  //         this.router.navigate(['/photo'])
  //       })
  //     } else if(res.error?.message) {
  //       this.errorTitle = 'Subscription failed'
  //       this.errorMessage = res.error?.message
  //       this.openDialog = true
  //     } 
  //   })
  // }

  discardPhoto(){
    this.fileList = null
    this.photoService.cropper();
    this.showCropper = null;
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
