<section class="my-account">
  @if(loading$ | async){
    <app-loader></app-loader>
  }

  <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>  

  <router-outlet></router-outlet>

  <div class="top" #top>
    <h3><span class="regular">My</span> Account</h3>
  </div>

  <div class="content">
    <div class="item">
      <div class="header">
        <h4>My Email</h4>
        <button type="button" class="edit-btn" [routerLink]="['edit-email']">
          <img src="../../../assets/icons/icon_edit.svg" alt="" class="icon-edit">
        </button>
      </div>

      <div class="input">
        {{user?.email}}
      </div>
    </div>
    
    <div class="item">
      <div class="header">
        <h4>My Password</h4>
        <button type="button" class="edit-btn" [routerLink]="['edit-password']">
          <img src="../../../assets/icons/icon_edit.svg" alt="" class="icon-edit">
        </button>
      </div>

      <input type="password" value="password">
    </div>

    <div class="bottom">
      <button type="button" class="text-link" [routerLink]="['delete']">Delete My Account</button>
    </div>
  </div>
</section>
  