<div class="bottom">
    <div class="actions">
        <button class="submit pagination" [disabled]="currentPage === 1" (click)="showStories('previous')">
            <span class="material-symbols-rounded">
                arrow_left_alt
            </span>
        </button>
        <button class="text-btn" [disabled]="currentPage === totalPages" (click)="showStories('next')">
            More stories
            <span class="material-symbols-rounded">
                arrow_right_alt
            </span>
        </button>
    </div>
    
    <div class="pagination-container">
        <div class="pagination">Page <div class="current">{{currentPage}}</div> of {{totalPages}}</div>
    </div>
</div>
