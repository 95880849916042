<div class="crop-holder">
  <div class="cancel" [ngStyle]="{display: origin === 'upload' ? 'none' : 'block'}">
    <button type="button" (click)="discardPhoto()">
      <span class="material-symbols-rounded arrow-left">
        arrow_left_alt
      </span>
    </button>
  </div>


  <div class="cropper" class="row">
    <div class="col-sm-6">
      <div class="img-container"
        [ngStyle]="{
          width: isDesktop() && origin === '' ? 400 : isDesktop() && origin === 'upload' ? 340 : 250, 
          height: isDesktop() && origin === '' ? 400 : isDesktop() && origin === 'upload' ? 340 : 250,
          maxWidth: '100%'
        }"
      >
        <img #image [src]="uploadedPhotoSrc" crossorigin alt="">
      </div>
    </div>
  </div>
  
  <div class="submit-btn-container">
    <button type="submit" class="submit" (click)="saveCropData()">
      <ng-container i18n="Text for the button to save the image cropping@@SAVE">
        Save
      </ng-container>
    </button>
  </div>
</div>