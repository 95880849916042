import { Injectable } from '@angular/core';
import { Hobby } from '../shared/interfaces/hobby';

@Injectable({
  providedIn: 'root'
})
export class MySpaceService {
  hobbies: Hobby[] = []
  selectedHobbies: Hobby[] = []

  constructor() {}

  addHobby(hobby: Hobby){
    this.selectedHobbies.push(hobby)
  }

  removeHobby(hobby: Hobby){
    const index = this.selectedHobbies.indexOf(hobby)

    this.selectedHobbies.splice(index, 1)
  }
}
