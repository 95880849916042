@if(origin !== 'main'){
  <button class="submit" (click)="dialogRef.close('main')">
    <span class="material-symbols-rounded check">
      check
    </span>
    Set as a Main Photo
  </button>
}
<button class="submit" (click)="dialogRef.close('delete')">
  <span class="material-symbols-rounded delete">
    delete
  </span>
  Delete the Photo
</button>
<button class="text-btn" (click)="dialogRef.close('cancel')">
  Cancel
</button>