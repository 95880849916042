<div class="cropped-photo">
    <div class="greeting">
        Hello <div class="firstname">{{user?.firstname}}!</div>
    </div>

    <div class="photo-container">
      @if(loading){
        <app-loader></app-loader>
      }
      @if(user?.main_photo?.url){
        <img [src]="user?.main_photo?.url" alt="Image">
      }
    </div>

    <div class="submit-btn-container" #submitBtnContainer>
        <div class="check" #check>
            <span class="material-symbols-rounded">
                done
            </span>
        </div>
            
        <div class="discover" #discover routerLink="/home" routerLinkActive="router-link-active" >
            Discover your perfect match
        </div>
    </div>
</div>
<router-outlet></router-outlet> 
