import { Component, ViewEncapsulation, OnInit, ViewChild, ElementRef, AfterViewChecked, OnDestroy, Output, EventEmitter, inject } from '@angular/core';
import { CarouselModule, CarouselComponent } from 'ngx-carousel-ease';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { filter, map, share, Subject, switchMap, takeUntil } from 'rxjs';
import dayjs from 'dayjs';
import { Dialog } from '@angular/cdk/dialog';

import { ProfileService } from '../profile.service';
import { HomeService } from '../../home/home.service';
import { EncountersService } from '../../new-encounters/encounters.service';
import { ChatService } from '../../chat/chat.service';
import { AuthService } from '../../auth.service';
import { LayoutService } from '../../layout.service';

import { InfoDialogComponent } from '../../dialog/info-dialog/info-dialog.component';
import { ProfileDialogComponent } from '../../dialog/profile-dialog/profile-dialog.component';
import { BlockUserDialogComponent } from '../../dialog/block-user-dialog/block-user-dialog.component';
import { ReportUserDialogComponent } from '../../dialog/report-user-dialog/report-user-dialog.component';
import { UnmatchDialogComponent } from '../../dialog/unmatch-dialog/unmatch-dialog.component';
import { MatchDialogComponent } from '../../dialog/match-dialog/match-dialog.component';
import { LoaderComponent } from '../../shared/loader/loader.component';

import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-profile-space',
  standalone: true,
  imports: [CarouselModule, CarouselComponent, RouterModule, CommonModule, InfoDialogComponent, MatchDialogComponent, LoaderComponent],
  templateUrl: './profile-space.component.html',
  styleUrl: './profile-space.component.scss',
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    'class': 'space'  // <-- It should be unique for each component you have encapsulation off
  }
})
export class ProfileSpaceComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('hobbiesContainer') hobbiesContainer!: ElementRef
  @Output() emitInfoDialogType = new EventEmitter<string>()

  profile!: User | null
  userAge!: number
  hobbyWidth!: number
  infoDialogType = ''
  relationshipStatus = ''
  dialog = inject(Dialog)
  match = false
  showWaitingStatus = false
  futureRelationshipStatus = ''
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop
  profileLoading = false
  notSubscribed = false
  showInfoDialog = false
  user!: User | null

  constructor(
    private profileService: ProfileService,
    private homeService: HomeService,
    private encountersService: EncountersService,
    private chatService: ChatService,
    private router: Router,
    private authService: AuthService,
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.profileLoading = true

    this.profileService.profileId.pipe(
      takeUntil(this._destroyed),
      map(id => {
        return id
      }),
      switchMap((id: number| null) => {
        return this.authService.getProfile(id!)
      }),
      share(),
      map((user: User) => {
        this.profile = user
        this.profileService.profile.next(user)

        if(!this.profile) return
  
        if(this.profile && this.profile.birthdate){
          const [y,m,d] = this.profile.birthdate.split('-')
          const date = `${y}-${m}-${d}`
          const birthDate = dayjs(date)
    
          this.userAge = dayjs().diff(birthDate, 'year')
        }
      }),
      switchMap(() => this.encountersService.getRelationshipStatus(this.profile!.id))
    ).subscribe((status: string) => {
      this.profileLoading = false
      this.relationshipStatus = status

      if(this.relationshipStatus === 'waiting') this.showWaitingStatus = true
    })

    this.authService.authMe.subscribe(res => this.user = res)
  }

  ngAfterViewChecked(){
    if(this.isDesktop() && this.hobbiesContainer){
      const bounds = this.hobbiesContainer.nativeElement.getBoundingClientRect()
      const hobbiesContainerWidth = bounds.right - bounds.left
      this.hobbyWidth = (hobbiesContainerWidth) / 4 - 8
    } else {
      const screenWidth = window.screen.width
      this.hobbyWidth = (screenWidth - 48) / 3 - 8
    }
  }

  getStyles() {
    return {
      'width': this.hobbyWidth + 'px',
      'height': this.hobbyWidth + 'px',
    }
  }

  closeDialog(event: boolean){
    this.homeService.dialogDisplayed.next(!event)
    this.infoDialogType = ''
  }

  openProfileDialog(e: Event) {
    e.stopPropagation()
    this.dialog.open(ProfileDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe((res) => {
      console.log(res)
      if(res === 'unmatch'){
        this.unmatchDialog()
      } else if(res === 'block'){
        this.blockUserDialog()
      } else {
        this.blockAndReportUserDialog()
      }
    })
  }

  unmatchDialog() {
    this.dialog.open(UnmatchDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.profile) return

      this.homeService.deleteRelationship(this.profile.id).subscribe(res => {
        console.log(res)
      })
    })
  }

  matchDialog() {
    this.dialog.open(MatchDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
      data: this.profile
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.profile) return

      this.contactProfile(this.profile.id)
    })
  }

  blockUserDialog() {
    this.dialog.open(BlockUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.profile) return
      this.homeService.updateRelationship(this.profile.id, 'blocked').subscribe(res => console.log(res))
    })
  }

  blockAndReportUserDialog() {
    this.dialog.open(ReportUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.profile) return

      this.homeService.createReportBetweenUsers(this.profile.id, 'user').subscribe(() => {
        if(!this.profile) return
        this.homeService.updateRelationship(this.profile.id, 'blocked_with_report').subscribe(res => console.log(res))
      })
    })
  }

  rejectProfile(userId: number){
    console.log('rejectProfile')
    let state = 'refused'

    // refuse si relation est waiting. Si déjà refused, passe à rejected
    this.encountersService.getRelationshipStatus(userId).subscribe(res => {
      console.log(res)
      if(res === "rejected") state = 'rejected'
    })

    this.homeService.updateRelationship(userId, state).subscribe()
  }

  likeProfile(userId: number){
    console.log('likeProfile ', userId)

    // le user peut liker jusqu'à 5 fois sans être abonné. Si le user n'est pas abonné et qu'il a déjà liké 5 fois, on affiche la popup. 
    // En cliquant sur le bouton, le user sera redirigé vers la landing page.

    // si le user est déjà abonné, on met à jour la relation
    let state = ''
    if(this.relationshipStatus === 'notfriend'){// la relation n'existe pas
      state = 'accepted'
      this.futureRelationshipStatus = 'waiting'// le user connecté like un autre profil
    } else if (this.relationshipStatus === 'pending') {// le profil du user connecté a été liké
      state = 'validated'
      this.futureRelationshipStatus = 'friend'
    }
    
    this.homeService.updateRelationship(userId, state).pipe(
      switchMap(() => {
        console.log('return switch map')
        // si la relation était à waiting, elle passe à accepted/pending. Si elle était à pending, elle passe à validated/friend
        return this.encountersService.getRelationshipStatus(this.profile!.id)})
    ).subscribe({
      next: (res) => {
        this.relationshipStatus = res
        this.profileService.setProfileLiked(this.profile)
      }, 
      error: (err) => {
        if(err.error.error_code === 32){ // "message" : "This user is not subscribed" renvoyé par /relationship/easy
          this.notSubscribed = true
          this.showInfoDialog = true
          this.infoDialogType = 'likeProfile'
          this.homeService.dialogDisplayed.next(true)
        } else {
          console.error(err)
        }
      },
      complete: () => { 
        if(this.futureRelationshipStatus === 'waiting'){
          this.showWaitingStatus = true
        } else if(this.futureRelationshipStatus === 'friend'){
          this.match = true
          this.matchDialog()
        }
      
        setTimeout(() => {
          this.profileService.setProfileLiked(null)
        }, 3000)
      }
    })
  }

  closeInfoDialog(e: boolean){
    this.showInfoDialog = !e
    this.infoDialogType = ''
    this.homeService.dialogDisplayed.next(false)
  }

  contactProfile(relatedUserId: number){
    console.log('contactProfile: ', relatedUserId)

    /* 
    Messages directs
    Cela permet aux users H et F d’envoyer un message direct (fonctionnalité dispo uniquement pour les hommes abonnés + pour toutes les femmes).

    User A envoie un message direct à user B --> Les 2 users sont automatiquement en contact (relation à autovalidated)+ user B reçoit bien le message + au clic sur la 
    conversation, l’user B doit alors avoir un encart qui lui demande d’accepter ou de refuser la conversation avec user A.

    Si user B refuse --> On supprime alors la relation et les users ne sont plus en contact.
    L’user B ne voit plus l’user A dans ses contacts + l’user B ne voit plus la conversation.
    L’user A ne voit plus l’user B dans ses contacts MAIS il peut toujours voir sa conversation (et envoyer des messages. L’user B ne recevra juste jamais les messages).
    Si user B accepte --> On maintient alors la relation et les users peuvent continuer à s’envoyer des messages.
    */

    // check the user's sex 
    if(this.user?.sex === 'man'){
      // the user is a man
      if(this.user?.isSubscribed){
        // if user is registered, then he can send a direct message 

        // check if we already have a conversation with the relatedUser (woman).
        // if so, navigate to /chat/conversation/ + talkId
        // if not, navigate to /chat/conversation/new- + relatedUserId. La création de la nouvelle conversation se fera au moment de l'envoi du 1er message.
        this.authService.getProfile(relatedUserId).subscribe((user: User) => {
          if(user.talking_id) {
            this.router.navigate(['/home/my-matches/chat/conversation/' + user.talking_id])
            this.chatService.conversationToShow = user.talking_id
          } else {
            this.router.navigate(['/home/my-matches/chat/conversation/new-' + relatedUserId])
            this.chatService.conversationToShow = 0
          }
        })
        
        this.openTalk()
        this.chatService.relatedUserId.next(relatedUserId)
      } else {
        // if not, a dialog is opened to redirect the user to the LP
        // TODO : Vérifier si le user est abonné
          // si le user n'est pas abonné
          // la boîte de dialogue sera affichée. En cliquant sur le bouton, le user sera redirigé vers la landing page
          this.infoDialogType = 'contactProfile'
          this.homeService.dialogDisplayed.next(true)
      }
    } else {
      // the user is a woman
      // she can send a direct message 
      // vérifier si on a déjà une conversation avec le relatedUser H.
      // si oui, rediriger vers /chat/conversation/ + talkId
      // si non, rediriger vers /chat/conversation/new- + relatedUserId. La création de la nouvelle conversation se fera au moment de l'envoi du 1er message.
      this.authService.getProfile(relatedUserId).subscribe((user: User) => {
        if(user.talking_id) {
          this.router.navigate(['/home/my-matches/chat/conversation/' + user.talking_id])
          this.chatService.conversationToShow = user.talking_id
        } else {
          this.router.navigate(['/home/my-matches/chat/conversation/new-' + relatedUserId])
          this.chatService.conversationToShow = 0
        }
      })
      
      this.openTalk()
      this.chatService.relatedUserId.next(relatedUserId)
    }
  }

  openTalk(){
    this.chatService.toggleSidenav.next(true)
    this.homeService.pageDisplayed.next('my-matches')
    this.homeService.dialogDisplayed.next(false)
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
