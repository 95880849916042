<section class="my-dating-criteria">
    @if(loading$ | async){
      <app-loader></app-loader>
    }

    <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>  
  
    <div class="top" #top>
        <h3 i18n="Title for the dating criteria management section in My Space section@@MY_DATING_CRITERIA"><span class="regular">My</span> Dating Criteria</h3>
    </div>

    <form
        class="dating-criteria-form"
        [formGroup]="datingCriteriaFormGroup"
        (ngSubmit)="onSubmit()"
    >
        <div class="fieldsets">
            <!-- <app-attraction-input [user]="user" (emitAttraction)="getAttraction($event)"></app-attraction-input> -->
            
            @if(ageRange.min && ageRange.max && distanceValue && user){
              <fieldset>
                <h2 i18n="Title for the age input in the dating criteria form@@AGE">Age</h2>
                <app-range-input 
                    [ageRange]="ageRange"
                    (minValueSelection)="getMinValueSelection($event)"
                    (maxValueSelection)="getMaxValueSelection($event)"
                ></app-range-input>
              </fieldset>

              <app-city-input [user]="user" (emitCity)="getCitySet($event)"></app-city-input>

              <fieldset id="distance-input">
                  <label for="distance" i18n="Title for the distance input in the dating criteria form@@DISTANCE">Distance</label>
                  <mat-slider min="1" max="200" discrete [displayWith]="formatLabel">
                    <input [value]="distanceValue" matSliderThumb (valueChange)="getDistanceValue($event)" formControlName="distanceCtrl">
                  </mat-slider>
              </fieldset>
            }
        </div>
    </form>

    @if(successMessage){
      <p class="success-message">{{ successMessage }}</p>
    }

    <div class="submit-btn-container">
        <button 
            class="submit"
            type="submit"
            (click)="onSubmit()"
            i18n="Text for the button to validate the dating criteria modification in the dating criteria form@@VALIDATE"
        >
            Validate 
        </button>
    </div>

    <app-top-button (scrollRequest)="getScrollRequest($event)"></app-top-button>
</section>