import { Component, ElementRef, ViewChild, OnInit, AfterContentInit, OnDestroy } from '@angular/core';
import { FormsModule, Validators, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { interval, Subscription, take } from 'rxjs';
import { Location, CommonModule } from '@angular/common';

import { AppService } from '../../app.service';
import { AuthService } from '../../auth.service';
import { CriteriaService } from '../../criteria.service';
import { HomeService } from '../../home/home.service';

import { LoaderComponent } from '../../shared/loader/loader.component';
import { RangeInputComponent } from '../../shared/range-input/range-input.component';
import { CityInputComponent } from '../../shared/city-input/city-input.component';
import { AttractionInputComponent } from '../../shared/attraction-input/attraction-input.component';
import { FooterComponent } from '../../footer/footer.component';
import { TopButtonComponent } from '../../shared/top-button/top-button.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { Gender } from '../../shared/enum/Gender';
import { City } from '../../shared/interfaces/city';
import { User } from '../../shared/interfaces/user';
import { Criteria } from '../../shared/interfaces/criteria';

@Component({
  selector: 'app-my-dating-criteria',
  standalone: true,
  imports: [LoaderComponent, FormsModule, ReactiveFormsModule, RangeInputComponent, CityInputComponent, AttractionInputComponent, FooterComponent, TopButtonComponent, GoBackButtonComponent, CommonModule],
  templateUrl: './my-dating-criteria.component.html',
  styleUrl: './my-dating-criteria.component.scss'
})
export class MyDatingCriteriaComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild('minInput') minInput!: ElementRef
  @ViewChild('maxInput') maxInput!: ElementRef
  @ViewChild('distanceText') distanceText!: ElementRef
  @ViewChild('distanceInput', {read: ElementRef, static: true}) distanceInput!: ElementRef
  @ViewChild('top') top!: ElementRef

  loading = false
  datingCriteriaFormGroup!: FormGroup
  error = false

  trackPosition = {left: 24, right: 40}
  trackWidth = 0

  user!: User | null
  userSex = ''
  userCityId = ''

  distanceValue = 50
  distanceStepWidthInPixels = 0
  loaderSubscription!: Subscription
  desktopDevice = false

  criteria!: Criteria
  ageRange = {min: 24, max: 40}

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private criteriaService: CriteriaService,
    private location: Location,
    private homeService: HomeService
  ){}

  ngOnInit(){
    this.desktopDevice = this.appService.desktopDevice
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    // this.userSex = this.authService.get('sex')
    // this.userCityId = this.authService.get('city_id')
    this.user = JSON.parse(this.authService.get('user'))

    if(!this.user) {
      this.authService.authMe.subscribe(res => {
        this.user = res
      })
    }

    this.datingCriteriaFormGroup = new FormGroup({
      attractionCtrl: new FormControl(this.user?.sex === 'man' ? Gender.woman : Gender.man, [Validators.required]),
      minAgeCtrl: new FormControl(24, [Validators.required]), 
      maxAgeCtrl: new FormControl(40, [Validators.required]), 
      locationCtrl: new FormControl(this.user?.city?.id || 0, [Validators.required]),
      distanceCtrl: new FormControl(50, [Validators.required]),
    });

    this.criteriaService.getCriterias().subscribe(res => {
      this.criteria = res

      this.datingCriteriaFormGroup.controls['attractionCtrl'].patchValue(this.criteria.sex)
      this.datingCriteriaFormGroup.controls['minAgeCtrl'].patchValue(this.criteria.age_start)
      this.datingCriteriaFormGroup.controls['maxAgeCtrl'].patchValue(this.criteria.age_end)
      this.datingCriteriaFormGroup.controls['locationCtrl'].patchValue(this.criteria.city.id)
      this.datingCriteriaFormGroup.controls['distanceCtrl'].patchValue(this.criteria.distance)

      this.ageRange = { min: this.criteria.age_start, max: this.criteria.age_end}
      this.distanceValue = this.criteria.distance

      this.fillDistanceSlider(this.criteria.distance, '#C6C6C6', '#F4706D', this.distanceInput)
    })

    this.fillDistanceSlider(this.distanceValue, '#C6C6C6', '#F4706D', this.distanceInput)
  }

  ngAfterContentInit(){ 
    //calcul de la largeur de l'input distance en pixels
    interval(100).pipe(take(2)).subscribe(() => { 
      const inputWidthInPixels = this.distanceInput.nativeElement.offsetWidth - 88 // 88 est la largeur du thumb
      this.distanceStepWidthInPixels = inputWidthInPixels / 200 // 200 est la valeur max de l'input
    }
  )}

  goBack(){
    if(this.desktopDevice) {
      this.location.back()
    }

    this.homeService.pageDisplayed.next('')
  }

  getDistanceStyle(){
    return {
      left: this.distanceValue * this.distanceStepWidthInPixels + 'px'
    }
  }

  getScrollRequest(request: string){
    if(request === 'scrollToTop') this.top.nativeElement.scrollIntoView()
  }

  onSubmit(){//data: {attractionCtrl: Gender.man | Gender.woman, minAgeCtrl: number, maxAgeCtrl: number, locationCtrl: number, distanceCtrl: number}
    const data = this.datingCriteriaFormGroup.value

    this.criteriaService.setCriteria(data).subscribe(() => {
      console.log('criteria updated')
    })
  }

  getCitySet($event: City | { id: number | null; name: string | null; }){
    this.datingCriteriaFormGroup.controls['locationCtrl'].setValue($event.id)
  }

  getDistanceValue($event: Event){
    this.distanceValue = parseInt(($event.target as HTMLInputElement).value)

    this.fillDistanceSlider(this.distanceValue, '#C6C6C6', '#F4706D', this.distanceInput)
  }

  fillDistanceSlider(to: number, sliderColor: string, rangeColor: string, controlSlider: ElementRef) {
    const rangeDistance = to * 100 / 200

    controlSlider.nativeElement.style.background = `linear-gradient(
      to right,
      ${rangeColor} 0%,
      ${rangeColor} ${rangeDistance}%, 
      ${sliderColor} ${rangeDistance}%, 
      ${sliderColor} 100%
    )`
  }

  getMinValueSelection($event: number){
    this.datingCriteriaFormGroup.controls['minAgeCtrl'].setValue($event)
  }

  getMaxValueSelection($event: number){
    this.datingCriteriaFormGroup.controls['maxAgeCtrl'].setValue($event)
  }

  getAttraction($event: Gender.man | Gender.woman){
    this.datingCriteriaFormGroup.controls['attractionCtrl'].setValue($event)
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
