import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private footerState$ = new Subject<boolean>();

  private storyDate = null;
  private photoDate = null;

  private stop$ = new Subject<void>();
  private start$ = new Subject<void>();

  toggleSidenav = new BehaviorSubject<boolean>(false)
  toggleEncounterSidenav = new BehaviorSubject<boolean>(false)
  pageDisplayed = new BehaviorSubject<string>('home')

  private photoLiked$ = new Subject<{ id: number; liked: boolean }>();

  get footerState(): Observable<boolean> {
    return this.footerState$.asObservable();
  }

  hideFooter(value: boolean) {
    this.footerState$.next(value);
  }

  set lastStoryDate(value) {
    this.storyDate = value;
  }

  get lastStoryDate() {
    return this.storyDate;
  }

  set lastPhotoDate(value) {
    this.photoDate = value;
  }

  get lastPhotoDate() {
    return this.photoDate;
  }

  setLikedPhoto(value: { id: number; liked: boolean }) {
    this.photoLiked$.next(value);
  }

  get likedPhoto(): Observable<{ id: number; liked: boolean }> {
    return this.photoLiked$.asObservable();
  }

  constructor(
    private http: HttpClient,
    private appService: AppService,
  ) {
  }
}
