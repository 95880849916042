<app-go-back-button class="edit-go-back-btn" (click)="goBack()"></app-go-back-button>  

<section class="edit-password">
  <div class="top" #top>
    <h3><span class="regular">Password</span> Modification</h3>
  </div>

  <form action="">
    <h3>Current Password</h3>
    <div class="input-holder">
      <button type="button" class="password-btn" (click)="showHidePassword()" [class.showHide]="!showPassword">
        <span class="material-symbols-outlined password-icon">
          visibility
        </span>
      </button>
      <button type="button" class="password-btn" (click)="showHidePassword()" [class.showHide]="showPassword">
        <span class="material-symbols-outlined password-icon">
          visibility_off
        </span>
      </button>

      <input
        id="current-password"
        formControlName="currentPasswordCtrl"
        placeholder="Enter your current password"
        autocapitalize="none"
        #inputPassword
        [type]="showPassword ? 'text': 'password'"
        (input)="onInput($event.target, 'currentPasswordCtrl')"
      />
      <!-- @if(showPassword){
        <div class="show-password">{{user?.firstname}}</div>
      } -->
    </div>

    <h3>New Password</h3>
    <div class="input-holder">
      <button type="button" class="password-btn" [class.showHide]="!showPassword" (click)="showHidePassword()">
        <span class="material-symbols-outlined password-icon">
          visibility
        </span>
      </button>
      <button type="button" class="password-btn" [class.showHide]="showPassword" (click)="showHidePassword()">
        <span class="material-symbols-outlined password-icon">
          visibility_off
        </span>
      </button>

      <input
        id="new-password"
        formControlName="newPasswordCtrl"
        placeholder="Enter your new password"
        autocapitalize="none"
        #inputPassword
        [type]="showPassword ? 'text': 'password'"
        (input)="onInput($event.target, 'newPasswordCtrl')"
      />
      <!-- @if(showPassword){
        <div class="show-password">{{user?.firstname}}</div>
      } -->
    </div>
    @if(passwordFormGroup.getError('sameAsCurrent', 'newPasswordCtrl')){
      <p class="error">toto</p>
    }
    

    <h3>Confirm New Password</h3>
    <div class="input-holder">
      <button type="button" class="password-btn" [class.showHide]="!showPassword" (click)="showHidePassword()">
        <span class="material-symbols-outlined password-icon">
          visibility
        </span>
      </button>
      <button type="button" class="password-btn" [class.showHide]="showPassword" (click)="showHidePassword()">
        <span class="material-symbols-outlined password-icon">
          visibility_off
        </span>
      </button>

      <input
        id="confirmed-new-password"
        formControlName="confirmedNewPasswordCtrl"
        placeholder="Confirm your new password"
        autocapitalize="none"
        #inputPassword
        [type]="showPassword ? 'text': 'password'"
        (input)="onInput($event.target, 'confirmedNewPasswordCtrl')"
      />
      <!-- @if(showPassword){
        <div class="show-password">{{user?.firstname}}</div>
      } -->
    </div>

    <button
      class="submit"
      type="submit"
      [disabled]="passwordFormGroup.invalid"
    ><!-- [class.wrong]="wrongCredential" [disabled]="authFormGroup.invalid || error"-->
      Validate new password
    </button>
  </form>
</section>
    