<dialog class="dialog" #errorMessageDialog>
    <span class="material-symbols-outlined warning">
        error
    </span> 
    <header>
        {{errorTitle}}
    </header>
    <div class="dialog-content">
        {{errorMessage}}
    </div>
</dialog>  