import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewChecked,
  Inject,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, concatMap, map, repeat, Subject, switchMap, takeUntil, throwError, timer } from 'rxjs';
import { Router, RouterOutlet, RouterModule } from '@angular/router';
import { CommonModule, Location, DOCUMENT } from '@angular/common';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { environment } from '../../environments/environment';
import { Amplify } from 'aws-amplify';

import { AuthService } from '../auth.service';
import { AppService } from '../app.service';
import { PhotoService } from '../photo/photo.service';
import { StoriesService } from '../stories.service';
import { StoryService } from '../create-story/story.service';
import { HomeService } from './home.service';
import { AmplifyService, Product, Sex } from '../amplify.service';
import { EncountersService } from '../new-encounters/encounters.service';
import { LoaderService } from '../loader.service';
import { LayoutService } from '../layout.service';

import { LoaderComponent } from '../shared/loader/loader.component';
import { LoggedInHeaderComponent } from '../logged-in-header/logged-in-header.component';
import { InfoDialogComponent } from '../dialog/info-dialog/info-dialog.component';
import { StoryComponent } from '../story/story.component';

import { User } from '../shared/interfaces/user';
import { Story } from '../shared/interfaces/story';

import { InitialStoryPhotoArray, PhotoObject } from '../create-story/images-holder/story-photos';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    RouterOutlet,
    LoaderComponent,
    LoggedInHeaderComponent,
    RouterModule,
    InfoDialogComponent,
    StoryComponent,
    CommonModule,
    MatSidenavModule,
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewChecked, OnDestroy {
  @HostListener('window:scroll', ['$event']) private onScrollWindow() {
    this.windowScrolling();
  }

  @ViewChild('mainHolder') holder!: ElementRef;
  @ViewChild('fileUpload') fileUpload!: ElementRef;
  @ViewChild('mobileFileUpload') mobileFileUpload!: ElementRef;  
  @ViewChild('sidenav') sidenav!: MatSidenav
  @ViewChild('photos') photosContainer!: ElementRef
  @ViewChild('scrollable') scrollable!: ElementRef

  loading$ = this.loaderService.loading$
  private _window: (Window & typeof globalThis) | null

  firstname!: string;
  user: User | null = null;
  photo!: string;

  savedImageUrl = '';
  stories: Story[] = [];

  storyImages: PhotoObject[] = [];

  showOverlay = false;
  showInfoDialog = false;
  currentPage = 1;
  totalPages = 1;

  encounters: User[] = [];
  infoDialogType = '';
  serviceName = environment.serviceName;

  pageDisplayed = 'home';
  loadingMore = false
  noMoreToLoad = false

  sidenavToggled = false
  storyWidth!: number
  count = 0
  photos = InitialStoryPhotoArray
  newStoriesAvailable = new BehaviorSubject(false)
  newEncountersAvailable = new BehaviorSubject(false)
  dialogDisplayed = false
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop
  liveNotifications$ = this.homeService.liveNotifications
  notificationsCount$ = new BehaviorSubject<number | null>(null)
  private stop$ = new Subject<void>()
  private start$ = new Subject<void>()

  //encounters$ = this.homeService.encounters

  constructor(
    public router: Router,
    private appService: AppService,
    private authService: AuthService,
    private photoService: PhotoService,
    private storiesService: StoriesService,
    private storyService: StoryService,
    private homeService: HomeService,
    private location: Location,
    private amplifyService: AmplifyService,
    private encountersService: EncountersService,
    private loaderService: LoaderService,
    private layoutService: LayoutService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.appService.setPage('service');
    this._window = this.document.defaultView

    if(this._window){
      this._window.addEventListener('popstate', (event) => {
        const popStateTarget = event.currentTarget as Window
        const urlSegmentsArr = popStateTarget.location.pathname.split('/')

        switch (popStateTarget.location.pathname) {
          case '/home':
            this.homeService.toggleSidenav.next(false)
            this.homeService.pageDisplayed.next('home')
            break;

          case '/login':
            this.homeService.toggleSidenav.next(false)
            this.homeService.pageDisplayed.next('home')
            break;

          default:
            if(popStateTarget.location.pathname.includes('story')){
              this.homeService.pageDisplayed.next('story')
            } else if (popStateTarget.location.pathname.includes('my-matches')){
              this.homeService.pageDisplayed.next('my-matches')
            } else {
              this.homeService.pageDisplayed.next(urlSegmentsArr[urlSegmentsArr.length-1])
            }
            break;
        }
      }, false);
    }
  }

  async ngOnInit() {
    this.homeService.pageDisplayed.pipe(
      takeUntil(this._destroyed)
    ).subscribe((res: string) => {
      this.pageDisplayed = res
    })

    // permet de réinitialiser l'url au refresh
    this.location.replaceState('/home')

    this.homeService.dialogDisplayed.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => this.dialogDisplayed = res)

    this.homeService.toggleSidenav.pipe(
      takeUntil(this._destroyed)
    ).subscribe((res: boolean) => {
      this.sidenavToggled = res
      if(this.sidenav) {
        if(this.sidenavToggled){
          this.sidenav.open()
        } else {
          this.sidenav.close()
        }
      }
    })

    this.user = JSON.parse(this.authService.get('user'));
    const userStoredLocally = !!this.user;
    let id: number | undefined;

    if (userStoredLocally) {
      id = this.user?.id;

      if (id) {
        this.authService.getProfile(id).pipe(
          takeUntil(this._destroyed)
        ).subscribe({
          next: (res: User | null) => {
            this.user = res;

            this.authService.authMe$.next(this.user)

            this.authService.remove('user');
            this.authService.set('user', JSON.stringify(res));

            this.user?.main_photo
              ? (this.photo = this.user?.main_photo.url)
              : this.router.navigate(['/upload-photo']);
          },
          error: (err) => {
            return throwError(() => err);
          },
        });
        // } else {
        //   if(!this.me) {
        //     this.authService.remove('token')
        //     this.authService.remove('user')
        //     this.router.navigate(['/login'])
        //   }
      }

      this.storiesService.getStories(this.currentPage, 9).pipe(
        takeUntil(this._destroyed)
      ).subscribe({
        next: (res) => {
          this.stories = res.stories
          const firstCard: Story = {
            id: 0,
            likes: 0,
            photos: []
          }
          this.stories.unshift(firstCard)
          this.homeService.stories = res.stories
        },
        error: (err) => {
          return throwError(() => err)
        },
      });
    } else {
      this.authService.authMe.pipe(
        takeUntil(this._destroyed)
      ).subscribe((res: User | null) => {
        if (!res) {
          this.authService.remove('token');
          this.authService.remove('user');
          this.router.navigate(['/login']);
        }

        this.user = res;
      });
    }

    // récupération de fake encounters : pour les tests
    // this.getEncounters().subscribe((res) => {
    //   this.encounters = res;
    // });

    Amplify.configure({
      API: {
        GraphQL: {
          endpoint: 'https://ynzsakwquzd7zkuzzem4fk5644.appsync-api.eu-west-1.amazonaws.com/graphql',
          region: 'eu-west-1',
          defaultAuthMode: 'apiKey',
          apiKey: 'da2-dsmvlevcxzcopbleelbamymvkm'
        }
      }
    });

    await this.amplifyService.GetContents(Product.woozgo, 18, 99, -1, undefined, undefined, Sex.man, "[\"story\"]", 1, 9, undefined, undefined, 1, 'false') //const allStories = 

    this.amplifyService.AddedContentListener().subscribe({ //const storiesSub = 
      next: ({ data }) => {
        console.log(data)
        if(data.addedContent){
          this.newStoriesAvailable.next(true)
          setTimeout(() => {
            this.newStoriesAvailable.next(false)
          }, 5000)
        
          // const updatedContent = data.addedContent.item?.data

          // if(!updatedContent) return
          // const newStory: Story = {
          //   id: updatedContent.content_id as number,
          //   views: updatedContent.views as number,
          //   likes: updatedContent.likes as number,
          //   type: updatedContent.type as string,
          //   photos: updatedContent.photos as string[],
          //   user: updatedContent.user as User,
          // }

          // console.log(newStory)

          //this.stories = [newStory, ...this.stories]
        }
      },
      error: (error) => console.warn(error)
    });

    /* timer(0, 300000)
    .pipe(
      takeUntil(this._destroyed),
      concatMap(() => {
        return this.encountersService.getSuggestions(1,30).pipe(
          map((res) => {
            return res
          }
        ))
      }
    ))
    .subscribe((res: User[]) => {
      this.encounters = res
      this.homeService.encounters.next(res)

      if(this.encounters.length > 0) this.newEncountersAvailable.next(true)
    }); */


    timer(0, 300000).pipe(
      takeUntil(this._destroyed),
      concatMap(() => {
        return this.encountersService.getSuggestions(1,30).pipe(
          map((suggestions) => {
            this.encounters = suggestions
            return suggestions
          }
        ))
      }),
      switchMap(() => this.authService.getAuthContactsRequest(1)),
      map(invitations => {
        const encounters = [...invitations, ...this.encounters]
        this.encounters = encounters
        return encounters
      }),
    ).subscribe(res => {
      this.homeService.encounters.next(res)

      if(this.encounters.length > 0) this.newEncountersAvailable.next(true)
    })

    timer(500, 20000).pipe(
      switchMap(() => {
        return this.homeService.getNotificationsCount().pipe(
          map((notificationsByType) => {
            let count = 0
            
            for(const [, value] of Object.entries(notificationsByType)){
              count += value
            }

            this.notificationsCount$.next(count)
            this.homeService.liveNotifications$.next(count)
  
            return count
          })
        )
      }),
      takeUntil(this._destroyed),
      repeat({delay: () => this.start$})
    ).subscribe();

    this.storiesService.storyEditionClosed.subscribe(closed => {
      if(closed){
        this.homeService.toggleSidenav.next(true)
        this.router.navigate(['/home/my-space/my-stories'])
        this.homeService.pageDisplayed.next('my-stories')
      }
    })
  }

  seeNewStories(){
    this._window?.scrollTo(0,0)
    this.reloadComponent(true)
  }

  reloadComponent(self: boolean, urlToNavigateTo?: string){
    //skipLocationChange:true means dont update the url to / when navigating
    const url = self ? this.router.url : urlToNavigateTo

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`])
    })
 }

  onScroll(e: Event){
    // récupère la valeur du scroll de la div #scrollable de la home pour la stocker dans un service
    this.appService.scrollValue.next(e)
  }

  onTouchEnd(e: TouchEvent){
    this.appService.scrollValue.next(e)
  }

  ngAfterViewChecked(){
    if(this.count > 0) return

    setTimeout(() => {
      const bounds = this.photosContainer.nativeElement.getBoundingClientRect()
      const photosContainerWidth = Number.parseFloat((bounds.right - bounds.left).toFixed(2))
  
      const gapWidth = 20
      let numberOfItemsPerRow = 2
  
      if(this.isDesktop()) numberOfItemsPerRow = 5
  
      this.storyWidth = Math.floor((photosContainerWidth - (numberOfItemsPerRow - 1) * gapWidth) / numberOfItemsPerRow)
      
      this.count++
    }, 100)
  }

  getStyles(){
    return {
      'width': this.storyWidth + 'px',
      'height': `${this.storyWidth * 1.4}px`,
    }
  }

  toggleSidenav(open: boolean){
    this.homeService.toggleSidenav.next(open)
    if(!open) this.homeService.pageDisplayed.next('')
  }

  windowScrolling() {
    const scrollValue = window.scrollY;
    const content = this.holder.nativeElement as HTMLElement;
    const bottom = scrollValue > content.clientHeight - window.innerHeight - 200;

    if(bottom && !this.loadingMore && !this.noMoreToLoad) {
      this.loadingMore = true
      this.currentPage++

      this.getStories()
    }
  }

  getShowStoryRequest(story: Story){
    if(!story) return
    this.router.navigate(['home/story/' + story.id])
    this.homeService.pageDisplayed.next('story')
    this.homeService.toggleSidenav.next(true)
  }

  getStories() {
    this.storiesService.getStories(this.currentPage, 10).pipe(
      takeUntil(this._destroyed)
    ).subscribe({
      next: (res) => {
        this.stories = this.stories.concat(res.stories)

        if(res.stories.length < 10) this.noMoreToLoad = true

        if(!this.loadingMore) {
          window.scrollTo(0, 0);
        }
        this.loadingMore = false;
      },
      error: (err) => {
        return throwError(() => err)
      },
    });
  }

  // récupération de fake encounters : pour les tests
  // getEncounters(): Observable<User[]> {
  //   return this.http.get<User[]>('./assets/encounters.json').pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  deletePhoto() {
    const id = this.user?.main_photo?.id;
    if (id) {
      this.photoService.deletePhoto(id).pipe(
        takeUntil(this._destroyed)
      ).subscribe(() => {
        this.authService.authMe$.pipe(
          takeUntil(this._destroyed)
        ).subscribe((res) => (this.user = res));

        const jsonString = this.authService.get('user');
        const userStoredLocally = JSON.parse(jsonString);
        userStoredLocally.main_photo = null;
        this.authService.set('user', JSON.stringify(userStoredLocally));
      });
    }
  }

  // ajout d'image via l'explorateur
  onPhotoOrVideoInputChange(event: Event) {
    console.log('photo selection')
    const element = event.currentTarget as HTMLInputElement;
    const files = element.files;

    Array.prototype.forEach.call(files, (file: File) => {
      const fileBlob = URL.createObjectURL(file);

      this.photos[0].src = fileBlob
    });

    this.router.navigate(['create-story']);
  }

  displayAddStoryDialog() {
    console.log('displayAddStoryDialog')

    this.router.navigate(['story'])
    this.homeService.toggleSidenav.next(true)
  }

  openMobileCamera() {
    this.mobileFileUpload.nativeElement.click();
  }

  openFileInput() {
    this.fileUpload.nativeElement.click();
  }

  drop(ev: DragEvent) {
    if (!ev.dataTransfer) return;
    //stops browser from opening the file
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (const item of ev.dataTransfer.items) {
        if (item.kind !== 'file') {
          return;
        }

        const file = item.getAsFile();

        if (!file) return;
        const fileBlob = URL.createObjectURL(file);

        this.storyService.storyImages.next([
          {
            id: this.storyImages.length,
            src: fileBlob,
            saved: false,
          },
        ]);
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (const file of ev.dataTransfer.files) {
        const fileBlob = URL.createObjectURL(file);
        this.storyService.storyImages.next([
          {
            id: this.storyImages.length,
            src: fileBlob,
            saved: false,
          },
        ]);
      }
    }
  }

  allowDrop(ev: DragEvent) {
    ev.preventDefault();
  }

  logOut() {
    this.authService.logged$.next(false);
    this.router.navigate(['/login']);
    this.authService.authDisconnect();
  }

  closeInfoDialog(event: boolean) {
    this.showInfoDialog = !event;
  }

  requestStories($event: 'previous' | 'next') {
    if (!$event) return;

    if (this.currentPage === 1 && $event === 'previous') return;

    const page = $event === 'next' ? this.currentPage + 1 : this.currentPage - 1;
    const pagesNumber = page === 1 ? 9 : 10;

    this.storiesService.getStories(page, pagesNumber).pipe(
      takeUntil(this._destroyed)
    ).subscribe({
      next: (res) => {
        console.log(res);
        this.stories = res.stories;
        this.currentPage = page;
        this.totalPages = res.total + 1; // res.total commence à 0
      },
      error: (err) => {
        if (err.error.message) {
          // this.errorTitle = "Login details are incorrect"
          // this.errorMessage = "Please check and re-enter your data."
          // this.openDialog = true
        }
      },
    });
  }

  getInfoDialogType(event: string) {
    this.infoDialogType = event;
    this.showInfoDialog = true;
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
    this.homeService.stop()
  }
}

