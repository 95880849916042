<div class="popin">
  <div class="popin-content">
    <span class="material-symbols-outlined warning">
      error
    </span> 
    <h2 i18n="@@ERROR_warning">Warning!</h2>

    @if(errorValue?.level !== 'subscribe notfound' &&
    errorValue?.level !== 'already subscribe' &&
    errorValue?.level !== 'subscribe already exist'){
      <div class="error-text">
        <div class="text">

          @switch(errorValue?.error_code){
            <!-- COMMON -->
            @case(-104) {
              <ng-container i18n="@@ERROR_INVALID_PARAMETER">Invalid parameter</ng-container>
            }

            <!-- AUTHENTICATE -->
            @case(21001){  
              <ng-container i18n="@@ERROR_UNABLE_TO_LOGIN">Unable to get user with email and product_id</ng-container>
            }
            @case(21012){
              <ng-container i18n="@@ERROR_UNABLE_TO_LOGIN">error</ng-container>
            }
            @case(21002){
              <ng-container i18n="@@ERROR_UNABLE_TO_LOGOUT">error</ng-container>
            }
            @case(21003){
              <ng-container i18n="@@ERROR_INVALID_TOKEN">error</ng-container>
            }

            <!-- USER PART -->
            @case(1){
              <ng-container i18n="@@ERROR_USER_BAD_CREDENTIALS">error</ng-container>
            }
            @case(2){
              <ng-container i18n="@@ERROR_USER_DOES_NOT_EXIST">error</ng-container>
            }
            <!-- @case(3){
              <ng-container i18n="@@ERROR_USER_ALREADY_EXIST">error</ng-container>
            } -->
            @case(7018){
              <ng-container i18n="@@ERROR_EMAIL_ALREADY_EXIST">ERROR. Email already in use. If you already have an account, please log in to it. Otherwise, please use another email address.</ng-container>
            }
            @case(4){
              <ng-container i18n="@@ERROR_INVALID_TOKEN">No token or malformed token</ng-container>
            }
            @case(5){
              <ng-container i18n="@@ERROR_UNABLE_TO_LOGOUT">Missing parameter</ng-container>
            }
            @case(6){
              <ng-container i18n="@@ERROR_UNABLE_TO_DELETE_PHOTO">Unable to delete profile photo</ng-container>
            }
            @case(7){
              <ng-container i18n="@@ERROR_EMAIL_NOT_FOUND">Unable to find user with email</ng-container>
            }
            @case(8){
              <ng-container i18n="@@ERROR_USER_BAD_PARAMS">error</ng-container>
            }
            @case(12){
              <ng-container i18n="@@ERROR_BAD_PARAMS_INPUT">Same user cannot send and receive a notification</ng-container>
            }
            @case(13){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_USER">error</ng-container>
            }
            @case(14){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_USER_FIRST_NAME">Unable to update user first name</ng-container>
            }
            @case(15){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_USER_BIRTHDATE">Unable to update user birthdate</ng-container>
            }
            @case(16){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_USER_DESCRIPTION">enable to update user description</ng-container>
            }
            @case(17){
              <ng-container i18n="@@ERROR_USER_HAS_NO_ACCESS_TO_PROFILE">Unable to access to user profile</ng-container>
            }
            @case(18){
              <ng-container i18n="@@ERROR_USER_BLOCKED">user id blocked by admin</ng-container>
            }
            @case(19){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_PASSWORD" >Unable to update password</ng-container>
            }
            @case(20){
              <ng-container i18n="@@ERROR_UNABLE_TO_DELETE_USER">Unable to delete user</ng-container>
            }
            @case(21){
              <ng-container i18n="@@ERROR_UNABLE_TO_DELETE_USER_DESCRIPTION">Unable to delete user description</ng-container
            >
            }
            @case(22){
              <ng-container i18n="@@ERROR_ERROR_USER_PASSWORD">Bad password input</ng-container>
            }
            @case(27){
              <ng-container i18n="@@ERROR_UNABLE_TO_SENT_RESET_PASSWORD_EMAIL">error</ng-container>
            }
            @case(35){
              <ng-container i18n="@@ERROR_NO_ACCOUNT">Unable to get user info by id</ng-container>
            }
          
            <!-- CITY -->
            @case(301){
              <ng-container i18n="@@ERROR_CITY_EMPTY_LIST">Unable to get cities list by lat and long</ng-container>
            }
            @case(302){
              <ng-container i18n="@@ERROR_CITY_NOT_FOUND">Unable to get city by city_id</ng-container>
            }

            <!-- PHOTO -->
            @case(401){
              <ng-container i18n="@@ERROR_PHOTO_CREATE_PHOTO">Unable to create photo</ng-container>
            }
            @case(402){
              <ng-container i18n="@@ERROR_PHOTO_DELETE_PHOTO">Unable to delete photo from S3</ng-container>
            }
            @case(403){
              <ng-container i18n="@@ERROR_UNABLE_TO_GET_PHOTO">Unable to get photo</ng-container>
            }
            @case(412){
              <ng-container i18n="@@ERROR_UNABLE_TO_SET_TO_PROFILE">Unable to set to profile</ng-container>
            }
            @case(415){
              <ng-container i18n="@@ERROR_UNABLE_TO_SET_TO_PROFILE">This photo is not yet moderated</ng-container><!-- clé à mettre à jour -->
            }

            <!-- STORIES -->
            @case(504){
              <ng-container i18n="@@ERROR_UNABLE_TO_CREATE_STORIES">Unable to create story</ng-container>
            }
            @case(512){
              <ng-container i18n="@@ERROR_UNABLE_TO_DELETE_STORIES">This story doesn't belong to this user</ng-container>
            }
            @case(517){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_STORIES">Unable to update story id</ng-container>
            }

            <!-- CRITERIA -->
            @case(605){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_CRITERIA">error</ng-container>
            }

            <!-- CRUSH -->
            @case(701){
              <ng-container i18n="@@ERROR_CRUSH_ALREADY_EXIST">Content already liked</ng-container>
            }

            <!-- RELATIONSHIP -->
            @case(1004){
              <ng-container i18n="@@ERROR_UNABLE_TO_CREATE_RELATION">Unable to get related user info by user_id</ng-container>
            }
            @case(1007){
            <ng-container i18n="@@ERROR_BAD_PARAMS_FOR_RELATIONSHIP">State not authorized for users</ng-container>
            }

            <!-- MESSAGE -->
            @case(1102){
              <ng-container i18n="@@ERROR_UNABLE_TO_SET_MESSAGE">Unable to create message</ng-container>
            }

            <!-- ALREADY REPORT -->
            @case(1205){
              <ng-container i18n="@@ERROR_REPORT_ALREADY_EXIST">User already reported this content</ng-container>
            }

            <!-- DESCRIPTION -->
            @case(1700){
              <ng-container i18n="@@ERROR_UNABLE_TO_HARDDELETE_DESCRIPTION">error</ng-container>
            }

            <!-- DEFAULT PART -->
            @default{
              <ng-container i18n="@@ERROR_ConnexionErrorService">error</ng-container>
            }
          }
        </div>
      </div>
    } @else {
        @if(errorValue?.level === 'subscription not found'){
          <div class="error-text">
            <div class="text">
              <p i18n="@@ERROR_subscriberTokenNotFound">Error while processing account creation.</p>
              
              <div class="actions">
                <a
                  [href]="'mailto:customer@woozgo.com?subject=Subscription%20token%20:%20' + token +'is%20invalid'"
                  i18n="@@ERROR_subsriberTokenNotFoundContact"
                >
                  Contact our support
                </a>
              </div>
            </div>
          </div>
        }
  
        @if(errorValue?.level === 'already subscribe'){
          <div class="error-text">
            <div class="text">
              <p i18n="@@ERROR_subscribeAlreadyExist">You already subscribed with this phone number</p>
              <p i18n="@@ERROR_subscribeAlreadyExistUseAnotherAccount">Please use another one</p>
            </div>
          </div>
        }
        
        @if(errorValue?.level === 'subscription already exists'){
          <div class="error-text">
            <div class="text">
              <p i18n="@@ERROR_subscriberTokenAlreadyExist">You already created your account</p>
              <p i18n="@@ERROR_subscriberTokenAlreadyExistAccount">Your login is</p>
              <p>{{ errorValue?.message }}</p>
            </div>
          </div>
        }
    }

    <div class="error-code">
      <p i18n="@@ERROR_errorCode">Error code: {{ errorValue?.error_code }}</p>
    </div>
  </div>
</div>
