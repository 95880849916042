<section class="my-stories" #myStoriesHolder>
  <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>  
  
  <div class="top" #top>
      <h3 i18n="Title for the stories management page in My Space section@@MY_STORIES"><span class="regular">My</span> Stories</h3>
  </div>

  @if(!stories.length && !storiesLoading){
    <div class="no-stories-container" [style.height.px]="(viewportHeight * 70 / 100)">
      <p class="no-stories" i18n="Default text if the user does not any story in the stories management page@@NO_STORIES">No stories for the moment.</p>
    </div>
  } @else {
    <div class="stories-container" #storiesContainer>
      @for(s of stories; track s){
        <button type="button" class="photo-container" [ngStyle]="getStyles()" (click)="s.state === 'pending' ? editStory(s) : showStory(s)">
          <img [src]="s.photos[0].url" alt="" class="cover">
          <button type="button" (click)="openDeleteDialog($event, s.id)"  class="delete-story-btn">
            <img src="assets/icons/icon_delete.svg" alt="" class="delete-icon"> 
          </button>
          <div class="overlay" [class.show]="s.state === 'pending'"></div>
          <button type="button" class="edit-story-btn" [class.show]="s.state === 'pending'">
            <img src="assets/icons/icon_edit_story.svg" alt="" class="edit-icon">
          </button>
        </button>
      }

      @if(loading$ | async){
        <app-loader></app-loader>
      }
    </div>
  }

  @if(stories.length){
    <app-top-button (scrollRequest)="getScrollRequest($event)"></app-top-button>
  }
</section>
