import { Component, inject, Renderer2 } from '@angular/core';
import { StoryService } from '../../create-story/story.service';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-save-image-dialog',
  standalone: true,
  imports: [],
  templateUrl: './save-image-dialog.component.html',
  styleUrl: './save-image-dialog.component.scss'
})
export class SaveImageDialogComponent {
  // create reference to dialog and pass the data type to pass to parent component when dialog is closed (boolean) to trigger save or cancel
  dialogRef = inject<DialogRef<boolean>>(DialogRef<boolean>);
  data = inject(DIALOG_DATA);
  emojis: unknown; // à spécifier

  constructor(
    private storyService: StoryService,
    private renderer: Renderer2,
  ) { }

  saveImage() {
    console.log('save image')
  }

  cancel() {
    this.storyService.saveStoryImage.next(null)
  }
}
