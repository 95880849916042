import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';

import { AppService } from '../../app.service';
import { ServiceService } from '../../service.service';
import { StoryService } from '../story.service';

import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { ColorPaletteComponent } from '../color-palette/color-palette.component';

import { Color } from '../../shared/interfaces/color';
import { Visual } from '../../shared/interfaces/visual';
import { StoryInCreationProcess } from '../../shared/interfaces/story-in-creation-process';
import { TextInput } from '../../shared/interfaces/text-input';
import { StoryContent } from '../../shared/interfaces/story-content';
import { environment } from '../../../environments/environment';
import { StoryImage } from '../../shared/interfaces/story-image';

@Component({
  selector: 'app-toolbar',
  standalone: true,
  imports: [ConfirmDialogComponent, CommonModule, ColorPaletteComponent],
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss'
})
export class ToolbarComponent implements OnInit {
  @Input() storyImages!: StoryImage[]
  @Input() selectedImageId!: number
  @Input() mobilePhotoHolder!: ElementRef
  @Input() desktopPhotoHolder!: ElementRef
  @Input() imageProcessed!: boolean
  @Input() visuals: Visual[] = []
  //@Input() toolSelected!: boolean

  @Output() textEditionActivated = new EventEmitter<boolean>()
  //@Output() inputElementFromToolbar = new EventEmitter<HTMLInputElement | null>()
  @Output() showConfirmDialogFromToolbar = new EventEmitter<boolean>()
  //@Output() visualsChange = new EventEmitter<Visual[]>()

  //@ViewChild('inputTextDomElement') inputTextDom!: ElementRef
  @ViewChild('textButton') textToolbarButton!: ElementRef
  @ViewChildren('visual', {read: ElementRef}) visualsRef!: QueryList<ElementRef>

  textEdition = false
  warning = false

  textPos: { x: number | 0, y: number | 0 } = { x: 0, y: 0 }

  textToolSelected = true
  visualToolSelected = false

  inputElement!: HTMLInputElement | null

  text!: {
    id: number;
    value: string | '';
    fontColorHex: string;
    pos: {
      x: number | 0;
      y: number | 0;
    }
  }

  showEmojisList = false
  showStickersList = false
  showMasksList = false
  emojiTypeSelected = 'emoticons'

  textParagraphElement!: HTMLParagraphElement
  emojiTypeIndex = 0
  emojis = {
    emoticons: Array(80)
      .fill('')
      .map((_, index) => `emoji_${index}`),
    hands: Array(40)
      .fill('')
      .map((_, index) => `emoji_${80 + index}`),
    fun: Array(64)
      .fill('')
      .map((_, index) => `emoji_${1000 + index}`),
    animals: Array(64)
      .fill('')
      .map((_, index) => `emoji_${1064 + index}`),
  }
  masks = {
    masks: Array(48)
      .fill('')
      .map((_, index) => `${index + 1}`),
  }

  imageId: number = 0

  rotationInProgress = false

  emojiImageElement!: HTMLDivElement
  emojiSize = 70

  story: StoryInCreationProcess[] = [] // length max : 5
  storyId: number | null = null

  inputId: string = '0' 

  textInputs: TextInput[] = []

  colors: Color[] = []

  textParagraphCreated = false

  fontColor: string = '#fff'
  shadowColor: string = '#000'

  displayOverlay = false

  maxVisualsReached = false

  menusHidden = false

  contents: StoryContent[] = []
  currentInput!: StoryContent

  textContent!: StoryContent[]

  constructor(
    private appService: AppService,
    private serviceService: ServiceService,
    private storyService: StoryService,
    private _location: Location
  ){}

  ngOnInit(){
    //this.storyId = 1

    this.serviceService.getColors().subscribe(res => this.colors = res)
    this.storyService.textToolSelected.subscribe(res => this.textToolSelected = res)
    this.storyService.visualToolSelected.subscribe(res => this.visualToolSelected = res)
    this.storyService.visuals.subscribe(res => {
      this.visuals = res

      if(this.visuals.length <= 9 ) {
        this.maxVisualsReached = false
      }
    })

    this.storyService.currentInput.subscribe(res => this.currentInput = res)

    this.storyService.textParagraphCreated.subscribe(res => this.textParagraphCreated = res)
    //this.storyService.textInputs.subscribe(res => this.textInputs = res)
    this.storyService.displayOverlay.subscribe(res => this.displayOverlay = res)
    this.storyService.contents.subscribe(res => {
      this.contents = res
      this.textContent = this.contents.filter(c => c.type === 'text')
    })
  }

  cancelTextEditionOnMobile(){
    console.log('cancelTextEditionOnMobile')
    this.storyService.pendingTextValue = ''
    this.inputElement = null
    //this.storyService.textInputs.next([])
    //this.inputElementFromToolbar.emit(null)
    this.textEdition = false
    this.storyService.displayOverlay.next(false)

    //this.toolSelected = false
    this.storyService.visualToolSelected.next(false)
    this.storyService.textToolSelected.next(false)
    this.textEditionActivated.emit(false)
    //this.textToolSelected = false

    // reset currentInput avec la valeur initiale contenue dans contents
    this.contents.forEach(c => {
      if(c.type === 'text'){
        console.log(c.value)
        this.currentInput.value = c.value
      }
    })

    console.log(this.currentInput)
    this.storyService.currentInput.next(this.currentInput)

    console.log(this.storyService.currentInput)
  }

  confirmTextEditionOnMobile(){
    console.log('confirmTextEditionOnMobile')
    this.currentInput.value = this.storyService.pendingTextValue

    console.log(this.currentInput)
    
    this.storyService.currentInput.next({...this.currentInput, type: 'text', value: this.storyService.pendingTextValue})

    console.log(this.currentInput)

   
    if(!this.contents.length){
      // si visuals déjà créés et création de texte
      this.contents.push(this.currentInput)
      console.log(this.contents)
    } else {
      
      let textEdited = false
      // si édition de texte
      this.contents.forEach(c => {
        if(c.type === 'text') {
          c.value = this.storyService.pendingTextValue,
          c.fontColor = this.currentInput.fontColor,
          c.shadowColor = this.currentInput.shadowColor
          textEdited = true
        }
      })
      if(!textEdited){
        // si création de texte
        this.contents.push(this.currentInput)
      }
    }

    this.storyService.contents.next(this.contents)

    console.log(this.contents)
    this.textEdition = false
    this.textEditionActivated.emit(false)
    this.storyService.displayOverlay.next(false)

    this.storyService.textToolSelected.next(false)
    // this.textToolSelected = false

    // let str = ''
    // this.storyService.textInputs.subscribe(res => {
    //   if(!res[0]) return
    //   str = res[0].value
    // })
  }

  //permet d'afficher la popup pour sortir de la création de la story
  closeNewStory() {
    console.log('closeNewStory')
    this.warning = true
    this.showConfirmDialogFromToolbar.emit(true)

    // if((this.story.length > 0)) { // || this.image) && !this.newFeedSuccess
    //   this.warning = true
    // } else {
    //   service.setNewFeedStory({ display: false, value: null })
    //   service.hideFooter(false)
    // }
  }

  // permet de confirmer la création de la story
  confirmCloseNewStory(value: boolean) {
    console.log('confirmCloseNewStory')
    //this.warning = value

    this.warning = false
    this.showConfirmDialogFromToolbar.emit(false)

    if(value) {
      this.serviceService.setNewFeedStory({ display: false, value: null })
      this.serviceService.hideFooter(false)
    }
  }

  addTextInputOnMobile(){
    console.log('addTextInputOnMobile')

    this.storyService.textToolSelected.next(true)

    this.textEditionActivated.emit(true)
    this.storyService.displayOverlay.next(true)
  }

  editInputOnMobile(){
    console.log('editInputOnMobile')

    this.storyService.textToolSelected.next(true)
    this.textEditionActivated.emit(true)
    this.storyService.displayOverlay.next(true)
  }

  // récupère la position du drag de l'élément sélectionné
  /* getNewPos($event: any){
    console.log('getNewPos')
    if(this.appService.desktopDevice){
      if($event.x === 0 && $event.y === 0) return

      this.textPos = {
        x: $event.x,
        y: $event.y
      }
    } else {
      console.log('mobile')
      const bounds = this.mobilePhotoHolder.nativeElement.getBoundingClientRect()

      let x = $event.targetTouches[0].clientX
      let y = $event.targetTouches[0].clientY

      if(!bounds) return
      if(!$event.targetTouches[0].clientX || !$event.targetTouches[0].clientY) return

      // recalcul de x si besoin
      if($event.targetTouches[0].clientX < 0) {
        console.log('x inférieur à 0')
        x = 0
      }
      if($event.targetTouches[0].clientX > (bounds.left + bounds.width - 200)) {
        console.log('x supérieur à la largeur de l écran')
        x = bounds.left + bounds.width - 200 // 200 est la largeur de l'input      
      }

      // recalcul de y si besoin
      if($event.targetTouches[0].clientY < 0){
        console.log('y inférieur à 0')
        y = 0
      }
      if($event.targetTouches[0].clientY > (bounds.top + bounds.height - 48)) {
        console.log('y supérieur à la longueur de l écran')
        y = bounds.top + bounds.height - 48 // 48 est la hauteur de l'input      
      }

      this.textPos = {
        x: x,
        y: y
      }
    }
    console.log(this.textPos)
  } */

  // déplace l'élément sélectionné dans photo-holder
  moveTextElement(elementType: string){
    console.log('moveTextElement')
    
    if(elementType === 'paragraph'){
      const bounds = this.desktopPhotoHolder.nativeElement.getBoundingClientRect()

      if(!bounds) return
      if(!this.textPos.x || !this.textPos.y) return

      this.textParagraphElement.style.left = (this.textPos.x - bounds.left) + "px"
      this.textParagraphElement.style.top = (this.textPos.y - bounds.top) + "px"
      this.text.pos.x = (this.textPos.x - bounds.left)
      this.text.pos.y = (this.textPos.y - bounds.top)
    } else {
      const bounds = this.mobilePhotoHolder.nativeElement.getBoundingClientRect()

      if(!bounds) return
      if(!this.textPos.x || !this.textPos.y) return
      if(!this.inputElement) return

      this.inputElement.style.left = (this.textPos.x - bounds.left) + "px"
      this.inputElement.style.top = (this.textPos.y - bounds.top) + "px"

      // this.text.pos.x = (this.textPos.x - bounds.left)
      // this.text.pos.y = (this.textPos.y - bounds.top)
    }
  }

  showMenu(type: string) {
    console.log('showMenu')

    if(this.maxVisualsReached) return

    //this.toolSelected = true
    this.storyService.displayOverlay.next(true)

    switch(type){
      case 'text': {
        this.storyService.textToolSelected.next(true)
        const textInput = this.contents.filter(c => c.type === 'text' && c.value !== '')
        console.log(textInput)
        this.storyService.currentInput.next(textInput[0])
        this.storyService.visualToolSelected.next(false)
        this.showStickersList = false
        this.showEmojisList = false
        this.showMasksList = false
        this.textEditionActivated.emit(true)
      }
      break

      case 'stickers': {
        this.storyService.textToolSelected.next(false)
        this.storyService.visualToolSelected.next(true)
        this.showStickersList = !this.showStickersList
        this.showEmojisList = false
        this.showMasksList = false
        this.emojiTypeSelected = 'fun'
      }
      break

      case 'masks': {
        this.storyService.textToolSelected.next(false)
        this.storyService.visualToolSelected.next(true)
        this.showMasksList = !this.showMasksList
        this.showEmojisList = false
        this.showStickersList = false
        this.emojiTypeSelected = 'masks'
      } 
      break

      default: {
        this.storyService.textToolSelected.next(false)
        this.storyService.visualToolSelected.next(true)
        this.showEmojisList = !this.showEmojisList
        this.showStickersList = false
        this.showMasksList = false
        this.emojiTypeSelected = 'emoticons'
      }
    }
  }

  hideMenus(){
    console.log('hideMenus')
    this.storyService.textToolSelected.next(false)
    this.showEmojisList = false
    this.showStickersList = false
    this.showMasksList = false
    this.menusHidden = true
  }

  showEmojiType(type: string, index: number) {
    console.log('showEmojiType')
    this.emojiTypeSelected = type;
    this.emojiTypeIndex = index;
  }

  cancelToolSelection(){
    console.log('cancelToolSelection')
    this.showEmojisList = false
    this.showMasksList = false
    this.showStickersList = false

    this.storyService.displayOverlay.next(false)
  }

  addVisual(img: string, type: 'emoji' | 'mask' | 'sticker') {
    console.log('addVisual toolbar', type)

    const storedVisuals = this.contents.filter(c => { return c.type === 'visual'})
    if(storedVisuals.length > 9) return

    let maskHeight = 0
    let maskWidth = 0

    if(type === 'mask' && this.visualsRef.toArray().length > 0){
      const item = this.visualsRef.toArray().filter((v: ElementRef<HTMLMediaElement>) => { 
        const itemUrl = v.nativeElement.currentSrc.replace(`${environment.localDomain}assets/masks/thumb/`, '')
        return itemUrl === img + '.png' 
      })

      maskHeight = item[0].nativeElement.height
      maskWidth = item[0].nativeElement.width
    }

    const visual: StoryContent = {
      id: storedVisuals.length + 1,
      value: type === 'emoji' || type === 'sticker' ? `assets/emoticons/emojis/thumb/${img}.png` : `assets/masks/thumb/${img}.png`,
      type: 'visual',
      initialData:  { 
        height: type === 'emoji' || type === 'sticker' ? this.emojiSize : maskHeight, 
        width: type === 'emoji' || type === 'sticker' ? this.emojiSize : maskWidth,
        angle: 0,
        x: type === 'emoji' || type === 'sticker' ? 'calc(50% - ' + this.emojiSize / 2 + 'px)' : 'calc(50% - ' + maskWidth / 2 + 'px)',
        y: type === 'emoji' || type === 'sticker' ? 'calc(50% - ' + this.emojiSize / 2 + 'px)' : 'calc(50% - ' + maskHeight / 2 + 'px)'
      },
      newData: null
    }
    this.storyService.currentInput.next(visual)
    this.storyService.contents.next([...this.storyService.contents.value, visual])

    if(!this.appService.desktopDevice){
      //this.storyService.visualToolSelected.next(false)
      this.storyService.displayOverlay.next(false)
      this.hideMenus()
      this.cancelToolSelection()
    }

    const newStoredVisuals = this.contents.filter(c => { return c.type === 'visual'})

    if(newStoredVisuals.length > 9) {
     this.hideMenus()
     this.showMenu('text') // seulement si pas de texte déjà ajouté
     this.maxVisualsReached = true
    }

    //this.storyService.visualToolSelected.next(false)
  }

  changeMobileTextColor(colorCode: string, colorIndex: number){
    console.log('changeMobileTextColor')

    if(this.appService.desktopDevice) {
      if(!this.textParagraphElement) return

      this.colors.forEach(c => c.selected = false)
      this.textParagraphElement.style.color = `${colorCode}`
      this.text.fontColorHex = colorCode
    } else {
      if(!this.inputElement) return

      this.colors.forEach(c => c.selected = false)
      this.inputElement.style.color = `${colorCode}`
    }

    this.colors[colorIndex].selected = true
  }

  setTextFocus() {
    console.log('setTextFocus')
  }

  // version desktop
  onBlur(target: EventTarget | null){
    console.log('desktop onblur toolbar')
    const input = target as HTMLInputElement

    if(input.value === '') return

    const texts = this.contents.filter(c => { return c.type === 'text' })
    if(texts.length > 0 && input.value === texts[0].value) return

    if(texts.length > 0) {
      this.contents.forEach(c => {
        if(c.type === 'text'){
          c.value = input.value
        }
      })
      this.storyService.textToolSelected.next(false)
    } else {
      this.contents.push({
        id: 0,
        value: input.value.trim(),
        fontColor: '#000',
        shadowColor: '#fff',
        type: 'text',
        initialData: {
          angle: 0,
          x: `calc(50% - 100px)`,
          y: `calc(50% - 24px)`
        },
        newData: null
      })
    }

    this.currentInput = {
      id: 0,
      value: input.value.trim(),
      fontColor: '#000',
      shadowColor: '#fff',
      type: 'text',
      initialData: {
        angle: 0,
        x: `calc(50% - 100px)`,
        y: `calc(50% - 24px)`
      },
      newData: null
    }

    this.storyService.currentInput.next(this.currentInput)
    this.storyService.contents.next(this.contents)
  }

  getTextColorInfo($event: { colorHex: string; colorType: string; colorIndex: number; }){
    console.log('getTextColorInfo')
    this.changeTextColor($event.colorHex, $event.colorType, $event.colorIndex)
  }

  changeTextColor(colorCode: string, colorType: string, colorIndex: number){
    console.log('changeTextColor')

    if(this.appService.desktopDevice) {
      this.colors.forEach(c => c.selected = false)
      
      this.contents.forEach(c => {
        if(c.type === 'text'){
          c.fontColor = colorCode
          c.shadowColor = colorType === "dark" ? "#fff" : "#000"
        }
      })

      this.storyService.contents.next(this.contents)
    } else {
      this.colors.forEach(c => c.selected = false)
      this.fontColor = colorCode
    }

    this.colors[colorIndex].selected = true
  }






  // déplace l'élément sélectionné dans photo-holder
  // moveVisualElement(id: string, deltaPos?: { deltaX: number | null, deltaY: number | null }){
  //   console.log('moveVisualElement toolbar')
    
  //   const bounds = this.desktopDevice ? this.desktopPhotoHolder.nativeElement.getBoundingClientRect() : this.mobilePhotoHolder.nativeElement.getBoundingClientRect();

  //   if(!bounds) return
  //   if(!this.textPos.x || !this.textPos.y) return
  //   if(!deltaPos) return
  //   if(!deltaPos.deltaX || !deltaPos.deltaY) return

  //   this.emojiImageElement.style.left = (this.textPos.x - bounds.left - deltaPos.deltaX) + "px"
  //   this.emojiImageElement.style.top = (this.textPos.y - bounds.top - deltaPos.deltaY) + "px"

  //   const newPosX = this.textPos.x - bounds.left
  //   const newPosY = this.textPos.y - bounds.top

  //   console.log(this.storyService.story)
  //   this.storyService.story.forEach(image => {
  //     image.content.visuals.forEach(v => {
  //       if(v.id === parseInt(id)){ // Problème de condition. v.id ne doit pas être bon.
  //         console.log(v)
  //         v.pos.x = newPosX
  //         v.pos.y = newPosY
  //       }
  //     })
  //   })

  //   console.log(this.storyService.story)
  // }
  
  // selectTextMenu(){
  //   console.log('selectTextMenu')
  //   this.textToolSelected = !this.textToolSelected
  // }
}
