<div class="row">
  <div class="col-sm-6">
    <div class="img-container" [ngStyle]="{
      'width': photoContainerDimensions ? photoContainerDimensions.width + 'px' : '200px', 
      'height': photoContainerDimensions ? photoContainerDimensions.height + 'px' : '200px'
    }">
      <img #image [src]="imageSource" crossorigin alt="">
    </div>
  </div>
</div>
