import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AppService } from '../../app.service';

import { User } from '../interfaces/user';
import { Gender } from '../enum/Gender';

@Component({
  selector: 'app-attraction-input',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './attraction-input.component.html',
  styleUrl: './attraction-input.component.scss'
})
export class AttractionInputComponent implements OnInit, OnDestroy {
  @Input() user!: User | null
  @Input() parentName!: string
  @Output() emitAttraction = new EventEmitter<Gender.woman | Gender.man>()

  loading = false
  attractionFormGroup!: FormGroup
  loaderSubscription!: Subscription

  constructor(
    private appService: AppService
  ){}

  ngOnInit(){
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    this.attractionFormGroup = new FormGroup({
      attractionCtrl: new FormControl(this.user?.attraction || this.user?.sex === 'man' ? Gender.woman : Gender.man || '', [Validators.required])
    });
  }

  setAttraction(value: {attractionCtrl: Gender.man | Gender.woman}){
    this.emitAttraction.emit(value.attractionCtrl)
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
