import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { AuthService } from '../../auth.service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService)
  const authToken = authService.get('token')

  // Clone the request and replace the original headers with
  // cloned headers, updated with the authorization.
  const authReq = req.clone({ setHeaders: { 
    'Authorization': authToken ? `Bearer ${authToken}` : '',
    'Content-Type':  'application/json',
    'Accept': 'application/x.nda.v6+json'
    } 
  })

  // send cloned request with header to the next handler.
  return next(authReq);
}