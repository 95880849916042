<section class="coach-stories">
    <div class="top">
        <h3><span class="regular">My</span> Stories</h3>
    </div>

    @if(!stories.length){
      <div class="no-stories-container">
        <p class="no-stories">No stories for the moment.</p>
      </div>
    } @else {
      <div class="stories-container" #storiesContainer>
        @for(s of stories; track s){
            <div class="story-container" [ngStyle]="getStyles()">
                <app-story [story]="s"></app-story>
            </div>
        }
      </div>
    }
</section>
