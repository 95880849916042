import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Validators, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { RouterModule, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';

import { AuthService } from '../../auth.service';
import { LoaderService } from '../../loader.service';

import { HeaderComponent } from '../../header/header.component';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';
import { LoaderComponent } from '../../shared/loader/loader.component';

import { emailOrPhoneValidator } from '../../shared/validators/emailOrPhoneValidator';

import { ClickAnywhereDirective } from '../../click-anywhere.directive';

@Component({
  selector: 'app-auth-password',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterModule, HeaderComponent, ReactiveFormsModule, ErrorDialogComponent, GoBackButtonComponent, LoaderComponent, ClickAnywhereDirective],
  templateUrl: './auth-password.component.html',
  styleUrls: ['./auth-password.component.scss']  
})
export class AuthPasswordComponent implements OnDestroy {
  @ViewChild('submitBtnContainer') submitBtnContainer!: ElementRef
  @ViewChild('btn') submitBtn!: ElementRef
  @ViewChild('check') check!: ElementRef

  loading$ = this.loaderService.loading$;
  public resetPasswordFormGroup: FormGroup
  openDialog = false
  errorMessage: string | null = null
  errorTitle: string | null = null
  passwordReset = false
  private readonly _destroyed = new Subject<void>()

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService
  ) {
    this.resetPasswordFormGroup = new FormGroup({
      emailOrPhoneCtrl: new FormControl('', [Validators.required, emailOrPhoneValidator()])
    });
  }

  getClickNature(clickNature: string){
    if(clickNature === 'anywhere' && this.openDialog === true){
      this.openDialog = false
    }
  }

  onSubmit() {
    this.loaderService.show()
    const inputValue = this.resetPasswordFormGroup.get('emailOrPhoneCtrl')?.value

    this.authService
    .authRecoverPassword(inputValue)
    .pipe(
      takeUntil(this._destroyed)
    ).subscribe({
      next: (() => {
        //this.router.navigate(['/login?user=' + inputValue]);

        this.passwordReset = true
        this.loaderService.hide()

        setTimeout(() => {
          this.check.nativeElement.style.opacity = "1"
          this.submitBtn.nativeElement.style.opacity = "0"
        }, 1000)
      }),
      error: (() => {
        this.loaderService.hide()
        this.passwordReset = false
      })
      // error: (err => { 
      //     this.loaderService.hide()
      //     this.passwordReset = false
      //     if(err.error.message){
      //       // TODO: clés de traduction à ajouter
      //       this.errorTitle = $localize`Login details are incorrect`
      //       this.errorMessage = $localize`Please check and re-enter your data.`
      //       // TODO: clés de traduction à ajouter
      //       this.openDialog = true
      //     }
      //  })
      })
  }

  closeDialog(){
    this.openDialog = false
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
