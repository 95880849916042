import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-info-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './info-dialog.component.html',
  styleUrl: './info-dialog.component.scss'
})
export class InfoDialogComponent implements OnChanges {
  @Input() infoDialogType!: string
  @Output() closeInfoDialog = new EventEmitter<boolean>()

  title = ''
  description = ''
  iconSrc = ''
  buttonText = ''

  ngOnChanges(){
    console.log(this.infoDialogType)

    this.title = this.infoDialogType === 'contactProfile' ? `Send a message` : `Like a profile`
    this.description = this.infoDialogType === 'contactProfile' ? 
      `Send a message: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus euismod dolor in leo scelerisque ullamcorper.` : 
      `Like a profile: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus euismod dolor in leo scelerisque ullamcorper.`
    this.iconSrc = this.infoDialogType === 'contactProfile' ? 'assets/icons/icon_message.svg' : 'assets/icons/icon_love_32.svg'
    this.buttonText = this.infoDialogType === 'contactProfile' ? 'Send a private message' : 'Like this profile'
  }

  closeDialog(){
    this.closeInfoDialog.emit(true)
  }
}
