<button type="button" class="story-photo-container" (click)="viewStory(story)">
    @if(story.photos.length){
      <img [src]="story.photos[0].url || story.photos[0]" alt="" class="item" width="227" height="318">
    }
    <div class="photo-overlay"></div>
    <div class="story-information">
        <div class="top">
            <div class="avatar">
              @if(story.user && story.user.photo){
                <img [src]="story.user.photo" alt="" [width]="isDesktop() ? 48 : 32" [height]="isDesktop() ? 48 : 32">
              } @else if (user){
                <img [src]="user.main_photo?.url" alt="" [width]="isDesktop() ? 48 : 32" [height]="isDesktop() ? 48 : 32">
              } @else {
                <img src="../../assets/icons/icon_user_96X96.png" alt="" class="no-photo" [width]="isDesktop() ? 48 : 32" [height]="isDesktop() ? 48 : 32">
              }
            </div>
            <div class="firstname-container">
              @if(story.user){
                <p class="firstname">{{story.user.firstname}}</p>
              } @else {
                <p class="firstname">{{user?.firstname}}</p>
              }
              {{story.id}}
            </div>
        </div>
  
        <div class="stats bottom">
            <div class="likes">
                <img src="assets/icons/icon_love_32.svg" alt="" class="icon" width="19" height="19">
                {{story.likes}}
            </div>
            <div class="views">
                <img src="assets/icons/icon_view.png" alt="" class="icon" width="19" height="15.67">
                {{story.views}}
            </div>
        </div>
    </div>
  </button>