import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';

@Component({
  selector: 'app-report-user-dialog',
  standalone: true,
  imports: [],
  templateUrl: './report-user-dialog.component.html',
  styleUrl: './report-user-dialog.component.scss'
})
export class ReportUserDialogComponent {
  // create reference to dialog and pass the data type to pass to parent component when dialog is closed (boolean) to trigger save or cancel
  dialogRef = inject<DialogRef<boolean>>(DialogRef<boolean>);
  data = inject(DIALOG_DATA);
}
