import { Component, AfterViewInit, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { TitleCasePipe, CommonModule } from '@angular/common';
import dayjs from 'dayjs';
import { map, Subject, switchMap, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

import { StoriesService } from '../../stories.service';
import { ProfileService } from '../profile.service';
import { LoaderService } from '../../loader.service';
import { LayoutService } from '../../layout.service';
import { HomeService } from '../../home/home.service';

import { StoryComponent } from '../../story/story.component';
import { LoaderComponent } from '../../shared/loader/loader.component';

import { User } from '../../shared/interfaces/user';
import { Story } from '../../shared/interfaces/story';

@Component({
  selector: 'app-profile-stories',
  standalone: true,
  imports: [StoryComponent, TitleCasePipe, CommonModule, LoaderComponent],
  templateUrl: './profile-stories.component.html',
  styleUrl: './profile-stories.component.scss'
})
export class ProfileStoriesComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('storiesContainer') storiesContainer!: ElementRef

  loading$ = this.loaderService.loading$
  stories!: Story[]
  profileId!: string
  profiles!: User[]
  profile!: User | null
  userAge!: number
  storyWidth!: number
  noStories = false
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop
  storiesLoading = false

  constructor(
    private storiesService: StoriesService,
    private profileService: ProfileService,
    private loaderService: LoaderService,
    private layoutService: LayoutService,
    private homeService: HomeService,
    private router: Router,
  ){}

  ngOnInit(){
    this.storiesLoading = true

    this.profileService.profile.pipe(
      takeUntil(this._destroyed),
      map(user => {
        this.profile = user
    
        if(!this.profile) return
        if(this.profile.birthdate){
          const [y,m,d] = this.profile.birthdate.split('-')
          const date = `${y}-${m}-${d}`
          const birthDate = dayjs(date)
    
          this.userAge = dayjs().diff(birthDate, 'year')
        }
      }),
      switchMap(() => this.storiesService.getStoriesByUser(1, 9, this.profile!.id))
    ).subscribe(stories => {
      this.stories = stories
      this.storiesLoading = false
      if(!stories.length) this.noStories = true
    })

    // A supprimer : Récupération des fake stories pour les tests
    // this.storiesService.getFakeStories().subscribe(res =>  {
    //   this.stories = res
    // })   
  }

  ngAfterViewInit(){
    const bounds = this.storiesContainer.nativeElement.getBoundingClientRect()
    const storiesContainerWidth = bounds.right - bounds.left
    const gapWidth = 10
    let numberOfItemsPerRow = 2

    if(this.isDesktop()) numberOfItemsPerRow = 4

    this.storyWidth = (storiesContainerWidth - (numberOfItemsPerRow - 1) * gapWidth) / numberOfItemsPerRow
  }

  getStyles(){
    return {
      'width': this.storyWidth + 'px',
      'height': `${this.storyWidth * 1.4}px`,
    }
  }

  getShowStoryRequest(story: Story){
    if(!story) return

    let storyId!: number | null

    if(this.router.url.includes('/stories')){
      this.storiesService.getStoryById(story.id!).pipe(
        map((s: Story) => {
          storyId = s.id
          this.homeService.story.next(s)
        })
      ).subscribe(() => {
        this.openStoryPlayer(storyId)
      })
    } else {
      storyId = story.id
      this.openStoryPlayer(storyId)
    }
  }

  openStoryPlayer(storyId: number | null){
    this.router.navigate(['home/story/' + storyId])
    this.homeService.pageDisplayed.next('story')
    this.homeService.toggleSidenav.next(true)
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
