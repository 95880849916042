import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild, ViewEncapsulation, OnInit, HostBinding, OnDestroy, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { CarouselModule, CarouselComponent } from 'ngx-carousel-ease';
import { BehaviorSubject, combineLatest, filter, map, mergeMap, Observable, of, Subject, takeUntil } from 'rxjs';
import dayjs from 'dayjs';
import { MatSidenav } from '@angular/material/sidenav';
import { Dialog } from '@angular/cdk/dialog';

import { HomeService } from '../home/home.service';
import { ProfileService } from '../profile/profile.service';
import { AuthService } from '../auth.service';
import { EncountersService } from './encounters.service';
import { SwipeService } from '../swipe.service';
import { LayoutService } from '../layout.service';
import { LoaderService } from '../loader.service';
import { ChatService } from '../chat/chat.service';

import { GoBackButtonComponent } from '../shared/go-back-button/go-back-button.component';
import { LoaderComponent } from '../shared/loader/loader.component';
import { InfoDialogComponent } from '../dialog/info-dialog/info-dialog.component';
import { MatchDialogComponent } from '../dialog/match-dialog/match-dialog.component';

import { User } from '../shared/interfaces/user';

import { ClickAnywhereDirective } from '../click-anywhere.directive';

@Component({
  selector: 'app-new-encounters',
  standalone: true,
  imports: [RouterModule, CommonModule, CarouselModule, CarouselComponent, ClickAnywhereDirective, GoBackButtonComponent, LoaderComponent, InfoDialogComponent],
  templateUrl: './new-encounters.component.html',
  styleUrl: './new-encounters.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class NewEncountersComponent implements OnInit, OnDestroy {
  @Output() emitInfoDialogType = new EventEmitter<string>()
  @Output() pageDisplayed = new EventEmitter<string>()

  @ViewChild('suggestionDiv') suggestionDiv!: ElementRef
  @ViewChild('cardSection') cardSection!: ElementRef
  @ViewChild('description') descriptionElt!: HTMLParagraphElement
  @ViewChild('image') image!: ElementRef
  @ViewChild('encounterSidenav') encounterSidenav!: MatSidenav

  @HostBinding('class.newEncounters') addClass: boolean = true // <-- add class to the host to target it in css as encapsulation is off
  loading$ = this.loaderService.loading$;
  user$: Observable<User | null> | undefined
  user!: User
  encounters: User[] = []
  userIndex = 0
  photoIndex = 0
  encounterSidenavToggled = false
  relationshipStatus = new BehaviorSubject('')
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop

  private _swipeCoord?: [number, number];
  private _swipeTime?: number;

  encountersFromService$ = this.homeService.encounters.asObservable()
  notSubscribed = false
  showInfoDialog = false
  infoDialogType = ''
  dialog = inject(Dialog)

  constructor(
    private renderer: Renderer2,
    private homeService: HomeService,
    private location: Location,
    private profileService: ProfileService,
    private authService: AuthService,
    private encountersService: EncountersService,
    private swipeService: SwipeService,
    private layoutService: LayoutService,
    private loaderService: LoaderService,
    private router: Router,
    private chatService: ChatService
  ){}

  ngOnInit(){
    this.user$?.subscribe(res => console.log('user', res))
    this.loaderService.show()
    this.homeService.toggleEncounterSidenav.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      this.encounterSidenavToggled = res

      if(this.encounterSidenav) {
        if(this.encounterSidenavToggled){
          this.encounterSidenav.open()
        } else {
          this.encounterSidenav.close()
        }
      }
    })

    this.encountersFromService$.subscribe(res => {
      console.log('res', res)
      if(!res.length) {
        this.loaderService.hide()
      }

      this.user$ = this.encountersFromService$.pipe(
        map(res => {
          this.encounters = res

          console.log(this.encounters)

          if(this.encounters.length){
            const user: User = this.encounters[this.userIndex]
            this.profileService.profile.next(user)

            return user
          }
          
          return null
        }),
        filter(Boolean),
        mergeMap((user) =>{
          return combineLatest([of(user), this.encountersService.getRelationshipStatus(user.id)]).pipe(
            map(([user, status]) => {
              this.relationshipStatus.next(status)
  
              if(!user.birthdate) user.birthdate = '0000-00-00'
              
              const [y,m,d] = user.birthdate.split('-')
              const date = `${y}-${m}-${d}`
              const birthDate = dayjs(date)
              const userAge = dayjs().diff(birthDate, 'year')

              this.loaderService.hide()

              return {
                ...user,
                userAge
              }
            }),
          )
        })
      )
    })
  }

  swipe(e: TouchEvent, when: string, user: User): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY]
    const time = new Date().getTime()

    if (when === 'start') {
      this._swipeCoord = coord
      this._swipeTime = time
    }
    else if (when === 'end') {
      if(!this._swipeCoord || !this._swipeTime) return

      const direction = [coord[0] - this._swipeCoord[0], coord[1] - this._swipeCoord[1]]
      const duration = time - this._swipeTime

      if (duration < 1000
        && Math.abs(direction[0]) > 30 
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { 
        if (direction[0] < 0) {
          //next
          this.swipeService.swipeNext()
          this.rejectProfile(user)
        } else {
          //previous
          this.swipeService.swipePrevious()
          this.likeProfile(user)
        }
      }
    }
  }

  toggleEncounterSidenav(open: boolean){
    this.homeService.toggleEncounterSidenav.next(open)
  }

  getClickNature(clickNature: string, id: number){
    this.encountersService.markProfileAsSeen(id).subscribe()

    if(clickNature === 'anywhere'){
      this.authService.getProfile(id).pipe(
        takeUntil(this._destroyed)
      ).subscribe(res => this.profileService.profile.next(res))
    }
  }

  rejectProfile(user: User){
    let state = 'refused'
    // refuse si relation non définie de base. Si déjà refused, passe à rejected
    if(this.relationshipStatus.getValue() === "rejected") state = 'rejected'

    this.homeService.updateRelationship(user.id, state).pipe(
      map(() => {
        this.renderer.addClass(this.suggestionDiv.nativeElement, 'slide-left')

        setTimeout(() => {
          this.user = this.encounters[this.userIndex]
          this.renderer.addClass(this.suggestionDiv.nativeElement, 'appear')
    
          setTimeout(() => {
            this.renderer.removeClass(this.suggestionDiv.nativeElement, 'appear')
          }, 500)
    
          this.renderer.removeClass(this.suggestionDiv.nativeElement, 'slide-left')
        }, 1500)
      }),
    ).subscribe({
      next: () => {
        setTimeout(() => {
          this.removeProfileFromEncounters(user)
        }, 1500)
      }, 
      error: (err) => {
        if(err.error.error_code === 32){ // "message" : "This user is not subscribed" renvoyé par la route /relationship/easy
          this.notSubscribed = true
          this.showInfoDialog = true
          this.infoDialogType = 'rejectProfile'
          this.homeService.dialogDisplayed.next(true)
        } else {
          console.error(err)
        }
      }
    })
  }

  likeProfile(user: User){
    console.log('likeProfile ', user.id)
    let state = ''
    if(this.relationshipStatus.getValue() === 'notfriend'){// la relation n'existe pas
      state = 'accepted'
    } else if ( this.relationshipStatus.getValue() === 'pending') {// le profil du user connecté a été liké
      state = 'validated'
    }

    this.homeService.updateRelationship(user.id, state).pipe(
      map(() => {
        this.renderer.addClass(this.suggestionDiv.nativeElement, 'slide-right')
        this.renderer.addClass(this.cardSection.nativeElement, 'enhance')
    
        setTimeout(() => {
          this.user = this.encounters[this.userIndex]
          this.renderer.addClass(this.suggestionDiv.nativeElement, 'appear')
    
          setTimeout(() => {
            if(this.suggestionDiv.nativeElement) this.renderer.removeClass(this.suggestionDiv.nativeElement, 'appear')
          }, 500)
    
          this.renderer.removeClass(this.suggestionDiv.nativeElement, 'slide-right')
          this.renderer.removeClass(this.cardSection.nativeElement, 'enhance')
        }, 1500)
      }),
    ).subscribe({
      next: () => {
        this.profileService.setProfileLiked(user)
        setTimeout(() => {
          this.removeProfileFromEncounters(user)
        }, 1500)

        setTimeout(() => {
          this.profileService.setProfileLiked(null)
        }, 3000)

        // si la relation est à validated, afficher la popup de match
        if(state === 'validated') this.matchDialog(user)
      }, 
      error: (err) => {
        // le user peut liker jusqu'à 5 fois sans être abonné (nombre de likes vérifié côté back). Si le user n'est pas abonné et qu'il a atteint le nombre max de likes gratuits, on affiche la popup. 
        // En cliquant sur le bouton, le user sera redirigé vers la landing page.
        if(err.error.error_code === 32){ // "message" : "This user is not subscribed" renvoyé par la route /relationship/easy
          this.notSubscribed = true
          this.showInfoDialog = true
          this.infoDialogType = 'likeProfile'
          this.homeService.dialogDisplayed.next(true)
        } else {
          console.error(err)
        }
      }
    })
  }

  matchDialog(user: User) {
    this.dialog.open(MatchDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
      data: user
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!user) return

      this.contactProfile(user.id)
    })
  }
  
  closeInfoDialog(e: boolean){
    this.showInfoDialog = !e
    this.infoDialogType = ''
    this.homeService.dialogDisplayed.next(false)
  }

  removeProfileFromEncounters(user: User){
    console.log("removeProfileFromEncounters", user)
    const filteredEncounters = this.encounters.filter(e => e.id !== user.id)
    this.homeService.encounters.next(filteredEncounters)

    if(!filteredEncounters.length) {
      this.homeService.noMoreEncountersAvailable.next(true)
    }
  }

  contactProfile(relatedUserId: number){
    console.log('contactProfile: ', relatedUserId)

    /* 
    Messages directs
    Cela permet aux users H et F d’envoyer un message direct (fonctionnalité dispo uniquement pour les hommes abonnés + pour toutes les femmes).

    User A envoie un message direct à user B --> Les 2 users sont automatiquement en contact (relation à autovalidated)+ user B reçoit bien le message + au clic sur la 
    conversation, l’user B doit alors avoir un encart qui lui demande d’accepter ou de refuser la conversation avec user A.

    Si user B refuse --> On supprime alors la relation et les users ne sont plus en contact.
    L’user B ne voit plus l’user A dans ses contacts + l’user B ne voit plus la conversation.
    L’user A ne voit plus l’user B dans ses contacts MAIS il peut toujours voir sa conversation (et envoyer des messages. L’user B ne recevra juste jamais les messages).
    Si user B accepte --> On maintient alors la relation et les users peuvent continuer à s’envoyer des messages.
    */

    // check the user's sex 
    if(this.user?.sex === 'man'){
      // the user is a man
      if(this.user?.isSubscribed){
        // if user is registered, then he can send a direct message 

        // check if we already have a conversation with the relatedUser (woman).
        // if so, navigate to /chat/conversation/ + talkId
        // if not, navigate to /chat/conversation/new- + relatedUserId. La création de la nouvelle conversation se fera au moment de l'envoi du 1er message.
        this.authService.getProfile(relatedUserId).subscribe((user) => {
          if(user.talking_id) {
            this.router.navigate(['/home/my-matches/chat/conversation/' + user.talking_id])
            this.chatService.conversationToShow = user.talking_id
          } else {
            this.router.navigate(['/home/my-matches/chat/conversation/new-' + relatedUserId])
            this.chatService.conversationToShow = 0
          }
        })
        
        this.openTalk()
        this.chatService.relatedUserId.next(relatedUserId)
      } else {
        // if not, a dialog is opened to redirect the user to the LP
        this.authService.getProfile(this.user.id).subscribe()
        // si le user n'est pas abonné
        // la boîte de dialogue sera affichée. En cliquant sur le bouton, le user sera redirigé vers la landing page
        this.infoDialogType = 'contactProfile'
        this.homeService.dialogDisplayed.next(true)
      }
    } else {
      // the user is a woman
      // she can send a direct message 
      // vérifier si on a déjà une conversation avec le relatedUser H.
      // si oui, rediriger vers /chat/conversation/ + talkId
      // si non, rediriger vers /chat/conversation/new- + relatedUserId. La création de la nouvelle conversation se fera au moment de l'envoi du 1er message.
      this.authService.getProfile(relatedUserId).subscribe((user) => {
        if(user.talking_id) {
          this.router.navigate(['/home/my-matches/chat/conversation/' + user.talking_id])
          this.chatService.conversationToShow = user.talking_id
        } else {
          this.router.navigate(['/home/my-matches/chat/conversation/new-' + relatedUserId])
          this.chatService.conversationToShow = 0
        }
      })

      this.chatService.relatedUserId.next(relatedUserId)
      this.homeService.pageDisplayed.next('my-matches')
      this.chatService.toggleSidenav.next(true)
      this.homeService.dialogDisplayed.next(false)
    }
  }

  openTalk(){
    this.chatService.toggleSidenav.next(true)
    this.homeService.pageDisplayed.next('my-matches')
    this.homeService.dialogDisplayed.next(false)
  }

  closeSidenav() {
    this.homeService.toggleSidenav.next(false)
    this.homeService.pageDisplayed.next('home')
    this.location.back()
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}

/* 
  si critères de rencontre matchent => new encounters 
  croix en haut à droite pour fermer la popup new encounters
  
  Boutons d'action : 
    icône croix : rejectProfile()
    icône coeur : likeProfile()
    icône message : contactProfile()

  L'info-dialog d'envoi de message ne doit s'afficher que si le user n'est pas abonné.
  L'info-dialog de validation d'un like ne s'affiche qu'une fois que le user a liké 5 personnes (gratuitement).
  
  Si le user like un profile qui l'a déjà liké, popup de match.
*/
