import { Component, ElementRef, EventEmitter, Output, Self, ViewChild, AfterContentInit, AfterViewChecked, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { interval, take } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-range-input',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './range-input.component.html',
  styleUrl: './range-input.component.scss',
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class RangeInputComponent implements OnInit, AfterContentInit, AfterViewChecked {
  @ViewChild('fromSlider') fromSlider!: ElementRef
  @ViewChild('toSlider') toSlider!: ElementRef
  @ViewChild('fromInput') fromInput!: ElementRef
  @ViewChild('toInput') toInput!: ElementRef
  @ViewChild('minText') minText!: ElementRef
  @ViewChild('maxText') maxText!: ElementRef

  @Output() minValueSelection = new EventEmitter<number>()
  @Output() maxValueSelection = new EventEmitter<number>()

  @Input() ageRange!: {min: number, max: number}

  stepWidthInPixels = 0
  trackPosition!: {left: number, right: number}
  trackWidth = 0

  constructor(
    @Self() private element: ElementRef,
  ){}

  ngOnInit(){
    this.trackPosition = {left: this.ageRange.min, right: this.ageRange.max}
  }

  ngAfterContentInit(){ 
    interval(100).pipe(take(2)).subscribe(() => { 
      const inputWidthInPixels = this.element.nativeElement.offsetWidth - 44 // 44 est la largeur du thumb
      this.stepWidthInPixels = inputWidthInPixels / (99 - 18) // 99 est la valeur max de l'input et 18 la valeur min

      this.trackWidth = (this.trackPosition.right - this.trackPosition.left) * this.stepWidthInPixels
    })
  }

  ngAfterViewChecked(){
    this.fillSlider(this.fromSlider, this.toSlider, '#C6C6C6', '#F4706D', this.toSlider)
    this.setToggleAccessible(this.toSlider)
  }

  controlFromInput() {
    console.log('controlFromInput')
    this.fillSlider(this.fromInput, this.toInput, '#C6C6C6', '#F4706D', this.fromSlider)
  }

  getMinTextStyle(){

    console.log(this.ageRange)
    return {
      left: ((this.ageRange.min - 18) * this.stepWidthInPixels) + 'px'
    }
  }

  getMaxTextStyle(){
    return {
      left: ((this.ageRange.max - 18) * this.stepWidthInPixels) + 'px'
    }
  }
    
  controlToInput() {
    console.log('controlToInput')
    this.fillSlider(this.fromInput, this.toInput, '#C6C6C6', '#F4706D', this.fromSlider)
    this.setToggleAccessible(this.toInput)
  }

  controlFromSlider(target: EventTarget | null) {
    console.log('controlFromSlider')
    const input = target as HTMLInputElement
    this.fillSlider(this.fromSlider, this.toSlider, '#C6C6C6', '#F4706D', this.toSlider)
    this.ageRange.min = input.valueAsNumber

    this.minValueSelection.emit(this.ageRange.min)
  }

  controlToSlider(target: EventTarget | null) {
    console.log('controlToSlider')
    const input = target as HTMLInputElement
    this.fillSlider(this.fromSlider, this.toSlider, '#C6C6C6', '#F4706D', this.toSlider)
    this.setToggleAccessible(this.toSlider)
    this.ageRange.max = input.valueAsNumber

    this.maxValueSelection.emit(this.ageRange.max)
  }

  fillSlider(from: ElementRef, to: ElementRef, sliderColor: string, rangeColor: string, controlSlider: ElementRef) {
    const rangeDistance = to.nativeElement.max - to.nativeElement.min
    const fromPosition = from.nativeElement.value - to.nativeElement.min
    const toPosition = to.nativeElement.value - to.nativeElement.min

    controlSlider.nativeElement.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${fromPosition/(rangeDistance)*100}%,
      ${rangeColor} ${fromPosition/(rangeDistance)*100}%,
      ${rangeColor} ${(toPosition)/(rangeDistance)*100}%, 
      ${sliderColor} ${(toPosition)/(rangeDistance)*100}%, 
      ${sliderColor} 100%)`
  }

  setToggleAccessible(currentTarget: ElementRef) {
    if(Number(currentTarget.nativeElement.value) <= 0 ) {
      this.toSlider.nativeElement.style.zIndex = '2'
    } else {
      this.toSlider.nativeElement.style.zIndex = '0'
    }
  }
}
