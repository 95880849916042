import { AfterContentInit, AfterViewChecked, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Subject, takeUntil, throwError } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Dialog } from '@angular/cdk/dialog';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';
import { StoriesService } from '../../stories.service';
import { HomeService } from '../../home/home.service';
import { StoryService } from '../../create-story/story.service';

import { LoaderComponent } from '../../shared/loader/loader.component';
import { TopButtonComponent } from '../../shared/top-button/top-button.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';
import { DeleteStoryDialogComponent } from '../../dialog/delete-story-dialog/delete-story-dialog.component';

import { Story } from '../../shared/interfaces/story';
import { LoaderService } from '../../loader.service';
import { LayoutService } from '../../layout.service';
import { PhotoObject } from '../../create-story/images-holder/story-photos';

@Component({
  selector: 'app-my-stories',
  standalone: true,
  imports: [LoaderComponent, TopButtonComponent, GoBackButtonComponent, CommonModule],
  templateUrl: './my-stories.component.html',
  styleUrl: './my-stories.component.scss'
})
export class MyStoriesComponent implements OnInit, AfterContentInit, AfterViewChecked, OnDestroy {
  @ViewChild('top') top!: ElementRef
  @ViewChild('storiesContainer') storiesContainer!: ElementRef
  @ViewChild('myStoriesHolder', { static: true, read: ElementRef }) myStoriesHolder!: ElementRef
  
  loading$ = this.loaderService.loading$
  stories: Story[] = []
  currentPage = 1
  totalPages = 1
  viewportHeight!: number
  viewportWidth!: number
  storyWidth!: number
  loadingMore = false
  noMoreToLoad = false
  storiesLoading = true
  dialog = inject(Dialog)
  scrollValueFromHome!: Event | null
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop

  constructor(
    private appService: AppService,
    private storiesService: StoriesService,
    private location: Location,
    private homeService: HomeService,
    private router: Router,
    private loaderService: LoaderService,
    private layoutService: LayoutService,
    private storyService: StoryService
  ){}

  ngOnInit(){ 
    this.loaderService.show()
    this.storiesService.getStoriesByUser(this.currentPage, 9).pipe(
      takeUntil(this._destroyed)
    ).subscribe({
      next: (res) => {
        this.storiesLoading = true
        this.stories = res
        this.storiesLoading = false

        this.loaderService.hide()
        console.log(this.stories)
      },
      error: (err) => {
        this.storiesLoading = false
        return throwError(() => err)
      },
    });
    
    // this.storiesService.getFakeStories().subscribe(res => {
    //   this.stories = this.stories.concat(res)
    // })

    this.appService.scrollValue.subscribe(res => {
      this.scrollValueFromHome = res
      this.windowScrolling()
    })
  }

  ngAfterViewChecked(){
    if(!this.isDesktop()) return
    if(!this.storiesContainer) return

    const bounds = this.storiesContainer.nativeElement.getBoundingClientRect()
    const storiesContainerWidth = Number.parseFloat((bounds.right - bounds.left).toFixed(2))
    const gapWidth = 16
    const numberOfItemsPerRow = 3

    this.storyWidth = Math.floor((storiesContainerWidth - (numberOfItemsPerRow + 1) * gapWidth) / numberOfItemsPerRow)
  }

  ngAfterContentInit(){
    if(this.isDesktop()) return

    this.viewportHeight = window.innerHeight
    this.viewportWidth = window.innerWidth
    const gapWidth = 16
    const numberOfItemsPerRow = 2

    this.storyWidth = Math.floor((this.viewportWidth - (numberOfItemsPerRow + 1) * gapWidth) / numberOfItemsPerRow)
  }

  windowScrolling() {
    const target = this.scrollValueFromHome?.target as HTMLElement
    
    if(!target) return

    const scrollValue = target.scrollTop
    const content = this.myStoriesHolder.nativeElement

    let bottom!: boolean
    if(this.isDesktop()) {
      bottom = scrollValue > content.clientHeight - window.innerHeight - 800;
    } else {
      bottom = scrollValue > content.clientHeight - window.innerHeight - 350;
    }
    
    if(bottom && !this.loadingMore && !this.noMoreToLoad) {
      this.loadingMore = true
      this.storiesLoading = true
      this.currentPage++
      this.getStories()
    }
  }

  editStory(story: Story){
    this.router.navigate(['/edit-story/', story.id])
    this.storyService.story.next(story)
    const photos: PhotoObject[] = []

    story.photos.forEach((_, index) => {
      photos.push({ src: story.photos[index].url, saved: true })
    })

    if(story.photos.length < 5){
      for(let i = story.photos.length; i < 5; i++){
        photos.push({
          src: null,
          disabled: i === story.photos.length ? false : true,
          saved: false
        })
      }
    }

    this.storyService.storyImages.next(photos)
  }

  getStories() {
    console.log('getStories')
    this.loaderService.show()

    this.storiesService.getStoriesByUser(this.currentPage, 9).pipe(
      takeUntil(this._destroyed)
    ).subscribe({
      next: (res) => {
        this.storiesLoading = true
        this.stories = this.stories.concat(res)

        if(res.length < 9) this.noMoreToLoad = true

        this.storiesLoading = false

        this.loaderService.hide()
        if(!this.loadingMore) {
          window.scrollTo(0, 0);
        }
        this.loadingMore = false;
      },
      error: (err) => {
        this.storiesLoading = false
        return throwError(() => err)
      },
    });
  }

  goBack(){
    if(this.isDesktop()) {
      this.homeService.pageDisplayed.next('')
      this.location.back()
    } else {
      this.homeService.pageDisplayed.next('')
    }
  }

  getStyles(){
    return {
      width: `${this.storyWidth}px`,
      height: `${this.storyWidth * 1.4}px`
    }
  }

  getScrollRequest(request: string){
    if(request === 'scrollToTop') this.top.nativeElement.scrollIntoView()
  }

  openDeleteDialog(e: Event, storyId: number | null | undefined){
    console.log('openDeleteDialog')
    e.stopPropagation()

    this.dialog.open(DeleteStoryDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.subscribe((res) => {
      if(res){
        if(!storyId) return
        this.storiesService.deleteStory('story', storyId).pipe(
          takeUntil(this._destroyed)
        ).subscribe(() => {
          const storyToDelete = this.stories.filter(s => s.id === storyId)
          const index = this.stories.indexOf(storyToDelete[0])
  
          this.stories.splice(index, 1)
  
          this.router.navigate(['/home/my-space/my-stories'])
          this.noMoreToLoad = false
          this.currentPage = 0
          this.top.nativeElement.scrollIntoView()
        })
      } else {
        this.router.navigate(['/home/my-space/my-stories'])
        this.noMoreToLoad = false
        this.currentPage = 0
        this.top.nativeElement.scrollIntoView()
      }
    })
  }

  showStory(s: Story) {
    this.router.navigate(['home/story/' + s.id])
    this.homeService.pageDisplayed.next('story')
    this.homeService.toggleSidenav.next(true)
    this.storiesService.story.next(s)
    this.storiesService.storyOwnedByMe = true
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
