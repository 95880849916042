import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subject } from 'rxjs';

import { AppService } from '../../../app.service';
import { AuthService } from '../../../auth.service';
import { LoaderService } from '../../../loader.service';

import { HeaderComponent } from '../../../header/header.component';
import { CityInputComponent } from '../../../shared/city-input/city-input.component';
import { ProgressBarComponent } from '../../../shared/progress-bar/progress-bar.component';
import { GoBackButtonComponent } from '../../../shared/go-back-button/go-back-button.component';
import { LoaderComponent } from '../../../shared/loader/loader.component';

import { City } from '../../../shared/interfaces/city';

@Component({
  selector: 'app-auth-subscribe-second',
  standalone: true,
  imports: [
    CommonModule, 
    ReactiveFormsModule, 
    HeaderComponent, 
    RouterModule, 
    CityInputComponent, 
    ProgressBarComponent, 
    GoBackButtonComponent,
    LoaderComponent
  ],
  templateUrl: './auth-subscribe-second.component.html',
  styleUrl: './auth-subscribe-second.component.scss'
})
export class AuthSubscribeSecondComponent implements OnInit, OnDestroy {
  @Input() citySet: { id: number | null, name: string | null}  = { id: null, name: null }

  loading$ = this.loaderService.loading$
  cityChoice: City[] | null = null; // liste des suggestions
  cityFocus = false
  cityChoiceDisplay = false
  error = false;
  cities: City[] = []
  hightlightedSteps: {
    first: boolean, 
    second?: boolean, 
    third?: boolean, 
    fourth?: boolean
  } = { first: true, second: true }

  subSecondFormGroup!: FormGroup

  user = null
  private readonly _destroyed = new Subject<void>()

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService
  ){}
  
  ngOnInit(){
    this.citySet = { 
      id: parseInt(this.authService.get('city_id')),
      name: this.authService.get('city_name')
    }

    // vérifier si les infos de l'étape 1 d'inscription sont disponibles
    if(!this.authService.get('sex') || !this.authService.get('birthdate')) this.router.navigate(['/subscribe-step-one'])
  
    this.subSecondFormGroup = new FormGroup({})

    console.log(this.subSecondFormGroup)
  }

  onSubmit(){
    const data = this.subSecondFormGroup.value
    console.log(data)

    this.authService.setSubscriptionFormData('city_id', this.citySet.id)
    // this.authService.setSubscriptionFormData('city_id', data.cityCtrl.id)
    // if(data.cityCtrl.id){
    //   this.authService.set('city_id', data.cityCtrl.id.toString())
    // }
    // if(data.cityCtrl.name){
    //   this.authService.set('city_name', data.cityCtrl.name)
    // }

    this.router.navigate(['/subscribe-step-three'], { relativeTo: this.route })
  }

  getCitySelected($event: City | null){
    this.citySet = {
      id: $event ? $event.id : null,
      name: $event ? $event.name : null
    }

    this.citySet.id ? this.authService.set('city_id', this.citySet.id.toString()) : this.authService.remove('city_id')
    this.citySet.name ? this.authService.set('city_name', this.citySet.name) : this.authService.remove('city_name')
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
