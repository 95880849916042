import { Component, Input } from '@angular/core';

import { Story } from '../shared/interfaces/story';

@Component({
  selector: 'app-story',
  standalone: true,
  imports: [],
  templateUrl: './story.component.html',
  styleUrl: './story.component.scss'
})
export class StoryComponent {
  @Input() story!: Story
}
