<h3>Are you sure you want to unmatch?</h3>

<p class="description">This person will no longer be able to contact you.</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()">
    Cancel
  </button>
  <button class="submit" (click)="dialogRef.close('unmatch')">
    <img src="assets/icons/icon_unmatch.svg" alt="" class="unmatch-icon">
    Unmatch
  </button>
</div>
