import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { RouterModule, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

import { AuthService } from '../../auth.service';
import { AppService } from '../../app.service';

import { HeaderComponent } from '../../header/header.component';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';
import { LoaderComponent } from '../../shared/loader/loader.component';

import { emailOrPhoneValidator } from '../../shared/validators/emailOrPhoneValidator';

import { ClickAnywhereDirective } from '../../click-anywhere.directive';

@Component({
  selector: 'app-auth-password',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterModule, HeaderComponent, ReactiveFormsModule, ErrorDialogComponent, GoBackButtonComponent, LoaderComponent, ClickAnywhereDirective],
  templateUrl: './auth-password.component.html',
  styleUrls: ['./auth-password.component.scss']  
})
export class AuthPasswordComponent implements OnInit, OnDestroy {
  @ViewChild('submitBtnContainer') submitBtnContainer!: ElementRef
  @ViewChild('btn') submitBtn!: ElementRef
  @ViewChild('check') check!: ElementRef

  public resetPasswordFormGroup: FormGroup
  openDialog = false
  errorMessage: string | null = null
  errorTitle: string | null = null
  passwordReset = false
  loading = false

  serviceName = environment.serviceName
  loaderSubscription!: Subscription

  constructor(
    private authService: AuthService,
    private appService: AppService,
  ) {
    this.resetPasswordFormGroup = new FormGroup({
      emailOrPhoneCtrl: new FormControl('', [Validators.required, emailOrPhoneValidator()])
    });
  }

  ngOnInit(): void {
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)
  }

  getClickNature(clickNature: string){
    if(clickNature === 'anywhere' && this.openDialog === true){
      this.openDialog = false
    }
  }

  onSubmit() {
    this.appService.setLoader(true)
    
    const inputValue = this.resetPasswordFormGroup.get('emailOrPhoneCtrl')?.value

    this.authService
    .authRecoverPassword(inputValue)
    .subscribe({
      next: (() => {
        this.appService.setLoader(false)
        //this.router.navigate(['/login?user=' + inputValue]);

        this.passwordReset = true

        setTimeout(() => {
          this.check.nativeElement.style.opacity = "1"
          this.submitBtn.nativeElement.style.opacity = "0"
        }, 1000)
      }),
      error: (err => { 
          this.passwordReset = false
          if(err.error.message){
            this.errorTitle = "Login details are incorrect"
            this.errorMessage = "Please check and re-enter your data."
            this.openDialog = true
            this.appService.setLoader(false)
          }
       })
      })
  }

  closeDialog(){
    this.openDialog = false
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
