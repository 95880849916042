<app-go-back-button (click)="closeSidenav();"></app-go-back-button>

<section class="content">
  <div class="popup">
      <h2><span class="regular">New</span> Encounters!</h2>

      @if(!user){
        <p class="no-encounters">
          No new encounters for the moment.
        </p>
      } @else {
        <div class="suggestion" #suggestionDiv>
          <div class="card-shadow"></div>
          <section 
            class="card" 
            #cardSection 
            (touchstart)="swipe($event, 'start', user)" 
            (touchend)="swipe($event, 'end', user)"
          >
              <div class="left">
                @if(user.photos && user.photos.length < 2) {
                  <div class="photo-container">
                    @if(user.photos[0]){
                      <img [src]="user.photos[0].url" alt="" class="photo" [routerLink]="['photos']" [queryParams]="{photoIndex: 0}">
                    } @else {
                      <img src="../../assets/icons/icon_user_400X400.png" alt="" class="no-photo">
                    }
                  </div>
                } @else {
                  <div class="carousel-component-container">
                    <carousel
                        [infinite]="true"
                        [autoPlay]="true"
                        [autoPlayAtStart]="true"
                        [autoPlayInterval]="2500"
                        [displayAutoPlayControls]="false"
                        [arrows]="false"
                        [counter]="false"
                        [slideToShow]="1"
                        [gapBetweenSlides]="6"
                        [slideMaxWidth]="400"
                        [responsive]="true"
                    >
                        @for(p of user.photos; track $index){
                            <div class="carousel-slide" [routerLink]="['photos']" [queryParams]="{photoIndex: $index}">
                                <img [src]="p.url" alt="" class="photo" >
                            </div>
                        }
                    </carousel>
                  </div>
                }
              </div>
              
              <div class="information">
                  <h3 
                    [routerLink]="['profile/' + user.id + '/space']" appClickAnywhere (clickNature)="getClickNature($event, user.id)"
                  >{{user.firstname}}</h3>
                  <p>{{userAge}} years - {{user.city?.name ? user.city?.name : user.city}}</p>
                  <div class="searching-container">
                      <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
                      {{user.attraction | titlecase}}
                  </div>
                  <p class="description" #description>
                      @if(user.description){
                        {{user.description}}
                      } @else {
                        Passport to Love: Seeking an Adventure Partner! I don't follow guidebooks; I forge my own path. Local experiences and cultural immersion are my souvenirs. Authenticity is key; I want to experience the world as it truly is. My ideal travel partner shares this insatiable curiosity, this desire to dive deep into the heart of every destination.
                      }
                  </p>
              </div>
          </section>
        </div>
      
        <div class="actions">
            <button 
                alt="Close this profile" 
                class="close-container"
                (click)="rejectProfile(user)"
            >
                <span class="material-symbols-rounded close">close</span>
            </button>

            <!-- Swipi V2 -->
            <!-- @if(relationshipStatus.value === "accepted"){
              <div class="accepted-mention">
                <p>{{user.sex === 'man' ? 'He' : 'She'}} already liked your profile!</p>
                <div class="arrow-down"></div>
              </div>
            } -->
            
            <button 
                alt="Like this profile" 
                class="like-container"
                (click)="likeProfile(user)"
            ><!-- afficher au clic une info-dialog si le user n'est pas abonné -->
                <img src="assets/icons/icon_love_32.svg" alt="" class="like">
            </button>

            <button 
                alt="Send a message" 
                class="message-container"
                (click)="contactProfile(user.id)"
            ><!-- afficher au clic une info-dialog si le user n'est pas abonné -->
                <img src="assets/icons/icon_message.png" alt="" class="message">
            </button>
        </div>    
      }
  </div>
</section>

<router-outlet></router-outlet>
