import { Router, CanActivateFn } from '@angular/router';
import { Injectable, inject } from '@angular/core';

import { AuthService } from '../../auth.service';
import { CriteriaService } from '../../criteria.service';

import { User } from '../../shared/interfaces/user';

Injectable({
  providedIn: 'root'
})
export const loginGuard: CanActivateFn = (route) => {
  const authService: AuthService = inject(AuthService)
  const criteriaService: CriteriaService = inject(CriteriaService)
  const router = inject(Router);

  // j'ai un token
  const token = authService.get('token')
  // on vérifie la validité du token
  if(token){
    criteriaService.getCriteria().subscribe({
      next: res => {
        if(!res.user_id){
          // je ne suis pas ou plus connecté
          router.navigate(['/login'])
          return false
        } else {
          // j'ai un token valide
          // je suis connecté mais je n'ai pas de photo de profil
          let user!: User | null 
          authService.user.subscribe(res => user = res)
          if(user && !user?.main_photo){
            router.navigate(['/upload-photo'])
            return true
          }

          // je suis connecté et je tente d'accéder au process d'inscription
          if(route.url[0].path.includes('subscribe')){
            router.navigate(['/home'])
            return true
          }

          return true
        }
      },
      error: () => {
        // je ne suis pas ou plus connecté
        router.navigate(['/login'])
        return false 
      }
    })
  } else {
    // je n'ai pas de token
    if(route.url[0].path.includes('subscribe')){
      return true
    } else {
      router.navigate(['/login'])
      return false
    }
  }

  return true
};