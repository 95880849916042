import { AfterViewChecked, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import dayjs from 'dayjs';
import { Dialog } from '@angular/cdk/dialog';
import { CommonModule, Location } from '@angular/common';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';

import { HomeService } from '../../home/home.service';
import { AuthService } from '../../auth.service';
import { StoriesService } from '../../stories.service';
import { SwipeService } from '../../swipe.service';
import { LayoutService } from '../../layout.service';
import { UrlService } from '../../url.service';
import { LoaderService } from '../../loader.service';

import { StoryPlayerDialogComponent } from '../../dialog/story-player-dialog/story-player-dialog.component';
import { FlagStoryDialogComponent } from '../../dialog/flag-story-dialog/flag-story-dialog.component';
import { BlockUserDialogComponent } from '../../dialog/block-user-dialog/block-user-dialog.component';
import { ReportUserDialogComponent } from '../../dialog/report-user-dialog/report-user-dialog.component';
import { LikersDialogComponent } from '../../dialog/likers-dialog/likers-dialog.component';
import { LoaderComponent } from '../loader/loader.component';

import { Story } from '../interfaces/story';
import { User } from '../interfaces/user';

@Component({
  selector: 'app-story-player',
  standalone: true,
  imports: [CommonModule, RouterModule, LoaderComponent],
  templateUrl: './story-player.component.html',
  styleUrl: './story-player.component.scss'
})
export class StoryPlayerComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('storyPhotoContainer') storyPhotoContainer!: ElementRef
  @ViewChild('progressionBar') progressionBar!: ElementRef

  loading$ = this.loaderService.loading$;
  stories: Story[] = []
  story: Story | null = null
  storyOwner!: User
  storyOwnerAge!: number
  dialog = inject(Dialog)
  currentPhotoIndex = 0
  private _swipeCoord?: [number, number];
  private _swipeTime?: number;

  viewportHeight!: number
  progressionBarWidth!: number
  private readonly _destroyed = new Subject<void>()
  storyLiked = false
  isDesktop = this.layoutService.isDesktop
  previousUrl!: string | null
  likers = new BehaviorSubject<User[]>([])
  storyOwnedByMe = false
  
  constructor(
    private route: ActivatedRoute,
    private homeService: HomeService,
    private authService: AuthService,
    public storiesService: StoriesService,
    private swipeService: SwipeService,
    private layoutService: LayoutService,
    private location: Location,
    private urlService: UrlService,
    private router: Router,
    private loaderService: LoaderService
  ){}

  ngOnInit(){
    this.loaderService.show()
    this.previousUrl = this.urlService.getPreviousUrl()

    console.log('ng on init story player')
    this.storiesService.story.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      this.story = res

      console.log(this.story)
  
      if(this.storiesService.storyOwnedByMe){
        // if I am the story owner
        this.storyOwnedByMe = true

        // retrieve the likers on my story
        console.log(this.story?.likes)
        if(this.story?.likes){
          if(!this.story?.id) return 
          this.storiesService.getCrushesByStory(this.story.id, 'story').subscribe(likers => {
            this.likers.next(likers)
            this.loaderService.hide()
          })
        } else {
          this.loaderService.hide()
        }
      } else {
        this.storyOwnedByMe = false

        if(!this.story?.already_seen){
          // if I am not the story owner and I've never seen the story (param already_seen), increment the number of views by calling getStoryById
          this.storiesService.getStoryById(this.route.snapshot.params['id']).subscribe(() => {
            if(!this.story?.views) return
            this.story.views++
          })
        }

        if(this.story?.likes){
          if(!this.story?.id) return 
          // check if the user has liked the story
          this.storiesService.getCrushesByStory(this.story.id, 'story').subscribe(likers => {
            this.likers.next(likers)
            const filteredLikers = likers.filter(liker => liker.id === this.authService.authMe$.getValue()!.id)
            this.storyLiked = !!filteredLikers.length
            this.loaderService.hide()
          })
        }

        if(!this.story?.user?.id) return
        this.authService.getProfile(this.story.user.id).subscribe(res => {
          this.storyOwner = res

          if(this.storyOwner.birthdate){
            const [y,m,d] = this.storyOwner.birthdate.split('-')
            const date = `${y}-${m}-${d}`
            const birthDate = dayjs(date)
      
            this.storyOwnerAge = dayjs().diff(birthDate, 'year')
          }

          this.loaderService.hide()
        })

        this.authService.authMe$.subscribe(res => {
          if(!res) return
          this.storyOwner = res

          if(this.storyOwner.birthdate){
            const [y,m,d] = this.storyOwner.birthdate.split('-')
            const date = `${y}-${m}-${d}`
            const birthDate = dayjs(date)
      
            this.storyOwnerAge = dayjs().diff(birthDate, 'year')
          }

          this.loaderService.hide()
        }) 
      }
    })
    
    this.viewportHeight = window.innerHeight

    // regarder si le user a déjà liké la story
    //this.storiesService.getCrushesByStory(this.story.id, 'story').subscribe(res => console.log(res))

    this.currentPhotoIndex = 0
  }

  ngAfterViewChecked(){
    if(!this.progressionBar || this.progressionBarWidth > 0) return

    const progressionBarBounds = this.progressionBar.nativeElement.getBoundingClientRect()
    this.progressionBarWidth = progressionBarBounds.width
  }

  close(e: Event){
    e.stopPropagation()
    console.log('close')

    console.log(this.previousUrl)
    // if(this.previousUrl?.includes('/my-stories') || this.previousUrl?.includes('/stories')){
    //   this.location.back()
    // } else {
    //   this.router.navigate(['/home'])
    //   this.homeService.toggleSidenav.next(false)
    //   this.homeService.pageDisplayed.next('home')
    //   this.currentPhotoIndex = 0
    // }

    this.location.back()
    // this.homeService.toggleSidenav.next(false)
    //   this.homeService.pageDisplayed.next('home')
    //   this.currentPhotoIndex = 0
  }

  getBarStyles(){
    if(!this.story) return
    return {
      width: `${this.progressionBarWidth / this.story.photos.length}px`
    }
  }

  likeStory(e: Event, story: Story){
    e.stopPropagation()
    if(!story || !story.id) return

    this.storiesService.addCrushOnStory(story.id, 'story').pipe(
      takeUntil(this._destroyed)
    ).subscribe(() => {
      this.storyLiked = true
      if(this.story?.likes !== null && this.story?.likes !== undefined) this.story.likes++
      console.log(this.storyLiked)
    })
  }

  unlikeStory(e: Event, story: Story){
    e.stopPropagation()
    if(!story || !story.id) return

    this.storiesService.removeCrushOnStory(story.id, 'story').pipe(
      takeUntil(this._destroyed)
    ).subscribe(() => {
      if(this.story?.likes !== null && this.story?.likes !== undefined) this.story.likes--
      this.storyLiked = false
      this.story!.liked = false
    })
  }

  showNextPhoto(){
    if(!this.story) return
    if(this.currentPhotoIndex === this.story.photos.length-1) {
      this.currentPhotoIndex = 0
    } else {
      this.currentPhotoIndex++
    }
  }

  showPreviousPhoto(){
    if(!this.story) return
    if(this.currentPhotoIndex === 0) {
      this.story.photos.length-1
    } else {
      this.currentPhotoIndex--
    }
  }

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY]
    const time = new Date().getTime()

    if (when === 'start') {
      this._swipeCoord = coord
      this._swipeTime = time
    }
    else if (when === 'end') {
      if(!this._swipeCoord || !this._swipeTime) return

      const direction = [coord[0] - this._swipeCoord[0], coord[1] - this._swipeCoord[1]]
      const duration = time - this._swipeTime

      if (duration < 1000
        && Math.abs(direction[0]) > 30 
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { 
        if (direction[0] < 0) {
          //next
          this.swipeService.swipeNext()
          this.showNextPhoto()
        } else {
          //previous
          this.swipeService.swipePrevious()
          this.showPreviousPhoto()
        }
      }
    }
  }

  getStoryPhotoContainerStyles(){
    if(!this.isDesktop()) return null

    return {
      height: `${this.viewportHeight}px`,
      width: `${this.viewportHeight / 1.4}px`,
      margin: 'auto'
    }
  }

  stopClickPropagation(e: Event) {
    e.stopPropagation()
  }

  openStoryDialog(e: Event) {
    e.stopPropagation()
    this.dialog.open(StoryPlayerDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe((res) => {
      console.log(res)
      if(res === 'flag'){
        this.flagStoryDialog()
      } else if(res === 'block'){
        this.blockUserDialog()
      } else {
        this.blockAndReportUserDialog()
      }
    })
  }

  flagStoryDialog() {
    this.dialog.open(FlagStoryDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.story?.id) return
      this.homeService.createReportBetweenUsers(this.story.id, 'story').pipe(
        takeUntil(this._destroyed)
      ).subscribe(res => console.log(res))
    })
  }

  blockUserDialog() {
    this.dialog.open(BlockUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.story?.user) return
      this.homeService.updateRelationship(this.story.user.id, 'blocked').pipe(
        takeUntil(this._destroyed)
      ).subscribe(res => console.log(res))
    })
  }

  blockAndReportUserDialog() {
    this.dialog.open(ReportUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.story?.user || !this.story.user.id) return

      this.homeService.createReportBetweenUsers(this.story.user.id, 'user').pipe(
        takeUntil(this._destroyed)
      ).subscribe(() => {
        if(!this.story?.user) return
        this.homeService.updateRelationship(this.story.user.id, 'blocked_with_report').pipe(
          takeUntil(this._destroyed)
        ).subscribe(res => console.log(res))
      })
    })
  }

  openLikersDialog() {
    if(!this.story?.id) return

    this.dialog.open(LikersDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
      data: this.likers.getValue()
    }).closed.pipe(
      filter(likerId => !!likerId)
    ).subscribe((likerId) => {
      this.router.navigate(['/home/story', this.storiesService.story.getValue()?.id,'profile',likerId,'space'])
    })
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
