import { Injectable } from '@angular/core';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';

import { Visual } from '../shared/interfaces/visual';
import { StoryContent } from '../shared/interfaces/story-content';
import { StoryImage } from '../shared/interfaces/story-image';
import { FastAverageColor } from 'fast-average-color';
import { StoriesService } from '../stories.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class StoryService {
  // story: {
  //   imageId: number | null;
  //   userId: number;
  //   order: number;
  //   photo: string;
  //   content: {
  //     text: {
  //       value: string; 
  //       colorHex: string; 
  //       shadowColor: string;
  //       pos: {x: number; y: number;}
  //     }[];
  //     visuals: Visual[] // length max : 10
  //   }
  // }[] = [] // length max : 5

  visuals = new BehaviorSubject<Visual[]>([])

  mobilePhotoHolderBounds!: DOMRect
  desktopPhotoHolderBounds!: DOMRect

  textToolSelected = new BehaviorSubject<boolean>(false)
  visualToolSelected = new BehaviorSubject<boolean>(false)

  inputId!: number

  textParagraphCreated = new BehaviorSubject<boolean>(false)

  displayOverlay = new BehaviorSubject<boolean>(false)

  imageToSave = new BehaviorSubject<boolean>(false)

  storyImages = new BehaviorSubject<StoryImage[]>([])

  storyCompleted = false
  /* infos à envoyer pour ajouter une image à une story 
      photo_b64: img,
      story_id: id.toString(),
      type: 'story', */

  imageProcessed = new BehaviorSubject<boolean>(false)
  contents = new BehaviorSubject<StoryContent[]>([])

  currentInput = new BehaviorSubject<StoryContent>({
    id: 0,
    value: '',
    fontColor: '#fff',
    shadowColor: '#000',
    type: 'text',
    initialData: {
      size: 20,
      angle: 0,
      x: 'calc(50% - 100px)',
      y: 'calc(50% - 48px)',
    },
    newData: null
  })

  selectedImageId = new BehaviorSubject<number>(0)
  storyId = 0

  pendingTextValue = ''

  constructor(
    private storiesService: StoriesService,
    private router: Router
  ){}

  loadImageFromUrl(url: string): Observable<HTMLImageElement> {
    return new Observable((subscribe) => {
      const image = new Image()
      image.src = url

      image.onload = () => {
        subscribe.next(image)
        subscribe.complete()
      }

      image.onerror = () => {
        subscribe.error('Not found')
      }
    })
  }

  loadManyImagesFromUrl(urls: string[]): Observable<HTMLImageElement[]> {
    return forkJoin(urls.map((url) => this.loadImageFromUrl(url))).pipe(defaultIfEmpty([]))
  }

  saveStoryImage(){
    console.log('saveStoryImage')
    const bgCanvas = document.getElementById('canvas') as HTMLCanvasElement
    const bgContext = bgCanvas.getContext('2d')

    if(this.storyImages.value.length > 1 && this.selectedImageId.value !== this.storyImages.value.length-1){
      this.selectedImageId.next(this.storyImages.value.length-1)
    }

    const storyImage = this.storyImages.value.filter(img => { 
      return img.id === this.selectedImageId.value
    })

    if(!bgContext) return
    const mainImage = new Image()
    
    mainImage.onload = () => {
      console.log('image loaded')
      const fac = new FastAverageColor()
      let bgColor = ''

      fac.getColorAsync(mainImage)
        .then(color => {
          bgColor = color.hex
          bgContext.fillStyle = bgColor
          bgContext.fillRect(0, 0, bgCanvas.width, bgCanvas.height)

          const ratio = mainImage.height / mainImage.width
          bgContext.drawImage(mainImage, 0,0, mainImage.width, mainImage.height, 0, ((bgCanvas.height - bgCanvas.width * ratio) / 2), bgCanvas.width, bgCanvas.width * ratio)

          const textInput = this.contents.value.filter(c => { return c.type === 'text' }) 

          if(textInput.length === 0){
            textInput.push({
              id: 0,
              value: '',
              fontColor: '#fff',
              shadowColor: '#000',
              type: 'text',
              initialData: {
                size: 20,
                angle: 0,
                x: `calc(50% - 100px)`,
                y: `calc(50% - 24px)`
              },
              newData: null
            })
          } 

          if(textInput.length > 0){
            const text = textInput[0].value || ''
            bgContext.font = `bold ${textInput[0].newData?.size || textInput[0].initialData.size || 40}px Montserrat`
            bgContext.fillStyle = textInput[0].fontColor as string || '#fff'
            bgContext.shadowColor = textInput[0].shadowColor as string || '#000'
            bgContext.shadowBlur = 3
            bgContext.textBaseline = "top" as CanvasTextBaseline

            //bgContext.save()

            if(textInput[0].newData){
              const textData = textInput[0].newData
              if(!textData.x) textData.x = bgCanvas.width / 2 + 'px'
              if(!textData.y) textData.y = bgCanvas.height / 2 + 'px'
              if(!textData.height) textData.height = 20
              if(!textData.width) textData.width = 100
          
              const textCenterPosY = parseInt(textData.y) + (textData.height / 2)
              const textCenterPosX = parseInt(textData.x) + (textData.width / 2)

              bgContext.translate(textCenterPosX, textCenterPosY)

              if(!textData.angle) textData.angle = 0
              const textAngleInRadians = (textData.angle * Math.PI) / 180
              bgContext.rotate(textAngleInRadians)

              bgContext.translate(-textCenterPosX, -textCenterPosY)
              
              const x = textData.x.slice(0, textData.x.length - 2)
              const y = textData.y.slice(0, textData.y.length - 2)

              bgContext.fillText(text as string, parseInt(x as string), parseInt(y as string))
            } else {
              bgContext.textAlign = 'center'
              const x = bgCanvas.width / 2
              const y = bgCanvas.height / 2
              bgContext.fillText(text as string, x, y)
            }

            //bgContext.restore()

            const newContents = this.contents.value.filter(c => { return c.id !== textInput[0].id })
            this.contents.next(newContents)
            console.log(newContents)

            const visualInputs = this.contents.value.filter(c => { return c.type === 'visual' })

            if(visualInputs.length > 0){
              const visualsCanvas = document.getElementById('canvasVisuals') as HTMLCanvasElement
              const visualsContext = visualsCanvas.getContext('2d')

              visualInputs.forEach(v => {
                if(!visualsContext) return

                const visualImage = new Image()
                
                visualImage.onload = () => {
                  console.log('visual loaded')
                  if(v.newData !== null){
                    //visualsContext.save()

                    if(!v.newData.x) v.newData.x = visualsCanvas.width / 2 + 'px'
                    if(!v.newData.y) v.newData.y = visualsCanvas.height / 2 + 'px'
                    if(!v.newData.height) v.newData.height = 70
                    if(!v.newData.width) v.newData.width = 70
                
                    const visualCenterPosY = parseInt(v.newData.y) + (v.newData.height / 2)
                    const visualCenterPosX = parseInt(v.newData.x) + (v.newData.width / 2)

                    // déplacer le canvas tel que son coin supérieur gauche est à visualCenterPos
                    visualsContext.translate(visualCenterPosX, visualCenterPosY)

                    // faire pivoter le canvas selon l'angle demandé
                    if(!v.newData.angle) v.newData.angle = 0
                    const visualAngleInRadians = (v.newData.angle * Math.PI) / 180
                    visualsContext.rotate(visualAngleInRadians)

                    // mettre le canvas à la position de départ du visuel
                    visualsContext.translate(-visualCenterPosX, -visualCenterPosY)

                    const x = v.newData.x.slice(0, v.newData.x.length - 2)
                    const y = v.newData.y.slice(0, v.newData.y.length - 2)

                    visualsContext.drawImage(visualImage, 0, 0, visualImage.width, visualImage.height, parseInt(x), parseInt(y), v.newData.width, v.newData.height)
                  
                    //visualsContext.restore()
                  } else {
                    //visualsContext.save()

                    const x = visualsCanvas.width / 2
                    const y = visualsCanvas.height / 2

                    visualsContext.drawImage(visualImage, 0, 0, visualImage.width, visualImage.height, v.initialData.width ? x - v.initialData.width / 2 : x - 35, v.initialData.height ? y - v.initialData.height / 2 : y - 35, v.initialData.width || 70, v.initialData.height || 70)
                 
                    //visualsContext.restore()
                  }  

                  bgContext.drawImage(visualsCanvas, 0, 0)

                  console.log(this.selectedImageId.value)
                  this.storyImages.value[index].value = bgCanvas.toDataURL()
                  this.storyImages.value[index].processed = true

                  console.log(this.storyImages.value)

                  bgCanvas.style.display = 'none'
                  visualsCanvas.style.display = 'none'

                  const newContents = this.contents.value.filter(c => { return c.type !== 'visual' })
                  this.contents.next(newContents)
                }
                visualImage.src = v.value as string

                //visualsContext.reset()
              })
            }
            storyImage[0].value = bgCanvas.toDataURL()
            storyImage[0].processed = true

            this.storiesService.addImageToStory(bgCanvas.toDataURL(), this.storyId).subscribe(() => {
                this.imageProcessed.next(true)
                bgContext.reset()
            })
          }
      })
      .catch(e => {
          console.log(e)
      })
    }

    const index = this.storyImages.value.indexOf(storyImage[0])
    mainImage.src = this.storyImages.value[index].value

    //réinitialiser le currentInput
    this.currentInput.next({
      id: 0,
      value: '',
      fontColor: '#fff',
      shadowColor: '#000',
      type: 'text',
      initialData: {
        size: 20,
        angle: 0,
        x: 'calc(50% - 100px)',
        y: 'calc(50% - 48px)',
      },
      newData: null
    })

    let id = this.selectedImageId.value
    this.selectedImageId.next(id++)

    console.log(this.storyImages.value)

    //this.contents.next([])

    if(this.storyCompleted) this.router.navigate(['/'])
  }










  // createImage(source: string, text: any, images: any[], ratio: number): Observable<string> {
  //   const emojisUrls = images.map(({ value }) => `assets/emoticons/emojis/original/${value}.png`)
  //   const canvas = document.createElement('canvas')
  //   const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

  //   let width = 0
  //   let height = 0

  //   return forkJoin([this.loadImageFromUrl(source), this.loadManyImagesFromUrl(emojisUrls)]).pipe(
  //     map(([sourceImage, emojisImages]) => {
  //       canvas.width = width = sourceImage.width
  //       canvas.height = height = sourceImage.height
  //       ctx.drawImage(sourceImage, 0, 0)

  //       emojisImages.forEach((imageTag, index) =>
  //         ctx.drawImage(
  //           imageTag,
  //           0,
  //           0,
  //           imageTag.width,
  //           imageTag.height,
  //           images[index].x,
  //           images[index].y,
  //           images[index].size,
  //           images[index].size
  //         )
  //       )

  //       if(text) {
  //         const textwidth = Math.max(120, text.width)

  //         if (text.background !== 'transparent') {
  //           this.drawRoundRect(
  //             ctx,
  //             15 / ratio,
  //             text.x - 10 / ratio,
  //             text.y - 15 / ratio,
  //             textwidth / ratio,
  //             text.height / ratio,
  //             text.background.hex
  //           )
  //         }

  //         ctx.textAlign = 'center'
  //         ctx.font = `${text.size}px ${text.font}`
  //         ctx.textBaseline = 'hanging'
  //         ctx.fillStyle = text.color.hex
  //         ctx.fillText(text.value, text.x + (textwidth - 20) / 2 / ratio, text.y)
  //       }

  //       if (height > 1200 || width > 1200) {
  //         let nw = 0
  //         let nh = 0
  //         const c2 = document.createElement('canvas')
  //         const ctx2 = c2.getContext('2d') as CanvasRenderingContext2D
          
  //         if (width > height) {
  //           nw = 1200
  //           nh = (1200 / width) * height
  //         } else {
  //           nh = 1200
  //           nw = (1200 / height) * width
  //         }

  //         c2.width = nw
  //         c2.height = nh

  //         ctx2.drawImage(canvas, 0, 0, nw, nh)
  //         return c2.toDataURL('image/jpeg', 0.5)
  //       } else {
  //         return canvas.toDataURL('image/jpeg', 0.7)
  //       }
  //     })
  //   )
  // }

  // drawRoundRect(ctx: CanvasRenderingContext2D, radius: number, x: number, y: number, width: number, height: number, color: string) {
  //   ctx.beginPath();
  //   ctx.moveTo(x + radius, y);
  //   ctx.lineTo(x + width - radius, y);
  //   ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  //   ctx.lineTo(x + width, y + height - radius);
  //   ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  //   ctx.lineTo(x + radius, y + height);
  //   ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  //   ctx.lineTo(x, y + radius);
  //   ctx.quadraticCurveTo(x, y, x + radius, y);
  //   ctx.closePath();
  //   ctx.fillStyle = color;
  //   ctx.fill();
  // }
}
