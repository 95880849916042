<div class="dropzone">
  <!-- (touchstart)="onTouchStart($event)"
   (touchend)="onTouchEnd($event)" -->
  <input 
    type="text" 
    (touchmove)="onTouchMove()"
    #text 
    class="text-input" 
    [ngStyle]="{ 
      'color': input?.fontColor || '#000', 
      'text-shadow': input?.shadowColor ? '0 0 1px ' + input?.shadowColor : '0 0 1px #fff', 
      'width': input?.value?.length + 'ch',
      'transform': input?.newData?.angle ? 'rotate(' + input?.newData?.angle + 'deg)' : 
        input?.initialData ? 'rotate(' + input?.initialData?.angle + 'deg)' : 'none',
      'font-size': currentInput.type === 'text' && currentInput.newData ? currentInput.newData.size + 'px' : '20px'
    }"
    [value]="currentInput.type === 'text' && currentInput.value !== '' ? currentInput.value : input ? input.value : ''"
    [class.selected]="textToolSelected || currentInput.type === 'text'"
    (blur)="onBlur($event.target)"
  >

  <!-- 'top': currentInput.newData ? currentInput.newData.y : 'calc(50% - 24px)', 
      'left': currentInput.newData ? currentInput.newData.x : 'calc(50% - 100px)' -->
  <!-- 
  draggable="true"
    (dragstart)="onDragStart($event)"
     -->




  <!-- 'height': inputSize.height + 'px', 
      'width': inputSize.width + 'px', -->
  <!-- 
  (drag)="getNewPos($event, id)" 
    (dragend)="moveInputElement(id, $event)"
    (dragover)="dragOver($event)"
    (dragstart)="getInitialPos($event)"
     -->
  <!-- (blur)="onBlur($event, id)" -->
  <!-- <div class="action-delete" (click)="deleteInput()">
    <span class="material-symbols-rounded close">close</span>
  </div> -->
</div>