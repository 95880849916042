import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';

@Component({
  selector: 'app-publish-story-dialog',
  standalone: true,
  imports: [],
  templateUrl: './publish-story-dialog.component.html',
  styleUrl: './publish-story-dialog.component.scss'
})
export class PublishStoryDialogComponent {
  // create reference to dialog and pass the data type to pass to parent component when dialog is closed (boolean) to trigger save or cancel
  dialogRef = inject<DialogRef<boolean>>(DialogRef<boolean>);
  data = inject(DIALOG_DATA);
}
