import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppService } from '../../app.service';
import { StoryService } from '../story.service';

import { Color } from '../../shared/interfaces/color';

@Component({
  selector: 'app-color-palette',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './color-palette.component.html',
  styleUrl: './color-palette.component.scss'
})
export class ColorPaletteComponent implements OnInit {
  @Output() colorInfo = new EventEmitter<{ colorHex: string; colorType: string; colorIndex: number; }>()
  @Input() colors: Color[] = []
  @Input() textParagraphCreated!: boolean
  
  desktopDevice = false

  constructor(
    private appService: AppService,
    private storyService: StoryService
  ){}

  ngOnInit(){
    this.desktopDevice = this.appService.desktopDevice

    this.storyService.textParagraphCreated.subscribe(res => this.textParagraphCreated = res)
  }

  setTextColor(hexCode: string, colorType: string, colorIndex: number){
    console.log('setTextColor')
    //if(this.desktopDevice && !this.textParagraphCreated) return
    this.colorInfo.emit({ colorHex: hexCode, colorType: colorType, colorIndex: colorIndex })
  }
}
