<app-go-back-button class="edit-go-back-btn" (click)="goBack()"></app-go-back-button>  

<section class="delete-account" appClickAnywhere (clickNature)="getClickNature($event)">
  <div class="top" #top>
    <h3 i18n="Title for the account management section in My Space section@@MY_ACCOUNT"><span class="regular">My</span> Account</h3>
  </div>

  <h4 i18n="Title for the account deletion form in the account management section in My Space section@@DELETE_ACCOUNT"><span class="regular">Delete</span> my account</h4>

  <div class="input-holder">
    <button type="button" class="password-btn" [class.showHide]="!showPassword" (click)="showHidePassword()">
      <span class="material-symbols-outlined password-icon">
        visibility
      </span>
    </button>
    <button type="button" class="password-btn" [class.showHide]="showPassword" (click)="showHidePassword()">
      <span class="material-symbols-outlined password-icon">
        visibility_off
      </span>
    </button>

    <input
      id="password"
      formControlName="passwordCtrl"
      placeholder="Password"
      autocapitalize="none"
      #inputPassword
      [type]="showPassword ? 'text': 'password'"
      (input)="getValue($event)"
    />

    <p class="tip" i18n="Text for the password confirmation input in the account deletion form@@SECURITY_REASONS">For security reasons, please enter your password to confirm the deletion of your account.</p>
  </div>

  <button
    class="submit"
    type="submit" 
    (click)="onSubmit()"
    [disabled]="currentInputValue.length < 6 || error"
    i18n="Title for the button to validate the account deletion in the account deletion form@@DELETE_ACCOUNT"
  >
    Delete my account
  </button>

  @if(showPortal){
    <div class="example-portal-outlet">
      <ng-template [cdkPortalOutlet]="componentPortal"></ng-template>
    </div>
  }

  @if(showErrorDialog){
    <app-error-dialog [errorMessage]="errorMessage" [errorTitle]="errorTitle"></app-error-dialog>
  }
</section>
    