<h3>Are you sure you wish to block this user?</h3>

<p class="description">This person will no longer be able to view your profile, contact you and be monitored by our moderation teams.</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()">Cancel</button>
  <button class="submit" (click)="dialogRef.close(true)">
    <img src="assets/icons/icon_report_block.svg" alt="" class="report-icon">
    Block and report
  </button>
</div>

