
<div class="left-col"></div>

<div class="right-col" appClickAnywhere (clickNature)="getClickNature($event)">
    <app-go-back-button [routerLink]="'/login'"></app-go-back-button>
    <app-header></app-header>
    @if(loading$ | async){
        <app-loader></app-loader>
    }
    
    <form [formGroup]="resetPasswordFormGroup" (ngSubmit)="onSubmit()">
        <h2>Email or phone number</h2>
        <div class="input-holder">
            <input
                id="email"
                type="text"
                formControlName="emailOrPhoneCtrl"
                placeholder="Enter your email or phone number"
                (click)="closeDialog()"
                [ngClass]="openDialog ? 'invalid': ''"
            />
            
            @if(openDialog){
                <app-error-dialog [errorMessage]="errorMessage" [errorTitle]="errorTitle"></app-error-dialog>
            }
        </div>
        <p class="info">
            Please enter the email address or the phone number of your {{ serviceName | titlecase }} account to generate a new password.
        </p>
        
        @if(passwordReset){
            <div class="success-message">
                <h4>Your new password has been sent.</h4>
                <p>Please check your emails or phone messages.</p>
            </div>
        }
    </form>

    <div class="submit-btn-container-pwd" #submitBtnContainer>
        <button 
            class="submit"
            type="submit" 
            [disabled]="resetPasswordFormGroup.invalid"
            #btn
            (click)="onSubmit()"
        >
            Generate a new password
        </button>
        <div class="check-pwd" #check>
            <span class="material-symbols-rounded">done</span>
        </div>
    </div>
</div>

<router-outlet></router-outlet>
