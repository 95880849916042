import { Injectable } from '@angular/core';
import { User } from '../shared/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profile!: User

  constructor() { }
}
