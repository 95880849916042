<section class="my-subscription">
  @if(loading$ | async){
    <app-loader></app-loader>
  }

  @if(!isDesktop()){
    <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>  
  }
  
  <div class="top" #top>
    <h3><span class="regular">My</span> Subscription</h3>
  </div>

  <div class="content">
    <div class="item item-subscription">
      <h4>Manage your subscription</h4>

      <div class="item-content">
        <div class="content-left">
          <img class="swapImages"
            srcset="assets/logo/logo_60x60.webp 300w,
                    assets/logo/logo_100x100.webp 350w,
                    assets/logo/logo.webp 500w"
            sizes="100vw"
            src="assets/logo/logo_60x60.webp"
            width="60"
            height="60"
            alt="{{ serviceName | titlecase }} logo"
            class="service-logo"
          >
        </div>
        <div class="content-right">
          <p class="account-type">Swipi Unlimited Account</p>
          <div class="date-container">
            <span class="material-symbols-rounded arrow">
              arrow_forward_ios
            </span>
            <p class="date">2024-03-24</p><!-- date de début d'abonnement -->
          </div>
        </div>
      </div>    
      <button type="button" class="text-btn">
        Unsubscribe now
      </button>
    </div>
    
    <div class="item item-conditions">
      <h4>Our General Conditions</h4>

      <div class="item-content">
        <p class="description">Click on the button below to download our T&Cs.</p>
      </div>

      <div class="actions">
        <button type="button" class="submit">
          Download the T&Cs
        </button>
        <button type="button" class="text-btn">
          Read the T&C archives
        </button>
      </div>
    </div>

    <div class="item item-help">
      <h4>Need help?</h4>

      <div class="item-content">
        <p class="description">For any questions or information, please contact our customer service:</p>

        <h5>By email</h5>
        <p>Send an email to <a href="mailto:customer@swipi.com" class="email">customer&#64;swipi.com</a></p>

        <h5>By telephone</h5>
        <p><a href="tel:+33970340348" class="phone">+33970340348 (price of a local call)</a></p>

        <h5>By mail</h5>
        <p>Customer service 94119 13629 Aix-en-Provence 1 - France</p>
      </div>
    </div>
  </div>
</section>