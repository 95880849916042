import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, mergeMap, throwError } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import { AppService } from '../app.service';
import { AuthService } from '../auth.service';

import { Photo } from '../shared/interfaces/photo';
import { User } from '../shared/interfaces/user';

export interface PhotoCropResponse { 
  code: number,
  photo: {
    url: string
  } 
}

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  photoCropped = new BehaviorSubject<boolean>(false)
  croppedPhotoUrl = new BehaviorSubject<string | undefined>('')
  photoToCropInBase64 = ''

  private localStorage: Storage | null = null
  private showCropper$ = new BehaviorSubject<{
    data: File;
    size: { height: number; width: number };
  } | null>(null)

  constructor(
    private http: HttpClient, 
    private appService: AppService, 
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
  ) { 
    this.localStorage = this.document.defaultView?.localStorage || null
  }
  
  get(key: string): string {
    return this.localStorage?.getItem(key) || ''
  }

  set(key: string, value: string): void {
    this.localStorage?.setItem(key, value)
  }

  cropperState(): Observable<{
    data: File;
    size: { height: number; width: number };
  } | null> {
    return this.showCropper$.asObservable()
  }

  cropper(): void {
    //this.showCropper$.next(value);
  }

  saveOriginalPhoto(data: { origin: string; }, token: string): Observable<{ photo: { id: number }, error?: { message: string} }> {
    const body = {
      photo_b64: data.origin,
      type: 'profile',
    }

    console.log(token)

    return this.http
      .post<{ photo: { id: number } }>(`${this.appService.API}/api/photo`, body)
      .pipe(
        map((res) => res),
        catchError((error) => {
          return throwError(() => error)
        })
      )
  }

  cropPhoto(source: string, destination: string, data: { x: number; y: number; height: number; width: number; preview?: string; }, photoId: number): Observable<Photo | undefined>{
    console.log('cropPhoto in photo service')

    const user: User = JSON.parse(this.get('user'))
    
    const bodyCrop = {
      source: source,
      destination: destination,
      x: data.x,
      y: data.y,
      height: data.height,
      width: data.width,
      photo_id: photoId
    }

    return this.http.post<PhotoCropResponse>(`${this.appService.API}/api/photo/crop`, bodyCrop).pipe(
      map((res: PhotoCropResponse) => {
        const result: Photo | undefined = res.photo

        //this.croppedPhotoUrl.next(res.photo.url)

        if(!user.id) return
       
        // this.authService.getProfile(user.id).subscribe({
        //   next: ((user: User) => {
        //     console.log(user)
        //     const userMainPhoto = user.main_photo
        //     if(!userMainPhoto) return
        //     this.croppedPhotoUrl.next(userMainPhoto?.url)
        //     console.log(this.croppedPhotoUrl)
        //     //return of()


        //     if(userMainPhoto.url){
          
        //       result = { url: userMainPhoto.url}
        //     }
            
            
        //   }),
        //   error: (err => {
        //     console.log(err)
        //     })
        //   }
        // )
        return result
      }),
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  setMainPhoto(id: number): Observable<{ photo: { id: number } }> {
    const body = { 
      photo_id: id.toString() 
    }

    return this.http
      .put<{ photo: { id: number } }>(`${this.appService.API}/photo/profile`, body)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(() => error)
        }),
      );
  }

  deletePhoto(photoId: number): Observable<User> {
    return this.http
      .delete<{ code: number }>(`${this.appService.API}/api/user/photo?photo_id=${photoId.toString()}`)
      .pipe(
        mergeMap(() => {
          return this.authService.getProfile(Number(this.get('user_id'))).pipe(
            map((user) => {
              this.authService.setAuthMe(user)
              return user
            }),
            catchError((error) => {
              return throwError(() => error)
            }),
          );
        })
      )
  }
}
