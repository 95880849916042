import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { StoryService } from '../../create-story/story.service';

import { InitialStoryPhotoArray, PhotoObject } from '../../create-story/images-holder/story-photos';

@Component({
  selector: 'app-add-story-dialog',
  standalone: true,
  imports: [],
  templateUrl: './add-story-dialog.component.html',
  styleUrl: './add-story-dialog.component.scss'
})
export class AddStoryDialogComponent implements OnInit {
  // // create reference to dialog and pass the data type to pass to parent component when dialog is closed (boolean) to trigger save or cancel
  // dialogRef = inject<DialogRef<boolean>>(DialogRef<boolean>);
  // data = inject(DIALOG_DATA);
  // dialog = inject(Dialog)

  @ViewChild('fileUpload') fileUpload!: ElementRef;
  
  storyImages: PhotoObject[] = []
  photos = InitialStoryPhotoArray

  constructor(
    private location: Location,
    private storyService: StoryService,
    private router: Router
  ){}

  ngOnInit(){
    console.log('add story dialog')
  }

  closeDialog(){
    this.location.back()
  }

  openFileInput() {
    this.fileUpload.nativeElement.click();
  }

  drop(ev: DragEvent) {
    if (!ev.dataTransfer) return
    //stops browser from opening the file
    ev.preventDefault()
    
    if (ev.dataTransfer!.files.length > 0) {
      const reader: FileReader = new FileReader()

      reader.readAsDataURL(ev.dataTransfer!.files[0])
      let fileUrl = ''
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reader.onload = (imgSrc: any) => {
        fileUrl = imgSrc.target.result

        this.storyService.storyImages.next([
          {
            id: this.storyImages.length,
            src: fileUrl,
            saved: false,
          },
        ])
      
        if (ev.dataTransfer?.items && fileUrl === '') {
          // Use DataTransferItemList interface to access the file(s)
          for (const item of ev.dataTransfer.items) {
            if (item.kind !== 'file') {
              return
            }
  
            const file = item.getAsFile()
  
            if (!file) return
            const fileBlob = URL.createObjectURL(file)
  
            this.storyService.storyImages.next([
              {
                id: this.storyImages.length,
                src: fileBlob,
                saved: false,
              },
            ])
          }
        } else if(fileUrl === ''){
          // Use DataTransfer interface to access the file(s)
          for (const file of ev.dataTransfer!.files) {
            const fileBlob = URL.createObjectURL(file)

            this.storyService.storyImages.next([
              {
                id: this.storyImages.length,
                src: fileBlob,
                saved: false,
              },
            ])
          }
        }

        console.log(this.storyService.storyImages.value)
      
        this.router.navigate(['create-story'])
      }
    }
  }

  allowDrop(ev: DragEvent) {
    ev.preventDefault()
    //console.log('allowDrop:', ev)
  }

  // ajout d'image via l'explorateur
  onPhotoOrVideoInputChange(event: Event) {
    console.log('photo selection')
    const element = event.currentTarget as HTMLInputElement;
    const files = element.files;

    Array.prototype.forEach.call(files, (file: File) => {
      const fileBlob = URL.createObjectURL(file);

      this.photos[0].src = fileBlob
    });

    this.router.navigate(['create-story']);
  }
}
