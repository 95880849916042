import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../auth.service';

import { GoBackButtonComponent } from '../../../shared/go-back-button/go-back-button.component';

import { User } from '../../../shared/interfaces/user';
import { confirmedPasswordValidator } from '../../../shared/validators/confirmedPasswordValidator';

@Component({
  selector: 'app-edit-password',
  standalone: true,
  imports: [GoBackButtonComponent],
  templateUrl: './edit-password.component.html',
  styleUrl: './edit-password.component.scss'
})
export class EditPasswordComponent implements OnInit {
  passwordFormGroup!: FormGroup
  user: User | null = null
  routeDataSubscription!: Subscription
  showPassword = false
  sameAsCurrentError = false

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private location: Location
  ){
    this.passwordFormGroup = new FormGroup({
      currentPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
      newPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
      confirmedNewPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
    }, {
      validators: confirmedPasswordValidator()
    })
  }

  ngOnInit(){
    this.authService.authMe.subscribe(res => {
      this.user = res
      
      if(!this.user){
        // récupération du user du resolver
        this.routeDataSubscription = this.route.data.subscribe(data => this.user = data['user'])
      }
    })
  }

  goBack(){
    this.location.back()
  }

  showHidePassword(){
    this.showPassword = !this.showPassword
  }

  onInput(target: EventTarget | null, controlName: string){
    const input = target as HTMLInputElement
    this.passwordFormGroup.controls[controlName].patchValue(input.value)
  }

  onSubmit(){
    const data = this.passwordFormGroup.value
    console.log(data)
  }
}
