import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../auth.service';

import { GoBackButtonComponent } from '../../../shared/go-back-button/go-back-button.component';

import { User } from '../../../shared/interfaces/user';
import { confirmedPasswordValidator } from '../../../shared/validators/confirmedPasswordValidator';

@Component({
  selector: 'app-edit-password',
  standalone: true,
  imports: [GoBackButtonComponent, ReactiveFormsModule],
  templateUrl: './edit-password.component.html',
  styleUrl: './edit-password.component.scss'
})
export class EditPasswordComponent implements OnInit, OnDestroy {
  passwordFormGroup!: FormGroup
  user: User | null = null
  showPassword = false
  passwordMinCharNumber = 6
  passwordMaxCharNumber = 25
  passwordUpdated = false
  private readonly _destroyed = new Subject<void>()

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private location: Location
  ){
    this.passwordFormGroup = new FormGroup({
      currentPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]),
      newPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]),
      confirmedNewPasswordCtrl: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]),
    }, {
      validators: [confirmedPasswordValidator()]
    })
  }

  ngOnInit(){
    this.authService.authMe.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      this.user = res
      
      if(!this.user){
        this.route.data.pipe(
          takeUntil(this._destroyed)
        ).subscribe(data => this.user = data['user'])
      }
    })
  }

  goBack(){
    this.location.back()
  }

  showHidePassword(){
    this.showPassword = !this.showPassword
  }

  onInput(target: EventTarget | null, controlName: string){
    const input = target as HTMLInputElement
    this.passwordFormGroup.controls[controlName].patchValue(input.value)
  }

  onSubmit(){
    const data = {
      old: this.passwordFormGroup.controls['currentPasswordCtrl'].value as string,
      new: this.passwordFormGroup.controls['newPasswordCtrl'].value as string
    }
    
    this.authService.authUpdatePassword(data).subscribe(res => {
      console.log(res)
      this.passwordUpdated = true
    })
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
