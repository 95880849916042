import { AfterViewChecked, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import dayjs from 'dayjs';
import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { filter, Subject, Subscription } from 'rxjs';

import { HomeService } from '../../home/home.service';
import { AuthService } from '../../auth.service';
import { StoriesService } from '../../stories.service';
import { AppService } from '../../app.service';
import { SwipeService } from '../../swipe.service';

import { StoryPlayerDialogComponent } from '../../dialog/story-player-dialog/story-player-dialog.component';
import { FlagStoryDialogComponent } from '../../dialog/flag-story-dialog/flag-story-dialog.component';
import { BlockUserDialogComponent } from '../../dialog/block-user-dialog/block-user-dialog.component';
import { ReportUserDialogComponent } from '../../dialog/report-user-dialog/report-user-dialog.component';

import { Story } from '../interfaces/story';
import { User } from '../interfaces/user';

@Component({
  selector: 'app-story-player',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './story-player.component.html',
  styleUrl: './story-player.component.scss'
})
export class StoryPlayerComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('storyPhotoContainer') storyPhotoContainer!: ElementRef
  @ViewChild('progressionBar') progressionBar!: ElementRef

  stories: Story[] = []
  story: Story | null = null
  storyOwner!: User
  storyOwnerAge!: number
  dialog = inject(Dialog)
  currentPhotoIndex = 0
  private swipeCoord?: [number, number];
  private swipeTime?: number;

  viewportHeight!: number
  desktopDevice = false
  progressionBarWidth!: number
  private _destroyed = new Subject<void>()
  storySubscription!: Subscription
  
  constructor(
    private route: ActivatedRoute,
    private homeService: HomeService,
    private authService: AuthService,
    private storiesService: StoriesService,
    private swipeService: SwipeService,
    private appService: AppService
  ){}

  ngOnInit(){
    if(!this.route) return

    this.homeService.pageDisplayed.next('story')

    this.storySubscription = this.homeService.story.subscribe(res => {
      this.story = res
    
      if(!this.story || !this.story.id) return

      const storyOwnerId = this.story.user?.id

      if(!storyOwnerId) return

      this.authService.getProfile(storyOwnerId).subscribe(res => {
        this.storyOwner = res
      
        if(this.storyOwner.birthdate){
          const [y,m,d] = this.storyOwner.birthdate.split('-')
          const date = `${y}-${m}-${d}`
          const birthDate = dayjs(date)
    
          this.storyOwnerAge = dayjs().diff(birthDate, 'year')
        }
      })
    })
    
    this.desktopDevice = this.appService.desktopDevice

    this.viewportHeight = window.innerHeight

    // regarder si le user a déjà liké la story
    //this.storiesService.getCrushesByStory(this.story.id, 'story').subscribe(res => console.log(res))

    this.currentPhotoIndex = 0
  }

  ngAfterViewChecked(){
    if(!this.progressionBar || this.progressionBarWidth > 0) return

    const progressionBarBounds = this.progressionBar.nativeElement.getBoundingClientRect()
    this.progressionBarWidth = progressionBarBounds.width
  }

  closeSidenav(e: Event){
    e.stopPropagation()
    this.homeService.toggleSidenav.next(false)
    this.homeService.pageDisplayed.next('home')
    this.currentPhotoIndex = 0
  }

  getBarStyles(){
    if(!this.story) return
    return {
      width: `${this.progressionBarWidth / this.story.photos.length}px`
    }
  }

  likeStory(e: Event, story: Story){
    e.stopPropagation()
    if(!story || !story.id) return

    this.storiesService.addCrushOnStory(story.id, 'story').subscribe(res => console.log(res))
  }

  unlikeStory(e: Event, story: Story){
    e.stopPropagation()
    if(!story || !story.id) return

    this.storiesService.removeCrushOnStory(story.id, 'story').subscribe(res => console.log(res))
  }

  showNextPhoto(){
    if(!this.story) return
    if(this.currentPhotoIndex === this.story.photos.length-1) {
      this.currentPhotoIndex = 0
    } else {
      this.currentPhotoIndex++
    }
  }

  showPreviousPhoto(){
    if(!this.story) return
    if(this.currentPhotoIndex === 0) {
      this.story.photos.length-1
    } else {
      this.currentPhotoIndex--
    }
  }

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY]
    const time = new Date().getTime()

    if (when === 'start') {
      this.swipeCoord = coord
      this.swipeTime = time
    }
    else if (when === 'end') {
      if(!this.swipeCoord || !this.swipeTime) return

      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]]
      const duration = time - this.swipeTime

      if (duration < 1000
        && Math.abs(direction[0]) > 30 
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { 
        if (direction[0] < 0) {
          //next
          this.swipeService.swipeNext()
          this.showNextPhoto()
        } else {
          //previous
          this.swipeService.swipePrevious()
          this.showPreviousPhoto()
        }
      }
    }
  }

  getStoryPhotoContainerStyles(){
    if(!this.desktopDevice) return null

    return {
      height: `${this.viewportHeight}px`,
      width: `${this.viewportHeight / 1.4}px`,
      margin: 'auto'
    }
  }

  stopClickPropagation(e: Event) {
    e.stopPropagation()
  }

  openStoryDialog(e: Event) {
    e.stopPropagation()
    this.dialog.open(StoryPlayerDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe((res) => {
      console.log(res)
      if(res === 'flag'){
        this.flagStoryDialog()
      } else if(res === 'block'){
        this.blockUserDialog()
      } else {
        this.blockAndReportUserDialog()
      }
    })
  }

  flagStoryDialog() {
    this.dialog.open(FlagStoryDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.story?.id) return
      this.homeService.createReportBetweenUsers(this.story.id, 'story').subscribe(res => console.log(res))
    })
  }

  blockUserDialog() {
    this.dialog.open(BlockUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.story?.user) return
      this.homeService.updateRelationship(this.story.user.id, 'blocked').subscribe(res => console.log(res))
    })
  }

  blockAndReportUserDialog() {
    this.dialog.open(ReportUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.story?.user || !this.story.user.id) return

      this.homeService.createReportBetweenUsers(this.story.user.id, 'user').subscribe(() => {
        if(!this.story?.user) return
        this.homeService.updateRelationship(this.story.user.id, 'blocked_with_report').subscribe(res => console.log(res))
      })
    })
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
    this.storySubscription.unsubscribe()
  }
}
