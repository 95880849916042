
import { CommonModule } from '@angular/common';
import { afterNextRender, Component, ElementRef, EventEmitter, inject, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import {CdkTextareaAutosize, TextFieldModule} from '@angular/cdk/text-field';

import { StoryElementInterface } from '../story.service';
import { LayoutService } from '../../layout.service';

import { ToolbarTopActionsComponent } from '../toolbar/toolbar-top-actions/toolbar-top-actions.component';
import { ColorPaletteComponent } from '../color-palette/color-palette.component';

@Component({
  selector: 'app-resize-story-element',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ToolbarTopActionsComponent, TextFieldModule, ColorPaletteComponent],
  templateUrl: './resize-story-element.component.html',
  styleUrl: './resize-story-element.component.scss'
})
export class ResizeStoryElementComponent implements OnInit, OnDestroy {
  @Input() componentHeight!: number;
  @Input() componentWidth!: number;
  @Input() currentElement!: StoryElementInterface | undefined;
  //using cdk text area
  @ViewChild('autosize') autosize!: CdkTextareaAutosize;
  @ViewChild('autosize', { read: ElementRef }) elRef!: ElementRef
  @ViewChild('sourceItem') sourceItem!: ElementRef;
  // actions to be triggered when the user saves/deletes
  @Output() addElement = new EventEmitter<StoryElementInterface>();
  @Output() closePanel = new EventEmitter<void>();

  readonly _destroyed = new Subject<void>();
  private _injector = inject(Injector);
  display = true;
  //form controls to track the scale and rotation of the element
  scale = new FormControl({ value: 40, disabled: false });
  rotate = new FormControl({ value: 0, disabled: false}, {nonNullable: true});
  text!: string | undefined;
  screenSize!: { height: number, width: number }
  isDesktop = this.layoutService.isDesktop

  constructor(
    private layoutService: LayoutService
  ) {}

  ngOnInit() {
    if (this.currentElement?.type === 'text') {
      this.text = this.currentElement?.content

      //trigger resize of text area element when the scale changes
      this.scale.valueChanges.pipe(
        takeUntil(this._destroyed)
      ).subscribe(() => {
        this.triggerResize();
      })
    }

    this.screenSize = {
      height: window.innerHeight,
      width: window.innerWidth
    }
  }

  //styles for the scale and rotate sliders
  get scaleSelectorStyles() {
    return {
      'background': `linear-gradient(to top, #F4706D ${(this.scale.value! /200)*100}%, #e5e5e5 ${(this.scale.value! /200)*100}%)`
    }
  }

  get rotateSelectorStyles() {
    return {
      'background': `linear-gradient(to top, #F4706D ${(this.rotate.value! /360)*100}%, #e5e5e5 ${(this.rotate.value! /360)*100}%)`
    }
  }
 
  get textareaStyles() {
    return {
      'font-family': '"Montserrat", Arial, Helvetica, sans-serif',
      'resize': 'none',
      'overflow': 'hidden',
      'left': '50%',
      'top':  '50%',
      'position': 'absolute',
      'background': 'transparent',
      'color': this.currentElement?.color,
      'border': 'none',
      'font-size.px': this.scale.value,
      'width': this.text && this.text?.length > 0 ? (this.text.length+1).toString() + 'ch' : '1ch',
      'display': 'block',
      'max-width': this.isDesktop() ? ((this.screenSize.height / 1.4) * 0.7).toString() + 'px' : (this.screenSize.width * 0.7).toString() + 'px',
      'max-height': (this.screenSize.height * 0.7).toString() + 'px',
      'transform': `translate(-50%, -50%) rotate(${this.rotate.value}deg)`,
    };
  }

  updateColor(event: { colorHex: string; colorType: string; colorIndex: number; }){
    this.currentElement!.color = event.colorHex
  }

  onSave(){
    let element!: StoryElementInterface

    if(this.currentElement?.type === 'visual'){
      element  = {
        src: this.sourceItem.nativeElement.currentSrc, 
        height: this.sourceItem.nativeElement.height, 
        width: this.sourceItem.nativeElement.width, 
        angle: this.rotate.value, 
        type: 'visual'}
    } else if(this.currentElement?.type === 'text'){

      element = {
        content: this.text, 
        color: this.currentElement.color, 
        fontSize: this.scale.value,
        width: this.elRef.nativeElement.offsetWidth, 
        height: this.elRef.nativeElement.offsetHeight, 
        angle: this.rotate.value, 
        src: null,
        type: 'text'
      }
    }

    //emit element to create-story component and close panel
    this.addElement.emit(element)
    this.closePanel.emit()
  }

  triggerResize() {
    afterNextRender(() => {
        this.autosize.resizeToFitContent(true);
      },
      {
        injector: this._injector,
      },
    );
  }

  deleteElement() {
    console.log("deleted")
  }

  ngOnDestroy(): void {
    this._destroyed.next()
    this._destroyed.complete()
  }
}
