import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';

import { AuthService } from '../../auth.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  
  return next(req).pipe(
    catchError(error => {
      if(error.error.error_code === 21003) {
        authService.remove('token')
        router.navigate(['/login'])
      }
      throw error
    })
  )
};
