<section class="my-profile" id="my-profile" appClickAnywhere (clickNature)="getClickNature($event)">
    @if(loading$ | async){
      <app-loader></app-loader>
    }

    <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>

    <div class="top" #top>
      <h3><span class="regular" i18n="Title for the profile management page in My Space section@@MY_PROFILE">My</span> Profile</h3>
    </div>

    @if(updateMainPhotoError){
        <app-error-dialog [errorMessage]="updateMainPhotoError.message" [errorTitle]="updateMainPhotoError.title"></app-error-dialog>
    }

    <div class="photos-container">
      <div class="main">
        <img [src]="photos[0]?.url" alt="" class="photo">
        <button type="button" class="contextual-menu closed show" [ngStyle]="{'display': (user.photos && user.photos.length > 1) ? 'block' : 'none'}" #contextualMenuClosed (click)="openProfilePhotosDialog(user.main_photo, 'main')">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </button>
      </div>

      @for(photo of photos | slice:1; track photo; let index = $index){
          @if(!photo) {
            <div class="secondary">
              <button type="file" class="icon-gallery-add-photo" (click)="openFileInput(index)">
                  <img src="assets/icons/icon_gallery.svg" alt="">
                  <div class="plus-badge">
                      <span class="material-symbols-rounded more">
                          add
                      </span>
                  </div>
              </button>
            </div>
          } @else {
            <div class="secondary">
              <img [src]="photo?.url" alt="">
              <button type="button" class="contextual-menu closed show" #contextualMenuClosed (click)="openProfilePhotosDialog(photo, null, index)">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </button>
            </div>
          }
      }
    </div>

    <input 
        type="file"
        accept="image/png, image/jpeg" 
        #fileUpload 
        style="opacity: 0;" 
        class="file-upload" 
        (change)="onPhotoInputChange($event)"
    >

    <div #cropper [ngStyle]="{'height': '1px','width': '100%'}"></div><!-- repère pour repositionnement uniquement -->
    <div class="cropper" #cropperContainer>  
      <!-- @if(showCropper){
          <div class="crop-holder">
            <div class="cancel">
              <button type="button" (click)="discardPhoto()">
                <span class="material-symbols-rounded close">
                  close
                </span>
              </button>
            </div>
              <app-photo-crop
                  [imageSource]="tempCropperPhotoInBase64"
                  (userCroppedImageEvent)="getUserCroppedImage($event)"
                  (cropDetailsEvent)="getCropDetails($event)"
                  [photoContainerDimensions]="photoContainerDimensions"
              ></app-photo-crop>
          
              <div class="submit-btn-container">
                <button type="submit" class="submit" (click)="saveCropData()">
                    Crop and save
                </button>
              </div>
          </div>
      } -->
    </div>

    @if(!editInformationMode){
        <div class="information">
            <button type="button" class="edit-btn" (click)="editProfile()">
              <img src="../../../assets/icons/icon_edit.svg" alt="" class="icon-edit">
            </button>
            <h3 class="firstname">{{user.firstname}}</h3>
            <p class="age-location">{{userAge}} <ng-container i18n="Age unit@@YEARS">years</ng-container> - {{user.city?.name}}</p>
            <div class="searching-container">
                <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
                @if(user.attraction === 'man'){
                  <ng-container i18n="Text for the genre the user is attracted to@@MEN">Men</ng-container>
                } @else {
                  <ng-container i18n="Text for the genre the user is attracted to@@WOMEN">Women</ng-container>
                }
            </div>
            
            <p class="description">
              @if(user.description){
                {{user.description}}
              } @else {
                <ng-container i18n="Default description if no description is provided in the profile management page@@MYSTERY_CONTINUES">The mystery continues... It’s up to you to find out who i am :-)</ng-container>
              }
            </p>
        </div>
    } @else {
        <div class="information-edit" appClickAnywhere (clickNature)="getClickNature($event)">
            <button type="button" class="close-btn" (click)="cancel($event)">
              <span class="material-symbols-rounded close">
                close
              </span>
            </button>
            <h3 i18n="Title for the profile information edition form in the profile management page@@PROFILE_MODIFICATION">Profile Modification</h3>
            <form
                [formGroup]="editProfileInformationFormGroup"
                [class.hide]="displayCreatingLoader"
                (ngSubmit)="onSubmit()"
            >
                <div class="fieldsets">
                    <fieldset>
                        <label for="firstname" i18n="Label for the first name input in the subscription form@@FIRSTNAME">Firstname</label>
                        <p class="tip" i18n="Tip for the first name constraints in the subscription form@@FIRSTNAME_TIP">Your first name should contain between 2 and 25 characters, without number, space nor special characters.</p>
                        <input required type="text" [value]="user.firstname" id="firstname" formControlName="firstnameCtrl" minlength="2" maxlength="25" placeholder="Enter your first name"  i18n-placeholder="Placeholder for the first name input in the subscription form@@ENTER_FIRSTNAME" >

                        @if(firstnameError){
                          <app-error-dialog [errorTitle]="errorTitle" [errorMessage]="errorMessage"></app-error-dialog>
                        }
                    </fieldset>
    
                    <app-birthdate-input [user]="user" (birthdayDialogData)="getBirthdateError($event)"></app-birthdate-input>
                    
                    @if(dateError){
                      <app-error-dialog [errorTitle]="errorTitle" [errorMessage]="errorMessage"></app-error-dialog>
                    }
                    
                    <app-city-input [user]="user" (emitCity)="getCity($event)"></app-city-input>
    
                    <!-- <app-attraction-input [user]="user" [parentName]="'myProfileComponent'" (emitAttraction)="getAttraction($event)"></app-attraction-input> -->
                    
                    <fieldset>
                        <label for="description" i18n="Label for the description input in the subscription form@@DESCRIPTION_MAX_CHAR">Description ({{descriptionMaxLength}} characters max)</label>
                        <textarea id="description" name="description" rows="5" cols="33" formControlName="descriptionCtrl" [maxlength]="descriptionMaxLength" class="description"
                        placeholder="Introduce yourself here..."
                        i18n-placeholder="Label for the description input placeholder in the subscription form@@DESCRIPTION">{{user.description}}</textarea>
                    </fieldset>

                    <!-- <p class="success" [ngStyle]="{'display': informationUpdated ? 'block' : 'none'}">Your information has been successfully updated.</p> -->
                </div>
    
                <div class="actions">
                    <button class="text-btn" (click)="cancel($event)" i18n="Text for the button to cancel the action in progress@@CANCEL">Cancel</button>
                    <button class="submit" i18n="Text for the button to save current profile information changes@@SAVE_MY_INFO" [disabled]="!editProfileInformationFormGroup.valid">
                        Save my information
                    </button>
                </div>
            </form>
        </div>
    }

    @if(!userHobbies.length){
      <div class="hobbies">
        <div class="icon-container">
            <img src="../../../assets/icons/icon_hobbies_white.svg" alt="" class="icon-hobbies">
        </div>
        
        <h4 i18n="Title for the hobbies section in the profile management page@@HOBBIES">Hobbies</h4>
        <p class="description" i18n="Description to invite the user to add hobbies to his profile in the profile management page@@INDICATE_YOUR_HOBBIES">Indicate your hobbies to complete your profile</p>

        <button 
            class="submit" 
            [routerLink]="['my-hobbies']" 
            routerLinkActive="selected" 
            #myProfileLink="routerLinkActive"
        >
            <img src="../../../assets/icons/icon_hobbies_white.svg" alt="" class="icon-hobbies">
            <ng-container i18n="Text for the button to access the hobbies form from the profile management page@@SELECT_MY_HOBBIES">Select my hobbies</ng-container>
        </button>
      </div>
    } @else {
      <section class="hobbies-section">
        <button type="button" class="edit-btn" (click)="editHobbies()">
          <img src="../../../assets/icons/icon_edit.svg" alt="" class="icon-edit">
        </button>

        <h3 i18n="Title for the hobbies selection form@@HOBBIES">Hobbies</h3>
        <div class="hobbies-list" #hobbiesListContainer>
          @for(h of userHobbies; track h){
            <div class="hobby-item" [ngStyle]="getStyles()">
              <p class="hobby-title">{{h.label}}</p>
            </div>
          }
        </div>
      </section>
    }

    <app-top-button (scrollRequest)="getScrollRequest($event)"></app-top-button>
</section>
