<div class="auth-login">
  <div class="left-col">
    <div class="overlay"></div>
    <!-- TODO: Intégrer les clés de traductions -->
    <div class="content">
      <h1>Share your 
        <br/>Stories!</h1>
      <p class="hook">For the first time, there is a free online dating service that allows you to introduce yourself to others through stories!</p>
      <p class="hook">{{ serviceName | uppercase }} has flipped the rules of online dating upside down with its</p>
      <div class="users-info">
        <div class="users-number">
          <span class="number">2.5</span> million
        </div>
        <p>active users</p>
      </div>
      <div class="users-info">
        <div class="users-number">
          <span class="number">200K</span>
        </div>
        <p>messages per month</p>
      </div>
      <div class="users-info">
        <div class="users-number">
          <span class="number">5K</span>
        </div>
        <p>new stories per month</p>
      </div>
    </div>
  </div>
  <!-- TODO: Intégrer les clés de traductions -->

  <div class="right-col">
    <div class="overlay"></div>
    <div class="content">
      <app-header></app-header>

      <form 
        [formGroup]="authFormGroup" 
        (ngSubmit)="onSubmit()"
      >
        <div class="field">
          <h2 i18n="Login section header@@LOGIN">Log in</h2>
          @if(inputs.login){
            <div class="input-holder">
              <input
                id="login"
                type="text"
                formControlName="loginCtrl"
                (focus)="setFocusInput()"
                (blur)="setBlurInput()"
                placeholder="Email or phone number"
                i18n-placeholder="Placeholder for email or phone form input@@EMAIL_OR_PHONE"
                autocapitalize="none"
                #inputLogin
              />
            </div>
          } 
          @if(inputs.password) {
            <div class="input-holder">
              <button type="button" class="password-btn" (click)="showHidePassword()" [class.showHide]="!showPassword">
                <span class="material-symbols-outlined password-icon">
                  visibility
                </span>
              </button>
              <button type="button" class="password-btn" (click)="showHidePassword()" [class.showHide]="showPassword">
                <span class="material-symbols-outlined password-icon">
                  visibility_off
                </span>
              </button>

              <input
                id="password"
                [type]="showPassword ? 'text': 'password'"
                formControlName="passwordCtrl"
                (focus)="setFocusInput()"
                (blur)="setBlurInput()"
                placeholder="Password"
                i18n-placeholder="Placeholder for password form input@@PASSWORD"
                autocapitalize="none"
                #inputPassword
              />

              @if(showPassword){
                <div class="show-password">{{ authFormGroup.value.password }}</div>
              }
            </div>
          }

          <button
            class="submit"
            [class.wrong]="wrongCredential"
            type="submit"
            [disabled]="formStatus === 'INVALID'"
            i18n="Text for login form submit button@@LOG_IN"
          >
            Log In
          </button>
          
          @if(inputs.password) {
            <button type="button" class="text-link" [routerLink]="['/forgotten-password']" routerLinkActive="active" i18n="Text for the link to the forgotten password form@@FORGOT_PASSWORD_?">
              Forgot your password?
            </button>
          }
        </div>

        @if(loading$ | async){
          <app-loader></app-loader>
        }
        
        <div class="field">
          <h3 i18n="Title for section when the user has no account@@NO_ACCOUNT">No account?</h3>
          <button
            class="text-btn"
            [routerLink]="['/subscribe-step-one']"
            queryParamsHandling="merge"
            i18="Text for the button to access the subscription process@@JOIN_US"
          >
            Join us now!
          </button>
        </div>
      </form>
    </div>
    
    <app-footer></app-footer>
  </div>
</div>
  