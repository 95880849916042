<button
  type="button"
  class="close-page-btn"
  (click)="closeSidenav();"
>
  <span class="material-symbols-rounded close-page"> close </span>
</button>
<section class="content">
  <div class="popup">
      <h2><span class="regular">New</span> Encounters!</h2>

      @if(!user){
        <p class="no-encounters">
          No new encounters for the moment.
        </p>
      } @else {
        <div class="suggestion" #suggestionDiv>
          <div class="card-shadow"></div>
          <section 
            class="card" 
            #cardSection 
            (touchstart)="swipe($event, 'start')" 
            (touchend)="swipe($event, 'end')"
          >
              <div class="left">
                  <div class="carousel-component-container">
                    <carousel
                        [infinite]="true"
                        [autoPlay]="true"
                        [autoPlayAtStart]="true"
                        [autoPlayInterval]="2500"
                        [displayAutoPlayControls]="false"
                        [arrows]="false"
                        [counter]="false"
                        [slideToShow]="1"
                        [gapBetweenSlides]="6"
                        [slideMaxWidth]="400"
                        [responsive]="true"
                    >
                        @for(p of user.photos; track $index){
                            <div class="carousel-slide" [routerLink]="['photos']" [queryParams]="{photoId: $index}">
                                <img [src]="p" alt="" class="photo" >
                            </div>
                        }
                    </carousel>
                  </div>
              </div>
              
              <div class="information">
                  <h3 
                    [routerLink]="['profile/' + user.id + '/space']" appClickAnywhere (clickNature)="getClickNature($event, user.id)"
                  >{{user.firstname}}</h3>
                  <p>{{userAge}} years - {{user.city?.name}}</p>
                  <div class="searching-container">
                      <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
                      {{user.attraction | titlecase}}
                  </div>
                  <p class="description" #description>
                      {{user.description}}
                  </p>
              </div>
          </section>
        </div>
      
        <div class="actions">
            <button 
                alt="Close this profile" 
                class="close-container"
                (click)="rejectProfile(212022)"
            > <!-- à supprimer : id pour test uniquement -->
                <span class="material-symbols-rounded close">close</span>
            </button>

            @if(user.relationship === "accepted"){
              <div class="accepted-mention">
                <p>{{user.sex === 'man' ? 'He' : 'She'}} already liked your profile!</p>
                <div class="arrow-down"></div>
              </div>
            }
            
            <button 
                alt="Like this profile" 
                class="like-container"
                (click)="likeProfile(212020)"
            ><!-- afficher au clic une info-dialog si le user n'est pas abonné -->
             <!-- à supprimer : id pour test uniquement -->
                <img src="assets/icons/icon_love_32.svg" alt="" class="like">
            </button>

            <button 
                alt="Send a message" 
                class="message-container"
                (click)="contactProfile()"
            ><!-- afficher au clic une info-dialog si le user n'est pas abonné -->
                <img src="assets/icons/icon_message.png" alt="" class="message">
            </button>
        </div>    
      }
  </div>
</section>

<router-outlet></router-outlet>
