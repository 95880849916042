<section class="slider-content encounter-photos">
  <div class="slider-popup">
    @if(isDesktop()){
      <button
        type="button"
        class="close-page-btn"
        (click)="goBack()"
      >
        <span class="material-symbols-rounded close-page"> close </span>
      </button>
     } @else {
      <app-go-back-button (click)="goBack()"></app-go-back-button>
     }
     
    @if(user){
      <div class="slider-container">
        <button type="button" class="previous" (click)="onClickBack()" [class.show]="user.photos && user.photos.length > 1">
          <span class="material-symbols-outlined back">
            arrow_back_ios
          </span>
        </button>
      
        <div class="photo">
          @if(user.photos){
            @for(p of photos; track p; let index = $index){
              @if(photoIndex === index){
                <img [src]="photos[photoIndex].url" alt="" #image (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
              }
            }
          }
        </div>

        <button type="button" class="next" (click)="onClickForward()" [class.show]="user.photos && user.photos.length > 1">
          <span class="material-symbols-outlined forward">
            arrow_forward_ios
          </span>
        </button>
      </div>
    }
  </div>
</section>