<section class="auth-subscribe-four">
  <div class="left-col">
    <div class="overlay"></div>
  </div>

  <div class="right-col">
      <app-upload-photo [flow]="flow"></app-upload-photo>
  </div>
</section>

