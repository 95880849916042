@if(loading){
  <app-loader></app-loader>
}

<div class="home" #mainHolder>
  <app-logged-in-header [class.hide]="pageDisplayed === 'new-encounters' || pageDisplayed === 'story'"></app-logged-in-header>
  
  <mat-sidenav-container
    class="sidenav-container"
    [ngStyle]="{
      'background-color': 'transparent',
      'z-index': sidenav.opened ? '3' : '1',
      'position' : sidenav.opened ? 'fixed' : 'relative'
    }"
  >
    <mat-sidenav
      #sidenav
      mode="push"
      class="secondary-sidenav"
      position="end"
    >
    <div #scrollable class="scrollable" (scrollend)="onScroll($event)" (touchend)="onTouchEnd($event)">
      <!-- contenu additionnel -->
      <router-outlet></router-outlet>
      <app-footer [class.hide]="(pageDisplayed === 'new-encounters') || (pageDisplayed === 'story')"></app-footer>
    </div>
     
    </mat-sidenav>

    <mat-sidenav-content>
      <section class="main" [class.no-padding]="showAddStoryDialog">
        <div class="top">
          <h3>
            Hello <span class="firstname">{{ user?.firstname }}!</span>
          </h3>

          <div class="actions">
            <div class="actions-icon-container">
              <button
                type="button"
                class="add-story-btn"
                [routerLink]="['/story']"
              >
                <img src="assets/icons/icon_add_new_story.png" alt="" />
              </button>
            </div>

            <button
              type="button"
              class="submit icon-encounters-container desktop"
              [routerLink]="['new-encounters']"
              (click)="
                toggleSidenav(true);
                pageDisplayed = 'new-encounters'
              "
            >
              <img
                src="../../assets/icons/icon_encounters.svg"
                alt=""
                class="icon-encounters"
              />
              Encounters
            </button>
            <button
              type="button"
              class="icon-encounters-container mobile"
              [routerLink]="['new-encounters']"
              (click)="
                toggleSidenav(true);
                pageDisplayed = 'new-encounters'
              "
            >
              <img
                src="../../assets/icons/icon_encounters.svg"
                alt=""
                class="icon-encounters"
              />
            </button>
          </div>
        </div>

        <div class="main-content">
          <!-- contenu de base -->
          <h4>Stories</h4>
          <div class="photos" #photos>
            <button
              type="button"
              class="profile-card-btn"
              (click)="
                !desktopDevice ? openMobileCamera() : displayAddStoryDialog()
              "
              [ngStyle]="getStyles()"
            >
              <div class="photo-container profile">
                <div class="main-photo-container">
                  <img
                    [src]="user?.main_photo?.url"
                    alt=""
                    class="item profile"
                  />
                  <div class="icon-container">
                    <img
                      src="assets/icons/icon_add_new_story.png"
                      alt=""
                      class="mobile"
                    />
                    <img
                      src="assets/icons/icon_add_new_story.png"
                      alt=""
                      class="desktop"
                    />
                  </div>

                  <input
                    type="file"
                    accept="image/*, video/*"
                    capture="camera"
                    #mobileFileUpload
                    style="opacity: 0; position: absolute"
                    class="file-upload mobile"
                    (change)="onPhotoOrVideoInputChange($event)"
                  />
                </div>

                <h5>Add a story</h5>
              </div>
            </button>
             
            @if(storiesLoading){
              <app-loader class="stories-loader"></app-loader>
            } 

            @for(s of stories; track s.id) {
              <div class="photo-container" [ngStyle]="getStyles()">
                <app-story [story]="s" (closeRequest)="getCloseRequest($event)"></app-story>
              </div>
            }
          </div>

          <div class="desktop">
            <div [ngClass]="{ overlay: showOverlay }"></div>

            <app-info-dialog
              [class.show]="showInfoDialog"
              [infoDialogType]="infoDialogType"
              (closeInfoDialog)="closeInfoDialog($event)"
            ></app-info-dialog>
          </div>
        </div>

        <div class="icon-container mobile">
          <button
            type="button"
            class="add-story-btn"
            (click)="openMobileCamera()"
          >
            <img src="assets/icons/icon_add_new_story.svg" alt="" />
          </button>
        </div>
      </section>

      <app-footer
        [class.show]="!showAddStoryDialog"
        [class.hide]="pageDisplayed === 'new-encounters' || pageDisplayed === 'story' || pageDisplayed === 'profile'"
      ></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
