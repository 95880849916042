import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import Cropper from "cropperjs";
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { PhotoService } from '../photo/photo.service';
import { AuthService } from '../auth.service';
import { LayoutService } from '../layout.service';
import { LoaderService } from '../loader.service';

import { User } from '../shared/interfaces/user';
import { Photo } from '../shared/interfaces/photo';

@Component({
  selector: 'app-profile-crop',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile-crop.component.html',
  styleUrl: './profile-crop.component.scss'
})
export class ProfileCropComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('contextualMenuClosed') contextualMenusClosed!: QueryList<ElementRef>
  @ViewChildren('contextualMenuOpened') contextualMenusOpened!: QueryList<ElementRef>
  @ViewChild("image", { static: false }) imageElement!: ElementRef

  @Output() fileList: EventEmitter<boolean> = new EventEmitter()
  
  user!: User
  photos: (Photo | null)[] = []
  tempCropperPhotoInBase64!: string
  showCropper!: { 
    data: File,
    temp?: string,
    size: { height: number; width: number; }
  } | null
  croppedImage: string = ''
  cropData!: { x: number; y: number; height: number; width: number; preview?: string; }
  uploadedPhotoSrc = ''
  isDesktop = this.layoutService.isDesktop
  origin = ''
  private readonly _destroyed = new Subject<void>()
  imageDestination!: string
  private cropper!: Cropper | null
  cropDetails!: Cropper.Data

  constructor(
    private location: Location,
    private photoService: PhotoService,
    private authService: AuthService,
    private layoutService: LayoutService,
    private loaderService: LoaderService,
    public router: Router
  ){}

  ngOnInit(){
    console.log('ngOnInit profile-crop')
    if(this.photoService.photoToCropInBase64) this.uploadedPhotoSrc = this.photoService.photoToCropInBase64
    if(this.router.url.includes('upload') || this.router.url.includes('subscribe')){
      this.origin = 'upload'
    }

    this.showCropper = this.photoService.showCropper

    const userStoredLocally = this.authService.get('user')
    if(userStoredLocally) this.user = JSON.parse(this.authService.get('user'))
  }

  ngAfterViewInit() {
    console.log('photo-crop')
    this.cropper = new Cropper(this.imageElement.nativeElement, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      crop: (event) => {         
        if(!this.cropper) return
        this.imageDestination = this.cropper.getCroppedCanvas().toDataURL("image/png");

        //this.userCroppedImageEvent.emit(this.imageDestination)

        this.cropDetails = event.detail

        this.cropData = {
          height: Math.floor(this.cropDetails.height), 
          width: Math.floor(this.cropDetails.width), 
          x: Math.floor(this.cropDetails.x), 
          y: Math.floor(this.cropDetails.y)
        }
        console.log(this.cropData)
      }
    })
  }

  // getUserCroppedImage(event: string){
  //   this.croppedImage = event
  // }
    
  // getCropDetails(event: { x: number; y: number; height: number; width: number; preview: string; }){
  //   this.cropData = event
  // }

  saveCropData() {
    console.log('saveCropData de profile-crop')
    const tempCropperPhotoInBase64Arr = this.uploadedPhotoSrc.split(',')

    //if(!this.showCropper?.temp){ throw new Error('Source image is missing') }

    this.loaderService.show()
    //this.photoService.cropper()

    // étape 2 : envoyer la photo en base64
    const data: {
      origin: string //image destination en base64
    } = {
      origin: tempCropperPhotoInBase64Arr[1]
    }

    // crop image on front
    const img = new Image()
    img.src = this.tempCropperPhotoInBase64
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if(img && ctx){
      img.onload = () => {
        ctx.drawImage(img,this.cropData.x,this.cropData.y,this.cropData.width,this.cropData.height,0,0,50,50)
       
        //const croppedImg = canvas.toDataURL('image/jpeg')
      }
    }
    // crop image on front

    this.photoService.saveOriginalPhoto(data).subscribe(async(res) => {
      if(res.photo.id){
        // étape 3 : cropper la photo
        const croppedImageArr = this.croppedImage.split(',')
        const photoId = res.photo.id

        this.photoService.cropPhoto(data.origin, croppedImageArr[1], this.cropData, photoId).subscribe(res => {
          if(!res) return
         
          this.loaderService.hide()

          // only on My Profile
          if(this.router.url.includes('my-profile')){
            this.authService.getProfile(this.user.id).subscribe(async(res) => {
              this.user = res
              this.photos = []
  
              for (let i = 0; i < 6; i++) {
                if(!res.photos?.[i]){
                  this.photos.push(null)
                } else {
                  this.photos.push(res.photos[i])
                }
              }
  
              this.authService.setAuthMe(this.user)
            })

            this.cropper = null
            //this.showCropper = null
            this.location.back()
          } else {
            this.cropper = null
            //this.showCropper = null
            //this.photoService.croppedPhotoUrl.next(res.url)
            this.router.navigate(['/photo'])
          }
        })
      }
    })
  }
 
  discardPhoto(){
    this.fileList.emit(false)
    this.location.back()
  }

  ngOnDestroy(){
    console.log('onDestroy')
    this._destroyed.next()
    this._destroyed.complete()
  }
}
