@if(loading){
    <app-loader></app-loader>
}

<section class="my-hobbies">
  <app-go-back-button (click)="goBack()"></app-go-back-button>
  <section class="main">
    <div class="top" #top>
      <h3><span class="regular">My</span> Hobbies</h3>
    </div>

    <p class="tip">Select your favorite hobbies.</p>

    <h4>Outdoor Activities</h4>
    <div class="hobbies-list">
      @for(hobby of hobbies; track hobby){
        @if(hobby.category === 'outdoor'){
          <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
            <h5 class="label">{{hobby.label}}</h5>
            <p class="sub-label" [class.hide]="!hobby.subLabel">({{hobby.subLabel}})</p>
          </button>
        }
      }
    </div>

    <h4>Sports & Physical Activities</h4>
    <div class="hobbies-list">
      @for(hobby of hobbies; track hobby){
        @if(hobby.category === 'sports'){
          <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
            <h5 class="label">{{hobby.label}}</h5>
            <p class="sub-label" [class.hide]="!hobby.subLabel">({{hobby.subLabel}})</p>
          </button>
        }
      }
    </div>

    <h4>Creative Hobbies</h4>
    <div class="hobbies-list">
      @for(hobby of hobbies; track hobby){
        @if(hobby.category === 'creative'){
          <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
            <h5 class="label">{{hobby.label}}</h5>
            <p class="sub-label" [class.hide]="!hobby.subLabel">({{hobby.subLabel}})</p>
          </button>
        }
      }
    </div>
  
    <h4>Music & Performing Arts</h4>
    <div class="hobbies-list">
      @for(hobby of hobbies; track hobby){
        @if(hobby.category === 'arts'){
          <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
            <h5 class="label">{{hobby.label}}</h5>
            <p class="sub-label" [class.hide]="!hobby.subLabel">({{hobby.subLabel}})</p>
          </button>
        }
      }
    </div>

    <h4>Games & Intellectual Activities</h4>
    <div class="hobbies-list">
      @for(hobby of hobbies; track hobby){
        @if(hobby.category === 'games-intellectual'){
          <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
            <h5 class="label">{{hobby.label}}</h5>
            <p class="sub-label" [class.hide]="!hobby.subLabel">({{hobby.subLabel}})</p>
          </button>
        }
      }
    </div>

    <h4>Cooking & Culinary Discovery</h4>
    <div class="hobbies-list">
      @for(hobby of hobbies; track hobby){
        @if(hobby.category === 'cooking'){
          <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
            <h5 class="label">{{hobby.label}}</h5>
            <p class="sub-label" [class.hide]="!hobby.subLabel">({{hobby.subLabel}})</p>
          </button>
        }
      }
    </div>

    <h4>Travel & Exploration</h4>
    <div class="hobbies-list">
      @for(hobby of hobbies; track hobby){
        @if(hobby.category === 'travel'){
          <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
            <h5 class="label">{{hobby.label}}</h5>
            <p class="sub-label" [class.hide]="!hobby.subLabel">({{hobby.subLabel}})</p>
          </button>
        }
      }
    </div>

    <h4>Technology & Innovation</h4>
    <div class="hobbies-list">
      @for(hobby of hobbies; track hobby){
        @if(hobby.category === 'technology'){
          <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
            <h5 class="label">{{hobby.label}}</h5>
            <p class="sub-label" [class.hide]="!hobby.subLabel">({{hobby.subLabel}})</p>
          </button>
        }
      }
    </div>

    <h4>Well-being & Personal Development</h4>
    <div class="hobbies-list">
      @for(hobby of hobbies; track hobby){
        @if(hobby.category === 'personal'){
          <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
            <h5 class="label">{{hobby.label}}</h5>
            <p class="sub-label" [class.hide]="!hobby.subLabel">({{hobby.subLabel}})</p>
          </button>
        }
      }
    </div>
  </section>

  <app-top-button (scrollRequest)="getScrollRequest($event)"></app-top-button>

  <app-footer></app-footer>
</section>