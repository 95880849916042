@if(origin !== 'main'){
  <button class="submit" (click)="dialogRef.close('main')"><!--  [class.disabled]="data.state === 'waiting'" -->
    <span class="material-symbols-rounded check">
      check
    </span>
    <ng-container i18n="Text for the button to set a profile photo as the main photo in the profile photo menu@@MAIN_PHOTO">Set as a main photo</ng-container>
  </button>
}
<button class="submit" (click)="dialogRef.close('delete')">
  <span class="material-symbols-rounded delete">
    delete
  </span>
  <ng-container i18n="Text for the button to delete a profile photo in the profile photo menu@@DELETE_PHOTO">Delete the photo</ng-container>
</button>
<button class="text-btn" (click)="dialogRef.close('cancel')" i18n="Text for the button to cancel the action in progress@@CANCEL">
  Cancel
</button>