export interface PhotoObject {
    id?: number;
    src: string | null;
    disabled?: boolean;
    saved?: boolean;
}

export const InitialStoryPhotoArray: PhotoObject[] = [
    {
        src: null,
        saved: false
    },
    {
        src: null,
        disabled: false,
        saved: false
    },
    {
        src: null,
        disabled: true,
        saved: false
    },
    {
        src: null,
        disabled: true,
        saved: false
    },
    {
        src: null,
        disabled: true,
        saved: false
    }
]