import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Overlay, OverlayConfig, OverlayModule} from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ComponentPortal, ComponentType, Portal, PortalModule } from '@angular/cdk/portal';

import { StoryElementInterface, StoryService } from '../../story.service';
import { LayoutService } from '../../../layout.service';

import { ToolbarEditStoryMenuComponent } from "./toolbar-edit-story-menu/toolbar-edit-story-menu.component";
import { TextEditorComponent } from '../../text-editor/text-editor.component';

@Component({
  selector: 'app-toolbar-edit-story',
  standalone: true,
  imports: [CommonModule,OverlayModule, OverlayModule, PortalModule],
  templateUrl: './toolbar-edit-story.component.html',
  styleUrl: './toolbar-edit-story.component.scss'
})
export class ToolbarEditStoryComponent implements OnInit {
  @Output() closeRequest = new EventEmitter<void>()
  @Output() openTextEditor = new EventEmitter<void>()
  @Input() hideMenu = false

  showPortal = false
  screenSize!: { height: number, width: number }
  componentPortal!: Portal<unknown>// type à spécifier
  textElement!: StoryElementInterface | undefined
  allStoryElements!: StoryElementInterface[] | undefined
  isDesktop = this.layoutService.isDesktop

  constructor(
    private storyService:  StoryService,
    private overlay: Overlay,
    private layoutService: LayoutService
  ) {
    this.screenSize = {
      height: window.innerHeight,
      width: window.innerWidth
    }
  }

  ngOnInit(): void {
    this.storyService.allStoryElements$.pipe().subscribe(res => {
      this.allStoryElements = res
      this.textElement = res.find(element => element.type === 'text') || undefined
    })
  }

  openText(){
    console.log('openText')
    if(this.textElement){
      this.openTextEditor.emit()
    } else {
      this.attachPortal(TextEditorComponent, {screenSize: this.screenSize})
    }
  }

  open(emojiCategory: string, emojiSubCategory?: string) {
    //check number of visual elements and prevent adding more than 10, create a portal and overlay instance to inject into the portal outlet in the html template
    if(this.allStoryElements && this.allStoryElements.filter(element => element.type === 'visual').length > 8){
      return console.log('You can only add a maximum of 10 emojis and text to a story')
    }

    const instances = {menuType: emojiCategory, subMenuType: emojiSubCategory}
    this.attachPortal(ToolbarEditStoryMenuComponent, instances)
  }

  attachPortal(component: ComponentType<unknown>, instances: unknown){// types à spécifier
    this.componentPortal = new ComponentPortal(component)

    let config: OverlayConfig
    if(this.isDesktop()){
       config = new OverlayConfig({
        positionStrategy: this.overlay.position().global().centerHorizontally(),
        hasBackdrop: true,
        width: '100%',
        height: '100%',
        panelClass: 'custom-overlay-container',
        maxHeight: `${this.screenSize.height}px`,
        maxWidth: `${this.screenSize.height / 1.4}px`
      })
    } else {
       config = new OverlayConfig({
        hasBackdrop: true,
        width: '100%',
        height: '100%'
      })
    }

    const overlayElement = this.overlay.create(config)
    const overlayWithAttachment = overlayElement.attach(this.componentPortal)
    
    Object.assign(overlayWithAttachment.instance, instances)
    overlayWithAttachment.instance.closePanel.subscribe(() => {
      overlayElement.detach();
      this.hideMenu = false
    });

    this.hideMenu = true
  }
}
