export const Emojis = {
    emoticons: Array(80)
      .fill('')
      .map((_, index) => `emoji_${index}`),
    hands: Array(40)
      .fill('')
      .map((_, index) => `emoji_${80 + index}`),
    fun: Array(64)
      .fill('')
      .map((_, index) => `emoji_${1000 + index}`),
    animals: Array(64)
      .fill('')
      .map((_, index) => `emoji_${1064 + index}`),
  }
  export const Masks = {
    masks: Array(48)
      .fill('')
      .map((_, index) => `${index + 1}`),
  }