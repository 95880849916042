import { Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

import { AuthService } from '../../../auth.service';
import { AppService } from '../../../app.service';

import { HeaderComponent } from '../../../header/header.component';
import { ProgressBarComponent } from '../../../shared/progress-bar/progress-bar.component';
import { ErrorDialogComponent } from '../../../dialog/error-dialog/error-dialog.component';
import { GoBackButtonComponent } from '../../../shared/go-back-button/go-back-button.component';
import { LoaderComponent } from '../../../shared/loader/loader.component';

import { emailOrPhoneValidator } from '../../../shared/validators/emailOrPhoneValidator';

import { ClickAnywhereDirective } from '../../../click-anywhere.directive';

@Component({
  selector: 'app-auth-subscribe-third',
  standalone: true,
  imports: [
    FormsModule, 
    ReactiveFormsModule, 
    HeaderComponent, 
    CommonModule, 
    ProgressBarComponent, 
    ErrorDialogComponent, 
    GoBackButtonComponent, 
    RouterModule, 
    LoaderComponent, 
    ClickAnywhereDirective
  ],
  templateUrl: './auth-subscribe-third.component.html',
  styleUrl: './auth-subscribe-third.component.scss'
})
export class AuthSubscribeThirdComponent implements OnInit, OnDestroy {
  @ViewChild('errorMessageDialog') errorMessageDialog!: ElementRef

  subThirdFormGroup!: FormGroup
  error = false
  showPassword = false
  errorMessage: string | null = null
  errorTitle: string | null = null
  openDialog = false
  
  hightlightedSteps: {
    first: boolean, 
    second?: boolean, 
    third?: boolean,
    fourth?: boolean
  } = { first: true, second: true, third: true }
  loading = false
  passwordMinCharNumber = 6
  passwordMaxCharNumber = 25

  loaderSubscription!: Subscription

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private router: Router
  ){
    this.subThirdFormGroup = new FormGroup({
      firstnameCtrl: new FormControl(this.authService.get('firstname') || '', [Validators.required, Validators.minLength(2), Validators.maxLength(25)]),
      emailCtrl: new FormControl(this.authService.get('email') || '', [Validators.required, emailOrPhoneValidator()]),
      passwordCtrl: new FormControl('', [Validators.required, Validators.minLength(this.passwordMinCharNumber), Validators.maxLength(this.passwordMaxCharNumber)]),
    });
  }

  ngOnInit() {
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    // vérifier si les infos des étapes 1 et 2 d'inscription sont disponibles
    if(!this.authService.get('sex') || !this.authService.get('birthdate')) this.router.navigate(['/subscribe-step-one'])
    if(!this.authService.get('city_id') || !this.authService.get('city_name')) this.router.navigate(['/subscribe-step-two'])
  }

  getClickNature(clickNature: string){
    if(clickNature === 'anywhere' && this.openDialog === true){
      this.openDialog = false
    }
  }

  onSubmit(data: {firstnameCtrl: string, emailCtrl: string, passwordCtrl: string}){
    this.appService.setLoader(true)

    this.authService.setSubscriptionFormData('firstname', data.firstnameCtrl)
    this.authService.setSubscriptionFormData('email', data.emailCtrl.toLowerCase())
    this.authService.setSubscriptionFormData('password', data.passwordCtrl)
    this.authService.set('firstname', data.firstnameCtrl)
    this.authService.set('email', data.emailCtrl)

    this.authService
    .authSubscribe()
    .subscribe({
      next: (res => {
        if(res.datas?.user && res.datas?.api_token){
          this.authService.set('token', res.datas.api_token)
          this.authService.set('user_id', res.datas?.user.user_id.toString())

          // A supprimer : uniquement pour les tests !!! 
          this.authService.validateQuizz().subscribe({
            next: (() => {
              console.log('utilisateur validé')
            })
          })
          // A supprimer : uniquement pour les tests !!! 

          this.authService.getProfile(res.datas?.user.user_id).subscribe({
            next: (res => {
              this.appService.setLoader(false)
              this.authService.set('user', JSON.stringify(res))

              this.router.navigate(['/subscribe-step-four'])
            }),
            error: (error => {
              this.appService.setLoader(false)
              this.errorTitle = 'Login failed'
              this.errorMessage = error?.error.message
              this.openDialog = true
            })
          }) 
        }
      }),
      error: (error => {
        this.appService.setLoader(false)
        this.errorTitle = 'Subscription failed'
        this.errorMessage = error?.error.message
        this.openDialog = true
      })
    })
  }

  setFocusInput(){
    this.openDialog = false
  }

  showHidePassword(){
    this.showPassword = !this.showPassword;
  }

  closeDialog(){
    this.openDialog = false
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
