<section class="mobile"  [ngStyle]="{'height': '80px'}" >
    @if(textToolSelected){
        <div class="toolbar" [ngStyle]="{'z-index': '1'}">
            <div class="left">
                <!-- <span class="material-symbols-rounded close" (click)="cancelTextEditionOnMobile()">
                    keyboard_backspace
                </span> -->
            </div>
            <div class="right">
                <button class="submit" (click)="confirmTextEditionOnMobile()">Done</button>
            </div>
        </div>
    } @else {
        <div class="toolbar" [ngStyle]="{'z-index': displayOverlay ? '4' : '1'}" >
            <div class="left">
                <!-- <span class="material-symbols-rounded close" (click)="displayOverlay ? cancelToolSelection() : closeNewStory()">
                    @if(displayOverlay){
                        keyboard_backspace
                    } @else {
                        close
                    }
                </span> -->
            </div>

            @if(!storyImages[selectedImageId].processed){
              <div class="right" [ngStyle]="{'display': displayOverlay ? 'none' : 'flex'}" >
                @if(textInputs.length === 0){
                    <!-- <div class="tool" #textButton (click)="textContent.length > 0 ? editInputOnMobile() : addTextInputOnMobile()">
                        <img src="../../../assets/icons/icon_add_text.svg" alt="" class="icon">
                    </div> -->
                }
                
                @if(visuals.length <= 9){
                    <!-- <div class="tool" #stickersButton (click)="showMenu('stickers')" [class.selected]="showStickersList">
                        <img src="../../../assets/icons/icon_add_stickers.svg" alt="" class="icon" >
                    </div>
                    <div class="tool" #emojiButton (click)="showMenu('emojis')" [class.selected]="showEmojisList">
                        <img src="../../../assets/icons/icon_add_emoticon.svg" alt="" class="icon">
                    </div>
                    <div class="tool" #maskButton (click)="showMenu('masks')" [class.selected]="showMasksList">
                        <img src="../../../assets/icons/icon_add_mask.svg" alt="" class="icon" >
                    </div> -->
                } 
              </div>
            }
        </div>
    }

    <!-- @else if(visualToolSelected){
        <div class="toolbar"  >
            <div class="left">
                <span class="material-symbols-rounded close" (click)="cancelToolSelection()">
                    keyboard_backspace
                </span>
            </div>
        </div>
    } -->

    <div class="overlay" [class.show]="displayOverlay"></div>
    <div class="sub-menus" 
        [class.show]="showStickersList || showEmojisList || showMasksList" 
        [ngStyle]="{'z-index': '3'}"
    >
        <div class="stickers" [class.show]="showStickersList">
            <div class="stickers-group">
                <div class="stickers-menu">
                    <button
                        class="sticker-type"
                        (click)="showEmojiType('fun', 1)"
                        [class.selected]="emojiTypeSelected === 'fun'"
                    >
                        <img src="assets/emoticons/emojis/thumb/emoji_1004.png" alt="Fun" class="button-image" title="Fun">
                    </button>
                    <button
                        class="sticker-type"
                        (click)="showEmojiType('animals', 2)"
                        [class.selected]="emojiTypeSelected === 'animals'"
                    >
                        <img src="assets/emoticons/emojis/thumb/emoji_1068.png" alt="Animals" class="button-image" title="Animals">
                    </button>
                </div>
                <div class="emojis-group-holder">
                    @for(img of emojiTypeSelected === 'fun' ? emojis.fun : emojis.animals; track img){
                        <div class="emojis-group-holder-emoji">
                            <!-- <img
                                #visual
                                (click)="addVisual(img, 'sticker')"
                                [src]="'assets/emoticons/emojis/thumb/' + img + '.png'"
                            /> -->
                        </div>
                    }
                </div>
            </div>
        </div>
    
        <div class="emojis" [class.show]="showEmojisList">
            <div class="emojis-group">
                <div class="emojis-menu">
                    <button
                        class="emoji-type"
                        (click)="showEmojiType('emoticons', 0)"
                        [class.selected]="emojiTypeSelected === 'emoticons'"
                    >
                        <img src="assets/emoticons/emojis/thumb/emoji_53.png" alt="Emoticons" class="button-image emoticon" title="Emoticons">
                    </button>
                    <button
                        class="emoji-type"
                        (click)="showEmojiType('hands', 1)"
                        [class.selected]="emojiTypeSelected === 'hands'"
                    >
                        <img src="assets/emoticons/emojis/thumb/emoji_84.png" alt="Hands" class="button-image" title="Hands">
                    </button>
                </div>
                <div class="emojis-group-holder">
                    @for(img of emojiTypeSelected === 'emoticons' ? emojis.emoticons : emojis.hands; track img){
                        <div class="emojis-group-holder-emoji">
                            <!-- <img
                            #visual
                                (click)="addVisual(img, 'emoji')"
                                [src]="'assets/emoticons/emojis/thumb/' + img + '.png'"
                            /> -->
                        </div>
                    }
                </div>
            </div>
        </div>
    
        <div class="emojis" [class.show]="showMasksList">
            <div class="emojis-group">
                <div class="emojis-group-holder">
                    @for(img of masks.masks; track img){
                        <div class="emojis-group-holder-emoji">
                            <!-- <img
                            #visual
                                (click)="addVisual(img, 'mask')"
                                [src]="'assets/masks/thumb/' + img + '.png'"
                            /> -->
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
</section>

<section class="desktop">
   <!--  @if(imageProcessed){
        <div class="overlay"></div>
    } -->

    <div class="toolbar">
        <!-- <div class="tool text" #textButton (click)="showMenu('text')" [class.selected]="textToolSelected">
            <img src="../../../assets/icons/icon_add_text.svg" alt="" class="icon">
            <p class="title">Add Text</p>
        </div>
        <div class="tool" #stickersButton (click)="showMenu('stickers')" [class.selected]="!textToolSelected && showStickersList" [class.disabled]="maxVisualsReached">
            <img src="../../../assets/icons/icon_add_stickers.svg" alt="" class="icon" >
            <p class="title">Add Stickers</p>
        </div>
        <div class="tool" #emojiButton (click)="showMenu('emojis')" [class.selected]="!textToolSelected && showEmojisList" [class.disabled]="maxVisualsReached">
            <img src="../../../assets/icons/icon_add_emoticon.svg" alt="" class="icon">
            <p class="title">Add Emojis</p>
        </div>
        <div class="tool" #maskButton (click)="showMenu('masks')" [class.selected]="!textToolSelected && showMasksList" [class.disabled]="maxVisualsReached">
            <img src="../../../assets/icons/icon_add_mask.svg" alt="" class="icon" >
            <p class="title">Add Masks</p>
        </div> -->
    </div>
    
    <div class="editor-container">
        <div class="editor" >
            @if(textToolSelected){
                <div class="text-custom">
                    <input
                        text="text"
                        class="textarea input"
                        (click)="setTextFocus()"
                        placeholder="Write your text here"
                        maxlength="150"
                        [value]="currentInput.value || ''"
                        (blur)="onBlur($event.target)"
                              
                    /><!-- #inputTextDomElement [disabled]="textInputs.length > 0" -->
    
                    @if(colors){
                        <app-color-palette 
                            [colors]="colors"
                            (colorInfo)="getTextColorInfo($event)"
                        ></app-color-palette>
                    }
                </div>
            } @else if(!textToolSelected && showStickersList){
                <div class="stickers" [class.show]="showStickersList">
                    <div class="stickers-group">
                        <div class="stickers-menu">
                            <button
                                class="sticker-type"
                                (click)="showEmojiType('fun', 1)"
                                [class.selected]="emojiTypeSelected === 'fun'"
                            >
                                <img src="assets/emoticons/emojis/thumb/emoji_1004.png" alt="Fun" class="button-image" title="Fun">
                            </button>
                            <button
                                class="sticker-type"
                                (click)="showEmojiType('animals', 2)"
                                [class.selected]="emojiTypeSelected === 'animals'"
                            >
                                <img src="assets/emoticons/emojis/thumb/emoji_1068.png" alt="Animals" class="button-image" title="Animals">
                            </button>
                        </div>
                        <div class="emojis-group-holder">
                            @for(img of emojiTypeSelected === 'fun' ? emojis.fun : emojis.animals; track img){
                                <div class="emojis-group-holder-emoji">
                                    <!-- <img
                                        (click)="addVisual(img, 'sticker')"
                                        [src]="'assets/emoticons/emojis/thumb/' + img + '.png'"
                                    /> -->
                                </div>
                            }
                        </div>
                    </div>
                </div>
            } @else if(!textToolSelected && showEmojisList){
                <div class="emojis" [class.show]="showEmojisList">
                    <div class="emojis-group">
                        <div class="emojis-menu">
                            <button
                                class="emoji-type"
                                (click)="showEmojiType('emoticons', 0)"
                                [class.selected]="emojiTypeSelected === 'emoticons'"
                            >
                                <img src="assets/emoticons/emojis/thumb/emoji_53.png" alt="Emoticons" class="button-image emoticon" title="Emoticons">
                            </button>
                            <button
                                class="emoji-type"
                                (click)="showEmojiType('hands', 1)"
                                [class.selected]="emojiTypeSelected === 'hands'"
                            >
                                <img src="assets/emoticons/emojis/thumb/emoji_84.png" alt="Hands" class="button-image" title="Hands">
                            </button>
                        </div>
                        <div class="emojis-group-holder">
                            @for(img of emojiTypeSelected === 'emoticons' ? emojis.emoticons : emojis.hands; track img){
                                <div class="emojis-group-holder-emoji">
                                    <!-- <img
                                        (click)="addVisual(img, 'emoji')"
                                        [src]="'assets/emoticons/emojis/thumb/' + img + '.png'"
                                    /> -->
                                </div>
                            }
                        </div>
                    </div>
                </div>
            } @else if(!textToolSelected && showMasksList){
                <div class="emojis" [class.show]="showMasksList">
                    <div class="emojis-group">
                        <div class="emojis-group-holder">
                            @for(img of masks.masks; track img){
                                <div class="emojis-group-holder-emoji">
                                    <!-- <img
                                        (click)="addVisual(img, 'mask')"
                                        [src]="'assets/masks/thumb/' + img + '.png'"
                                    /> -->
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
</section>
