import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoryService } from '../story.service';
import { AppService } from '../../app.service';
import { TextInput } from '../../shared/interfaces/text-input';
import { StoryContent } from '../../shared/interfaces/story-content';

@Component({
  selector: 'app-text-input',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss'
})
export class TextInputComponent implements OnInit {
  @Output() textValue = new EventEmitter<string>()
  @Output() textDeleted = new EventEmitter<boolean>()
  @Input() imageId!: number
  @Input() id!: number
  @Input() bounds!: DOMRect
  @Input() inputSize!: {height: number, width: number}
  @Input() input!: StoryContent | null
  @Input() textInput!: TextInput
  @ViewChild('dropzone', {read: ElementRef}) dropzone!: ElementRef

  textToolSelected = false
  inputNewPos: { x: number | 0, y: number | 0 } | null = null
  inputInitialPos: {x: number, y: number} | null = null
  textInputs: { id: number, value: string, pos: {x: number, y: number} }[] = []
  storyImages: { imageId: number, value: string, processed: boolean }[] = []

  mobileTouchStartPosition:{ x: number, y: number } = { x: 0, y: 0 }
  desktopDragStartPosition:{ x: number, y: number } = { x: 0, y: 0 }
  textTransformData: {
    size: number, 
    angle: number
    x: string,
    y: string
  } = {
    size: 20,
    angle: 0,
    x: 'calc(50% - 100px)',
    y: 'calc(50% - 24px)'
  }

  contents: StoryContent[] = []
  currentInput!: StoryContent

  constructor(
    private storyService: StoryService,
    private appService: AppService
  ){}

  ngOnInit(){
    console.log('text-input')
    this.storyService.inputId = this.id

    this.storyService.contents.subscribe(res => this.contents = res)
    this.storyService.currentInput.subscribe(res => this.currentInput = res)
  }

  onBlur(target: EventTarget | null){
    const inputElement = target as HTMLInputElement
    this.currentInput.value = inputElement.value

    this.storyService.currentInput.next(this.currentInput)

    this.contents.forEach(c => {
      if(c.type === 'text'){
        c.value = inputElement.value
      }
    })

    this.storyService.contents.next(this.contents)
  }

  /* onTouchStart(event: any){
    this.mobileTouchStartPosition.x = event.changedTouches[0].clientX
    this.mobileTouchStartPosition.y = event.changedTouches[0].clientY
  } */

  onTouchMove(){}

  /* onTouchEnd(event: any){
    if(this.mobileTouchStartPosition.x === event.changedTouches[0].clientX && this.mobileTouchStartPosition.y === event.changedTouches[0].clientY) {
      return
    }

    if(Math.abs(event.changedTouches[0].clientX - this.mobileTouchStartPosition.x) < 5 && Math.abs(event.changedTouches[0].clientY - this.mobileTouchStartPosition.y) < 5) {
      return
    }

    this.textTransformData.x = `${Math.floor(event.changedTouches[0].clientX - this.inputSize.width / 2)}px`
    this.textTransformData.y = `${Math.floor(event.changedTouches[0].clientY - this.inputSize.height / 2)}px`

    this.contents.forEach(c => {
      if(c.type === 'text'){
        c.newData = {
          ...c.newData,
          x: this.textTransformData.x,
          y: this.textTransformData.y
        }
      }
    })

    this.storyService.contents.next(this.contents)
  } */

  onDragStart(event: DragEvent){
    this.desktopDragStartPosition.x = event.x
    this.desktopDragStartPosition.y = event.y
  }

  onDragOver(event: DragEvent){
    event.preventDefault()
  }

  onDrop(event: DragEvent){
    const initialX = this.currentInput.initialData.x.slice(0, this.currentInput.initialData.x.length - 2)
    const initialY = this.currentInput.initialData.y.slice(0, this.currentInput.initialData.y.length - 2)
    if(parseInt(initialX) === event.offsetX && parseInt(initialY) === event.offsetY) return

    if(Math.abs(event.offsetX - parseInt(initialX)) < 5 && Math.abs(event.offsetY - parseInt(initialY)) < 5) return

    const x = event.offsetX 
    const y = event.offsetY

    this.currentInput.newData = {
      x: `${x - this.inputSize.width / 2}px`,
      y: `${y - this.inputSize.height / 2}px`
    }

    this.contents.forEach(c => {
      if(c.type === 'text'){
        c.newData = {
          x: `${x - this.inputSize.width / 2}px`,
          y: `${y - this.inputSize.height / 2}px`
        }
      }
    })

    this.storyService.contents.next(this.contents)
  }

  // récupère la position du drag de l'élément sélectionné
 /*  _getNewPos($event: any){   
    console.log('getNewPos') 

    // $event.stopPropagation()
    if(this.appService.desktopDevice){
      if($event.clientX === 0 && $event.clientY === 0) { console.log('ignore move'); return }
  
      const bounds = this.bounds
      console.log(bounds)

      if($event.clientX > bounds.right || 
        $event.clientX < bounds.left ||
        $event.clientY < bounds.top ||
        $event.clientY > bounds.bottom){
          console.log('hors champ')
          return
        }

      this.inputNewPos = {
        x: $event.clientX,
        y: $event.clientY
      }
    } else {
      console.log('mobile')
      
      const bounds = this.storyService.mobilePhotoHolderBounds
      const toolbarHeight = 80
      const imagesHolderHeight = 150

      if($event.clientX && $event.clientY){
        if($event.clientX > bounds.right || 
          $event.clientX < bounds.left ||
          $event.clientY < bounds.top + toolbarHeight || 
          $event.clientY > bounds.bottom - imagesHolderHeight){
            console.log('hors champ')
            return
          }
  
        this.inputNewPos = {
          x: $event.clientX,
          y: $event.clientY
        }
      } else {
        if($event.changedTouches[0].pageX > bounds.right || 
          $event.changedTouches[0].pageX < bounds.left ||
          $event.changedTouches[0].pageY < bounds.top + toolbarHeight ||
          $event.changedTouches[0].pageY > bounds.bottom - imagesHolderHeight){
          console.log('hors champ')
          return
        }
  
        if($event.changedTouches){
          this.inputNewPos = {
            x: Math.floor($event.changedTouches[0].pageX),
            y: Math.floor($event.changedTouches[0].pageY)
          }
        }

        //this.moveInputElement(id)
      }
    }
  } */

  // déplace l'élément sélectionné dans photo-holder de create story
  /* _moveInputElement(id: number, $event?: any){
    console.log($event)
    console.log('moveInputElement')

    const bounds = this.bounds
    console.log(bounds)

    if($event.clientX > bounds.right || 
      $event.clientX < bounds.left ||
      $event.clientY < bounds.top ||
      $event.clientY > bounds.bottom){
        console.log('hors champ')
        return
      }
      
    this.inputNewPos = {
      x: $event.clientX,
      y: $event.clientY
    }

    if(!this.inputNewPos) {console.log('no new pos'); return}
    
    const input = this.textInputs.filter(input => { return input.id === id })

    if(!this.inputInitialPos) {console.log('no initial pos'); return}

    if(this.appService.desktopDevice){
      input[0].pos.x = this.inputNewPos.x - this.inputInitialPos.x
      input[0].pos.y = this.inputNewPos.y  - this.inputInitialPos.y
    } else {
      const inputWidthHalf = 100
      const inputHeightHalf = 24
      input[0].pos.x = this.inputNewPos.x - inputWidthHalf
      input[0].pos.y = this.inputNewPos.y - inputHeightHalf

      // const deltaX = $event.changedTouches[0].clientX - this.inputInitialPos.x
      // const deltaY = $event.changedTouches[0].clientY - this.inputInitialPos.y
      // console.log(deltaX, deltaY)
    }
  } */

  // deleteInput(){
  //   console.log('deleteInput')
  //   this.textDeleted.emit(true)
  //   this.storyService.displayOverlay.next(false)
  //   this.storyService.textToolSelected.next(false)
  // }

    // permet de récupérer le texte saisi par l'utilisateur
  // onBlur($event: any, id: number){
  //   console.log('onBlur text input')

  //   const input = this.textInputs.filter(input => { 
  //     return input.id === id 
  //   })

  //   if($event.target.value !== input[0].value){
  //     this.textValue.emit($event.target.value)
  //   }
  // }

  // onChange($event: any){
  //   console.log('onChange')
  //   console.log($event)
  // }
}
