<app-go-back-button class="edit-go-back-btn"></app-go-back-button>  

<section class="delete-account">
  <div class="top" #top>
    <h3><span class="regular">Delete</span> Account</h3>
  </div>

  <h3>My Password</h3>
  <div class="input-holder">
    <button type="button" class="password-btn"><!--  [class.showHide]="!showPassword" (click)="showHidePassword()" -->
      <span class="material-symbols-outlined password-icon">
        visibility
      </span>
    </button>
    <button type="button" class="password-btn"><!--  [class.showHide]="showPassword" (click)="showHidePassword()" -->
      <span class="material-symbols-outlined password-icon">
        visibility_off
      </span>
    </button>

    <input
      id="password"
      
      formControlName="passwordCtrl"
      
      placeholder="Password"
      autocapitalize="none"
      #inputPassword
      
    /><!-- [type]="showPassword ? 'text': 'password'" -->

    <!-- @if(showPassword){
      <div class="show-password">{{user?.firstname}}</div>
    } -->

    <p class="tip">For security reasons, please enter your password to confirm deletion of your Swipi account.</p>
  </div>

  <button
    class="submit"
    type="submit"
  ><!-- [class.wrong]="wrongCredential" [disabled]="authFormGroup.invalid || error"-->
    Delete my account
  </button>
</section>
    