import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UploadPhotoComponent } from '../../../photo/upload-photo/upload-photo.component';
import { ProgressBarComponent } from '../../../shared/progress-bar/progress-bar.component';
import { GoBackButtonComponent } from '../../../shared/go-back-button/go-back-button.component';

@Component({
  selector: 'app-auth-subscribe-fourth',
  standalone: true,
  imports: [UploadPhotoComponent, ProgressBarComponent, GoBackButtonComponent, RouterModule],
  templateUrl: './auth-subscribe-fourth.component.html',
  styleUrl: './auth-subscribe-fourth.component.scss'
})
export class AuthSubscribeFourthComponent {
  flow = 'subscription'

  hightlightedSteps: {
    first: boolean, 
    second?: boolean, 
    third?: boolean, 
    fourth?: boolean
  } = { first: true, second: true, third: true, fourth: true }
}
