import { Component } from '@angular/core';

import { GoBackButtonComponent } from '../../../shared/go-back-button/go-back-button.component';

@Component({
  selector: 'app-delete-account',
  standalone: true,
  imports: [GoBackButtonComponent],
  templateUrl: './delete-account.component.html',
  styleUrl: './delete-account.component.scss'
})
export class DeleteAccountComponent {

}
