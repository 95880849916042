<button class="text-btn" (click)="dialogRef.close('delete')">
  <img src="assets/icons/icon_chat_delete.svg" alt="" class="icon delete-icon">
  <ng-container i18n="Text for the delete conversation item of the conversation menu@@DELETE_CONVERSATION">Delete conversation</ng-container>
</button>
<button class="text-btn" (click)="dialogRef.close('unmatch')">
  <img src="assets/icons/icon_unmatch.svg" alt="" class="icon unmatch-icon">
  <ng-container i18n="Text for the unmatch item of the conversation menu@@UNMATCH">Unmatch</ng-container> 
</button>
<button class="text-btn" (click)="dialogRef.close('block')">
  <img src="assets/icons/icon_block.svg" alt="" class="icon block-icon">
  <ng-container i18n="Text for the block user item of the conversation menu@@BLOCK_USER">Block the user</ng-container> 
</button>
<button class="text-btn" (click)="dialogRef.close('report')">
  <img src="assets/icons/icon_report_block.svg" alt="" class="icon report-icon">
  <ng-container i18n="Text for the report and block item of the conversation menu@@BLOCK_AND_REPORT">Block and Report</ng-container> 
</button>