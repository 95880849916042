import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { User } from '../shared/interfaces/user';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class EncountersService {

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private authService: AuthService
  ) { }

  getEncounters(page: number, offset: number, id: number): Observable<User[]>{
    const lang = 'fr'

    return this.http.get<{users: User[]}>(`${this.appService.API}/api/relationship/invitations/${lang}/${page}/${offset}/${id}`)
    .pipe(
      map(res => {
        res.users.map(u => {
          u.relationship = 'accepted'
        })
        return res.users
      }),
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  getFakeEncounters(): Observable<User[]> {
    return this.http.get<User[]>('./assets/encounters.json').pipe(map((res) => {
      return res
    }))
  }

  createRelationship(relatedUserId: number, state: string){
    const body = {
      related_user_id: relatedUserId,
      state: state
    }

    return this.http.post(`${this.appService.API}/api/relationship`, body)
    .pipe(
      map(res => {
        console.log(res)
        return res
      }),
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }
}
