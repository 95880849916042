import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { AppService } from './app.service';

import { PopupWarningComponent } from './shared/popup-warning/popup-warning.component';

import { ErrorReturn } from './shared/interfaces/errorReturn';

import { ClickAnywhereDirective } from './click-anywhere.directive';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, PopupWarningComponent, ClickAnywhereDirective],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'swipi';
  error: ErrorReturn | null = null;
  errorValue: ErrorReturn | null = null;
  loader = {
    loading: true,
  };

  constructor(
    private appService: AppService
  ){}

  ngOnInit(){
    this.appService.popin.subscribe((ret) => {
      if(ret) {
        this.loader.loading = false;
      }
      this.error = ret;
      this.errorValue = ret;
    });
  }

  getClickNature(clickNature: string){
    if(clickNature === 'anywhere'){
      this.appService.setPopin(null);
    }
  }
}
