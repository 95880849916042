import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ProfileService } from '../../profile/profile.service';
import { AuthService } from '../../auth.service';

import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-match',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './match.component.html',
  styleUrl: './match.component.scss'
})
export class MatchComponent implements OnInit {
  @Input() profile!: User

  constructor(
    private authService: AuthService,
    private profileService: ProfileService
  ){}

  ngOnInit(){
    this.authService.getProfile(this.profile.id).subscribe(res => {
      
      this.profileService.profile = res
    })
  }
}
