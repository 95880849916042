<section class="profile-stories" #profileStoriesHolder>
    <div class="profile">
      @if(profile){
        <div class="user-information mobile">
          <div class="avatar-container">
            <img [src]="profile.main_photo?.url" alt="">
          </div>
          <div class="info">
            <h3>{{profile.firstname}}</h3>
            <p>{{userAge}} <ng-container i18n="Age unit@@YEARS">years</ng-container> - {{profile.city?.name}}</p>
            <div class="searching-container">
                <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
                {{profile.attraction | titlecase}}
            </div>
          </div>
        </div>
      }
    </div>

    <div class="stories-container" #storiesContainer>
      @for(s of stories; track s.id){
        <div class="story-container"
          [ngStyle]="getStyles()"
        >
            <app-story [story]="s" [user]="profile" (showStoryRequest)="getShowStoryRequest($event)"></app-story>
        </div>
      } @empty {
        @if((loading$ | async) === false) {
          <p class="no-stories" i18n="Text displayed when the profile has no stories to show in the profile stories page@@NOT_STORIES_YET">This person has not published any stories yet.</p>
        }
      }
      
      @if(loading$ | async){
        <app-loader></app-loader>
      }
    </div>
</section>