<div 
    class="contents-container" 
    (dragover)="onDragOver($event)"
    (drop)="onDrop($event)"
>

  @for(c of contents; track c.id){
    @if(c.type === 'text' && c.value !== ''){
      <app-text-input 
        (textDeleted)="getTextDeleted()"
        (click)="selectStoryContent(c)"
        [ngStyle]="{
          'font-size': c.newData && c.newData.size ? c.newData.size + 'px' : 
            c.initialData.size ? c.initialData.size + 'px' : '14px', 
          'transform-origin': 'center',
          'position': 'absolute',
          'top': c.newData && c.newData.y ? c.newData.y : c.initialData.y,
          'left': c.newData && c.newData.x ? c.newData.x : c.initialData.x
        }"
        [inputSize]="inputSize"
        [input]="c"
        #textInput
        [draggable]="currentInput?.id === c.id"
      ></app-text-input>
    } @else if(c.type === 'visual'){
      <div
        class="visual-container"
        [class.selected]="c.id === currentInput?.id"
        [ngStyle]="{
          'height': c.newData && c.newData.height ? c.newData.height + 'px' : c.initialData.height + 'px',
          'width': c.newData && c.newData.width ? c.newData.width + 'px' : c.initialData.width + 'px',
          'position': 'absolute',
          'top': c.newData && c.newData.y ? c.newData.y : c.initialData.y,
          'left': c.newData && c.newData.x ? c.newData.x : c.initialData.x,
          'transform': c.newData && c.newData.angle ? 'rotate(' + c.newData.angle + 'deg)' : 'rotate(' + c.initialData.angle + 'deg)'
        }"
        [draggable]="currentInput?.id === c.id"
      >
<!--         <img alt="" class="visual" [src]="c.value" [id]="c.id" (click)="selectStoryContent(c)" (touchstart)="onTouchStart($event)" (touchmove)="onTouchMove($event)" (touchend)="onTouchEnd($event)" #visual>
 -->      </div>
    }
  }

  <div class="actions-container" [class.hide]="textToolSelected && !desktopDevice">
    <div class="input resize">
      <label for="size">
        <span class="material-symbols-outlined resize">resize</span>
      </label>
      <input 
        #size 
        type="range" 
        id="size" 
        name="size" 
        min="0" 
        max="200" 
        [attr.value]="currentInput && currentInput.newData && currentInput.newData.size ? currentInput.newData.size * 3 : 
          currentInput && currentInput.initialData.size ? currentInput.initialData.size * 3 : 40" 
        (input)="getResizeValue(size.value)"
        />
    </div>
    <div class="input angle">
      <label for="angle">
        <span class="material-symbols-rounded rotate" id="imageId">rotate_left</span>
      </label>
        <input #angle type="range" id="angle" 
        [attr.value]="currentInput && currentInput.newData && currentInput.newData.angle ? currentInput.newData.angle : 
          currentInput && currentInput.initialData.angle ? currentInput.initialData.angle : 0"  name="angle" min="0" max="360" (input)="getAngleValue(angle.value)"/>
    </div>

    <!-- <div class="delete-container" (click)="deleteVisual()">
      <span class="material-symbols-outlined delete">
        delete
      </span>
    </div> -->
  </div>
 
  <!-- 
      [ngStyle]="{ 
          'display': 'block',
          'top': input.pos.y === 0 ? 'calc(50% - 24px)' : input.pos.y+'px', 
          'left': input.pos.x === 0 ? 'calc(50% - 100px)' : input.pos.x+'px',
          'z-index': visualToolSelected ? '1' : '3',
          'position': 'absolute'
      }"
  [value]="input.value" 
      [color]="input.fontColor"
      [shadowColor]="input.shadowColor"
      [imageId]="imageId" 
      (textDeleted)="getTextDeleted($event)"
      (textValue)="getTextValue($event)" 
          -->
 </div>
