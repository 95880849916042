<dialog class="dialog">
    <header>
        {{title}}
    </header>
    <div class="dialog-content">
        {{content}}
    </div>

    <div class="actions">
        <button class="text-btn" (click)="cancel($event)">Cancel</button>
        <button class="submit" (click)="onSubmit()">
            <span class="material-symbols-rounded delete">
                delete
            </span>
            Yes, delete
        </button>
    </div>
</dialog>    