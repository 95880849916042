import { Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
//import { Observable, ObservableInput, catchError, map, of } from 'rxjs';

//import { AuthService } from '../../auth.service';


Injectable({
  providedIn: 'root'
})
export const subscribeGuard: CanActivateFn = (
  //route: ActivatedRouteSnapshot
) => {
  //const authService: AuthService = inject(AuthService)

  //const token = route.params['SBtoken'] //le token de Smart Builder

  //const tokenStoredLocally = authService.get('token')

  // on vérifie si on a un SBtoken, si oui, cela signifie que l'utilisateur est abonné (transaction id).

  /* token */ // TODO : à terminer une fois qu'on aura un compte de test abonné
  /* if(token){
    //appService.setLoader({ timer: false, display: true, value: 'welcome', type: 'loading' });

    console.log('subscribe guard')

    return authService.authCheckToken(token).pipe(
      map((res: any) => {
        console.log('res: ', res)
        
        // authService.userBilledInfos = res;
        //appService.setLoader({ timer: false, display: false, value: null, type: null });
        //return !!res;
      }),
      catchError((err: any, caught: Observable<void>) : ObservableInput<any>=> {
        console.log(err)
        return of(false)
      })
    )
  }  */

  /* pas de SBtoken */
  console.log('utilisateur non abonné')
  
  return true;
};
