import { AfterContentInit, AfterViewChecked, Component, ElementRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { filter, Subscription, throwError } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Dialog } from '@angular/cdk/dialog';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';
import { StoriesService } from '../../stories.service';
import { HomeService } from '../../home/home.service';

import { LoaderComponent } from '../../shared/loader/loader.component';
import { TopButtonComponent } from '../../shared/top-button/top-button.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';
import { DeleteStoryDialogComponent } from '../../dialog/delete-story-dialog/delete-story-dialog.component';
import { PublishStoryDialogComponent } from '../../dialog/publish-story-dialog/publish-story-dialog.component';

import { Story } from '../../shared/interfaces/story';

@Component({
  selector: 'app-my-stories',
  standalone: true,
  imports: [LoaderComponent, TopButtonComponent, GoBackButtonComponent, CommonModule],
  templateUrl: './my-stories.component.html',
  styleUrl: './my-stories.component.scss'
})
export class MyStoriesComponent implements OnInit, AfterContentInit, AfterViewChecked, OnDestroy {
  @ViewChild('top') top!: ElementRef
  @ViewChild('storiesContainer') storiesContainer!: ElementRef
  @ViewChild('myStoriesHolder', { static: true, read: ElementRef }) myStoriesHolder!: ElementRef
   
  loading = false 
  stories: Story[] = []
  currentPage = 1
  totalPages = 1
  loaderSubscription!: Subscription
  desktopDevice = false
  viewportHeight!: number
  viewportWidth!: number
  storyWidth!: number
  loadingMore = false
  noMoreToLoad = false
  storiesLoading = true
  dialog = inject(Dialog)
  scrollValueFromHome!: Event | null

  constructor(
    private appService: AppService,
    private storiesService: StoriesService,
    private location: Location,
    private homeService: HomeService,
    private router: Router
  ){}

  ngOnInit(){
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }
    this.desktopDevice = this.appService.desktopDevice
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    // this.storiesService.getStoriesByUser(1, 10).subscribe(res => {
    //   this.stories = res
    // })
    
    this.storiesService.getStoriesByUser(this.currentPage, 9).subscribe({
      next: (res) => {
        this.storiesLoading = true
        this.stories = res
        this.storiesLoading = false

        console.log(this.stories)
      },
      error: (err) => {
        this.storiesLoading = false
        return throwError(() => err)
      },
    });
    
    // this.storiesService.getFakeStories().subscribe(res => {
    //   this.stories = this.stories.concat(res)
    // })

    this.appService.scrollValue.subscribe(res => {
      this.scrollValueFromHome = res
      this.windowScrolling()
    })
  }

  ngAfterViewChecked(){
    if(!this.desktopDevice) return
    if(!this.storiesContainer) return

    const bounds = this.storiesContainer.nativeElement.getBoundingClientRect()
    const storiesContainerWidth = Number.parseFloat((bounds.right - bounds.left).toFixed(2))
    const gapWidth = 16
    const numberOfItemsPerRow = 3

    this.storyWidth = Math.floor((storiesContainerWidth - (numberOfItemsPerRow + 1) * gapWidth) / numberOfItemsPerRow)
  }

  ngAfterContentInit(){
    if(this.desktopDevice) return

    this.viewportHeight = window.innerHeight
    this.viewportWidth = window.innerWidth
    const gapWidth = 16
    const numberOfItemsPerRow = 2

    this.storyWidth = Math.floor((this.viewportWidth - (numberOfItemsPerRow + 1) * gapWidth) / numberOfItemsPerRow)
  }

  windowScrolling() {
    const target = this.scrollValueFromHome?.target as HTMLElement
    
    if(!target) return

    const scrollValue = target.scrollTop
    const content = this.myStoriesHolder.nativeElement

    let bottom!: boolean
    if(this.desktopDevice) {
      bottom = scrollValue > content.clientHeight - window.innerHeight - 800;
    } else {
      bottom = scrollValue > content.clientHeight - window.innerHeight - 350;
    }
    

    if(bottom && !this.loadingMore && !this.noMoreToLoad) {
      this.loadingMore = true
      this.storiesLoading = true
      this.currentPage++
      this.getStories()
    }
  }

  getStories() {
    console.log('getStories')
    this.storiesService.getStoriesByUser(this.currentPage, 9).subscribe({
      next: (res) => {
        this.storiesLoading = true
        this.stories = this.stories.concat(res)

        if(res.length < 9) this.noMoreToLoad = true

        this.storiesLoading = false

        if(!this.loadingMore) {
          window.scrollTo(0, 0);
        }
        this.loadingMore = false;
      },
      error: (err) => {
        this.storiesLoading = false
        return throwError(() => err)
      },
    });
  }

  goBack(){
    if(this.desktopDevice) {
      this.homeService.pageDisplayed.next('')
      this.location.back()
    } else {
      this.homeService.pageDisplayed.next('')
    }
  }

  getStyles(){
    return {
      width: `${this.storyWidth}px`,
      height: `${this.storyWidth * 1.4}px`
    }
  }

  getScrollRequest(request: string){
    if(request === 'scrollToTop') this.top.nativeElement.scrollIntoView()
  }

  openDeleteDialog(storyId: number | null){
    console.log('openDeleteDialog')
    this.dialog.open(DeleteStoryDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res),
    ).subscribe(() => {
      if(!storyId) return
      this.storiesService.deleteStory('story', storyId).subscribe(() => {
        const storyToDelete = this.stories.filter(s => s.id === storyId)
        const index = this.stories.indexOf(storyToDelete[0])

        this.stories.splice(index, 1)

        this.noMoreToLoad = false
        this.currentPage = 0
        this.top.nativeElement.scrollIntoView()
        this.storiesService.getStoriesByUser(1, 9).subscribe(res => this.stories = res)
      })
    })
  }

  openPublishDialog(storyId: number | null){
    console.log('openPublishDialog')
    this.dialog.open(PublishStoryDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res),
    ).subscribe(() => {
      if(!storyId) return

      this.storiesService.publishStory(storyId).subscribe(res => {
        console.log('story published: ', res)
        this.storiesService.getStoriesByUser(1,9).subscribe(res => this.stories = res)
      })
    })
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }

    // requestStories($event: 'previous' | 'next'){
  //   if(!$event) return

  //   if(this.currentPage === 1 && $event === 'previous') return

  //   const page = $event === 'next' ? this.currentPage + 1 : this.currentPage - 1 
    
  //   this.storiesService.getStoriesByUser(page, 9).subscribe({
  //     next: (res => {
  //       this.stories = res
  //       console.log(res)
  //       this.currentPage = page
  //       //this.totalPages = res.total + 1 // res.total commence à 0 
  //     }), 
  //     error: (err => {
  //       if(err.error.message){
  //         // this.errorTitle = "Login details are incorrect"
  //         // this.errorMessage = "Please check and re-enter your data."
  //         // this.openDialog = true
  //       }

  //       this.appService.setLoader(false)
  //     })
  //   })
  // }
}
