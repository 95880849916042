import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { inject } from '@angular/core';
import { User } from '../../shared/interfaces/user';

export const mainPhotoGuard: CanActivateFn = () => {
  const authService: AuthService = inject(AuthService)
  const router = inject(Router)

  let user!: User | null 
  const id = authService.get('user_id')

  authService.getProfile(parseInt(id)).subscribe(res => {
    user = res

    if(user.main_photo){
      router.navigate(['/login'])
      return false
    }

    return true
  })
  
  return true
}
