<button type="button" class="close-btn" (click)="dialogRef.close()">
  <span class="material-symbols-rounded close">
    close
  </span>
</button>

<section class="likers-section">
  @for(liker of likers; track liker){
    <button class="liker-container" (click)="dialogRef.close(liker.id)">
      <img [src]="liker.photo" alt="" class="liker-photo">
      <p class="firstname">
        {{liker.firstname}}
      </p>
    </button>
  }
</section>
