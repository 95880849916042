<section class="notifications">
  <app-go-back-button (click)="goBack()"></app-go-back-button>

  <div class="top">
      <h3><span class="regular">My</span> Notifications</h3>
  </div>

  @if(loading){
    <app-loader></app-loader>
  }    

  @if(notifications.length === 0){
    <p>No notification</p>
  } @else {
    <section class="notifications-container">
      <hr class="separation-line">
      @for(n of notifications; track n){
        @if(n.type === 'contact'){
          @if(n.user){
            <button type="button" class="item" [ngStyle]="{borderColor: n.readed === 0 ? '#FFC46A': 'transparent'}">
              <div class="photo-container">
                <img [src]="n.user.photo" alt="">
              </div>
              <div class="message">
                <div class="description">
                  You have a new match with <span class="firstname">{{n.user.firstname}}</span>.
                </div>
                <div class="time">
                  {{timeExpression}}
                </div>
              </div>
            </button>
            <hr class="separation-line">
          }
        } @else if (n.type === 'crush') {
            @if(n.story && n.story.likes > 0){
              <button class="item story" [ngStyle]="{borderColor: n.readed === 0 ? '#FFC46A': 'transparent'}">
                <div class="left">
                  @if(n.story.users && n.story.users.length > 0){
                    <div class="photo-container">
                      <img [src]="n.story.users[0].photo" alt="">
                    </div>
                  }
      
                  <div class="message">
                    <div class="description">
                      {{n.story.likes}} person{{n.story.likes > 1 ? 's' : ''}} liked your story {{n.story.id}}.
                    </div>
                    <div class="time">
                      {{n.timeExpression}}
                    </div>
                  </div>
                </div>

                <div class="right">
                  <div class="story-photo">
                    <img [src]="n.story.photos[0]" alt="">
                  </div>
                </div>
              </button>
              <hr class="separation-line">
            }
          }
        }
    </section>
  }
</section>

  <!-- <div class="notif-holder" *ngIf="notifications; else loading">
    <ng-container *ngIf="notifications.length > 0; else nonotifications">
      <div
        [ngClass]="['notif pop', n.type, n.readed === 0 ? 'new' : '']"
        *ngFor="let n of notifications; let i = index"
      >
        <ng-container *ngIf="n.type === 'crush'; else userNotif">
          <ng-container *ngIf="n.related_photo_id">
            <div class="story" [ngStyle]="{ 'background-image': 'url(' + n.photo.photos + ')' }">
              <div class="loading" *ngIf="!n.loaded && !n.error">
                <mat-icon svgIcon="update"></mat-icon>
              </div>
              <div class="error" *ngIf="n.error">404</div>
              <img
                [src]="n.photo.photos"
                (load)="imageLoaded(i)"
                (error)="imageError(i)"
                [class.ready]="n.loaded && !n.error"
                (click)="showStory('photo', n.photo)"
              />
            </div>
            <div class="content">
              <div class="user-name" (click)="showStory('photo', n.photo)">
                {{ n.photo.likes }} <ng-container i18n="@@like">like</ng-container>
                <div class="text">
                  <span i18n="@@likeOnYourPhoto">your story</span>
                </div>
              </div>
            </div>
            <div class="actions">
              <div class="action likes">
                <button (click)="getUsersWhoLikes('photo', n.photo)">
                  <mat-icon svgIcon="liked"></mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="n.story">
            <div class="story" [ngStyle]="{ 'background-image': 'url(' + n.story.photos[0] + ')' }">
              <div class="loading" *ngIf="!n.loaded && !n.error">
                <mat-icon svgIcon="update"></mat-icon>
              </div>
              <div class="error" *ngIf="n.error">404</div>
              <img
                [src]="n.story.photos[0]"
                (load)="imageLoaded(i)"
                (error)="imageError(i)"
                [class.ready]="n.loaded && !n.error"
                (click)="showStory('story', n.story)"
                *ngIf="!n.related_photo_id"
              />
            </div>
            <div class="content">
              <div class="user-name" (click)="showStory('story', n.story)">
                {{ n.story.likes }} <ng-container i18n="@@like">like</ng-container>
                <div class="text">
                  <span i18n="@@likeOnYourStory">your story</span>
                </div>
              </div>
            </div>
            <div class="actions">
              <div class="action likes">
                <button (click)="getUsersWhoLikes('story', n.story)">
                  <mat-icon svgIcon="liked"></mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="n.event">
            <div class="story">
              <mat-icon svgIcon="event"></mat-icon>
            </div>
            <div class="content">
              <div class="user-name">
                {{ n.event.likes }} <ng-container i18n="@@Participate">Participe</ng-container>
                <div class="text">
                  <span i18n="@@ParticipateAtYourEvent">à votre évenement</span>
                </div>
              </div>
            </div>
            <div class="actions">
              <div class="action likes">
                <button (click)="getUsersWhoLikes('event', n.event)">
                  <mat-icon svgIcon="liked"></mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="n.externallink">
            <div class="story">
              <mat-icon svgIcon="link"></mat-icon>
            </div>
            <div class="content">
              <div class="user-name">
                {{ n.externallink.likes }} <ng-container i18n="@@like">Like</ng-container>
                <div class="text">
                  <span i18n="@@likeOnYourLink">à votre lien externe</span>
                </div>
              </div>
            </div>
            <div class="actions">
              <div class="action likes">
                <button (click)="getUsersWhoLikes('externallink', n.externallink)">
                  <mat-icon svgIcon="liked"></mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #userNotif>
          <div class="user" [routerLink]="'/service/profil/' + n.user.id" *ngIf="n.user">
            <div class="loading" *ngIf="!n.loaded && !n.error">
              <mat-icon svgIcon="update"></mat-icon>
            </div>
            <div class="error" *ngIf="n.error">404</div>
            <img
              [src]="n.user.photo"
              (load)="imageLoaded(i)"
              (error)="imageError(i)"
              [class.ready]="n.loaded && !n.error"
            />
          </div>
          <div class="content" [routerLink]="'/service/profil/' + n.user.id">
            <div class="user-name">
              {{ n.user.firstname }}
              <div class="text">
                <span *ngIf="n.type === 'contact'" i18n="@@notifContactAdd"
                  >Accept your invitation</span
                >
                <span *ngIf="n.type === 'messages'" i18n="@@notifSendMessage"
                  >Send you a message</span
                >
                <span *ngIf="n.type === 'invitation'" i18n="@@notifReequestInvitation"
                  >Want to be friend</span
                >
              </div>
            </div>
          </div>
          <div class="actions">
            <ng-container *ngIf="n.updating; else displayactions">
              <mat-icon svgIcon="update" class="update"></mat-icon>
            </ng-container>
            <ng-template #displayactions>
              <div class="action choose" *ngIf="n.type === 'invitation'">
                <button class="validate" (click)="changeRelationship(i, 'validated')">
                  <mat-icon svgIcon="ok"></mat-icon>
                </button>
                <button class="denied" (click)="changeRelationship(i, 'rejected')">
                  <mat-icon svgIcon="denied"></mat-icon>
                </button>
              </div>
            </ng-template>
            <div class="action message" *ngIf="n.type === 'contact'" (click)="talkWith(i, n.user.id)">
              <button>
                <mat-icon svgIcon="message"></mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #nonotifications>
      <div class="empty-module">
        <div class="empty-box">
          <mat-icon svgIcon="friends"></mat-icon>
        </div>
        <h2 i18n="@@oops">Ooops</h2>
        <p i18n="@@noNotifications">No notifications</p>
      </div>
    </ng-template>
  </div>
  
  <ng-template #loading>
    <app-loader-inside [value]="'notifications'" [icon]="'bell'"></app-loader-inside>
  </ng-template> -->
  