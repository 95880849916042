<div class="left-col"></div>
<div class="right-col">
    <app-progress-bar [hightlightedSteps]="hightlightedSteps"></app-progress-bar>
    <app-go-back-button [routerLink]="'/subscribe-step-one'"></app-go-back-button>
    <app-header></app-header>
    
    @if(loading){
        <app-loader></app-loader>
    }

    <form
        [formGroup]="subSecondFormGroup"   
        (ngSubmit)="onSubmit()"
        id="subSecondForm"
    >
    <!-- [class.hide]="displayCreatingLoader" -->

      <app-city-input [user]="user" (emitCity)="getCitySelected($event)"></app-city-input>
    </form>

    <div class="submit-btn-container">
      <button 
          routerLinkActive="router-link-active"
          class="submit"
          [disabled]="!citySet.id"
          form="subSecondForm"
      >
          Next
          <span class="material-symbols-rounded arrow-right">
              arrow_right_alt
          </span>
      </button>
  </div>
</div>
