<app-go-back-button (click)="closeSidenav();"></app-go-back-button>

@if(loading$ | async){
  <app-loader></app-loader>
}

<section class="content">
  <div class="popup">
      <h2 i18n="Title for New Encounters section@@NEW_ENCOUNTERS"><span class="regular">New</span> Encounters!</h2>

      @if((user$ | async); as user) {
        <div class="suggestion" #suggestionDiv>
          <div class="card-shadow"></div>
          <section 
            class="card" 
            #cardSection 
            (touchstart)="swipe($event, 'start', user)" 
            (touchend)="swipe($event, 'end', user)"
          >
              <div class="left" [class.one-photo]="!user.photos || user.photos && user.photos.length < 2">
                @if(!user.photos){
                  <div class="photo-container">
                    <img src="../../assets/icons/icon_user_400X400.png" alt="" class="no-photo">
                  </div>
                } @else if(user.photos && user.photos.length < 2) {
                  <div class="photo-container">
                    @if(user.photos[0]){
                      <img [src]="user.photos[0].url" alt="" class="photo" [routerLink]="['photos']" [queryParams]="{photoIndex: 0}">
                    } @else {
                      <img src="../../assets/icons/icon_user_400X400.png" alt="" class="no-photo">
                    }
                  </div>
                } @else {
                  <div class="carousel-component-container">
                    <carousel
                        [infinite]="true"
                        [autoPlay]="true"
                        [autoPlayAtStart]="true"
                        [autoPlayInterval]="2500"
                        [displayAutoPlayControls]="false"
                        [arrows]="false"
                        [counter]="false"
                        [slideToShow]="1"
                        [gapBetweenSlides]="6"
                        [slideMaxWidth]="400"
                        [responsive]="false"
                    >
                        @for(p of user.photos; track $index){
                            <div class="carousel-slide" [routerLink]="['photos']" [queryParams]="{photoIndex: $index}">
                                <img [src]="p.url" alt="" class="photo" >
                            </div>
                        }
                    </carousel>
                  </div>
                }
              </div>
              
              <div class="information">
                  <h3 
                    [routerLink]="['profile/', user.id, 'space']" appClickAnywhere (clickNature)="getClickNature($event, user.id)"
                  >{{user.firstname}}</h3>
                  <p>{{user.userAge}} <ng-container i18n="Age unit@@YEARS">years</ng-container> - {{user.city?.name ? user.city?.name : user.city}}</p>
                  <div class="searching-container">
                      <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
                      {{user.attraction | titlecase}}
                  </div>
                  <p class="description" #description>
                      @if(user.description){
                        {{user.description}}
                      } @else {
                        <ng-container i18n="Default description if no description was provided by the suggested profile@@MYSTERY_CONTINUES">The mystery continues... It’s up to you to find out who i am :-)</ng-container>
                      }
                  </p>
              </div>
          </section>
        </div>
      
        <div class="actions">
            <button 
                title="Decline this profile" 
                i18n-title="Text shown when hovering over the button to decline a profile suggestion in New Encounters section@@DECLINE_PROFILE"
                class="close-container"
                (click)="rejectProfile(user)"
            >
                <span class="material-symbols-rounded close">close</span>
            </button>
    
            @if(relationshipStatus.value === "pending"){
              <div class="accepted-mention">
                @if(user.sex === 'man'){
                  <p i18n="@@HE_LIKE_PROFILE">He already liked your profile!</p>
                } @else {
                  <p i18n="@@SHE_LIKE_PROFILE">She already liked your profile!</p>
                }
                <div class="arrow-down"></div>
              </div>
            }
            
            <button 
                title="Like this profile" 
                i18n-title="Text shown when hovering over the button to like a profile suggestion in New Encounters section@@LIKE_PROFILE"
                class="like-container"
                (click)="likeProfile(user)"
            >
                <img src="assets/icons/icon_love_32.svg" alt="" class="like">
            </button>

            <button 
                title="Send a message" 
                i18n-title="Text shown when hovering over the button to send a message to a profile suggestion in New Encounters section@@SEND_MESSAGE"
                class="message-container"
                (click)="contactProfile(user.id)"
            >
                <img src="assets/icons/icon_message.png" alt="" class="message">
            </button>
        </div>    
      } @else {
        @if(!encounters.length){
          <p class="no-encounters" i18n="Text for when no encounters are available for the user in New Encounters section@@NO_ENCOUNTERS" [ngStyle]="{'display': (loading$ | async) ? 'none' : 'block'}">
            No new encounters for the moment.
          </p>
        }
      }
  </div>
</section>

<div>
  <div [ngClass]="{ overlay: showInfoDialog && isDesktop() }"></div>

  <app-info-dialog
    [class.show]="showInfoDialog"
    [infoDialogType]="infoDialogType"
    (closeInfoDialog)="closeInfoDialog($event)"
  ></app-info-dialog>
</div>

<router-outlet></router-outlet>
