<mat-sidenav-container
  class="sidenav-container"
  [ngStyle]="{
    'background-color': 'transparent',
    'z-index': conversationSidenav.opened ? '3' : '1',
    'position' : conversationSidenav.opened ? 'fixed' : 'relative'
  }"
>
  <mat-sidenav
    #conversationSidenav
    [mode]="isDesktop() ? 'side' : 'push'"
    class="secondary-sidenav"
    position="end"
    [opened]="isDesktop()"
  >
    <!-- contenu additionnel -->
    @if((conversationToShow$ | async) === null){
      <div class="conversations-current">
        <div class="icon-container">
            <img src="../../assets/icons/icon_chat_with.svg" alt="">
        </div>
        <h3 i18n="Title for the section where the conversations with contacts are displayed@@MY_CONVERSATIONS">My conversations</h3>
      </div>
    }
    
    <router-outlet></router-outlet>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="list">
      <div class="list-conversations" #conversationsContainer (scroll)="onScroll($event)" [ngStyle]="{'display': talks && talks.length ? 'block' : 'none'}">
        @for(talk of talks; track $index){
          <div
            class="conversation pop"
            [class.closed]="talk.closed"
            [class.silent]="talk.warning_silent_talking && !talk.myMessage"
            [class.unread]="!talk.readed"
            #conversationDiv
          >
            <hr class="separation-line">

            <app-user-tiny
              [data]="talk.userDatas"
              [new]="!talk.readed"
              [newMessages]="talk.new_messages"
              [origin]="'chat'"
              [link]="'/home/my-matches/chat/conversation/' + talk.id"
              (click)="openTalk(talk)"
            ><!-- 
            [feed]="false"
            (talkSelected)="getTalkSelected($event)" 
            (click)="openTalk(talk);"-->
            </app-user-tiny>

            @if(talk.warning_silent_talking && !talk.myMessage){
              <button type="button"
                class="direct-message"
                (click)="openTalk(talk)"
                [routerLink]="['/home/my-matches/chat/conversation/', talk.id]"
              >
                <!-- TODO : genrer la clé -->
                <span i18n="Text displayed to introduce a direct message conversation on the recipient's side@@CAUGHT_HIS_EYE">Looks like you've caught his eye… this user sent you a first message !</span>
              </button>
            }

            <hr class="separation-line last">
          </div>
        }

        @if(loading$ | async){
          <app-loader class="stories-loader"></app-loader>
        }
      </div>

      <div class="empty-module" [ngStyle]="{'display': talks && talks.length ? 'none' : 'block'}">
        <h2>Ooops</h2>
        <p>You don't have any active conversation yet</p>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
