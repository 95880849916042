

import { subscribeGuard } from './core/guards/subscribe.guard';
import { loginGuard } from './core/guards/login.guard';

import { AuthLoginComponent } from './auth/auth-login/auth-login.component';
import { AuthPasswordComponent } from './auth/auth-password/auth-password.component';
import { HomeComponent } from './home/home.component';
import { AuthSubscribeFirstComponent } from './auth/auth-subscribe/auth-subscribe-first/auth-subscribe-first.component';
import { AuthSubscribeSecondComponent } from './auth/auth-subscribe/auth-subscribe-second/auth-subscribe-second.component';
import { AuthSubscribeThirdComponent } from './auth/auth-subscribe/auth-subscribe-third/auth-subscribe-third.component';
import { AuthSubscribeFourthComponent } from './auth/auth-subscribe/auth-subscribe-fourth/auth-subscribe-fourth.component';
import { CroppedPhotoComponent } from './photo/cropped-photo/cropped-photo.component';
import { UploadPhotoComponent } from './photo/upload-photo/upload-photo.component';
import { CreateStoryComponent } from './create-story/create-story.component';
import { MyMatchesComponent } from './my-matches/my-matches.component';
import { MySpaceComponent } from './my-space/my-space.component';
import { NewEncountersComponent } from './new-encounters/new-encounters.component';
import { MyProfileComponent } from './my-space/my-profile/my-profile.component';
import { MyStoriesComponent } from './my-space/my-stories/my-stories.component';
import { MyDatingCriteriaComponent } from './my-space/my-dating-criteria/my-dating-criteria.component';
// import { MyCoachComponent } from './my-space/my-coach/my-coach.component';
// import { MyCoachStoriesComponent } from './my-space/my-coach/my-coach-stories/my-coach-stories.component';
// import { MyCoachSpaceComponent } from './my-space/my-coach/my-coach-space/my-coach-space.component';
import { MyHobbiesComponent } from './shared/my-hobbies/my-hobbies.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileStoriesComponent } from './profile/profile-stories/profile-stories.component';
import { ProfileSpaceComponent } from './profile/profile-space/profile-space.component';
import { userResolver } from './user.resolver';
import { MySubscriptionComponent } from './my-space/my-subscription/my-subscription.component';
import { MyAccountComponent } from './my-space/my-account/my-account.component';
import { InfoServiceComponent } from './my-space/info-service/info-service.component';
import { ContactComponent } from './my-space/contact/contact.component';
import { TermsComponent } from './my-space/terms/terms.component';
import { LegalComponent } from './my-space/legal/legal.component';
import { PersonalDataComponent } from './my-space/personal-data/personal-data.component';
import { CookiesComponent } from './my-space/cookies/cookies.component';
import { mainPhotoGuard } from './core/guards/main-photo.guard';
import { NewEncounterPhotosComponent } from './new-encounters/new-encounter-photos/new-encounter-photos.component';
import { ProfileCropComponent } from './profile-crop/profile-crop.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotificationsComponent } from './my-matches/notifications/notifications.component';
import { matchResolver } from './match.resolver';
import { DeleteAccountComponent } from './my-space/my-account/delete-account/delete-account.component';
import { EditEmailComponent } from './my-space/my-account/edit-email/edit-email.component';
import { EditPasswordComponent } from './my-space/my-account/edit-password/edit-password.component';
import { Routes } from '@angular/router';
import { AddStoryDialogComponent } from './dialog/add-story-dialog/add-story-dialog.component';
import { StoryPlayerComponent } from './shared/story-player/story-player.component';
import { ChatConversationComponent } from './chat/chat-conversation/chat-conversation.component';
import { ChatComponent } from './chat/chat.component';

export const routes: Routes = [
    { path: "login", component: AuthLoginComponent },
    { path: "forgotten-password", component: AuthPasswordComponent },
    { path: "subscribe-step-one", component: AuthSubscribeFirstComponent, 
        canActivate: [subscribeGuard] 
    },
    { path: "subscribe-step-two", component: AuthSubscribeSecondComponent, 
        canActivate: [subscribeGuard] // à remettre quand login réparé
    },
    { path: "subscribe-step-three", component: AuthSubscribeThirdComponent, 
        canActivate: [subscribeGuard] // à remettre quand login réparé
    },
    { path: "subscribe-step-four", component: AuthSubscribeFourthComponent, 
        canActivate: [subscribeGuard, mainPhotoGuard] // à remettre quand login réparé
    },
    { 
      path: "home", component: HomeComponent, 
      canActivate: [loginGuard], // à remettre quand login réparé
      children: [
        { 
          path: "new-encounters", 
          children: [
            {
              path: "",
              component: NewEncountersComponent, 
            },
            {
              path: "photos",
              component: NewEncounterPhotosComponent
            },
            { 
              path: "profile/:id", 
              component: ProfileComponent,
              resolve: {user: userResolver},
              children: [
                {
                  path: "space",
                  component: ProfileSpaceComponent,
                },
                {
                  path: "stories",
                  component: ProfileStoriesComponent,
                  resolve: {user: userResolver}
                }
              ]
            },
          ]
        },
        { 
          path: "my-matches",
          canActivate: [loginGuard],
          children: [
            {
              path: "chat", 
              component: MyMatchesComponent,
              children: [
                {
                  path: "",
                  component: ChatComponent,
                  children: [
                    {
                      path: "conversation/:id",
                      component: ChatConversationComponent,
                    },
                  ]
                },
              ]
            },
            
            {
              path: 'profile/:id',
              resolve: {user: matchResolver},
              children: [
                {
                  path: "",
                  component: ProfileComponent,
                  children: [
                    {
                      path: "space",
                      component: ProfileSpaceComponent,
                    },
                    {
                      path: "stories",
                      component: ProfileStoriesComponent,
                    }
                  ]
                },
                
              ]
            },
            {
              path: "notifications",
              component: NotificationsComponent
            }
          ]
        },
        { 
          path: "my-space", 
          component: MySpaceComponent,
          children: [
              {
                  path: "my-profile",
                  children: [
                      {
                          path: "", 
                          component: MyProfileComponent
                      },
                      {
                        path: "crop",
                        component: ProfileCropComponent
                      },
                      {
                          path: "my-hobbies",
                          component: MyHobbiesComponent
                      }
                  ]
              },
              {
                path: "my-stories",
                component: MyStoriesComponent
              },
              {
                  path: "my-dating-criteria",
                  component: MyDatingCriteriaComponent
              },
              // {
              //     path: "my-coach",
              //     component: MyCoachComponent,
              //     resolve: {user: userResolver},
              //     children: [
              //         {
              //           path: "space",
              //           component: MyCoachSpaceComponent,
              //         },
              //         {
              //             path: "stories",
              //             component: MyCoachStoriesComponent
              //         }
              //     ]
              // },
              {
                path: "my-subscription",
                component: MySubscriptionComponent,
              },
              {
                path: "my-account",
                children: [
                  {
                    path: "",
                    component: MyAccountComponent,
                  },
                  {
                    path: "delete",
                    component: DeleteAccountComponent,
                  },
                  {
                    path: "edit-email",
                    component: EditEmailComponent,
                  },
                  {
                    path: "edit-password",
                    component: EditPasswordComponent,
                  }
                ]
              },
              {
                path: "info-service",
                component: InfoServiceComponent,
              },
              {
                path: "contact",
                component: ContactComponent,
              },
              {
                path: "terms",
                component: TermsComponent,
              },
              {
                path: "legal",
                component: LegalComponent,
              },
              {
                path: "personal-data",
                component: PersonalDataComponent,
              },
              {
                path: "cookies",
                component: CookiesComponent,
              },
          ]
        },
        {
          path: "story/:id",
          children: [
            { 
              path: '',
              component: StoryPlayerComponent,
            },
            {
              path: 'profile/:id',
              component: ProfileComponent,
              resolve: {user: matchResolver},
              children: [
                {
                  path: "space",
                  component: ProfileSpaceComponent,
                },
                {
                  path: "stories",
                  component: ProfileStoriesComponent,
                }
              ]
            },
          ]
        }
      ]
    },
    { 
      path: "upload-photo", 
      component: UploadPhotoComponent, 
      canActivate: [mainPhotoGuard] 
    },
    { 
      path: "photo", component: CroppedPhotoComponent 
    },
    {
      path: "story",
      children: [
        {
          path: "",
          component: AddStoryDialogComponent, 
        },
      ]
    },
    { path: "create-story", 
      component: CreateStoryComponent,
      canActivate: [loginGuard] // à remettre quand login réparé
    },
    { path: "edit-story/:id", 
      component: CreateStoryComponent,
      canActivate: [loginGuard] // à remettre quand login réparé
    },
    { path: "", redirectTo: "login", pathMatch: "full" },
    { path: "**", component: NotFoundComponent }
];
