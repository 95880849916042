import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-dialog',
  standalone: true,
  imports: [],
  templateUrl: './error-dialog.component.html',
  styleUrl: './error-dialog.component.scss'
})
export class ErrorDialogComponent {
  @Input() errorMessage!: string | null
  @Input() errorTitle!: string | null

  blur(){
    
  }
}
