<div class="home" #mainHolder>
  <app-logged-in-header [class.hide]="pageDisplayed === 'new-encounters' || pageDisplayed === 'story' || dialogDisplayed || (pageDisplayed === 'edit-story' && !isDesktop())"></app-logged-in-header>
  
  <mat-sidenav-container
    class="sidenav-container"
    [ngStyle]="{
      'background-color': 'transparent',
      'z-index': sidenav.opened ? '3' : '1',
      'position' : sidenav.opened ? 'fixed' : 'relative'
    }"
  >
    <mat-sidenav
      #sidenav
      mode="push"
      class="secondary-sidenav"
      position="end"
    >
      <div #scrollable class="scrollable" (scrollend)="onScroll($event)" (touchend)="onTouchEnd($event)">
        <!-- contenu additionnel -->
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      @if(newStoriesAvailable.value) {
        <button class="notification fadeout" (click)="seeNewStories()">
          <p i18n="Text for the notification for new available stories on the home page@@NEW_STORIES_AVAILABLE">New stories are available.</p>
        </button>
      }
      <section class="main">
        <div class="top">
          <h3 i18n="Title to welcome the user on the home page@@HELLO">
            Hello <span class="firstname">{{ user?.firstname }}!</span>
          </h3>

          <div class="actions">
            <div class="actions-icon-container">
              <button
                type="button"
                class="add-story-btn"
                [routerLink]="['/story']"
              >
                <img src="assets/icons/icon_add_new_story.png" alt="" />
              </button>
            </div>

            <button
              type="button"
              class="icon-encounters-container desktop"
              [routerLink]="['new-encounters']"
              (click)="
                toggleSidenav(true);
                pageDisplayed = 'new-encounters'
              "
            >
              <div class="new-badge" [class.show]="newEncountersAvailable">!</div>
              <img
                src="../../assets/icons/icon_encounters.svg"
                alt=""
                class="icon-encounters"
                width="20"
                height="20"
                i18n="Text for the button to show the new encounters@@NEW_ENCOUNTERS"
              />
              New Encounters!
            </button>
            <button
              type="button"
              class="icon-encounters-container mobile"
              [routerLink]="['new-encounters']"
              (click)="
                toggleSidenav(true);
                pageDisplayed = 'new-encounters'
              "
            >
              <div class="new-badge" [class.show]="newEncountersAvailable">!</div>
              <img
                src="../../assets/icons/icon_encounters.svg"
                alt=""
                class="icon-encounters"
                width="48"
                height="48"
              />
            </button>
          </div>
        </div>

        <div class="main-content">
          <!-- contenu de base -->
          <h4 i18n="Title for the section showing other users' story cards on the home page@@STORIES">Stories</h4>
          <div class="photos" #photos>
            @for(s of stories; track s.id){
              @if(s.id === 0){
                <button
                  type="button"
                  class="profile-card-btn"
                  (click)="
                    !isDesktop() ? openMobileCamera() : displayAddStoryDialog()
                  "
                  [ngStyle]="getStyles()"
                >
                  <div class="photo-container profile">
                    <div class="main-photo-container">
                      @if(s.photos[0].url){
                        <img [src]="s.photos[0].url" alt="" class="item profile" />
                      } @else {
                        <img src="../../assets/icons/icon_user_400X400.png" alt="" class="no-photo" />
                      }
                    
                      <div class="icon-container">
                        <img
                          src="assets/icons/icon_add_new_story.png"
                          alt=""
                          class="mobile"
                          width="28"
                          height="28"
                        />
                        <img
                          src="assets/icons/icon_add_new_story.png"
                          alt=""
                          class="desktop"
                          width="28"
                          height="28"
                        />
                      </div>
    
                      <input
                        type="file"
                        accept="image/png/image/jpeg"
                        capture="camera"
                        #mobileFileUpload
                        style="opacity: 0; position: absolute"
                        class="file-upload mobile"
                        (change)="onPhotoOrVideoInputChange($event)"
                      />
                    </div>

                    <h5 i18n="Title for the connected user card leading to the story creation module@@ADD_STORY">Add a story</h5>
                  </div>
              </button>
              } @else {
                <div class="photo-container" [ngStyle]="getStyles()">
                  <app-story [story]="s" (showStoryRequest)="getShowStoryRequest($event)"></app-story>
                </div>
              }
            }
            
            @if(loading$ | async){
              <app-loader class="stories-loader"></app-loader>
            }
          </div>

          <div class="desktop">
            <div [ngClass]="{ overlay: showOverlay }"></div>

            <app-info-dialog
              [class.show]="showInfoDialog"
              [infoDialogType]="infoDialogType"
              (closeInfoDialog)="closeInfoDialog($event)"
            ></app-info-dialog>
          </div>
        </div>

        <div class="icon-container mobile">
          <button
            type="button"
            class="add-story-btn"
            (click)="openMobileCamera()"
          >
            <img src="assets/icons/icon_add_new_story.svg" alt="" width="40" height="40"/>
          </button>
        </div>
      </section>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
