import { Component, AfterViewInit, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { TitleCasePipe, CommonModule } from '@angular/common';
import dayjs from 'dayjs';
import { map, Subject, switchMap, takeUntil, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { StoriesService } from '../../stories.service';
import { ProfileService } from '../profile.service';
import { LoaderService } from '../../loader.service';
import { LayoutService } from '../../layout.service';
import { HomeService } from '../../home/home.service';
import { AppService } from '../../app.service';

import { StoryComponent } from '../../story/story.component';
import { LoaderComponent } from '../../shared/loader/loader.component';

import { User } from '../../shared/interfaces/user';
import { Story } from '../../shared/interfaces/story';

@Component({
  selector: 'app-profile-stories',
  standalone: true,
  imports: [StoryComponent, TitleCasePipe, CommonModule, LoaderComponent],
  templateUrl: './profile-stories.component.html',
  styleUrl: './profile-stories.component.scss'
})
export class ProfileStoriesComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('storiesContainer') storiesContainer!: ElementRef
  @ViewChild('profileStoriesHolder', { static: true, read: ElementRef }) profileStoriesHolder!: ElementRef

  loading$ = this.loaderService.loading$
  stories!: Story[]
  profileId!: string
  profiles!: User[]
  profile!: User | null
  userAge!: number
  storyWidth!: number
  noStories = false
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop
  storiesLoading = false
  scrollValueFromHome!: Event | null
  currentPage = 1
  loadingMore = false
  noMoreToLoad = false

  constructor(
    private storiesService: StoriesService,
    private profileService: ProfileService,
    private loaderService: LoaderService,
    private layoutService: LayoutService,
    private homeService: HomeService,
    private router: Router,
    private appService: AppService
  ){}

  ngOnInit(){
    this.loaderService.show()

    this.profileService.profile.pipe(
      takeUntil(this._destroyed),
      map(user => {
        // reset la valeur de scrollValue pour ne pas déclencher le chargement de toutes les stories 
        // d'un coup quand on navigue entre space et stories
        this.appService.scrollValue.next(null)

        this.profile = user
    
        if(!this.profile) return
        if(this.profile.birthdate){
          const [y,m,d] = this.profile.birthdate.split('-')
          const date = `${y}-${m}-${d}`
          const birthDate = dayjs(date)
    
          this.userAge = dayjs().diff(birthDate, 'year')
        }

        return user
      }),
      switchMap((user) => this.storiesService.getStoriesByUser(1, 8, user?.id))
    ).subscribe(stories => {
      this.stories = stories
      this.loaderService.hide()
    })

    this.appService.scrollValue.subscribe(res => {
      if(!res) return
      this.scrollValueFromHome = res
      this.windowScrolling()
    })

    // A supprimer : Récupération des fake stories pour les tests
    // this.storiesService.getFakeStories().subscribe(res =>  {
    //   this.stories = res
    // })   
  }

  ngAfterViewInit(){
    const bounds = this.storiesContainer.nativeElement.getBoundingClientRect()
    const storiesContainerWidth = bounds.right - bounds.left
    const gapWidth = 10
    let numberOfItemsPerRow = 2

    if(this.isDesktop()) numberOfItemsPerRow = 4

    this.storyWidth = (storiesContainerWidth - (numberOfItemsPerRow - 1) * gapWidth) / numberOfItemsPerRow
  }

  getStyles(){
    return {
      'width': this.storyWidth + 'px',
      'height': `${this.storyWidth * 1.4}px`,
    }
  }

  getShowStoryRequest(story: Story){
    if(!story) return

    this.storiesService.story.next(story)

    let storyId!: number | null | undefined

    if(this.router.url.includes('/stories')){
      this.storiesService.getStoryById(story.id!).pipe(
        map((s: Story) => {
          storyId = s.id
          
          this.storiesService.story.next(s)
        })
      ).subscribe(() => {
        if(!storyId) return
        this.openStoryPlayer(storyId)
      })
    } else {
      storyId = story.id
      if(!storyId) return
      this.openStoryPlayer(storyId)
    }
  }

  windowScrolling() {
    const target = this.scrollValueFromHome?.target as HTMLElement
    
    if(!target) return

    const scrollValue = target.scrollTop
    const content = this.profileStoriesHolder.nativeElement

    const bottom = scrollValue > content.clientHeight - window.innerHeight - (this.isDesktop() ? 800 : 350)
    
    if(bottom && !this.loadingMore && !this.noMoreToLoad) {
      this.loadingMore = true
      this.currentPage++
      this.getStories()
    }
  }

  getStories() {
    console.log('getStories')
    this.loaderService.show()

    this.storiesService.getStoriesByUser(this.currentPage, 8, this.profile?.id).pipe(
      takeUntil(this._destroyed)
    ).subscribe({
      next: (res) => {
        if(!this.stories) this.stories = []
        this.stories = this.stories.concat(res)

        if(res.length < 8) this.noMoreToLoad = true

        this.loaderService.hide()
        
        if(!this.loadingMore) {
          window.scrollTo(0, 0)
        }
        this.loadingMore = false
      },
      error: (err) => {
        this.storiesLoading = false
        return throwError(() => err)
      },
    });
  }

  openStoryPlayer(storyId: number | null){
    this.router.navigate(['home/story/' + storyId])
    this.homeService.pageDisplayed.next('story')
    this.homeService.toggleSidenav.next(true)
    this.storiesService.storyOwnedByMe = false
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
