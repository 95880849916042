<div [formGroup]="attractionFormGroup">
    <label for="genders" class="main-label">{{parentName === 'myProfileComponent' ? 'Interested in' : 'Show stories of'}}</label>
    <p id="gender-select">
        <label>
            <input 
                formControlName="attractionCtrl" 
                type="radio" 
                name="attractionCtrl" 
                value="woman" 
                [checked]="user?.attraction === 'woman'" 
                (change)="setAttraction(attractionFormGroup.value)"
            >
            <span class="gender">Women</span>
        </label>
        <label>
            <input 
                formControlName="attractionCtrl" 
                type="radio" 
                name="attractionCtrl" 
                value="man" 
                [checked]="user?.attraction === 'man'" 
                (change)="setAttraction(attractionFormGroup.value)"
            >
            <span class="gender">Men</span>
        </label>
    </p>
</div>