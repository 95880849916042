<div class="toolbar">
    <div class="left">
      <button type="button" (click)="cancel.emit()">
        <span class="material-symbols-rounded close">
          {{showDelete ? 'delete' : 'keyboard_backspace'}}
        </span>
      </button>
    </div>

    <div class="right">
        <button class="submit" (click)="confirm.emit()">Done</button>
    </div>
</div>