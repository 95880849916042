import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { Color } from './shared/interfaces/color';
import { Story } from './shared/interfaces/story';
import { Slider } from './shared/interfaces/slider';
import { Likers } from './shared/interfaces/likers';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  private footerState$ = new Subject<boolean>()
  private newFeedStory$ = new Subject<{ display: boolean; value: Story | null }>()
  private slider$ = new Subject<Slider>()
  private likers$ = new Subject<Likers>()

  private storyDate = null
  private photoDate = null

  private photoLiked$ = new Subject<{ id: number; liked: boolean }>()

  get footerState(): Observable<boolean> {
    return this.footerState$.asObservable()
  }

  hideFooter(value: boolean) {
    this.footerState$.next(value)
  }

  get newFeedStory(): Observable<{ display: boolean; value: Story | null }> {
    return this.newFeedStory$.asObservable()
  }

  setNewFeedStory(value: { display: boolean; value: Story | null }) {
    this.newFeedStory$.next(value)
  }

  get slider(): Observable<Slider> {
    return this.slider$.asObservable()
  }

  setSlider(value: Slider) {
    this.slider$.next(value)
  }

  setLikers(value: Likers) {
    this.likers$.next(value)
  }

  get likers(): Observable<Likers> {
    return this.likers$.asObservable()
  }

  set lastStoryDate(value) {
    this.storyDate = value
  }

  get lastStoryDate() {
    return this.storyDate
  }

  set lastPhotoDate(value) {
    this.photoDate = value
  }

  get lastPhotoDate() {
    return this.photoDate
  }

  constructor(
    private http: HttpClient,
  ) {}

  getColors(): Observable<Color[]> {
    return this.http.get<Color[]>('./assets/colors.json').pipe(map((res) => {
      return res
    }))
  }
}
