<section class="profile-space">
    @if(profileLoading){
      <app-loader></app-loader>
    }
    
    <div class="left">
      @if(profile){
        <div class="user-information mobile">
          <div class="avatar-container">
            @if(profile.main_photo){
              <img [src]="profile.main_photo.url" alt="">
            } @else {
              <img src="../../../assets/icons/icon_user_400X400.png" alt="" class="no-photo">
            }
          </div>
          <div class="right">
            <!-- <h3 [routerLink]="['/profile/' + profile.id]">{{profile.firstname}}</h3> -->
            <h3>{{profile.firstname}}</h3>
            <p>{{userAge}} <ng-container i18n="Age unit@@YEARS">years</ng-container> - {{profile.city?.name}}</p>
            <div class="searching-container">
                <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
                {{profile.attraction | titlecase}}
            </div>
          </div>
        </div>
           
        @if(profile.photos) {          
          @if(profile.photos.length > 1){
            <div class="carousel-component-container">
              <carousel
                  [infinite]="true"
                  [autoPlay]="true"
                  [autoPlayAtStart]="true"
                  [autoPlayInterval]="2500"
                  [displayAutoPlayControls]="false"
                  [arrows]="false"
                  [counter]="false"
                  [slideToShow]="1"
                  [gapBetweenSlides]="6"
                  [slideMaxWidth]="400"
                  [responsive]="true"
              >
                @for(p of profile.photos; track $index){
                  <div class="carousel-slide">
                      <img [src]="p.url" alt="" class="photo">
                  </div>
                }
              </carousel>
            </div>
          } @else {
            <div class="photo-container">
              @if(profile.photos.length){
                <img [src]="profile.photos[0].url" alt="">
              } @else {
                <img src="../../../assets/icons/icon_user_400X400.png" alt="" class="no-photo">
              }
            </div>
          }
        } 

        @if(relationshipStatus === 'notfriend' || relationshipStatus === 'pending'){
          <div class="actions">
            <button 
                title="Decline this profile" 
                i18n-title="Text shown when hovering over the button to decline a profile suggestion in New Encounters section@@DECLINE_PROFILE"
                class="close-container"
                (click)="rejectProfile(profile.id)"
            >
                <span class="material-symbols-rounded close">close</span>
            </button>
  
            <button 
                title="Like this profile" 
                i18n-title="Text shown when hovering over the button to like a profile suggestion in New Encounters section@@LIKE_PROFILE"
                class="like-container"
                (click)="likeProfile(profile.id)"
            >
                <img src="assets/icons/icon_love_32.svg" alt="" class="like">
            </button>
  
            <!-- contacting the profile will create a silent talking. If the user is a man, he has to be registered -->
            <button 
                i18n-title="Text shown when hovering over the button to send a message to the profile in a profile page@@SEND_MESSAGE"
                class="message-container"
                (click)="contactProfile(profile.id)"
            >
                <img src="assets/icons/icon_message.png" alt="" class="message">
            </button>
          </div>
        } @else if(relationshipStatus === 'waiting' || showWaitingStatus) {
          <div class="waiting-status">
            <img src="assets/icons/icon_magical_wand.png" alt="" class="wand-icon">

            <div class="text">
              <p i18n="Text when the user liked or contacted a profile, in a profile page@@ALREADY_LIKE_MESSAGE">You already liked or sent a message to this profile!</p>
              @if(profile.sex === 'man'){
                <p i18n="Text when the user liked or contacted a he-profile and has to wait for an answer, in a profile page@@HE_WAIT_ANSWER">Now you just have to wait for his answer.</p>
              } @else {
                <p i18n="Text when the user liked or contacted a she-profile and has to wait for an answer, in a profile page@@SHE_WAIT_ANSWER">Now you just have to wait for her answer.</p>
              }
              <p i18n="Text when the user liked or contacted a profile and has to wait for an answer, in a profile page@@MAGIC_BEGIN">If the attraction is mutual, let the magic begin...</p>
            </div>
          </div>
        } @else if(relationshipStatus === 'friend' || futureRelationshipStatus === "friend"){
          <div class="actions-validated">
            <button i18n-title="Text shown when hovering over the button to start a conversation in a profile page@@SEND_MESSAGE" (click)="contactProfile(profile.id)">
              <img src="assets/icons/icon_chat_with.svg" alt="" class="chat-icon">
            </button>
            <button type="button" class="contextual-menu closed show" #contextualMenuClosed (click)="openProfileDialog($event)">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </button>
          </div>
        }
      }
    </div>
        
    @if(profile){
      <div class="right">
        <div class="user-information desktop">
          <!-- <h3 [routerLink]="['/profile/' + profile.id]">{{profile.firstname}}</h3> -->
          <h3>{{profile.firstname}}</h3>
          <p>{{userAge}} <ng-container i18n="Age unit@@YEARS">years</ng-container> - {{profile.city?.name}}</p>
          <div class="searching-container">
              <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
              {{profile.attraction | titlecase}}
          </div>
        </div>
          
          <h4 i18n="Title for the description section in the profile page@@DESCRIPTION">Description</h4>
          <p class="description" #description>
            @if(profile.description){
              {{profile.description}}
            } @else {
              <ng-container i18n="Default description if no description is provided in the profile management page@@MYSTERY_CONTINUES">The mystery continues... It’s up to you to find out who i am :-)</ng-container>
            }
          </p>
  
          <h4 i18n="Title for the hobbies section in the profile page@@HOBBIES">Hobbies</h4>
          <div class="hobbies-container" #hobbiesContainer>
              @for (h of profile.topics; track $index) {
                  <div class="hobby" 
                    [ngStyle]="getStyles()"
                  >
                      {{h.name}}
                  </div>
              } @empty {
                <p class="no-hobbies">This person has not shared any hobbies yet.</p>
              }
          </div>
      </div>
    }

    <div class="overlay" [ngStyle]="{'display': infoDialogType !== '' ? 'block' : 'none'}"></div>
    <app-info-dialog [infoDialogType]="infoDialogType" [ngStyle]="{'display': infoDialogType !== '' ? 'flex' : 'none'}" (closeInfoDialog)="closeDialog($event)"></app-info-dialog>

    @if(match){
      <app-match-dialog></app-match-dialog>
    }

    @if(notSubscribed){
      <app-info-dialog
        [class.show]="showInfoDialog"
        [infoDialogType]="infoDialogType"
        (closeInfoDialog)="closeInfoDialog($event)"
      ></app-info-dialog>
    }
</section>