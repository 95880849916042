import { ElementRef, Injectable } from '@angular/core';
import { Observable, forkJoin, BehaviorSubject, Subject } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';

import { InitialStoryPhotoArray, PhotoObject } from './images-holder/story-photos';

import { Story } from '../shared/interfaces/story';

export interface StoryElementInterface {
  color?: string;
  src: string | null;
  content?: string;
  height?: number | null ;
  fontSize?: number | null;
  width?: number | null | undefined;
  x?: number;
  y?: number;
  angle?: number;
  type?: string
  saved?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class StoryService {
  addStoryElement = new Subject<StoryElementInterface>()
  story = new BehaviorSubject<Story | null>(null)
  ratio!: number

  get latestStoryElement() {
    return this.addStoryElement.asObservable()
  }

  saveStoryImage = new BehaviorSubject<{storyVisuals: ElementRef[], storyImage: ElementRef} | null>(null);

  get storyImageForProcessing() {
    return this.saveStoryImage.asObservable();
  }

  allStoryElements = new Subject<StoryElementInterface[]>()

  get allStoryElements$() {
    return this.allStoryElements.asObservable()
  }

  textParagraphCreated = new BehaviorSubject<boolean>(false)
  imageToSave = new BehaviorSubject<boolean>(false)
  storyImages = new BehaviorSubject<PhotoObject[]>(InitialStoryPhotoArray)

  constructor(
  ){}

  loadImageFromUrl(url: string): Observable<HTMLImageElement> {
    return new Observable((subscribe) => {
      const image = new Image()
      image.src = url

      image.onload = () => {
        subscribe.next(image)
        subscribe.complete()
      }

      image.onerror = () => {
        subscribe.error('Not found')
      }
    })
  }

  loadManyImagesFromUrl(urls: string[]): Observable<HTMLImageElement[]> {
    return forkJoin(urls.map((url) => this.loadImageFromUrl(url))).pipe(defaultIfEmpty([]))
  }
}
