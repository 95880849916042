import { Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Location } from '@angular/common';

import { PhotoService } from '../photo/photo.service';
import { AppService } from '../app.service';
import { AuthService } from '../auth.service';

import { PhotoCropComponent } from '../shared/photo-crop/photo-crop.component';

import { User } from '../shared/interfaces/user';
import { Photo } from '../shared/interfaces/photo';

@Component({
  selector: 'app-profile-crop',
  standalone: true,
  imports: [PhotoCropComponent],
  templateUrl: './profile-crop.component.html',
  styleUrl: './profile-crop.component.scss'
})
export class ProfileCropComponent implements OnInit {
  @ViewChildren('contextualMenuClosed') contextualMenusClosed!: QueryList<ElementRef>
  @ViewChildren('contextualMenuOpened') contextualMenusOpened!: QueryList<ElementRef>
  @ViewChild('cropper') cropper!: ElementRef

  @Output() fileList: EventEmitter<boolean> = new EventEmitter()
  
  user!: User
  photos: (Photo | null)[] = []

  //fileList: FileList | null = null
  tempCropperPhotoInBase64!: string
  showCropper!: { 
    data: File,
    temp?: string,
    size: { height: number; width: number; }
  } | null
  croppedImage: string = ''
  cropData!: { x: number; y: number; height: number; width: number; preview: string; }

  editInformationMode = false

  displayCreatingLoader = false
  newPhotoIndex!: number

  uploadedPhotoSrc = ''

  desktopDevice = false

  constructor(
    private location: Location,
    private photoService: PhotoService,
    private appService: AppService,
    private authService: AuthService
  ){}

  ngOnInit(){
    if(this.photoService.photoToCropInBase64) this.uploadedPhotoSrc = this.photoService.photoToCropInBase64

    const userStoredLocally = this.authService.get('user')

    if(userStoredLocally) {
      this.user = JSON.parse(this.authService.get('user'))
    }

    // gestion taille écran
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }

    this.desktopDevice = this.appService.desktopDevice
  }

  getUserCroppedImage(event: string){
    this.croppedImage = event
  }
    
  getCropDetails(event: { x: number; y: number; height: number; width: number; preview: string; }){
    this.cropData = event
  }

  saveCropData() {
    console.log('saveCropData de my profile')
    const tempCropperPhotoInBase64Arr = this.uploadedPhotoSrc.split(',')

    //if(!this.showCropper?.temp){ throw new Error('Source image is missing') }

    this.appService.setLoader(true)
    this.photoService.cropper()

    // étape 2 : envoyer la photo en base64
    const data: {
      origin: string //image destination en base64
    } = {
      origin: tempCropperPhotoInBase64Arr[1]
    }

    // crop image on front
    const img = new Image()
    img.src = this.tempCropperPhotoInBase64
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if(img && ctx){
      img.onload = () => {
        ctx.drawImage(img,this.cropData.x,this.cropData.y,this.cropData.width,this.cropData.height,0,0,50,50)
       
        //const croppedImg = canvas.toDataURL('image/jpeg')
      }
    }
    // crop image on front

    const token = this.authService.get('token')

    this.photoService.saveOriginalPhoto(data, token).subscribe(async(res) => {
      if(res.photo.id){
        // étape 3 : cropper la photo
        const croppedImageArr = this.croppedImage.split(',')
        const photoId = res.photo.id

        this.photoService.cropPhoto(data.origin, croppedImageArr[1], this.cropData,photoId).subscribe(res => {
          if(!res) return
         
          this.appService.setLoader(false)

          this.authService.getProfile(this.user.id).subscribe(async(res) => {
            this.user = res

            this.photos = []

            for (let i = 0; i < 6; i++) {
              if(!res.photos?.[i]){
                this.photos.push(null)
              } else {
                this.photos.push(res.photos[i])
              }
            }

            this.authService.setAuthMe(this.user)
          })

          this.showCropper = null
          this.location.back()

        })
      } else if(res.error?.message) {
        // this.errorTitle = 'Subscription failed'
        // this.errorMessage = res.error?.message
        // this.openDialog = true
      } 
    })
  }
 
  discardPhoto(){
    this.fileList.emit(false)

    this.location.back()
  }
}
