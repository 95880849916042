import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HomeService } from '../home/home.service';
import { LayoutService } from '../layout.service';

import { Story } from '../shared/interfaces/story';
import { User } from '../shared/interfaces/user';

@Component({
  selector: 'app-story',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './story.component.html',
  styleUrl: './story.component.scss'
})
export class StoryComponent {
  @Input() story!: Story
  @Input() user!: User | null
  @Output() showStoryRequest: EventEmitter<Story> = new EventEmitter()

  isDesktop = this.layoutService.isDesktop

  constructor(
    private homeService: HomeService,
    private layoutService: LayoutService
  ){}

  viewStory(story: Story){
    console.log('viewStory')
    this.homeService.story.next(story)
    this.showStoryRequest.emit(story)
  }
}
