import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-top-button',
  standalone: true,
  imports: [],
  templateUrl: './top-button.component.html',
  styleUrl: './top-button.component.scss'
})
export class TopButtonComponent {
  @Output() scrollRequest: EventEmitter<string> = new EventEmitter()

  constructor(){}

  scrollToTop(){
    this.scrollRequest.emit('scrollToTop')
  }
}
