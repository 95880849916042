import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TitleCasePipe],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  serviceName = environment.serviceName
}
