<h3 i18n="Title for the flag story dialog@@TEXT_FLAG_STORY">Are you sure you wish to report this story?</h3>

<p class="description" i18n="Description for the flag story dialog@@TEXT2_FLAG_STORY">This story will be monitored by our moderation teams.</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()" i18n="Text for the button to cancel the action in progress@@CANCEL">Cancel</button>
  <button class="submit" (click)="dialogRef.close(true)">
    <img src="assets/icons/icon_flag.svg" alt="" class="flag-icon">
    <ng-container i18n="Text for the button to confirm the flagging of a story@@FLAG">Flag</ng-container> 
  </button>  
</div>
