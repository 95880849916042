import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { ActivatedRoute, RouterModule, RouterOutlet } from '@angular/router';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppService } from '../../app.service';
import { HomeService } from '../../home/home.service';
import { AuthService } from '../../auth.service';

import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';
import { LoaderComponent } from '../../shared/loader/loader.component';

import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-my-account',
  standalone: true,
  imports: [GoBackButtonComponent, LoaderComponent, FormsModule, ReactiveFormsModule, RouterOutlet, RouterModule],
  templateUrl: './my-account.component.html',
  styleUrl: './my-account.component.scss'
})
export class MyAccountComponent implements OnInit, OnDestroy {
  desktopDevice = false
  loading = false
  loaderSubscription!: Subscription
  showPassword = true
  user: User | null = null
  routeDataSubscription!: Subscription
  editEmailMode = false
  editPasswordMode = false
  deleteAccountMode = false
  emailFormGroup!: FormGroup

  constructor(
    private location: Location,
    private appService: AppService,
    private homeService: HomeService,
    private authService: AuthService,
    private route: ActivatedRoute
  ){}

  ngOnInit(){
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }
    this.desktopDevice = this.appService.desktopDevice
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    this.authService.authMe.subscribe(res => {
      this.appService.setLoader(true)

      this.user = res
      
      this.appService.setLoader(false)
    })

    if(!this.user){
      // récupération du user du resolver
      this.routeDataSubscription = this.route.data.subscribe(data => this.user = data['user'])
    }
  }

  goBack(){
    if(this.desktopDevice) {
      this.homeService.pageDisplayed.next('')
      this.location.back()
    } else {
      this.homeService.pageDisplayed.next('')
    }
  }

  getClickNature(clickNature: string){
    console.log(clickNature)
    // if(clickNature === 'anywhere' && this.openDialog === true){
    //   this.openDialog = false
    // }
  }

  showHidePassword() {
    this.showPassword = !this.showPassword
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
