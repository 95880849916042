import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"

export function emailOrPhoneValidator() : ValidatorFn {
    return (control:AbstractControl<string>) : ValidationErrors | null => {
      const value = control.value
  
      if(!value.length) {
        return { isEmpty : true }
      }
      
      // la string commence par + ou ne contient que des chiffres
      const phonePattern = "^\\+?([0-9]+)$"
      if(value.match(phonePattern)){
        return null
      }
  
      // la string est un e-mail
      const emailPattern = "^[a-zA-Z_\\-\\.0-9]+@[a-zA-Z_\\-0-9]+?\\.[a-zA-Z]{2,3}$"; 
      if(value.match(emailPattern)){
        return null
      }

      return { valid: false}
    }
  }