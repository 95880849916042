import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild, ViewEncapsulation, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { CarouselModule, CarouselComponent } from 'ngx-carousel-ease';
import { BehaviorSubject, map, Subject, switchMap, takeUntil } from 'rxjs';
import dayjs from 'dayjs';
import { MatSidenav } from '@angular/material/sidenav';

import { HomeService } from '../home/home.service';
import { ProfileService } from '../profile/profile.service';
import { AuthService } from '../auth.service';
import { EncountersService } from './encounters.service';
import { SwipeService } from '../swipe.service';
import { LayoutService } from '../layout.service';

import { GoBackButtonComponent } from '../shared/go-back-button/go-back-button.component';

import { User } from '../shared/interfaces/user';

import { ClickAnywhereDirective } from '../click-anywhere.directive';

@Component({
  selector: 'app-new-encounters',
  standalone: true,
  imports: [RouterModule, CommonModule, CarouselModule, CarouselComponent, ClickAnywhereDirective, GoBackButtonComponent],
  templateUrl: './new-encounters.component.html',
  styleUrl: './new-encounters.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class NewEncountersComponent implements OnInit, OnDestroy {
  @Output() emitInfoDialogType = new EventEmitter<string>()
  @Output() pageDisplayed = new EventEmitter<string>()

  @ViewChild('suggestionDiv') suggestionDiv!: ElementRef
  @ViewChild('cardSection') cardSection!: ElementRef
  @ViewChild('description') descriptionElt!: HTMLParagraphElement
  @ViewChild('image') image!: ElementRef
  @ViewChild('encounterSidenav') encounterSidenav!: MatSidenav

  @HostBinding('class.newEncounters') addClass: boolean = true // <-- add class to the host to target it in css as encapsulation is off

  user!: User
  userAge!: number
  encounters: User[] = []
  userIndex = 0
  photoIndex = 0
  encounterSidenavToggled = false
  relationshipStatus = new BehaviorSubject('')
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop

  private _swipeCoord?: [number, number];
  private _swipeTime?: number;

  constructor(
    private renderer: Renderer2,
    private homeService: HomeService,
    private location: Location,
    private profileService: ProfileService,
    private authService: AuthService,
    private encountersService: EncountersService,
    private swipeService: SwipeService,
    private layoutService: LayoutService
  ){}

  ngOnInit(){
    this.homeService.toggleEncounterSidenav.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      this.encounterSidenavToggled = res

      if(this.encounterSidenav) {
        if(this.encounterSidenavToggled){
          this.encounterSidenav.open()
        } else {
          this.encounterSidenav.close()
        }
      }
    })

    this.homeService.encounters.pipe(
      map(suggestions => {
        this.encounters = suggestions
      }),
      switchMap(() => this.authService.getAuthContactsRequest(1)),
      map(invitations => {
        this.encounters = [...invitations, ...this.encounters]
        const user = this.encounters[this.userIndex]
        this.user = user
        this.profileService.profile.next(this.user)

        return user
      }),
      switchMap((user) => this.encountersService.getRelationshipStatus(user.id)),
      map(status => {
        this.relationshipStatus.next(status)
      })
    ).subscribe(() => {
        if(this.user && this.user.birthdate){
          const [y,m,d] = this.user.birthdate.split('-')
          const date = `${y}-${m}-${d}`
          const birthDate = dayjs(date)
    
          this.userAge = dayjs().diff(birthDate, 'year')
        }
    })

    /* this.homeService.encounters.pipe(
      takeUntil(this._destroyed)
    ).subscribe((suggestions: User[]) => {
      this.encounters = suggestions

      this.authService.getAuthContactsRequest(1).subscribe(invitations => {
        this.encounters = [...invitations, ...this.encounters]
        this.user = this.encounters[this.userIndex]

        if(!this.user) return

        this.encountersService.getRelationshipStatus(this.user.id).pipe(
          takeUntil(this._destroyed)
        ).subscribe(res => {
          this.relationshipStatus.next(res)
        })

        this.profileService.profile.next(this.user)

        if(this.user && this.user.birthdate){
          const [y,m,d] = this.user.birthdate.split('-')
          const date = `${y}-${m}-${d}`
          const birthDate = dayjs(date)
    
          this.userAge = dayjs().diff(birthDate, 'year')
        }
      })
    }) */
  }

  swipe(e: TouchEvent, when: string, user: User): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY]
    const time = new Date().getTime()

    if (when === 'start') {
      this._swipeCoord = coord
      this._swipeTime = time
    }
    else if (when === 'end') {
      if(!this._swipeCoord || !this._swipeTime) return

      const direction = [coord[0] - this._swipeCoord[0], coord[1] - this._swipeCoord[1]]
      const duration = time - this._swipeTime

      if (duration < 1000
        && Math.abs(direction[0]) > 30 
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { 
        if (direction[0] < 0) {
          //next
          this.swipeService.swipeNext()
          this.rejectProfile(user)
        } else {
          //previous
          this.swipeService.swipePrevious()
          this.likeProfile(user)
        }
      }
    }
  }

  toggleEncounterSidenav(open: boolean){
    this.homeService.toggleEncounterSidenav.next(open)
  }

  getClickNature(clickNature: string, id: number){
    if(clickNature === 'anywhere'){
      this.authService.getProfile(id).pipe(
        takeUntil(this._destroyed)
      ).subscribe(res => this.profileService.profile.next(res))
    }
  }

  rejectProfile(user: User){
    let state = 'refused'

    // refuse si relation non définie de base. Si déjà refused, passe à rejected
    this.encountersService.getRelationshipStatus(user.id).pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      console.log(res)
      if(res === "rejected") state = 'rejected'
    })

    this.homeService.updateRelationship(user.id, state).pipe(
      takeUntil(this._destroyed)
    ).subscribe(() => {
      this.renderer.addClass(this.suggestionDiv.nativeElement, 'slide-left')

      setTimeout(() => {
        this.userIndex++
        this.user = this.encounters[this.userIndex]
        this.renderer.addClass(this.suggestionDiv.nativeElement, 'appear')
  
        setTimeout(() => {
          this.renderer.removeClass(this.suggestionDiv.nativeElement, 'appear')
        }, 1000)
  
        this.renderer.removeClass(this.suggestionDiv.nativeElement, 'slide-left')
      }, 2000)

      this.encountersService.markProfileAsSeen(user.id).pipe(
        takeUntil(this._destroyed)
      ).subscribe(res => console.log(res.message))

      this.removeProfileFromEncounters(user)
    })
  }

  likeProfile(user: User){
    console.log('likeProfile')

    this.homeService.updateRelationship(user.id, "accepted").pipe(
      takeUntil(this._destroyed)
    ).subscribe(() => {
      this.renderer.addClass(this.suggestionDiv.nativeElement, 'slide-right')
      this.renderer.addClass(this.cardSection.nativeElement, 'enhance')
  
      setTimeout(() => {
        this.userIndex++
        this.user = this.encounters[this.userIndex]
        this.renderer.addClass(this.suggestionDiv.nativeElement, 'appear')
  
        setTimeout(() => {
          if(this.suggestionDiv.nativeElement) this.renderer.removeClass(this.suggestionDiv.nativeElement, 'appear')
        }, 1000)
  
        this.renderer.removeClass(this.suggestionDiv.nativeElement, 'slide-right')
        this.renderer.removeClass(this.cardSection.nativeElement, 'enhance')
      }, 1500)
  
      this.encountersService.markProfileAsSeen(user.id).pipe(
        takeUntil(this._destroyed)
      ).subscribe(res => console.log(res.message))

      this.emitInfoDialogType.emit('likeProfile')

      this.removeProfileFromEncounters(user)
    })
  }

  removeProfileFromEncounters(user: User){
    const index = this.encounters.indexOf(user)
    const newEncounters = this.encounters.splice(index, 1)
    this.homeService.encounters.next(newEncounters)
  }

  contactProfile(userId: number){
    console.log(userId)
    this.emitInfoDialogType.emit('contactProfile')
  }

  closeSidenav() {
    this.homeService.toggleSidenav.next(false)
    this.homeService.pageDisplayed.next('home')
    this.location.back()
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}

/* 
  si critères de rencontre matchent => new encounters 
  croix en haut à droite pour fermer la popup new encounters
  
  Boutons d'action : 
    icône croix : rejectProfile()
    icône coeur : likeProfile()
    icône message : contactProfile()

  L'info-dialog d'envoi de message ne doit s'afficher que si le user n'est pas abonné.
  L'info-dialog de validation d'un profil ne s'affiche qu'une fois que le user a liké 5 personnes (gratuitement).
  
  Si le user like un profile qui l'a déjà liké, popup de match.
*/
