<canvas [ngStyle]="awaitingImage ? {'display': 'none'}: {'display': 'block'}" id="canvasSource"></canvas>

<div class="story-container" [ngStyle]="getImageWrapperStyle()">
  @if(awaitingImage){
    <div>
        <div #imageContainer [ngStyle]="getImageWrapperStyle()" class="image-wrapper">
            @if(!photos[photoIndex].saved){
              <app-toolbar-edit-story (openTextEditor)="openResizeEl()" (closeRequest)="showCloseDialog()" [hideMenu]="hideMenu"></app-toolbar-edit-story>
            } @else {
              <button type="button" (click)="close()" class="close-btn">
                <span class="material-symbols-rounded close">
                  close
                </span>
              </button>
            }

            <div class="images-container">
                <img class="story-photo" [ngStyle]="getStoryPhotoStyle()" #storyPhoto [src]="currentPhoto.src" alt="" (load)="onImageLoad()">
        
                @for(img of allStoryElements$ | async; track img; let index = $index){
                    @if(img.type === 'visual'){
                        <div 
                          cdkDrag 
                          #draggable
                          [style.position]="'absolute'"
                          [style.top]="img.y + 'px' || ''"
                          [style.left]="img.x + 'px' || ''"
                          cdkDragBoundary=".images-container"
                          (cdkDragEnded)="dragEnded($event, index)" 
                          class="visual-container"
                        >
                          <button type="button" (click)="open($event, img, index)">
                            <img class="image-emoji" [style.transform]="'rotate(' + img.angle + 'deg)'" [src]="img.src" [height]="img.height" [width]="img.width" alt="">
                          </button>  
                        </div>
                    }
                    
                    @if (img.type === 'text') {
                        <div 
                          #textContainer 
                          [ngStyle]="parentTextBoxStyles" 
                          cdkDrag 
                          (cdkDragEnded)="dragEnded($event, index)" 
                          class="text-container"
                        >
                            <button type="button" (click)="open($event, img, index)">
                              <span [ngStyle]="textBoxStyles">{{img.content}}</span>
                            </button>
                        </div>
                    }
                }
            </div>
        </div>
    </div> 
  }

  @if(showPortal){
    <div class="example-portal-outlet">
      <ng-template [cdkPortalOutlet]="componentPortal"></ng-template>
    </div>
  }

  <app-images-holder [ngStyle]="{width: isDesktop() ? screenSize.height / 1.4 + 'px' : '100%' }" (removeImage)="awaitingImage = $event; emptyStoryVisuals($event)" (openDialog)="openDialog($event)" (currentImageDisplayed)="currentImage($event)"></app-images-holder>

  @if(warning){
    <app-confirm-dialog 
      [title]="dialogInfo.title" 
      [content]="dialogInfo.content"
      (confirmation)="confirmStoryDeletion($event)"
    ></app-confirm-dialog>
  }
</div>