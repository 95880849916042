<div class="form" [formGroup]="childForm">
    <h2>City</h2>
    <div class="input-holder" #input>
        <input 
            type="text"
            formControlName="city"
            placeholder="Enter your city"
            i18n-placeholder="Text for the placeholder of the city input@@ENTER_CITY"
            required
        >
        
        @if(this.childForm.get('city')){
          <button type="button" class="cancel-btn" (click)="setCity()">
            <span class="material-symbols-outlined cancel">
              cancel
            </span>
          </button>
        }
    </div>
    
    @if (citiesLoading){
        <div class="input-select-choice loading" i18n="Text while cities are being loaded in the city input@@SEARCH_CITIES">Searching cities...</div>
    } @else if (noCityFound){
        <div class="no-cities warning"
          [ngStyle]="{
            'bottom': !isDesktop() ? '63px' : 'unset',
            'width': inputWidth + 'px'
          }"
        >
            <button class="close-btn" (click)="setCity()">
              <p i18n="Text when the searched city was not found in the city input@@NO_CITY_AVAILABLE">No city available</p>
              <span class="material-symbols-rounded close">
                close
              </span>
            </button>
        </div>
    } @else if(cityChoice) {
        <div class="cities-list" 
          [ngStyle]="{
            'bottom': !isDesktop() ? '63px' : 'unset',
            'width': inputWidth + 'px'
          }"
        >
            @for(city of cityChoice; track city.id){
              <button type="button" class="city-btn" (click)="setCity(city)">
                <div class="city-choice" >
                  <img src="assets/icons/icon_location.svg" alt="" class="location-icon">

                  <div class="city-information">
                    <div class="left">
                      <div class="city-name">{{city.name}}</div>
                      <div class="city-country">{{ city.country ?? city.country }}</div>
                    </div>
                    
                    <div class="city-zipcode">{{city.location}}</div>
                  </div>
                </div>
              </button>
            }
        </div>
    }
</div>

  