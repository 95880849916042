import { Component } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [TitleCasePipe],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  serviceName = environment.serviceName
}
