import { Component, OnDestroy } from '@angular/core';
import { Location, TitleCasePipe, AsyncPipe } from '@angular/common';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

import { HomeService } from '../../home/home.service';
import { LoaderService } from '../../loader.service';
import { LayoutService } from '../../layout.service';

import { LoaderComponent } from '../../shared/loader/loader.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

@Component({
  selector: 'app-my-subscription',
  standalone: true,
  imports: [LoaderComponent, GoBackButtonComponent, TitleCasePipe, AsyncPipe],
  templateUrl: './my-subscription.component.html',
  styleUrl: './my-subscription.component.scss'
})
export class MySubscriptionComponent implements OnDestroy {
  loading$ = this.loaderService.loading$
  serviceName = environment.serviceName
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop

  constructor(
    private location: Location,
    private homeService: HomeService,
    private loaderService: LoaderService,
    private layoutService: LayoutService
  ){}

  goBack(){
    if(this.isDesktop()) {
      this.homeService.pageDisplayed.next('')
      this.location.back()
    } else {
      this.homeService.pageDisplayed.next('')
    }
  }

  ngOnDestroy() {
    this._destroyed.next()
    this._destroyed.complete()
  }
}
