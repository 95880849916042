<section class="coach-space">
    <!-- TODO : intégrer le carousel -->
    <div class="photo-container">
      @if(coach){
        <img src="../../../assets/icons/icon_coach_badge.svg" alt="" class="badge">
        <img [src]="coach.photo" alt="" class="photo">
      }
      <img src="../../../assets/icons/icon_chat_with.svg" alt="" class="chat-icon">
    </div>

    <div class="photo-pagination">
        <div class="active"></div>
        <div class="inactive"></div>
        <div class="inactive"></div>
    </div>
    
    <div class="coach-description">
        <h5>Description</h5>
        <p>
            I’m Clara, the Swipi dating coach!!! 
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut eros quis turpis aliquet placerat ac ut sem. Morbi sit amet dui rhoncus, venenatis tellus quis, tincidunt ligula. 
        </p>
    </div>
    
    <div class="coach-hobbies">
        <h5>Hobbies</h5>
        <div class="hobbies" #hobbiesContainer>
            <div class="hobby-item" [ngStyle]="getStyles()">
                <p class="hobby-title">Music</p>
            </div>
            <div class="hobby-item" [ngStyle]="getStyles()">
                <p class="hobby-title">Sports</p>
            </div>
            <div class="hobby-item" [ngStyle]="getStyles()">
                <p class="hobby-title">Cinema</p>
            </div>
            <div class="hobby-item" [ngStyle]="getStyles()">
                <p class="hobby-title">Homebody</p>
            </div>
            <div class="hobby-item" [ngStyle]="getStyles()">
                <p class="hobby-title">Discreet</p>
            </div>
            <div class="hobby-item" [ngStyle]="getStyles()">
                <p class="hobby-title">Mountain</p>
            </div>
            <div class="hobby-item" [ngStyle]="getStyles()">
                <p class="hobby-title">TV Series</p>
            </div>
        </div>
    </div>
</section>
