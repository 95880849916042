<div class="popin">
  <div class="popin-content">
    <span class="material-symbols-outlined warning">
      error
    </span> 
    <h2 i18n="@@ERROR_warning">Warning!</h2>

    @if(errorValue?.level !== 'subscribe notfound' &&
    errorValue?.level !== 'already subscribe' &&
    errorValue?.level !== 'subscribe already exist'){
      <div class="error-text">
        <div class="text">

          @switch(errorValue?.error_code){
            <!-- COMMON -->
            @case(-104) {
              <ng-container i18n="@@ERROR_INVALID_PARAMETER">error</ng-container>
            }

            <!-- AUTHENTICATE -->
            @case(21001){  
              <ng-container i18n="@@ERROR_UNABLE_TO_LOGIN">error</ng-container>
            }
            @case(21012){
              <ng-container i18n="@@ERROR_UNABLE_TO_LOGIN">error</ng-container>
            }
            @case(21002){
              <ng-container i18n="@@ERROR_UNABLE_TO_LOGOUT">error</ng-container>
            }
            @case(21003){
              <ng-container i18n="@@ERROR_INVALID_TOKEN">error</ng-container>
            }

            <!-- USER PART -->
            @case(1){
              <ng-container i18n="@@ERROR_USER_BAD_CREDENTIALS">error</ng-container>
            }
            @case(2){
              <ng-container i18n="@@ERROR_USER_DOES_NOT_EXIST">error</ng-container>
            }
            @case(3){
              <ng-container i18n="@@ERROR_USER_ALREADY_EXIST">error</ng-container>
            }
            @case(4){
              <ng-container i18n="@@ERROR_INVALID_TOKEN">error</ng-container>
            }
            @case(5){
              <ng-container i18n="@@ERROR_UNABLE_TO_LOGOUT">error</ng-container>
            }
            @case(6){
              <ng-container i18n="@@ERROR_UNABLE_TO_DELETE_PHOTO">error</ng-container>
            }
            @case(7){
              <ng-container i18n="@@ERROR_EMAIL_NOT_FOUND">error</ng-container>
            }
            @case(8){
              <ng-container i18n="@@ERROR_USER_BAD_PARAMS">error</ng-container>
            }
            @case(12){
              <ng-container i18n="@@ERROR_BAD_PARAMS_INPUT">error</ng-container>
            }
            @case(13){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_USER">error</ng-container>
            }
            @case(14){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_USER_FIRST_NAME">error</ng-container>
            }
            @case(15){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_USER_BIRTHDATE">error</ng-container>
            }
            @case(16){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_USER_DESCRIPTION">error</ng-container>
            }
            @case(17){
              <ng-container i18n="@@ERROR_USER_HAS_NO_ACCESS_TO_PROFILE">error</ng-container>
            }
            @case(18){
              <ng-container i18n="@@ERROR_USER_BLOCKED">error</ng-container>
            }
            @case(19){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_PASSWORD" >error</ng-container>
            }
            @case(20){
              <ng-container i18n="@@ERROR_UNABLE_TO_DELETE_USER">error</ng-container>
            }
            @case(21){
              <ng-container i18n="@@ERROR_UNABLE_TO_DELETE_USER_DESCRIPTION">error</ng-container
            >
            }
            @case(22){
              <ng-container i18n="@@ERROR_ERROR_USER_PASSWORD">error</ng-container>
            }
            @case(27){
              <ng-container i18n="@@ERROR_UNABLE_TO_SENT_RESET_PASSWORD_EMAIL">error</ng-container>
            }
          
            <!-- CITY -->
            @case(301){
              <ng-container i18n="@@ERROR_CITY_EMPTY_LIST">error</ng-container>
            }
            @case(302){
              <ng-container i18n="@@ERROR_CITY_NOT_FOUND">error</ng-container>
            }

            <!-- PHOTO -->
            @case(401){
              <ng-container i18n="@@ERROR_PHOTO_CREATE_PHOTO">error</ng-container>
            }
            @case(402){
              <ng-container i18n="@@ERROR_PHOTO_DELETE_PHOTO">error</ng-container>
            }
            @case(403){
              <ng-container i18n="@@ERROR_UNABLE_TO_GET_PHOTO">error</ng-container>
            }
            @case(412){
              <ng-container i18n="@@ERROR_UNABLE_TO_SET_TO_PROFILE">error</ng-container>
            }

            <!-- STORIES -->
            @case(504){
              <ng-container i18n="@@ERROR_UNABLE_TO_CREATE_STORIES">error</ng-container>
            }
            @case(512){
              <ng-container i18n="@@ERROR_UNABLE_TO_DELETE_STORIES">error</ng-container>
            }
            @case(517){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_STORIES">error</ng-container>
            }

            <!-- CRITERIA -->
            @case(605){
              <ng-container i18n="@@ERROR_UNABLE_TO_UPDATE_CRITERIA">error</ng-container>
            }

            <!-- CRUSH -->
            @case(701){
              <ng-container i18n="@@ERROR_CRUSH_ALREADY_EXIST">error</ng-container>
            }

            <!-- RELATIONSHIP -->
            @case(1004){
              <ng-container i18n="@@ERROR_UNABLE_TO_CREATE_RELATION">error</ng-container>
            }
            @case(1007){
            <ng-container i18n="@@ERROR_BAD_PARAMS_FOR_RELATIONSHIP">error</ng-container>
            }

            <!-- MESSAGE -->
            @case(1102){
              <ng-container i18n="@@ERROR_UNABLE_TO_SET_MESSAGE">error</ng-container>
            }

            <!-- ALREADY REPORT -->
            @case(1205){
              <ng-container i18n="@@ERROR_REPORT_ALREADY_EXIST">error</ng-container>
            }

            <!-- DESCRIPTION -->
            @case(1700){
              <ng-container i18n="@@ERROR_UNABLE_TO_HARDDELETE_DESCRIPTION">error</ng-container>
            }

            <!-- DEFAULT PART -->
            @default{
              <ng-container i18n="@@ERROR_ConnexionErrorService">error</ng-container>
            }
          }
        </div>
      </div>
    } @else {
        @if(errorValue?.level === 'subscription not found'){
          <div class="error-text">
            <div class="text">
              <p i18n="@@ERROR_subscriberTokenNotFound">Error while processing account creation.</p>
              
              <div class="actions">
                <a
                  [href]="'mailto:customer@woozgo.com?subject=Subscription%20token%20:%20' + token +'is%20invalid'"
                  i18n="@@ERROR_subsriberTokenNotFoundContact"
                >
                  Contact our support
                </a>
              </div>
            </div>
          </div>
        }
  
        @if(errorValue?.level === 'already subscribe'){
          <div class="error-text">
            <div class="text">
              <p i18n="@@ERROR_subscribeAlreadyExist">You already subscribed with this phone number</p>
              <p i18n="@@ERROR_subscribeAlreadyExistUseAnotherAccount">Please use another one</p>
            </div>
          </div>
        }
        
        @if(errorValue?.level === 'subscription already exists'){
          <div class="error-text">
            <div class="text">
              <p i18n="@@ERROR_subscriberTokenAlreadyExist">You already created your account</p>
              <p i18n="@@ERROR_subscriberTokenAlreadyExistAccount">Your login is</p>
              <p>{{ errorValue?.message }}</p>
            </div>
          </div>
        }
    }

    <div class="error-code">
      <p i18n="@@ERROR_errorCode">Error code: {{ errorValue?.error_code }}</p>
    </div>
  </div>
</div>
