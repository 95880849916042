<section class="my-coach">
  <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>  
  
    <div class="top" #top>
        <h3><span class="regular">My</span> Coach</h3>
    </div>

    <section class="content">
        <header class="header">
            <div class="coach-info">
                @if(coach){
                  <div class="avatar-container">
                    <img [src]="coach.photo" alt="" class="avatar">
                  </div>
                  <div class="info">
                    <h4>{{coach.firstname}}</h4>
                    <p>{{coachAge}} years old - {{coach.city?.name}}</p>
                    <p class="occupation">{{ serviceName | titlecase }} dating coach</p>
                </div> 
                }                                                                        
            </div> 

            <nav class="coach-menu">
                <ul>
                  <li [class.selected]="pageDisplayed === 'space'">
                    <button type="button" (click)="pageDisplayed = 'space'" [routerLink]="['space']">
                      <img [src]="pageDisplayed === 'space' ? '../../assets/icons/icon_myspace_selected.svg' : '../../assets/icons/icon_myspace_menu.svg'" alt="" class="icon">
                    </button>
                  </li>
                  <li [class.selected]="pageDisplayed === 'stories'">
                    <button type="button" (click)="pageDisplayed = 'stories'" [routerLink]="['stories']">
                      <img [src]="pageDisplayed === 'stories' ? '../../assets/icons/icon_stories_selected.png' : '../../assets/icons/icon_stories.svg'" alt="" class="icon stories"> 
                    </button>
                  </li>
                </ul>
            </nav>
        </header>
          
        @if(loading){
          <app-loader></app-loader>
        }

        <router-outlet></router-outlet>
    </section>

    @if(stories.length){
      <app-top-button (scrollRequest)="getScrollRequest($event)"></app-top-button>
    }

    <app-footer></app-footer>
</section>