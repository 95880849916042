<div class="button-container" [ngClass]="{'hide-buttons' : hideMenu}">
    <div class="left">
      <button type="button" (click)="closeRequest.emit()">
        <span class="material-symbols-rounded close">
          close
        </span>
      </button>
    </div>

    <div class="right">
            <button class="tool" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="openText()">
                <img src="../../../assets/icons/icon_add_text.svg" alt="" class="icon">
            </button>
            <button class="tool" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="open('stickers', 'fun')">
                <img src="../../../assets/icons/icon_add_stickers.svg" alt="" class="icon">
            </button>
            <button class="tool" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="open('emojis', 'emoticons')">
                <img src="../../../assets/icons/icon_add_emoticon.svg" alt="" class="icon">
            </button>
            <button class="tool" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="open('masks')">
                <img src="../../../assets/icons/icon_add_mask.svg" alt="" class="icon">
            </button>
      </div>
</div>

@if(showPortal){
    <div class="example-portal-outlet">
        <ng-template  [cdkPortalOutlet]="componentPortal"></ng-template>
    </div>
}
