<section class="my-hobbies">
  @if(loading$ | async){
    <app-loader></app-loader>
  }

  <app-go-back-button (click)="goBack()"></app-go-back-button>
  
  <section class="main">
    <div class="top" #top>
      <h3 i18n="Title for the hobbies selection page@@MY_HOBBIES"><span class="regular">My</span> Hobbies</h3>
    </div>

    <p class="tip" i18n="Instruction for the hobbies selection page@@SELECT_FAVORITE_HOBBIES">Select here your favorite hobbies</p>
    
    @if(hobbiesWithSelection){
      <h4 i18n="Category of hobbies in the hobbies selection form@@HOBBIES_OUTDOOR">Outdoor Activities</h4>
      <div class="hobbies-list">
        @for(hobby of hobbiesWithSelection; track hobby){
          @if(hobby.category === 'outdoor'){
            <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
              @switch (hobby.name) {
                @case('mountain_activities'){
                  <h5 class="label" i18n="Hobby label@@MOUNTAIN_ACTIVITIES">Mountain activities</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@MOUNTAIN_ACTIVITIES_SUBLABEL">(hiking, climbing, etc.)</p>
                }
                @case('water_sports'){
                  <h5 class="label" i18n="Hobby label@@WATER_SPORTS">Water sports</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@WATER_SPORTS_SUBLABEL">(kayaking, surfing, paddleboarding)</p>
                }
                @case('cycling'){
                  <h5 class="label" i18n="Hobby label@@CYCLING">Cycling</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@CYCLING_SUBLABEL">(city biking, mountain biking, cycling tours)</p>
                }
                @case('camping_and_backpacking'){
                  <h5 class="label" i18n="Hobby label@@CAMPING">Camping and backpacking</h5>
                }
                @case('wildlife_and_nature_observation'){
                  <h5 class="label" i18n="Hobby label@@WILDLIFE">Wildlife and nature observation</h5>
                }
                @default {
                  <h5 class="label" i18n="Hobby label@@NATURE_EXPLORATION">Nature exploration</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@NATURE_EXPLORATION_SUBLABEL">(hiking, landscape photography)</p>
                }
              }
            </button>
          }
        }
      </div>
  
      <h4 i18n="Category of hobbies in the hobbies selection form@@HOBBIES_SPORTS_PHYSICAL">Sports & Physical Activities</h4>
      <div class="hobbies-list">
        @for(hobby of hobbiesWithSelection; track hobby){
          @if(hobby.category === 'sports'){
            <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
              @switch (hobby.name) {
                @case ('team_sports') {
                  <h5 class="label" i18n="Hobby label@@TEAM_SPORTS">Team sports</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@TEAM_SPORTS_SUBLABEL">(soccer, basketball, etc.)</p>
                }
                @case ('racquet_sports') {
                  <h5 class="label" i18n="Hobby label@@RACQUET_SPORTS">Racquet sports</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@RACQUET_SPORTS_SUBLABEL">(tennis, badminton)</p>
                }
                @case ('physical_training') {
                  <h5 class="label" i18n="Hobby label@@PHYSICAL_TRAINING">Physical training</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@PHYSICAL_TRAINING_SUBLABEL">(weightlifting, fitness, crossfit)</p>
                }
                @case ('water_based_activities') {
                  <h5 class="label" i18n="Hobby label@@WATER_BASED_ACTIVITIES">Water-based activities</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@WATER_BASED_ACTIVITIES_SUBLABEL">(swimming, diving)</p>
                }
                @case ('combat_sports') {
                  <h5 class="label" i18n="Hobby label@@COMBAT_SPORTS">Combat sports</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@COMBAT_SPORTS_SUBLABEL">(boxing, martial arts)</p>
                }
                @case ('wellness_activities') {
                  <h5 class="label" i18n="Hobby label@@WELLNESS_ACTIVITIES">Wellness activities</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@WELLNESS_ACTIVITIES_SUBLABEL">(yoga, pilates, stretching)</p>
                }
                @default {
                  <h5 class="label" i18n="Hobby label@@DANCE">Dance</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@DANCE_SUBLABEL">(all styles)</p>
                }
              }
            </button>
          }
        }
      </div>
  
      <h4 i18n="Category of hobbies in the hobbies selection form@@HOBBIES_CREATIVE">Creative Hobbies</h4>
      <div class="hobbies-list">
        @for(hobby of hobbiesWithSelection; track hobby){
          @if(hobby.category === 'creative'){
            <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
              @switch (hobby.name) {
                @case ('visual_arts') {
                  <h5 class="label" i18n="Hobby label@@VISUAL_ARTS">Visual arts</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@VISUAL_ARTS_SUBLABEL">(painting, drawing, sculpture)</p>    
                }
                @case ('handmade_crafts') {
                  <h5 class="label" i18n="Hobby label@@HANDMADE_CRAFTS">Handmade crafts</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@HANDMADE_CRAFTS_SUBLABEL">(sewing, knitting, DIY)</p>    
                }
                @case ('digital_arts') {
                  <h5 class="label" i18n="Hobby label@@DIGITAL_ARTS">Digital arts</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@DIGITAL_ARTS_SUBLABEL">(photography, graphic design, photo editing)</p>    
                }
                @case ('writing') {
                  <h5 class="label" i18n="Hobby label@@WRITING">Writing</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@WRITING_SUBLABEL">(poetry, storytelling, screenwriting)</p>    
                }
                @case ('diy_home_and_decor_projects') {
                  <h5 class="label" i18n="Hobby label@@DIY">DIY home and decor projects</h5>
                }
                @default {
                  <h5 class="label" i18n="Hobby label@@JEWELRY">Jewelry and accessory making</h5>
                }
              }
            </button>
          }
        }
      </div>
    
      <h4 i18n="Category of hobbies in the hobbies selection form@@HOBBIES_MUSIC_ARTS">Music & Performing Arts</h4>
      <div class="hobbies-list">
        @for(hobby of hobbiesWithSelection; track hobby){
          @if(hobby.category === 'arts'){
            <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
              @switch (hobby.name) {
                @case ('playing_an_instrument') {
                  <h5 class="label" i18n="Hobby label@@PLAYING_INSTRUMENT">Playing an instrument</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@PLAYING_INSTRUMENT_SUBLABEL">(piano, guitar, drums, etc.)</p>    
                }
                @case ('singing_and_karaoke') {
                  <h5 class="label" i18n="Hobby label@@SINGING">Singing and karaoke</h5>
                }
                @case ('music_composition') {
                  <h5 class="label" i18n="Hobby label@@MUSIC_COMPOSITION">Music composition</h5>
                }
                @case ('performing_arts') {
                  <h5 class="label" i18n="Hobby label@@PERFORMING_ARTS">Performing arts</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@PERFORMING_ARTS_SUBLABEL">(theater, dance, stand-up)</p>    
                }
                @case ('djing_and_mixing') {
                  <h5 class="label" i18n="Hobby label@@DJING_AND_MIXING">DJing and mixing</h5>
                }
                @default {
                  <h5 class="label" i18n="Hobby label@@BANDS_AND_MUSICAL_ENSEMBLES">Participating in bands or musical ensembles</h5>
                }
              }
            </button>
          }
        }
      </div>
  
      <h4 i18n="Category of hobbies in the hobbies selection form@@HOBBIES_GAMES_INTELLECTUAL">Games & Intellectual Activities</h4>
      <div class="hobbies-list">
        @for(hobby of hobbiesWithSelection; track hobby){
          @if(hobby.category === 'games-intellectual'){
            <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
              @switch (hobby.name) {
                @case ('board_and_card_games') {
                  <h5 class="label" i18n="Hobby label@@BOARD_CARD_GAMES">Board and card games</h5>
                }
                @case ('strategy_games') {
                  <h5 class="label" i18n="Hobby label@@STRATEGY_GAMES">Strategy games</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@STRATEGY_GAMES_SUBLABEL">(chess, go)</p>    
                }
                @case ('video_games_and_e_sports') {
                  <h5 class="label" i18n="Hobby label@@E_SPORTS">Video games and e-sports</h5>
                }
                @case ('reading_and_literary_exploration') {
                  <h5 class="label" i18n="Hobby label@@READING">Reading and literary exploration</h5>
                }
                @case ('puzzle_solving_and_escape_rooms') {
                  <h5 class="label" i18n="Hobby label@@PUZZLE">Puzzle solving and escape rooms</h5>
                }
                @default {
                  <h5 class="label" i18n="Hobby label@@SELF_LEARNING">Self-learning</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@SELF_LEARNING_SUBLABEL">(languages, history, sciences)</p>    
                }
              }
            </button>
          }
        }
      </div>
  
      <h4 i18n="Category of hobbies in the hobbies selection form@@HOBBIES_COOKING">Cooking & Culinary Discovery</h4>
      <div class="hobbies-list">
        @for(hobby of hobbiesWithSelection; track hobby){
          @if(hobby.category === 'cooking'){
            <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
              @switch (hobby.name) {
                @case ('home_cooking_and_recipe_experimentation') {
                  <h5 class="label" i18n="Hobby label@@HOME_COOKING">Home cooking and recipe experimentation</h5>
                }
                @case ('baking_and_desserts') {
                  <h5 class="label" i18n="Hobby label@@BAKING_DESSERTS">Baking and desserts</h5>
                }
                @case ('beverage_exploration') {
                  <h5 class="label" i18n="Hobby label@@BEVERAGE">Beverage exploration</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@BEVERAGE_SUBLABEL">(tea, coffee, wine, cocktails)</p>    
                }
                @case ('gardening_and_growing_plants') {
                  <h5 class="label" i18n="Hobby label@@GARDENING">Gardening and growing plants</h5>
                }
                @case ('discovering_new_cuisines') {
                  <h5 class="label" i18n="Hobby label@@NEW_CUISINES">Discovering new cuisines</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@NEW_CUISINES_SUBLABEL">(world gastronomy)</p>    
                }
                @default {
                  <h5 class="label" i18n="Hobby label@@CULINARY_CRAFTING">Culinary crafting</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@CULINARY_CRAFTING_SUBLABEL">(preserves, jams, fermentation)</p>    
                }
              }
            </button>
          }
        }
      </div>
  
      <h4 i18n="Category of hobbies in the hobbies selection form@@HOBBIES_TRAVEL_EXPLORATION">Travel & Exploration</h4>
      <div class="hobbies-list">
        @for(hobby of hobbiesWithSelection; track hobby){
          @if(hobby.category === 'travel'){
            <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
              @switch (hobby.name) {
                @case ('exploring_new_cultures_and_traditions') {
                  <h5 class="label" i18n="Hobby label@@NEW_CULTURES">Exploring new cultures and traditions</h5> 
                }
                @case ('nature_travel') {
                  <h5 class="label" i18n="Hobby label@@NATURE_TRAVEL">Nature travel</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@NATURE_TRAVEL_SUBLABEL">(camping, road trips)</p>    
                }
                @case ('urban_exploration_and_cultural_visits') {
                  <h5 class="label" i18n="Hobby label@@URBAN_CULTURAL_VISITS">Urban exploration and cultural visits</h5> 
                }
                @case ('travel_photography') {
                  <h5 class="label" i18n="Hobby label@@TRAVEL_PHOTOGRAPHY">Travel photography</h5>  
                }
                @case ('hiking_and_expeditions') {
                  <h5 class="label" i18n="Hobby label@@HIKING">Hiking and expeditions</h5>
                }
                @default {
                  <h5 class="label" i18n="Hobby label@@ROAD_TRIPS">Road trips and van life</h5>
                }
              }
            </button>
          }
        }
      </div>
  
      <h4 i18n="Category of hobbies in the hobbies selection form@@HOBBIES_TECHNOLOGY_INNOVATION">Technology & Innovation</h4>
      <div class="hobbies-list">
        @for(hobby of hobbiesWithSelection; track hobby){
          @if(hobby.category === 'technology'){
            <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
              @switch (hobby.name) {
                @case ('coding_and_software_development') {
                  <h5 class="label" i18n="Hobby label@@CODING">Coding and software development</h5>
                }
                @case ('creating_objects') {
                  <h5 class="label" i18n="Hobby label@@CREATING_OBJECTS">Creating objects</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@CREATING_OBJECTS_SUBLABEL">(3D printing, electronics projects)</p>    
                }
                @case ('exploring_virtual_reality_and_new_tech') {
                  <h5 class="label" i18n="Hobby label@@VR_NEW_TECH">Exploring virtual reality and new tech</h5>
                }
                @case ('gaming_and_online_streaming') {
                  <h5 class="label" i18n="Hobby label@@GAMING_ONLINE_STREAMING">Gaming and online streaming</h5>
                }
                @case ('interest_in_science_and_innovation') {
                  <h5 class="label" i18n="Hobby label@@SCIENCE_AND_INNOVATION">Interest in science and innovation</h5>
                }
                @default {
                  <h5 class="label" i18n="Hobby label@@REPAIRS_AND_HACKS">Repairs and hacks</h5>
                  <p class="sub-label" i18n="Hobby sub-abel@@REPAIRS_AND_HACKS_SUBLABEL">(device repairs, smart home setups)</p>    
                }
              }
            </button>
          }
        }
      </div>
  
      <h4 i18n="Category of hobbies in the hobbies selection form@@HOBBIES_WELL_BEING">Well-being & Personal Development</h4>
      <div class="hobbies-list">
        @for(hobby of hobbiesWithSelection; track hobby){
          @if(hobby.category === 'personal'){
            <button type="button" class="hobby-btn" (click)="clickHobby(hobby)" [class.selected]="hobby.selected">
              @switch (hobby.name) {
                @case ('meditation_and_relaxation_practices') {
                  <h5 class="label" i18n="Hobby label@@MEDITATION">Meditation and relaxation practices</h5>
                }
                @case ('self_improvement') {
                  <h5 class="label" i18n="Hobby label@@SELF_IMPROVEMENT">Self improvement</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@SELF_IMPROVEMENT_SUBLABEL">(reading, podcasts, coaching)</p>    
                }
                @case ('health_and_wellness') {
                  <h5 class="label" i18n="Hobby label@@HEALTH_AND_WELLNESS">Health and wellness</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@HEALTH_AND_WELLNESS_SUBLABEL">(nutrition, fitness, self-care)</p>    
                }
                @case ('skill_development') {
                  <h5 class="label" i18n="Hobby label@@SKILL_DEVELOPMENT">Skill development</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@SKILL_DEVELOPMENT_SUBLABEL">(arts, languages, communication)</p>    
                }
                @case ('creating_positive_routines_and_daily_wellness') {
                  <h5 class="label" i18n="Hobby label@@POSITIVE_ROUTINES">Creating positive routines and daily wellness</h5>
                }
                @default {
                  <h5 class="label" i18n="Hobby label@@RELAXING_OUTDOOR">Relaxing outdoor activities</h5>
                  <p class="sub-label" i18n="Hobby sub-label@@RELAXING_OUTDOOR_SUBLABEL">(jogging, walking, yoga)</p>    
                }
              }
            </button>
          }
        }
      </div>
    }
  </section>

  <app-top-button (scrollRequest)="getScrollRequest($event)"></app-top-button>
</section>