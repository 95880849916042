<div class="color-palette">
    <p class="instruction">Select the color of your text</p>
    
    <div class="colors">
        @for(color of colors; track color; let i = $index){
            <button
                type="button"
                (click)="setTextColor(color.hex, color.type, i)"
                [class]="color.name"
                class="color-dot"
                [ngStyle]="{ background: color.hex }"
            >
                <div class="selected" [ngStyle]="{ backgroundColor: color.selected ? color.hex : 'transparent' }"></div>
            </button>
        }
    </div>
</div>