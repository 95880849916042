import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AppService } from './app.service';

import { Gender } from './shared/enum/Gender';
import { Criteria } from './shared/interfaces/criteria';

export interface GetCriteriaResponse {
  code: number,
  criterias: Criteria,
  moderation: { firstname: boolean, photo: boolean },
  user:{ boosters: number }
}
@Injectable({
  providedIn: 'root'
})
export class CriteriaService {
  constructor(
    private http: HttpClient,
    private appService: AppService,
  ) { }

  getCriteria(): Observable<Criteria>{
    return this.http.get<GetCriteriaResponse>(`${this.appService.API}/api/criterias`).pipe(
      map(res => {
        return res.criterias
      })
    )
  }

  setCriteria(data: {attractionCtrl: Gender.man | Gender.woman, minAgeCtrl: number, maxAgeCtrl: number, locationCtrl: number, distanceCtrl: number}): Observable<{code: number} | object> {
    const body = {
        age_start: data.minAgeCtrl,
        age_end: data.maxAgeCtrl,
        distance: data.distanceCtrl,
        sex: data.attractionCtrl,
        city_id: data.locationCtrl,
        type: '["event","photo"]'
    }

    return this.http.post(`${this.appService.API}/api/criterias`, body).pipe(
      map(res => {
        return res
      })
    )
  }
}
