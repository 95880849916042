import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';

@Component({
  selector: 'app-likers-dialog',
  standalone: true,
  imports: [],
  templateUrl: './likers-dialog.component.html',
  styleUrl: './likers-dialog.component.scss'
})
export class LikersDialogComponent {
  // create reference to dialog and pass the data type to pass to parent component when dialog is closed (boolean) to trigger save or cancel
  dialogRef = inject<DialogRef>(DialogRef<string>);
  likers = inject(DIALOG_DATA);
}
