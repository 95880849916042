<div class="match-screen">
  <img src="assets/icons/icon_match.svg" alt="" class="match-icon">
  <img src="assets/icons/icon_match.svg" alt="" class="match-icon">
  <img src="assets/icons/icon_match.svg" alt="" class="match-icon">
  <img src="assets/icons/icon_match.svg" alt="" class="match-icon">

  <h1 i18n="Title for the match dialog@@MATCH">It's a <span class="match">Match!</span></h1>
  <p class="description" i18n="Description for the match dialog@@LIKED_EACH_OTHER">You and {{matchProfile?.firstname}} have liked each other!</p>
  
  <div class="photo-container">
    <img [src]="matchProfile?.main_photo?.url || matchProfile?.photos![0].url" alt="">
  </div>
  <div class="info">
    <h2>{{matchProfile?.firstname}}</h2>
    <p class="age-city">{{userAge}} <ng-container i18n="Age unit@@YEARS">years</ng-container> - {{matchProfile?.city?.name || matchProfile?.city}}</p>
    <div class="attraction">
      <img src="assets/icons/icon_searching_for.svg" alt="" class="search-icon">
      @if(matchProfile?.attraction === 'man'){
        <p class="genre" i18n="Text for the genre the user is attracted to@@MEN">Men</p>
      } @else {
        <p class="genre" i18n="Text for the genre the user is attracted to@@WOMEN">Women</p>
      }
    </div>
    
  </div>
  <button type="button" class="submit" (click)="dialogRef.close(true)" i18n="Text for the button to send a message in the match dialog@@SEND_MESSAGE">Send a message</button>
  <button type="button" class="text-link" (click)="dialogRef.close()" i18n="Text for the button to exit and keep using swipi in the match dialog@@KEEP_USE">Keep using Swipi</button>
</div>