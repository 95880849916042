<div class="user-tiny" >
  <div class="content" [class.border]="new && newMessages">
      @if(origin === 'conversation'){
        <app-go-back-button (click)="goBack()"></app-go-back-button>
      }

      <div class="photo-container" [class.logged-in]="loggedIn">
        <button type="button" class="user-tiny-photo" (click)="viewProfile(data?.user?.id)">
          @if(data?.user?.photo && data?.user?.photo !== ''){
            <img [src]="data?.user?.photo" alt=""/>
          } @else {
            <div class="no-photo">
              <img src="assets/icons/icon_user_96X96.png" alt="">
            </div>
          }
        </button>
      </div>
  
      <button type="button" class="user-tiny-data" (click)="openTalk(data)" [ngStyle]="{'cursor': origin === 'chat' ? 'pointer' : 'unset'}">
        <h5 class="user-tiny-data-name">
          @if(user.value){
            <span>{{ user.value.firstname }}</span>
          } @else {
            <span>{{ data?.user?.firstname }}</span>
          }
        </h5>

        @if(origin === "chat"){
          @if(data?.message){
            <p class="user-tiny-data-message" [innerHTML]="data?.message"></p>
          }
  
          <div class="user-tiny-time-date">
            <span>{{dateExpression}} {{timeExpression}}</span>
          </div>
        }
      </button>
  
      @if(origin === 'chat'){
        @if(new && newMessages > 0){
          <div class="badge">
            {{newMessages}}
          </div>
        }
      } @else {
        <button type="button" class="contextual-menu closed show" #contextualMenuClosed (click)="openConversationDialog($event)">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </button>
      }
    </div>
    <!-- version chat : photo, firstname, début du dernier message, date et heure du dernier message
    version conversation: photo, firstname, menu -->
</div>

