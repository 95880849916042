import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AuthService } from '../../../auth.service';

import { GoBackButtonComponent } from '../../../shared/go-back-button/go-back-button.component';

import { User } from '../../../shared/interfaces/user';
import { emailValidator } from '../../../shared/validators/emailValidator';

@Component({
  selector: 'app-edit-email',
  standalone: true,
  imports: [GoBackButtonComponent, ReactiveFormsModule],
  templateUrl: './edit-email.component.html',
  styleUrl: './edit-email.component.scss'
})
export class EditEmailComponent implements OnInit {
  emailFormGroup!: FormGroup
  user: User | null = null
  routeDataSubscription!: Subscription

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private location: Location
  ){
    this.emailFormGroup = new FormGroup({
      emailCtrl: new FormControl(this.user?.email || '', [Validators.required, Validators.minLength(2), Validators.maxLength(25), emailValidator()]),
    })
  }

  ngOnInit(){
    this.authService.authMe.subscribe(res => {
      this.user = res

      this.emailFormGroup.patchValue({emailCtrl: this.user?.email})
      
      if(!this.user){
        // récupération du user du resolver
        this.routeDataSubscription = this.route.data.subscribe(data => this.user = data['user'])
      }
    })
  }

  goBack(){
    this.location.back()
  }

  onSubmit(){
    const data = this.emailFormGroup.value
    console.log(data)
  }
}
