<dialog class="dialog">
    <header>
        {{title}}
    </header>
    <div class="dialog-content">
        {{content}}
    </div>

    <div class="actions">
        <button class="text-btn" (click)="cancel($event)" i18n="Text for the button to cancel the action in progress@@CANCEL">Cancel</button>
        <button class="submit" (click)="onSubmit()">
            <span class="material-symbols-rounded delete">
                delete
            </span>
            <ng-container i18n="Text for the button to confirm the deletion in a dialog@@YES_DELETE">Yes, delete</ng-container> 
        </button>
    </div>
</dialog>    