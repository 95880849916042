import { Component, Input } from '@angular/core';

import { Talk } from '../../shared/interfaces/talk';
import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-talk',
  standalone: true,
  imports: [],
  templateUrl: './talk.component.html',
  styleUrl: './talk.component.scss'
})
export class TalkComponent {
  @Input() talk!: Talk

  relatedUser: User = {
    id: 1,
    firstname: 'Marguerite',
    birthdate: '05-07-1982',
    photo: '../../assets/rabbit-close-up.jpg',
    sex: 'woman',
    city: null
  }
}
