import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';

import { ChatService } from './chat.service';
import { UserTinyService } from './user-tiny/user-tiny.service';
import { LayoutService } from '../layout.service';

import { UserTinyComponent } from './user-tiny/user-tiny.component';

import { Talk } from '../shared/interfaces/talk';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [RouterModule, UserTinyComponent, MatSidenav, MatSidenavModule, CommonModule],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild('conversationSidenav') conversationSidenav!: MatSidenav

  showConversation = false;
  talks: Talk[] = [];
  loading = true;
  talkUserId: number | null = null;
  talk!: Talk | undefined
  conversationSidenavToggled = false
  isDesktop = this.layoutService.isDesktop

  private readonly _destroyed = new Subject<void>()

  constructor(
    private chatService: ChatService,
    private userTinyService: UserTinyService,
    private layoutService: LayoutService,
  ) {}

  ngOnInit() {
    this.chatService.getConversations().pipe(
      takeUntil(this._destroyed)
    ).subscribe((res) => {
      this.talks = res
      this.loading = false
      
      // ne garder que les conversations qui comportent au moins un message
      const talkWithMessages = this.talks.filter(t => t.last_message !== null)
      talkWithMessages.map((t) => {
        const text =
          t.last_message && t.last_message.content
            ? decodeURIComponent(t.last_message.content).slice(0, 18) + '...'
            : null

        t.myMessage = t.last_message ? t.last_message.user_id !== t.user.id : false
        t.userDatas = {
          user: {
            firstname: t.user.firstname,
            type: 'friend',
            id: t.user.id,
            gendre: t.user.sex,
            photo: t.user.photo,
          },
          message: text,
          photos: t.last_message ? !!t.last_message.photos : false,
          time: t.last_message ? t.last_message.created_at : null,
          talking: t.id,
        };
      });

      this.talks = talkWithMessages

      if(this.isDesktop()) {
        this.conversationSidenav.open()
        this.chatService.toggleSidenav.next(true)
      }

      this.chatService.toggleSidenav.pipe(
        takeUntil(this._destroyed)
      ).subscribe((res: boolean) => {
        this.conversationSidenavToggled = res

        if(this.conversationSidenav) {
          if(res){
            this.conversationSidenav.open()
          } else {
            this.conversationSidenav.close()
          }
        }
      })
    });
    
    this.chatService.getShowConversation().pipe(
      takeUntil(this._destroyed)
    ).subscribe((res) => {
      this.loading = !!res
      this.showConversation = !!res
      
      if(res === 0) this.showConversation = true
      this.talkUserId = res
    });

    this.userTinyService.success.pipe(
      takeUntil(this._destroyed)
    ).subscribe((res) => {
      if(res.type === 'accept') {
        const silent = this.talks.find((t) => t.user.id === res.user)

        if(!silent) return
        silent.warning_silent_talking = false
      }

      if(res.type === 'delete') {
        this.talks = this.talks.filter((t) => t.user.id !== res.user)
      }
    });
  }

  chatShow() {
    this.showConversation = true;
  }

  openTalk(talk: Talk){
    this.talk = talk
    this.setAsRead(talk)
    this.chatService.toggleSidenav.next(true)
    this.chatService.relatedUser.next(this.talk.userDatas)
  }

  setAsRead(talk: Talk): void {
    if(!talk.readed) {
      talk.readed = true;
      this.chatService.setAsRead(talk.id, talk.last_message.id).pipe(
        takeUntil(this._destroyed)
      ).subscribe();
    }
  }

  /* getTalkSelected(e: number | null | undefined) {
    this.talkId = e

    this.talk = this.talks.find(t => t.id === this.talkId)

    this.conversationSidenav.open()
    this.showConversation = true
  } */

  // onSwipeLeft(user) {
  //   user.actions = true;
  // }

  // onSwipeRight(user) {
  //   user.actions = false;
  // }

  // showHideHeader($event) {
  //   this.hideHeader = $event;
  // }

  ngOnDestroy(){
    this._destroyed.next();
    this._destroyed.complete();    
  }
}
