<section class="my-account">
  @if(loading$ | async){
    <app-loader></app-loader>
  }

  <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>  

  <router-outlet></router-outlet>

  <div class="top" #top>
    <h3 i18n="Title for the account management section in My Space section@@MY_ACCOUNT"><span class="regular">My</span> Account</h3>
  </div>

  <div class="content">
    <div class="item">
      <div class="header">
        <h4 i18n="Title for the user's email on the account management page in My Space section@@MY_EMAIL">My Email</h4>
        <button type="button" class="edit-btn" [routerLink]="['edit-email']">
          <img src="../../../assets/icons/icon_edit.svg" alt="" class="icon-edit">
        </button>
      </div>

      <div class="input">
        {{user?.email}}
      </div>
    </div>
    
    <div class="item">
      <div class="header">
        <h4 i18n="Title for the user's password on the account management page in My Space section@@MY_PASSWORD">My Password</h4>
        <button type="button" class="edit-btn" [routerLink]="['edit-password']">
          <img src="../../../assets/icons/icon_edit.svg" alt="" class="icon-edit">
        </button>
      </div>

      <input type="password" value="password">
    </div>

    <div class="bottom">
      <button type="button" class="text-link" [routerLink]="['delete']" i18n="Text for the link to access account deletion form on the account management page in My Space section@@DELETE_ACCOUNT">Delete my account</button>
    </div>
  </div>
</section>
  