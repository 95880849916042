import { AfterContentInit, AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

import { AppService } from '../../app.service';
import { StoriesService } from '../../stories.service';
import { HomeService } from '../../home/home.service';

import { LoaderComponent } from '../../shared/loader/loader.component';
import { PaginatorComponent } from '../../shared/paginator/paginator.component';
import { FooterComponent } from '../../footer/footer.component';
import { TopButtonComponent } from '../../shared/top-button/top-button.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { Story } from '../../shared/interfaces/story';

@Component({
  selector: 'app-my-stories',
  standalone: true,
  imports: [LoaderComponent, PaginatorComponent, FooterComponent, TopButtonComponent, GoBackButtonComponent, CommonModule],
  templateUrl: './my-stories.component.html',
  styleUrl: './my-stories.component.scss'
})
export class MyStoriesComponent implements OnInit, AfterContentInit, AfterViewChecked, OnDestroy {
  @ViewChild('top') top!: ElementRef
  @ViewChild('storiesContainer') storiesContainer!: ElementRef
   
  loading = false 
  stories: Story[] = []
  currentPage = 1
  totalPages = 1
  loaderSubscription!: Subscription
  desktopDevice = false
  viewportHeight!: number
  viewportWidth!: number
  storyWidth!: number

  constructor(
    private appService: AppService,
    private storiesService: StoriesService,
    private location: Location,
    private homeService: HomeService
  ){}

  ngOnInit(){
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }
    this.desktopDevice = this.appService.desktopDevice
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    // this.storiesService.getStoriesByUser(1, 10).subscribe(res => {
    //   this.stories = res
    // })
    
    this.storiesService.getFakeStories().subscribe(res => {
      this.stories = res
    })
  }

  ngAfterViewChecked(){
    if(!this.desktopDevice) return
    if(!this.storiesContainer) return

    const bounds = this.storiesContainer.nativeElement.getBoundingClientRect()
    const storiesContainerWidth = Number.parseFloat((bounds.right - bounds.left).toFixed(2))
    const gapWidth = 16
    const numberOfItemsPerRow = 3

    this.storyWidth = Math.floor((storiesContainerWidth - (numberOfItemsPerRow + 1) * gapWidth) / numberOfItemsPerRow)
  }

  ngAfterContentInit(){
    if(this.desktopDevice) return

    this.viewportHeight = window.innerHeight
    this.viewportWidth = window.innerWidth
    const gapWidth = 16
    const numberOfItemsPerRow = 2

    this.storyWidth = Math.floor((this.viewportWidth - (numberOfItemsPerRow + 1) * gapWidth) / numberOfItemsPerRow)
    console.log(this.storyWidth)
  }

  goBack(){
    if(this.desktopDevice) {
      this.homeService.pageDisplayed.next('')
      this.location.back()
    } else {
      this.homeService.pageDisplayed.next('')
    }
  }

  getStyles(){
    return {
      width: `${this.storyWidth}px`,
      height: `${this.storyWidth * 1.4}px`
    }
  }

  getScrollRequest(request: string){
    if(request === 'scrollToTop') this.top.nativeElement.scrollIntoView()
  }

  requestStories($event: 'previous' | 'next'){
    if(!$event) return

    if(this.currentPage === 1 && $event === 'previous') return

    const page = $event === 'next' ? this.currentPage + 1 : this.currentPage - 1 
    
    this.storiesService.getStoriesByUser(page, 9).subscribe({
      next: (res => {
        this.stories = res
        console.log(res)
        this.currentPage = page
        //this.totalPages = res.total + 1 // res.total commence à 0 
      }), 
      error: (err => {
        if(err.error.message){
          // this.errorTitle = "Login details are incorrect"
          // this.errorMessage = "Please check and re-enter your data."
          // this.openDialog = true
        }

        this.appService.setLoader(false)
      })
    })
  }

  deleteStory(id: number | null){
    console.log('deleteStory')

    if(!id) return
    this.storiesService.deleteStory('story', id).subscribe(() => {
      // this.storiesService.getStoriesByUser(1, 10).subscribe(res => {
      //   this.stories = res 
      // })

      const storyToDelete = this.stories.filter(s => s.id === id)
      const index = this.stories.indexOf(storyToDelete[0])

      this.stories.splice(index, 1)
    })
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
