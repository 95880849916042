<div class="my-space">
    <section class="main">
      <div class="top">
        <h3><span class="regular" i18n="Title for My Space section written at the top of My Space menu@@MY_SPACE">My</span> Space</h3>
      </div>

      <div class="center">
          <div class="navbar">
              <div class="manage-profile">
                  <h4 i18n="Title for the profile management section in My Space menu@@MANAGE_MY_PROFILE">Manage my Profile</h4>
                  <nav class="menu">
                      <li [class.selected]="pageDisplayed === 'my-profile'">
                        <button type="button" [routerLink]="['my-profile']" (click)="displayPage('my-profile')">
                          <img src="../../assets/icons/icon_myspace.svg" alt="" class="icon">
                          <ng-container i18n="Text for My Profile item in My Space menu@@MY_PROFILE">
                            My Profile
                          </ng-container>
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'my-stories'">
                        <button type="button" [routerLink]="['my-stories']" (click)="displayPage('my-stories')">
                          <img src="../../assets/icons/icon_stories.svg" alt="" class="icon stories"> 
                          <ng-container i18n="Text for My Stories item in My Space menu@@MY_STORIES">
                            My Stories
                          </ng-container>
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'my-dating-criteria'">
                        <button type="button" [routerLink]="['my-dating-criteria']" (click)="displayPage('my-dating-criteria')">
                          <img src="../../assets/icons/icon_datingcriteria.svg" alt="" class="icon">
                          <ng-container i18n="Text for My Dating Criteria item in My Space menu@@MY_DATING_CRITERIA">
                            My Dating Criteria
                          </ng-container>
                        </button>
                      </li>
                      <!-- <li [class.selected]="pageDisplayed === 'my-coach'">
                        <button type="button" [routerLink]="['my-coach']" (click)="displayPage('my-coach')">
                          <img src="../../assets/icons/icon_coach.svg" alt="" class="icon">
                          My coach
                        </button>
                      </li> -->
                  </nav>
              </div>
  
              <div class="manage-account">
                  <h4 i18n="Title for the account management section in My Space menu@@MANAGE_MY_ACCOUNT">Manage my Account</h4>
                  <nav class="menu">
                      <li [class.selected]="pageDisplayed === 'my-subscription'">
                        <button type="button" [routerLink]="['my-subscription']" (click)="displayPage('my-subscription')">
                          <img src="../../assets/icons/icon_boosters.svg" alt="" class="icon">
                          <ng-container i18n="Text for My Subscription item in My Space menu@@MY_SUB">
                            My Subscription
                          </ng-container>
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'my-account'">
                        <button type="button" [routerLink]="['my-account']" (click)="displayPage('my-account')">
                          <img src="../../assets/icons/icon_myaccount.svg" alt="" class="icon">
                          <ng-container i18n="Text for My Account item in My Space menu@@MY_ACCOUNT">
                            My Account
                          </ng-container>
                        </button>
                      </li>
                  </nav>
              </div>
  
              <div class="tc">
                  <h4 i18n="Title for the terms and conditions section in My Space menu@@T&C">Terms & conditions</h4>
                  <nav class="menu">
                      <li [class.selected]="pageDisplayed === 'info-service'">
                        <button type="button" [routerLink]="['info-service']" (click)="displayPage('info-service')">
                          <ng-container i18n="Text for Info Service item in My Space menu@@INFO_SERVICE">
                            Info Service
                          </ng-container>
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'contact'">
                        <button type="button" [routerLink]="['contact']" (click)="displayPage('contact')">
                          <ng-container i18n="Text for Contact item in My Space menu@@CONTACT">
                            Contact
                          </ng-container>
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'terms'">
                        <button type="button" [routerLink]="['terms']" (click)="displayPage('terms')">
                          <ng-container i18n="Text for Terms item in My Space menu@@TERMS">
                            Terms
                          </ng-container>
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'legal'">
                        <button type="button" [routerLink]="['legal']" (click)="displayPage('legal')">
                          <ng-container i18n="Text for Legal item in My Space menu@@LEGAL">
                            Legal
                          </ng-container>
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'personal-data'">
                        <button type="button" [routerLink]="['personal-data']" (click)="displayPage('personal-data')">
                          <ng-container i18n="Text for Personal Data item in My Space menu@@PERSONAL_DATA">
                            Personal Data
                          </ng-container>
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'cookies'">
                        <button type="button" [routerLink]="['cookies']" (click)="displayPage('cookies')">
                          <ng-container i18n="Text for Cookies item in My Space menu@@COOKIES">
                            Cookies
                          </ng-container>
                        </button>
                      </li>
                      <li class="logout">
                        <button type="button" class="logout-btn" (click)="logOut()">
                          <ng-container i18n="Text for Logout button used to exit the service in My Space menu@@LOG_OUT">
                            Log out
                          </ng-container>
                        </button>
                      </li>
                  </nav>
              </div>
          </div>
          
          <div class="content" [ngStyle]="{display: pageDisplayed === '' ? 'none' : 'flex'}">
            <router-outlet></router-outlet>
          </div>
      </div>
    </section>
</div>