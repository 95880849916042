<h3 i18n="Title for the report user dialog@@TEXT_BLOCK_REPORT_USER">Are you sure you wish to block this user?</h3>

<p class="description" i18n="Description for the report user dialog@@TEXT2_BLOCK_REPORT_USER">This person will no longer be able to view your profile, contact you and be monitored by our moderation teams.</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()" i18n="Text for the button to cancel the action in progress@@CANCEL">Cancel</button>
  <button class="submit" (click)="dialogRef.close(true)">
    <img src="assets/icons/icon_report_block.svg" alt="" class="report-icon">
    <ng-container i18n="Text for the button to confirm user blocking and reporting in the report user dialog@@BLOCK_AND_REPORT">Block and report</ng-container> 
  </button>
</div>

