{{ageRange.min}} - {{ageRange.max}} - {{stepWidthInPixels}}
<div class="range-container">
    <div class="sliders-control">
        <input 
            id="fromSlider" 
            type="range" 
            [value]="ageRange.min" 
            min="18" 
            max="99"
            (input)="controlFromSlider($event.target)"
            #fromSlider
        />
        <div class="min-text" [ngStyle]="getMinTextStyle()" #minText>{{ageRange.min}}</div>
        <!-- <div class="min-text" [style.left]="(((minValue - 18) * 100) / (99 - 18)) + '%'" #minText>{{minValue}}</div> -->
        <input 
            id="toSlider" 
            type="range" 
            [value]="ageRange.max" 
            min="18" 
            max="99"
            (input)="controlToSlider($event.target)"
            #toSlider
        />
        <div class="max-text" [ngStyle]="getMaxTextStyle()" #maxText>{{ageRange.max}}</div>
        <!-- <div class="max-text" [style.left]="(((maxValue - 18) * 100) / (99 - 18)) + '%'" #maxText>{{maxValue}}</div> -->
    </div>
</div>
