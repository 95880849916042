<div class="info-dialog" [ngClass]="[infoDialogType ==='contactProfile' ? 'contact-profile' : 'like-profile']">
    <button type="button" class="close-btn" (click)="closeDialog()">
      <span class="material-symbols-rounded close">
        close
      </span>
    </button>  
    
    <div class="icon-container">
        <img [src]="iconSrc" alt="" class="icon">
    </div>
    
    <h3>{{title}}</h3>
    <p class="description">{{description}}</p>
    <button class="submit neutral" [ngClass]="[infoDialogType ==='contactProfile' ? 'contact-profile' : 'like-profile']">
        {{buttonText}} 
        <img [src]="iconSrc" alt="" class="icon">
    </button>
</div>
