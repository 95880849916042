@if(loadingConversation){
  <app-loader></app-loader>
}

@if(talkingId || isNew){ <!-- || talkingId === 0 -->
  <div class="dialog-user">
    <div class="dialog-user-left">
      <app-user-tiny
        [data]="userData | async"
        [origin]="'conversation'"
        [link]="'/home/my-matches/chat/conversation/' + userData.value?.user?.id"
      ></app-user-tiny><!-- [relatedUser]="relatedUser" -->
      <!-- [direction]="'vertical'"
        [feed]="false" -->
    </div>

    <!-- @if(silentTalkingReceived){
      <div
        class="button-show-profile"
      > [routerLink]="'/service/profil/' + userDatas?.user?.id" 
        voir le profil
      </div>
    } -->
  </div>

  <div class="dialog-text" #dialog [scrollTop]="dialog.scrollHeight"><!--  [class.subscribe]="notSubscribe" -->
    @if(!isNew || isNew){
      <div
        class="messages"
        #messagesHolder
        (scroll)="onTalkScroll($event)"
      >
        <!-- <div class="tiny-loader" [class.show]="tinyLoader">
          <div class="tiny-loader-bar"></div>
          <div class="tiny-loader-text">Loading</div>
        </div> -->

        @if(messages.length > 0){
          @for(message of messages; track message; let index = $index){
            @if(message.date_expression !== ''){
              <div class="date">
                @if(index > 0){
                  @if(message.date_expression && messages[index -1].date_expression && (message.date_expression !== messages[index -1].date_expression)){
                    <div class="line"></div>
                    <div class="expression">
                      {{message.date_expression}}
                    </div>
                    <div class="line"></div>
                  }
                } @else {
                  <div class="line"></div>
                    <div class="expression">
                      {{message.date_expression}}
                    </div>
                    <div class="line"></div>
                }
              </div>
            }

            <div
              class="message"
              [class.related-user]="me?.id !== message.user_id"
            >
              <div class="message-text">
                @if(message.photos){
                  <img
                    [src]="message.photos.url"
                    [class.withText]="message.text"
                    alt=""
                  /><!-- (load)="imageLoaded(message.photos)"
                  (error)="imageError(message.photos)"  -->
                }
  
                @if(message.text){
                  <p [class.withPhoto]="message.photos">{{ message.text }}</p>
                }

                <span class="time">
                  {{message.time_expression}}
                </span>
              </div>

              <ng-template #picture></ng-template>
            </div>
          }
        }
      </div>
    } <!-- @else {
      <div class="messages empty">
        <h2>Start a new conversation</h2>
        <span class="sayhello">Say hello to</span>
        <span class="helloname">{{ user?.firstname }}</span>
        down arrow icon
        <mat-icon svgIcon="downArrow"></mat-icon>
      </div>
    } -->
  </div>

  <div class="dialog-actions" [class.subscribe]="notSubscribe">
    @if(silentTalkingReceived){
      <div class="silent-talking-actions">
        <h4 i18n="Title displayed to introduce a direct message conversation on the recipient's side@@DIRECT_MESSAGE">Direct message</h4>
        @if(user?.attraction === 'man'){
          <span i18n="Text displayed to introduce a direct message conversation on the recipient's side@@CAUGHT_HER_EYE">Looks like you've caught her eye… this user sent you a first message !</span>
        } @else {
          <span i18n="Text displayed to introduce a direct message conversation on the recipient's side@@CAUGHT_HIS_EYE">Looks like you've caught his eye… this user sent you a first message !</span>
        }
        <span i18n="Text displayed to invite the user to accept a direct message or not@@GET_IN_TOUCH">Would you like to get in touch with this person?</span>
        <div class="silent-talking-actions-holder">
          <button (click)="denySilentTalking()">
            <span class="label" i18n="@@NO">No</span>
          </button>
          <button class="ok" (click)="acceptSilentTalking()">
            <span class="label" i18n="@@YES">Yes</span>
          </button>
        </div>
      </div>
    }
    
    @if(messagePhoto){
      <div class="dialog-actions-photo-holder">
        <div class="dialog-actions-photo-img">
          <button type="button" class="dialog-actions-photo-remove" (click)="removeMessagePhoto()">
            <span class="material-symbols-rounded close">close</span>
          </button>
          <img [src]="messagePhoto" alt=""/>
        </div>
      </div>
    }
    
    <div class="dialog-actions-actions">
      @if(!notSubscribe){
        <form
          [formGroup]="messageFormGroup"
          (ngSubmit)="onSubmit()"
        >
          <input
            #photoFileInput
            type="file"
            formControlName="photo"
            accept="image/*"
            (change)="onPhotoInputChange($event)"
          />
          
          <button type="button" class="add-photo-btn" (click)="photoFileInput.click()">
            <img src="assets/icons/icon_gallery.svg" alt="" class="gallery-icon">
          </button>

          <div class="input-holder">
            <div class="input-faketext" #textHolder [class.hide]="sendingMessage"></div>
            <textarea
              class="input-text"
              #textareaHolder
              placeholder="Message"
              i18n-placehoder="Text for the placeholder on the chat conversation text input@@MESSAGE"
              [class.hide]="sendingMessage"
              (keyup)="typing($event)"
              formControlName="text"
              spellcheck="false"
            ></textarea><!-- (focus)="this.isNew = false" -->
            <div class="loader" [class.hide]="!sendingMessage"></div>
            <span class="sending" [class.hide]="!sendingMessage">Sending Message</span>
          </div>

          <button
            type="submit"
            [disabled]="sendingMessage || !hasMessageSet" 
            class="send-btn"
          >
            <img src="assets/icons/icon_send.svg" alt="" class="send-icon">
          </button>
        </form>
      } <!-- @else {
        <div class="subscribing">
          <div class="subscribing-icon">
            warning icon
          </div>
          <h2>You should be premium guest to send messages</h2>
          <div class="subscribing-explaination">
            Follow the link below to become a premium guest. Premium guest can send unlimited
            message for only 24.99 euros per day, it's just a wonderful gift for you to find true
            love with a social network
          </div>
          <button class="subscribing-action" (click)="redirect()">
            Become a premium guest
          </button>
        </div>
      } -->
    </div>
  </div>
} <!-- @else {
  <div class="conversations-current">
    <div class="icon-container">
        <img src="../../assets/icons/icon_chat_with.svg" alt="">
    </div>
    <h3>My conversations</h3>
  </div>
} -->