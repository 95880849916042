import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  standalone: true,
  imports: [],
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.scss'
})
export class PaginatorComponent {
  @Input() currentPage!: number
  @Input() totalPages!: number
  @Output() storiesRequested = new EventEmitter<'previous' | 'next'>()

  constructor(){}
  
  showStories(value: 'previous' | 'next'){
    this.storiesRequested.emit(value)
  }
}
