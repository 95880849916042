import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"

export function firstnameValidator() : ValidatorFn {
    return (control:AbstractControl<string>) : ValidationErrors | null => {
      const value = control.value.trim()
  
      if(!value.length) {
        return { isEmpty : true }
      } else {
        // la string contient un ou plusieurs chiffres
        const numberPattern = /\d/

        if(numberPattern.test(value)){
          return { invalid : true }
        }

        // la string contient un ou plusieurs caractères spéciaux et/ou un ou plusieurs espaces
        const specialCharacterPattern = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/

        if(specialCharacterPattern.test(value)){
          return { invalid : true }
        }
  
        // la string est un firstname valide
        return null
      }
    }
  }