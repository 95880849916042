import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"
import dayjs from 'dayjs';

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl<{birthDayCtrl: string, birthMonthCtrl: string, birthYearCtrl: string}>): ValidationErrors | null => {
    let invalid = false
    const error: {underAged?: boolean; invalidDate?: boolean} = {}
    
    console.log('date validator')
    // seul le jour est renseigné
    if(control.value.birthDayCtrl && (!control.value.birthMonthCtrl || !control.value.birthYearCtrl)) {
      invalid = false
      return invalid ? error: null
    }

    // vérification âge
    if(control.value.birthDayCtrl && control.value.birthMonthCtrl && control.value.birthYearCtrl){
      console.log('verif age')
      const y = dayjs().diff(dayjs(`${control.value.birthYearCtrl}-${control.value.birthMonthCtrl}-${control.value.birthDayCtrl}`), 'year')

      if(y < 18){
        error.underAged = true
        invalid = true
      }
    }
    
    // si l'utilisateur choisit 31
    if(parseInt(control.value.birthDayCtrl) > 30){
      console.log('verif mois')
      const longMonths = ['01', '03', '05', '07', '08', '10', '12']

      if(!longMonths.includes(control.value.birthMonthCtrl.toString())){
        error.invalidDate = true
        invalid = true
      }
    }

    // si l'utilisateur choisit 29 février
    if(parseInt(control.value.birthDayCtrl) > 28 && control.value.birthMonthCtrl === '02'){
      console.log('verif fevrier')
      const dateRequested = new Date(parseInt(control.value.birthYearCtrl), parseInt(control.value.birthMonthCtrl) -1, parseInt(control.value.birthDayCtrl))
      const subStr = `Feb`

      if(!dateRequested.toString().includes(subStr)){
        error.invalidDate = true
        invalid = true
      }
    }
    
    return invalid ? error : null
  }
}