import { Component, AfterViewInit, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { TitleCasePipe, CommonModule } from '@angular/common';
import dayjs from 'dayjs';

import { StoriesService } from '../../stories.service';
import { AppService } from '../../app.service';
import { ProfileService } from '../profile.service';

import { StoryComponent } from '../../story/story.component';

import { User } from '../../shared/interfaces/user';
import { Story } from '../../shared/interfaces/story';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile-stories',
  standalone: true,
  imports: [StoryComponent, TitleCasePipe, CommonModule],
  templateUrl: './profile-stories.component.html',
  styleUrl: './profile-stories.component.scss'
})
export class ProfileStoriesComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('storiesContainer') storiesContainer!: ElementRef

  stories: Story[] = []
  profileId!: string
  profiles!: User[]
  profile!: User | null
  loading = false
  userAge!: number
  desktopDevice = false
  storyWidth!: number
  profileSubscription!: Subscription

  constructor(
    private storiesService: StoriesService,
    private appService: AppService,
    private profileService: ProfileService,
  ){}

  ngOnInit(){
    // TODO : récupérer les stories de l'utilisateur avec la route /api/apistory/user/:user_id/:page/:length 
    
    this.profileSubscription = this.profileService.profile.subscribe(res => {
      this.profile = res
    
      if(!this.profile) return
      if(this.profile.birthdate){
        const [y,m,d] = this.profile.birthdate.split('-')
        const date = `${y}-${m}-${d}`
        const birthDate = dayjs(date)
  
        this.userAge = dayjs().diff(birthDate, 'year')
      }
  
      this.storiesService.getStoriesByUser(1, 9, this.profile.id).subscribe(res => this.stories = res)
    })

    // A supprimer : Récupération des fake stories pour les tests
    // this.storiesService.getFakeStories().subscribe(res =>  {
    //   this.stories = res
    // })   
  }

  ngAfterViewInit(){
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }
    this.desktopDevice = this.appService.desktopDevice

    const bounds = this.storiesContainer.nativeElement.getBoundingClientRect()
    const storiesContainerWidth = bounds.right - bounds.left
    const gapWidth = 10
    let numberOfItemsPerRow = 2

    if(this.desktopDevice) numberOfItemsPerRow = 4

    this.storyWidth = (storiesContainerWidth - (numberOfItemsPerRow - 1) * gapWidth) / numberOfItemsPerRow
  }

  getStyles(){
    return {
      'width': this.storyWidth + 'px',
      'height': `${this.storyWidth * 1.4}px`,
    }
  }

  ngOnDestroy(){
    this.profileSubscription.unsubscribe()
  }
}
