<app-go-back-button class="edit-go-back-btn" (click)="goBack()"></app-go-back-button>  

<section class="edit-password">
  <div class="top" #top>
    <h3><span class="regular" i18n="Title for the password modification form in My Space section@@PASSWORD_MODIFICATION">Password</span> modification</h3>
  </div>

  @if(passwordUpdated){
    <div class="success-message">
      <p i18n="Success message for the password edition form in My Space section@@PASSWORD_CHANGE_OK">Your password has been successfully changed</p>
    </div>
  }

  <form action="" [formGroup]="passwordFormGroup" (submit)="onSubmit()">
    <h3>Current Password</h3>
    <div class="input-holder">
      <button type="button" class="password-btn" (click)="showHidePassword()" [class.showHide]="!showPassword">
        <span class="material-symbols-outlined password-icon">
          visibility
        </span>
      </button>
      <button type="button" class="password-btn" (click)="showHidePassword()" [class.showHide]="showPassword">
        <span class="material-symbols-outlined password-icon">
          visibility_off
        </span>
      </button>

      <input
        id="current-password"
        formControlName="currentPasswordCtrl"
        placeholder="Enter your current password"
        i18n-placeholder="Text for current password input placeholder in the password edition form@@ENTER_CURRENT_PASSWORD"
        autocapitalize="none"
        #inputPassword
        [type]="showPassword ? 'text': 'password'"
        (input)="onInput($event.target, 'currentPasswordCtrl')"
      />
    </div>

    <h3>New Password</h3>
    <div class="input-holder new">
      <button type="button" class="password-btn" [class.showHide]="!showPassword" (click)="showHidePassword()">
        <span class="material-symbols-outlined password-icon">
          visibility
        </span>
      </button>
      <button type="button" class="password-btn" [class.showHide]="showPassword" (click)="showHidePassword()">
        <span class="material-symbols-outlined password-icon">
          visibility_off
        </span>
      </button>

      <input
        id="new-password"
        formControlName="newPasswordCtrl"
        placeholder="Enter your new password"
        i18n-placeholder="Text for new password input placeholder in the password edition form@@ENTER_NEW_PASSWORD"
        autocapitalize="none"
        #inputPassword
        [type]="showPassword ? 'text': 'password'"
        (input)="onInput($event.target, 'newPasswordCtrl')"
      />
      <p class="tip" i18n="Text for the new password input constraints in the password edition form@@PASSWORD_CONTAIN_CHAR">Your password must contain between {{passwordMinCharNumber}} and {{passwordMaxCharNumber}} characters.</p>

      @if(passwordFormGroup.errors && passwordFormGroup.errors['sameAsCurrent']){
        <p class="warning">Your new password must differ from your old one.</p>
      }
    </div>
    
    <h3>Confirm New Password</h3>
    <div class="input-holder">
      <button type="button" class="password-btn" [class.showHide]="!showPassword" (click)="showHidePassword()">
        <span class="material-symbols-outlined password-icon">
          visibility
        </span>
      </button>
      <button type="button" class="password-btn" [class.showHide]="showPassword" (click)="showHidePassword()">
        <span class="material-symbols-outlined password-icon">
          visibility_off
        </span>
      </button>

      <input
        id="confirmed-new-password"
        formControlName="confirmedNewPasswordCtrl"
        placeholder="Confirm your new password"
        i18n-placeholder="Text for confirm new password input placeholder in the password edition form@@CONFIRM_YOUR_PASSWORD"
        autocapitalize="none"
        #inputPassword
        [type]="showPassword ? 'text': 'password'"
        (input)="onInput($event.target, 'confirmedNewPasswordCtrl')"
      />
    </div>

    @if(passwordFormGroup.errors && passwordFormGroup.errors['notConfirmed']){
      <p class="warning" i18n="Error message for new password input and confirmation input not matching in the password edition form@@ERROR_PASSWORD_DO_NOT_MATCH">ERROR. The passwords you have entered do not match. Please check your information.</p>
    }
   
    <button
      class="submit"
      type="submit"
      [disabled]="passwordFormGroup.invalid"
      i18n="Text for the button to validate the new password in the password edition form@@VALIDATE_NEW_PASSWORD"
    ><!-- [class.wrong]="wrongCredential" [disabled]="authFormGroup.invalid || error"-->
      Validate new password
    </button>
  </form>
</section>
    