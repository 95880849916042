/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import { Client, generateClient, GraphQLResult } from "aws-amplify/api";
import { Observable } from "rxjs";

export type __SubscriptionContainer = {
  addedContent: AddedContentSubscription;
  updatedContent: UpdatedContentSubscription;
  removedContent: RemovedContentSubscription;
};

export enum Product {
  woozgo = "woozgo",
  weezchat_fr = "weezchat_fr"
}

// Define usefull enums
export enum Type {
  photo = "photo",
  story = "story",
  event = "event",
  externallink = "externallink"
}

export type PlaceInput = {
  city_id?: number | null;
  city_name?: string | null;
  region_id?: number | null;
  region_name?: string | null;
  country_id?: number | null;
  country_name?: string | null;
};

export type UserInput = {
  id?: number | null;
  firstname?: string | null;
  photo?: string | null;
  sex?: Sex | null;
  age?: number | null;
  pin?: PinInput | null;
  country_id?: number | null;
  premium?: string | null;
};

export enum Sex {
  man = "man",
  woman = "woman"
}

export type PinInput = {
  location?: LocationInput | null;
};

export type LocationInput = {
  lat?: number | null;
  lon?: number | null;
};

export type Content = {
  __typename: "Content";
  item?: Item | null;
};

export type Item = {
  __typename: "Item";
  id: string;
  data?: Data | null;
};

export type Data = {
  __typename: "Data";
  content_id?: number | null;
  type?: string | null;
  views?: number | null;
  already_seen?: Array<number | null> | null;
  likes?: number | null;
  liked?: Array<number | null> | null;
  hashtags?: Array<string | null> | null;
  photos?: Array<string | null> | null;
  user?: User | null;
  reported?: Array<number | null> | null;
  created_at?: string | null;
  name?: string | null;
  description?: string | null;
  date?: string | null;
  place?: Place | null;
  link?: string | null;
};

export type User = {
  __typename: "User";
  id?: number | null;
  firstname?: string | null;
  photo?: string | null;
  sex?: string | null;
  age?: number | null;
  country_id?: number | null;
  premium?: string | null;
};

export type Place = {
  __typename: "Place";
  city_id?: number | null;
  city_name?: string | null;
  region_id?: number | null;
  region_name?: string | null;
  country_id?: number | null;
  country_name?: string | null;
};

export type UContent = {
  __typename: "UContent";
  item?: UItem | null;
};

export type UItem = {
  __typename: "UItem";
  id: string;
  content_id?: number | null;
  data?: UData | null;
  document?: Data | null;
};

export type UData = {
  __typename: "UData";
  hashtags?: string | null;
  liked?: number | null;
  already_seen?: number | null;
  reported?: number | null;
  blocked_relationships?: number | null;
  photos?: string | null;
  disliked?: number | null;
  views?: number | null;
  likes?: number | null;
  photo?: string | null;
  firstname?: string | null;
  sex?: Sex | null;
  age?: number | null;
  pin?: Location | null;
  country_id?: number | null;
  premium?: string | null;
  name?: string | null;
  description?: string | null;
  date?: string | null;
  place?: Place | null;
  link?: string | null;
};

export type Location = {
  __typename: "Location";
  lat?: number | null;
  lon?: number | null;
};

export type RContent = {
  __typename: "RContent";
  content_id?: number | null;
  deleted?: boolean | null;
};

export type AddContentMutation = {
  __typename: "Content";
  item?: {
    __typename: "Item";
    id: string;
    data?: {
      __typename: "Data";
      content_id?: number | null;
      type?: string | null;
      views?: number | null;
      already_seen?: Array<number | null> | null;
      likes?: number | null;
      liked?: Array<number | null> | null;
      hashtags?: Array<string | null> | null;
      photos?: Array<string | null> | null;
      user?: {
        __typename: "User";
        id?: number | null;
        firstname?: string | null;
        photo?: string | null;
        sex?: string | null;
        age?: number | null;
        country_id?: number | null;
        premium?: string | null;
      } | null;
      reported?: Array<number | null> | null;
      created_at?: string | null;
      name?: string | null;
      description?: string | null;
      date?: string | null;
      place?: {
        __typename: "Place";
        city_id?: number | null;
        city_name?: string | null;
        region_id?: number | null;
        region_name?: string | null;
        country_id?: number | null;
        country_name?: string | null;
      } | null;
      link?: string | null;
    } | null;
  } | null;
};

export type UpdateContentMutation = {
  __typename: "UContent";
  item?: {
    __typename: "UItem";
    id: string;
    content_id?: number | null;
    data?: {
      __typename: "UData";
      hashtags?: string | null;
      liked?: number | null;
      already_seen?: number | null;
      reported?: number | null;
      blocked_relationships?: number | null;
      photos?: string | null;
      disliked?: number | null;
      views?: boolean | null;
      likes?: boolean | null;
      photo?: string | null;
      firstname?: string | null;
      sex?: Sex | null;
      age?: number | null;
      pin?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      country_id?: number | null;
      premium?: string | null;
      name?: string | null;
      description?: string | null;
      date?: string | null;
      place?: {
        __typename: "Place";
        city_id?: number | null;
        city_name?: string | null;
        region_id?: number | null;
        region_name?: string | null;
        country_id?: number | null;
        country_name?: string | null;
      } | null;
      link?: string | null;
    } | null;
  } | null;
};

export type RemoveContentMutation = {
  __typename: "RContent";
  content_id?: number | null;
  deleted?: boolean | null;
};

export type GetContentsQuery = {
  __typename: "Content";
  item?: {
    __typename: "Item";
    id: string;
    data?: {
      __typename: "Data";
      content_id?: number | null;
      type?: string | null;
      views?: number | null;
      already_seen?: Array<number | null> | null;
      likes?: number | null;
      liked?: Array<number | null> | null;
      photos?: Array<string | null> | null;
      user?: {
        __typename: "User";
        id?: number | null;
        firstname?: string | null;
        photo?: string | null;
        sex?: string | null;
        age?: number | null;
        country_id?: number | null;
        premium?: string | null;
      } | null;
      reported?: Array<number | null> | null;
      created_at?: string | null;
    } | null;
  } | null;
};

export type GetContentByIdQuery = {
  __typename: "Content";
  item?: {
    __typename: "Item";
    id: string;
    data?: {
      __typename: "Data";
      content_id?: number | null;
      type?: string | null;
      views?: number | null;
      already_seen?: Array<number | null> | null;
      likes?: number | null;
      liked?: Array<number | null> | null;
      hashtags?: Array<string | null> | null;
      photos?: Array<string | null> | null;
      user?: {
        __typename: "User";
        id?: number | null;
        firstname?: string | null;
        photo?: string | null;
        sex?: string | null;
        age?: number | null;
        country_id?: number | null;
        premium?: string | null;
      } | null;
      reported?: Array<number | null> | null;
      created_at?: string | null;
      name?: string | null;
      description?: string | null;
      date?: string | null;
      place?: {
        __typename: "Place";
        city_id?: number | null;
        city_name?: string | null;
        region_id?: number | null;
        region_name?: string | null;
        country_id?: number | null;
        country_name?: string | null;
      } | null;
      link?: string | null;
    } | null;
  } | null;
};

export type AddedContentSubscription = {
  __typename: "Content";
  item?: {
    __typename: "Item";
    id: string;
    data?: {
      __typename: "Data";
      content_id?: number | null;
      type?: string | null;
      views?: number | null;
      already_seen?: Array<number | null> | null;
      likes?: number | null;
      liked?: Array<number | null> | null;
      hashtags?: Array<string | null> | null;
      photos?: Array<string | null> | null;
      user?: {
        __typename: "User";
        id?: number | null;
        firstname?: string | null;
        photo?: string | null;
        sex?: string | null;
        age?: number | null;
        country_id?: number | null;
        premium?: string | null;
      } | null;
      reported?: Array<number | null> | null;
      created_at?: string | null;
      name?: string | null;
      description?: string | null;
      date?: string | null;
      place?: {
        __typename: "Place";
        city_id?: number | null;
        city_name?: string | null;
        region_id?: number | null;
        region_name?: string | null;
        country_id?: number | null;
        country_name?: string | null;
      } | null;
      link?: string | null;
    } | null;
  } | null;
};

export type UpdatedContentSubscription = {
  __typename: "UContent";
  item?: {
    __typename: "UItem";
    id: string;
    content_id?: number | null;
    /* data?: {
      __typename: "UData";
      hashtags?: string | null;
      liked?: number | null;
      already_seen?: number | null;
      reported?: number | null;
      blocked_relationships?: number | null;
      photos?: string | null;
      disliked?: number | null;
      views?: boolean | null;
      likes?: boolean | null;
      photo?: string | null;
      firstname?: string | null;
      sex?: Sex | null;
      age?: number | null;
      pin?: {
        __typename: "Location";
        lat?: number | null;
        lon?: number | null;
      } | null;
      country_id?: number | null;
      premium?: string | null;
      name?: string | null;
      description?: string | null;
      date?: string | null;
      place?: {
        __typename: "Place";
        city_id?: number | null;
        city_name?: string | null;
        region_id?: number | null;
        region_name?: string | null;
        country_id?: number | null;
        country_name?: string | null;
      } | null;
      link?: string | null;
    } | null; */
    document?: UData | null;
  } | null;
};

export type RemovedContentSubscription = {
  __typename: "RContent";
  content_id?: number | null;
  deleted?: boolean | null;
};

@Injectable({
  providedIn: "root"
})
export class AmplifyService {
  public client: Client;
  constructor() {
    this.client = generateClient() as unknown as Client;
  }
  async AddContent(
    content_id?: number,
    product?: Product,
    type?: Type,
    views?: number,
    likes?: number,
    created_at?: string,
    name?: string,
    description?: string,
    date?: string,
    place?: PlaceInput,
    link?: string,
    photos?: Array<string | null>,
    hashtags?: Array<string | null>,
    liked?: Array<number | null>,
    already_seen?: Array<number | null>,
    reported?: Array<number | null>,
    blocked_relationships?: Array<number | null>,
    user?: UserInput
  ): Promise<AddContentMutation> {
    const statement = `mutation AddContent($content_id: Int, $product: Product, $type: Type, $views: Int, $likes: Int, $created_at: String, $name: String, $description: String, $date: AWSDateTime, $place: PlaceInput, $link: String, $photos: [String], $hashtags: [String], $liked: [Int], $already_seen: [Int], $reported: [Int], $blocked_relationships: [Int], $user: UserInput) {
        addContent(
          content_id: $content_id
          product: $product
          type: $type
          views: $views
          likes: $likes
          created_at: $created_at
          name: $name
          description: $description
          date: $date
          place: $place
          link: $link
          photos: $photos
          hashtags: $hashtags
          liked: $liked
          already_seen: $already_seen
          reported: $reported
          blocked_relationships: $blocked_relationships
          user: $user
        ) {
          __typename
          item {
            __typename
            id
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (content_id) {
      gqlAPIServiceArguments.content_id = content_id;
    }
    if (product) {
      gqlAPIServiceArguments.product = product;
    }
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (views) {
      gqlAPIServiceArguments.views = views;
    }
    if (likes) {
      gqlAPIServiceArguments.likes = likes;
    }
    if (created_at) {
      gqlAPIServiceArguments.created_at = created_at;
    }
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (description) {
      gqlAPIServiceArguments.description = description;
    }
    if (date) {
      gqlAPIServiceArguments.date = date;
    }
    if (place) {
      gqlAPIServiceArguments.place = place;
    }
    if (link) {
      gqlAPIServiceArguments.link = link;
    }
    if (photos) {
      gqlAPIServiceArguments.photos = photos;
    }
    if (hashtags) {
      gqlAPIServiceArguments.hashtags = hashtags;
    }
    if (liked) {
      gqlAPIServiceArguments.liked = liked;
    }
    if (already_seen) {
      gqlAPIServiceArguments.already_seen = already_seen;
    }
    if (reported) {
      gqlAPIServiceArguments.reported = reported;
    }
    if (blocked_relationships) {
      gqlAPIServiceArguments.blocked_relationships = blocked_relationships;
    }
    if (user) {
      gqlAPIServiceArguments.user = user;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <AddContentMutation>response.data.addContent;
  }
  async UpdateContent(
    id: string,
    product?: Product,
    index?: string,
    content_id?: number,
    hashtags?: string,
    liked?: number,
    already_seen?: number,
    reported?: number,
    blocked_relationships?: number,
    photos?: string,
    disliked?: number,
    views?: boolean,
    likes?: boolean,
    photo?: string,
    firstname?: string,
    sex?: Sex,
    age?: number,
    pin?: PinInput,
    country_id?: number,
    premium?: string
  ): Promise<UpdateContentMutation> {
    const statement = `mutation UpdateContent($product: Product, $id: ID!, $index: String, $content_id: Int, $hashtags: String, $liked: Int, $already_seen: Int, $reported: Int, $blocked_relationships: Int, $photos: String, $disliked: Int, $views: Boolean, $likes: Boolean, $photo: String, $firstname: String, $sex: Sex, $age: Int, $pin: PinInput, $country_id: Int, $premium: String) {
        updateContent(
          product: $product
          id: $id
          index: $index
          content_id: $content_id
          hashtags: $hashtags
          liked: $liked
          already_seen: $already_seen
          reported: $reported
          blocked_relationships: $blocked_relationships
          photos: $photos
          disliked: $disliked
          views: $views
          likes: $likes
          photo: $photo
          firstname: $firstname
          sex: $sex
          age: $age
          pin: $pin
          country_id: $country_id
          premium: $premium
        ) {
          __typename
          item {
            __typename
            id
            content_id
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    if (product) {
      gqlAPIServiceArguments.product = product;
    }
    if (index) {
      gqlAPIServiceArguments.index = index;
    }
    if (content_id) {
      gqlAPIServiceArguments.content_id = content_id;
    }
    if (hashtags) {
      gqlAPIServiceArguments.hashtags = hashtags;
    }
    if (liked) {
      gqlAPIServiceArguments.liked = liked;
    }
    if (already_seen) {
      gqlAPIServiceArguments.already_seen = already_seen;
    }
    if (reported) {
      gqlAPIServiceArguments.reported = reported;
    }
    if (blocked_relationships) {
      gqlAPIServiceArguments.blocked_relationships = blocked_relationships;
    }
    if (photos) {
      gqlAPIServiceArguments.photos = photos;
    }
    if (disliked) {
      gqlAPIServiceArguments.disliked = disliked;
    }
    if (views) {
      gqlAPIServiceArguments.views = views;
    }
    if (likes) {
      gqlAPIServiceArguments.likes = likes;
    }
    if (photo) {
      gqlAPIServiceArguments.photo = photo;
    }
    if (firstname) {
      gqlAPIServiceArguments.firstname = firstname;
    }
    if (sex) {
      gqlAPIServiceArguments.sex = sex;
    }
    if (age) {
      gqlAPIServiceArguments.age = age;
    }
    if (pin) {
      gqlAPIServiceArguments.pin = pin;
    }
    if (country_id) {
      gqlAPIServiceArguments.country_id = country_id;
    }
    if (premium) {
      gqlAPIServiceArguments.premium = premium;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <UpdateContentMutation>response.data.updateContent;
  }
  async RemoveContent(
    id: string,
    index?: string,
    content_id?: number
  ): Promise<RemoveContentMutation> {
    const statement = `mutation RemoveContent($index: String, $id: ID!, $content_id: Int) {
        removeContent(index: $index, id: $id, content_id: $content_id) {
          __typename
          content_id
          deleted
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    if (index) {
      gqlAPIServiceArguments.index = index;
    }
    if (content_id) {
      gqlAPIServiceArguments.content_id = content_id;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <RemoveContentMutation>response.data.removeContent;
  }
  async GetContents(
    product?: Product,
    age_min?: number,
    age_max?: number,
    distance?: number,
    lat?: number,
    lon?: number,
    sex?: Sex,
    type?: string,
    start?: number,
    limit?: number,
    hashtags?: string,
    related_user?: number,
    country_id?: number,
    premium?: string
  ): Promise<Array<GetContentsQuery>> {
    const statement = `query GetContents($product: Product, $age_min: Int, $age_max: Int, $distance: Int, $lat: Float, $lon: Float, $sex: Sex, $type: AWSJSON, $start: Int, $limit: Int, $hashtags: String, $related_user: Int, $country_id: Int) {
        getContents(
          product: $product
          age_min: $age_min
          age_max: $age_max
          distance: $distance
          lat: $lat
          lon: $lon
          sex: $sex
          type: $type
          start: $start
          limit: $limit
          hashtags: $hashtags
          related_user: $related_user
          country_id: $country_id
        ) {
          __typename
          item {
            __typename
            id
            data {
              __typename
              content_id
              type
              views
              already_seen
              likes
              liked
              photos
              user {
                __typename
                id
                firstname
                photo
                sex
                age
                country_id
                premium
              }
              reported
              created_at
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (product) {
      gqlAPIServiceArguments.product = product;
    }
    if (age_min) {
      gqlAPIServiceArguments.age_min = age_min;
    }
    if (age_max) {
      gqlAPIServiceArguments.age_max = age_max;
    }
    if (distance !== undefined || distance !== null) {
      gqlAPIServiceArguments.distance = distance;
    }
    if (lat !== undefined || lat !== null) {
      gqlAPIServiceArguments.lat = lat;
    }
    if (lon !== undefined || lon !== null) {
      gqlAPIServiceArguments.lon = lon;
    }
    if (sex) {
      gqlAPIServiceArguments.sex = sex;
    }
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (start !== undefined || start !== null) {
      gqlAPIServiceArguments.start = start;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (hashtags) {
      gqlAPIServiceArguments.hashtags = hashtags;
    }
    if (related_user) {
      gqlAPIServiceArguments.related_user = related_user;
    }
    if (country_id) {
      gqlAPIServiceArguments.country_id = country_id;
    }
    if (premium) {
      gqlAPIServiceArguments.premium = premium;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <Array<GetContentsQuery>>response.data.getContents;
  }
  async GetContentById(
    id?: string,
    product?: Product
  ): Promise<GetContentByIdQuery> {
    const statement = `query GetContentById($id: ID, $product: Product) {
        getContentById(id: $id, product: $product) {
          __typename
          item {
            __typename
            id
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (product) {
      gqlAPIServiceArguments.product = product;
    }
    const response = (await this.client.graphql({
      query: statement,
      variables: gqlAPIServiceArguments
    })) as any;
    return <GetContentByIdQuery>response.data.getContentById;
  }
  AddedContentListener(): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "addedContent">>
  > {
    return this.client.graphql({
      query: `subscription AddedContent {
        addedContent {
          __typename
          item {
            __typename
            id
            data {
              __typename
              content_id
              type
              views
              already_seen
              likes
              liked
              hashtags
              photos
              user {
                __typename
                id
                firstname
                photo
                sex
                age
                country_id
              }
              reported
              created_at
              name
              description
              date
              place {
                __typename
                city_id
                city_name
                region_id
                region_name
                country_id
                country_name
              }
              link
            }
          }
        }
      }`
    }) as any;
  }

  UpdatedContentListener(): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "updatedContent">>
  > {
    return this.client.graphql({
      query: `subscription UpdatedContent {
        updatedContent {
          item {
            document {
              already_seen
              content_id
              created_at
              date
              description
              hashtags
              liked
              likes
              link
              name
              photos
              place {
                city_id
                city_name
                country_id
                country_name
                region_id
                region_name
              }
              reported
              type
              user {
                age
                country_id
                firstname
                id
                photo
                premium
                sex
              }
              views
            }
            __typename
            id
            content_id
          }
        }
      }`
    }) as any;
  }

  RemovedContentListener(): Observable<
    GraphQLResult<Pick<__SubscriptionContainer, "removedContent">>
  > {
    return this.client.graphql({
      query: `subscription RemovedContent {
        removedContent {
          __typename
          content_id
          deleted
        }
      }`
    }) as any;
  }
}
