import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';

import { AppService } from '../app.service';

import { User } from '../shared/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class EncountersService {

  constructor(
    private http: HttpClient,
    private appService: AppService,
  ) { }

  getInvitations(page: number, offset: number, id: number): Observable<User[]>{
    const lang = 'fr'

    return this.http.get<{users: User[]}>(`${this.appService.API}/api/relationship/invitations/${lang}/${page}/${offset}/${id}`)
    .pipe(
      map(res => {
        res.users.map(u => {
          u.relationship = 'accepted'
        })
        return res.users
      }),
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  getFakeEncounters(): Observable<User[]> {
    return this.http.get<User[]>('./assets/encounters.json').pipe(map((res) => {
      return res
    }))
  }

  updateRelationship(relatedUserId: number, state: string): Observable<{ code: number }> {
    const body = {
      related_user_id: relatedUserId.toString(),
      state,
    }

    return this.http.post<{ code: number }>(`${this.appService.API}/relationship/easy`, body)
      .pipe(
        map(res => res))
  }
  
  getRelationshipStatus(relatedUserId: number): Observable<string>{
    return this.http.get<{ relationships: string }>(`${this.appService.API}/relationship/status/${relatedUserId}`)
      .pipe(
        map(res => {
          console.log(res)
          return res.relationships
        })
      )
  }
}
