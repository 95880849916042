import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Emojis, Masks } from '../../toolbar-media';

import { StoryElementInterface, StoryService } from '../../../story.service';

import { ToolbarTopActionsComponent } from '../../toolbar-top-actions/toolbar-top-actions.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-toolbar-edit-story-menu',
  standalone: true,
  imports: [ToolbarTopActionsComponent, CommonModule],
  templateUrl: './toolbar-edit-story-menu.component.html',
  styleUrl: './toolbar-edit-story-menu.component.scss'
})
export class ToolbarEditStoryMenuComponent {
  @Input() menuType = ''
  @Input() subMenuType = ''
  @Output() addToStory = new EventEmitter<StoryElementInterface>()
  @Output() closePanel = new EventEmitter<void>()

  emojis = Emojis
  masks = Masks
  emojiTypeSelected: string = 'fun'
  visual!: StoryElementInterface

  constructor(
    private storyService: StoryService,
  ) {}

  closeOverlay(){
    this.closePanel.emit()
  }

  selectEmoji(){
    this.storyService.addStoryElement.next(this.visual)
    this.closePanel.emit()
  }

  changeEmoji(emoji: StoryElementInterface){
    this.visual = emoji
    this.storyService.addStoryElement.next(this.visual)
    this.closePanel.emit()
  }

  onScroll(ev: Event){
    console.log(ev, 'scrolled')
  }
}