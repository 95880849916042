import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { TitleCasePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { RouterModule } from '@angular/router';

import { HomeService } from '../home/home.service';

import { ClickAnywhereDirective } from '../click-anywhere.directive';
import { AppService } from '../app.service';

@Component({
  selector: 'app-logged-in-header',
  standalone: true,
  imports: [TitleCasePipe, RouterModule, ClickAnywhereDirective],
  templateUrl: './logged-in-header.component.html',
  styleUrl: './logged-in-header.component.scss'
})
export class LoggedInHeaderComponent implements OnInit, OnDestroy {
  serviceName = environment.serviceName
  pageDisplayed = ''
  sidenavIsOpened = false
  pageSubscription!: Subscription
  desktopDevice = false

  constructor(
    private homeService: HomeService,
    private appService: AppService
  ){}

  ngOnInit(){
    this.desktopDevice = this.appService.desktopDevice

    this.pageSubscription = this.homeService.pageDisplayed.subscribe(res => {
      this.pageDisplayed = res
    })
  }

  toggleSidenav(open: boolean, pageRequested: string){
    this.homeService.toggleSidenav.next(open)
    this.homeService.pageDisplayed.next(pageRequested)
  }

  getClickNature(){
    this.toggleSidenav(false, 'home')
  }

  ngOnDestroy(){
    this.pageSubscription.unsubscribe()
  }
}
