<div class="my-space">
    <section class="main">
      <div class="top">
        <h3><span class="regular">My</span> Space</h3>
      </div>

      <div class="center">
          <div class="navbar">
              <div class="manage-profile">
                  <h4>Manage my profile</h4>
                  <nav class="menu">
                      <li [class.selected]="pageDisplayed === 'my-profile'">
                        <button type="button" [routerLink]="['my-profile']" (click)="displayPage('my-profile')">
                          <img src="../../assets/icons/icon_myspace.svg" alt="" class="icon">
                          My profile
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'my-stories'">
                        <button type="button" [routerLink]="['my-stories']" (click)="displayPage('my-stories')">
                          <img src="../../assets/icons/icon_stories.svg" alt="" class="icon stories"> 
                          My stories
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'my-dating-criteria'">
                        <button type="button" [routerLink]="['my-dating-criteria']" (click)="displayPage('my-dating-criteria')">
                          <img src="../../assets/icons/icon_datingcriteria.svg" alt="" class="icon">
                          My dating criteria
                        </button>
                      </li>
                      <!-- <li [class.selected]="pageDisplayed === 'my-coach'">
                        <button type="button" [routerLink]="['my-coach']" (click)="displayPage('my-coach')">
                          <img src="../../assets/icons/icon_coach.svg" alt="" class="icon">
                          My coach
                        </button>
                      </li> -->
                  </nav>
              </div>
  
              <div class="manage-account">
                  <h4>Manage my account</h4>
                  <nav class="menu">
                      <li [class.selected]="pageDisplayed === 'my-subscription'">
                        <button type="button" [routerLink]="['my-subscription']" (click)="displayPage('my-subscription')">
                          <img src="../../assets/icons/icon_boosters.svg" alt="" class="icon">
                          My subscription
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'my-account'">
                        <button type="button" [routerLink]="['my-account']" (click)="displayPage('my-account')">
                          <img src="../../assets/icons/icon_myaccount.svg" alt="" class="icon">
                          My account
                        </button>
                      </li>
                  </nav>
              </div>
  
              <div class="tc">
                  <h4>Terms & conditions</h4>
                  <nav class="menu">
                      <li [class.selected]="pageDisplayed === 'info-service'">
                        <button type="button" [routerLink]="['info-service']" (click)="displayPage('info-service')">
                          Info service
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'contact'">
                        <button type="button" [routerLink]="['contact']" (click)="displayPage('contact')">
                          Contact
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'terms'">
                        <button type="button" [routerLink]="['terms']" (click)="displayPage('terms')">
                          Terms
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'legal'">
                        <button type="button" [routerLink]="['legal']" (click)="displayPage('legal')">
                          Legal
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'personal-data'">
                        <button type="button" [routerLink]="['personal-data']" (click)="displayPage('personal-data')">
                          Personal data
                        </button>
                      </li>
                      <li [class.selected]="pageDisplayed === 'cookies'">
                        <button type="button" [routerLink]="['cookies']" (click)="displayPage('cookies')">
                          Cookies
                        </button>
                      </li>
                      <li class="logout">
                        <button type="button" class="logout-btn" (click)="logOut()">
                          Logout
                        </button>
                      </li>
                  </nav>
              </div>
          </div>
          
          <div class="content" [ngStyle]="{display: pageDisplayed === '' ? 'none' : 'flex'}">
            <router-outlet></router-outlet>
          </div>
      </div>
    </section>
</div>