import { Component, ElementRef, OnInit, ViewChild, AfterViewChecked, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

import { AppService } from '../../../app.service';
import { CoachService } from '../coach.service';

import { LoaderComponent } from '../../../shared/loader/loader.component';

import { User } from '../../../shared/interfaces/user';

@Component({
  selector: 'app-my-coach-space',
  standalone: true,
  imports: [CommonModule, LoaderComponent],
  templateUrl: './my-coach-space.component.html',
  styleUrl: './my-coach-space.component.scss'
})
export class MyCoachSpaceComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('hobbiesContainer') hobbiesContainer!: ElementRef

  loading = false
  user!: User
  coaches: User[] = []
  coach!: User
  desktopDevice = false
  hobbyWidth!: number
  loaderSubscription!: Subscription
  routeDataSubscription!: Subscription

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private coachService: CoachService
  ){}

  ngOnInit(){
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    // récupération du user du resolver
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.user = data['user']
    })

    this.coach = this.coachService.coach

    // gestion taille écran
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }

    this.desktopDevice = this.appService.desktopDevice
  }

  ngAfterViewChecked(){
    if(this.desktopDevice){
      const bounds = this.hobbiesContainer.nativeElement.getBoundingClientRect()
      const hobbiesContainerWidth = bounds.right - bounds.left

      this.hobbyWidth = hobbiesContainerWidth / 5 - 16
    } else {
      const screenWidth = window.screen.width
      this.hobbyWidth = (screenWidth - 48) / 3 - 16
    }
  }

  getStyles() {
    return {
      'width': this.hobbyWidth + 'px',
      'height': this.hobbyWidth + 'px',
    }
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
    this.routeDataSubscription.unsubscribe()
  }
}
