import { HttpEvent, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { inject } from '@angular/core';

import { LoaderService } from '../../loader.service';

export const networkInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next): Observable<HttpEvent<unknown>> => {
  const loaderService = inject(LoaderService)
  let totalRequests = 0
  let completedRequests = 0

  loaderService.show()
  totalRequests++

  return next(req).pipe(
    finalize(() => {
      completedRequests++

      if(completedRequests === totalRequests) {
        loaderService.hide()
        completedRequests = 0
        totalRequests = 0
      }
    })
  );
};
