
<section class="images-holder">
    @for(image of storyImages; track image; let i = $index){
        <div class="story-image images-holder-item" [class.selected]="selectedImageIdFromService === image.id"><!-- class.selected à supprimer, pour les tests -->
            @if(selectedImageIdFromService === image.id){
                <div class="delete-container">
                    <!-- <span class="material-symbols-rounded delete" (click)="removeImageFromStory(image.id)">
                        delete
                    </span> -->
                </div>
            }
            
            <!-- <img [src]="image.value" alt="" (click)="displayImage(image.id)" > -->
        </div>
    }

    @if(noPhotoNumber){
        @for(item of [].constructor(5-storyImages.length); track item; let i = $index){
            <!-- <div class="no-photo images-holder-item" (click)="openFileInput(i)">
                <span class="material-symbols-rounded add">
                    add
                </span>
            </div> -->
        }
    }

    <button class="submit" (click)="onSubmit()">
        <span class="material-symbols-rounded check">
            check
        </span>
    </button>
</section>
