<app-toolbar-top-actions (cancel)="closePanel.emit()" (confirm)="confirmText()"></app-toolbar-top-actions>

<div class="text-editor">
    <div>
        <textarea 
          maxlength="75"
          #textInputBox
          class="text-area"
          [ngStyle]="textareaStyles"
          [formControl]="textValue"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          wrap="off"
        ></textarea>
    </div>

    <app-color-palette
      [colors]="colors"
      (colorInfo)="getTextColorInfo($event)"
    ></app-color-palette>
</div>
