<h3 i18n="Title for the exit story edition dialog@@STOP_EDIT_STORY">Are you sure you want to stop editing your story?</h3>

<p class="description" i18n="Description for the exit story edition dialog@@STOP_EDIT_STORY2">Unsaved changes will no longer be recoverable.</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()" i18n="Text for the button to cancel the action in progress@@CANCEL">Cancel</button>
  <button class="submit" (click)="dialogRef.close(true)">
    <ng-container i18n="Text for the button to confirm quitting story edition for the exit story edition dialog@@EXIT">Exit</ng-container> 
  </button>
</div>
