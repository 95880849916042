<div class="left-col"></div>

<div class="right-col" appClickAnywhere (clickNature)="getClickNature($event)">
    <app-progress-bar [hightlightedSteps]="hightlightedSteps"></app-progress-bar>
    <app-go-back-button [routerLink]="'/subscribe-step-two'"></app-go-back-button>

    <app-header></app-header>

    @if(loading){
        <app-loader></app-loader>
    }

    <form
        [formGroup]="subThirdFormGroup"   
        (ngSubmit)="onSubmit(subThirdFormGroup.value)"
    >
    <!-- [class.hide]="displayCreatingLoader" -->

        <div class="fieldsets">
            <fieldset>
                <label for="gender-select">First name</label>
                <p class="tip">Your first name should contain 2 to 25 characters, without number, space nor special characters.</p>
                <input type="text" placeholder="Enter your first name" formControlName="firstnameCtrl" (focus)="setFocusInput()">
    
                <label for="gender-select">Email</label>
                <input type="mail" placeholder="Enter your email" formControlName="emailCtrl" (focus)="setFocusInput()">
    
                <label for="gender-select">Password</label>
                <p class="tip">Your password should contain {{passwordMinCharNumber}} to {{passwordMaxCharNumber}} characters.</p>
                
                <div class="input-holder">
                    <button type="button" class="password-btn" (click)="showHidePassword()" [class.showHide]="!showPassword">
                        <span class="material-symbols-outlined password-icon">
                        visibility
                        </span>      
                    </button>
                    <button type="button" class="password-btn" (click)="showHidePassword()" [class.showHide]="showPassword">
                        <span class="material-symbols-outlined password-icon">
                        visibility_off
                        </span>
                    </button>
    
                    <input 
                        [type]="showPassword ? 'text': 'password'"
                        placeholder="Enter your password" 
                        formControlName="passwordCtrl"
                        (focus)="setFocusInput()"
                    >
    
                    @if(showPassword){
                        <div class="show-password">{{ subThirdFormGroup.value.passwordCtrl }}</div>
                    }
                </div>
            
                <!-- @if(repeatPassword){
                    <label for="gender-select">Confirm password</label>
                    <input 
                        type="password" 
                        placeholder="Enter your password" 
                        formControlName="repeatPasswordCtrl"
                        (blur)="onblurRepeatPasswordInput(subThirdFormGroup.value.repeatPasswordCtrl)"
                    >
                    {{subThirdFormGroup.value.repeatPasswordCtrl}}
                    @if(repeatPasswordError){
                        <p class="warning">Les mots de passe doivent être identiques.</p>
                    }
                } -->
            </fieldset>
        </div>
    </form>
    
    <div class="submit-btn-container">
      @if(openDialog){
        <app-error-dialog [errorMessage]="errorMessage" [errorTitle]="errorTitle"></app-error-dialog>
      }
        <button 
            type="button" 
            (click)="onSubmit(subThirdFormGroup.value)" 
            class="submit"
            [disabled]="subThirdFormGroup.invalid || error"
        >
            Create my profile
        </button>
    </div>
</div>
