@if(display){
  <app-toolbar-top-actions [showDelete]="true" (cancel)="closePanel.emit()" (confirm)="onSave()"></app-toolbar-top-actions>
  
  <div class="actions-container">
    <div class="input-container">
      <label for="size">
        <span class="material-symbols-rounded resize">resize</span>
      </label>
      
      <input 
        [ngStyle]="scaleSelectorStyles"
        #scaleSelector
        orient="vertical"
        [formControl]="scale"
        #sizeSelector name="size" type="range" min="0" max="200" 
        formCont
      />
    </div>

    @if(currentElement?.type === 'visual'){
      <img
        [width]="scale.value"
        [style.transform]="'rotate(' + rotate.value + 'deg)'"
        #sourceItem
        [src]="currentElement?.src!.replace('thumb', 'original')"
        alt=""
      />
    }

    @if(currentElement?.type === 'text'){
      <textarea 
        [ngStyle]="textareaStyles"
        [(ngModel)]="text"
        class="text-area"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize" 
        maxlength="75"
        wrap="off"
      ></textarea>
      <app-color-palette (colorInfo)="updateColor($event)"></app-color-palette>
    }

    <div class="input-container">
      <label for="angle">
        <span class="material-symbols-rounded rotate" id="imageId">rotate_left</span>
      </label>

      <input 
        [ngStyle]="rotateSelectorStyles"
        orient="vertical"
        [formControl]="rotate"
        #angle name="angle" type="range" min="0" max="360"
      />
    </div>
  </div>
}


