import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ProfileService } from '../../profile/profile.service';
import { AuthService } from '../../auth.service';
import { EncountersService } from '../../new-encounters/encounters.service';

import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-match',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './match.component.html',
  styleUrl: './match.component.scss'
})
export class MatchComponent implements OnInit {
  @Input() profile!: User
  
  match = false

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private encountersService: EncountersService
  ){}

  ngOnInit(){
    this.authService.getProfile(this.profile.id).subscribe(res => {
      this.profileService.profile.next(res)

      this.encountersService.getRelationshipStatus(this.profile.id).subscribe(res => {
        if(res === "validated") this.match = true
      })
    })
  }
}
