<app-go-back-button class="edit-go-back-btn" (click)="goBack()"></app-go-back-button>  

<section class="edit-email">
  <div class="top" #top>
    <h3><span class="regular">Email</span> Modification</h3>
  </div>

  <form [formGroup]="emailFormGroup" (ngSubmit)="onSubmit()">
    <div class="input-holder">
      <input
        id="login"
        type="text"
        placeholder="Login"
        autocapitalize="none"
        #inputLogin
        [value]="user?.email"
        formControlName="emailCtrl"
      />
      <!-- 
        (blur)="setBlurInput()"
        (focus)="setFocusInput()"
         -->
    </div>

    <button
      class="submit"
      type="submit"
      [disabled]="emailFormGroup.invalid"
    ><!-- [class.wrong]="wrongCredential" [disabled]="authFormGroup.invalid || error"-->
      Validate new email
    </button>
  </form>
</section>
    