<h3 i18n="Title for the block user dialog@@TEXT_BLOCK_USER">Are you sure you wish to block this user?</h3>

<p class="description" i18n="Description for the block user dialog@@TEXT2_BLOCK_USER">This person will no longer be able to view your profile or contact you.</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()" i18n="Text for the button to cancel the action in progress@@CANCEL">Cancel</button>
  <button class="submit" (click)="dialogRef.close(true)">
    <img src="assets/icons/icon_block.svg" alt="" class="block-icon">
    <ng-container i18n="Text for the button to confirm the user blocking in the block user dialog@@BLOCK">Block</ng-container> 
  </button>
</div>

