<div class="upload-photo">
    <app-progress-bar [hightlightedSteps]="hightlightedSteps"></app-progress-bar>
    <app-go-back-button [routerLink]="'/subscribe-step-three'"></app-go-back-button>

    <app-header></app-header>

    <h3>
      Upload your best photo
      <button type="button" class="help-btn" (click)="helpIsOpened = true;">
        <span class="material-symbols-outlined help"> 
          help
        </span>
      </button>
    </h3>
    
  
    <div class="cancel">
      @if(showCropper){
      <button type="button" class="close-btn" (click)="discardPhoto()">
        <span class="material-symbols-rounded close">
          close
        </span>
      </button>
    }
    </div>
  
    <div class="photo-container" #photoContainer>
        @if(!fileList){
          <div class="no-image-container" appClickAnywhere (clickNature)="getClickNature($event)">               
              <button type="file" class="icon-gallery-add-photo" (click)="openFileInput()">
                  <img src="assets/icons/icon_gallery.svg" alt="No image">
                  <div class="plus-badge">
                      <span class="material-symbols-rounded more">
                          add
                      </span>
                  </div>
              </button>
          </div>
        }

        @if(showCropper){
            <div class="cropper">
              <canvas id="canvas" width="350" height="350"></canvas>
              
              <div class="crop-holder">
                  <app-photo-crop
                      [imageSource]="tempCropperPhotoInBase64"
                      (userCroppedImageEvent)="getUserCroppedImage($event)"
                      (cropDetailsEvent)="getCropDetails($event)"
                      [photoContainerDimensions]="photoContainerDimensions"
                  ></app-photo-crop>
              </div>
            </div>
        }

        @if(loading){
          <app-loader></app-loader>
        }
    </div>

    <input 
        type="file"
        accept=".jpg, .jpeg, .png" 
        #fileUpload 
        style="opacity: 0;" 
        class="file-upload" 
        (change)="onPhotoInputChange($event)"
    >
    <div id="myImage"></div>
    
    <div class="submit-btn-container" #submitBtnContainer>
        <button type="submit" class="submit" [disabled]="!fileList" (click)="saveCropData()">
            Crop and save
        </button>
    </div>

    <div class="add-photo-help" [class.hide]="!helpIsOpened">
      <div class="top-container">
        <button type="button" class="close-btn" (click)="helpIsOpened = false;">
          <span class="material-symbols-rounded close">
            close
          </span>
        </button>
      </div>

      <div class="content">
          <div class="icon-container">
              <img src="assets/icons/icon_gallery.svg" alt="No image" class="icon_gallery_pink">
          </div>
          <h3>Add a photo</h3>
          <p>Please select a photo with good quality, lighting and one where we can clearly see you.</p>
      </div>
    </div>

    @if(openDialog){
        <app-error-dialog [errorMessage]="errorMessage" [errorTitle]="errorTitle"></app-error-dialog>
    }

    <div class="overlay" [class.show]="helpIsOpened"></div>
</div>

<router-outlet></router-outlet>
