import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { map, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { AppService } from '../app.service';
import { MatchesService } from './matches.service';

import { LoaderComponent } from '../shared/loader/loader.component';
import { MatchComponent } from './match/match.component';
import { TalkComponent } from './talk/talk.component';
import { FooterComponent } from '../footer/footer.component';

import { User } from '../shared/interfaces/user';
import { Talk } from '../shared/interfaces/talk';
import { Notification } from '../shared/interfaces/notification';

@Component({
  selector: 'app-my-matches',
  standalone: true,
  imports: [LoaderComponent, FooterComponent, MatchComponent, TalkComponent, RouterModule, CommonModule],
  templateUrl: './my-matches.component.html',
  styleUrl: './my-matches.component.scss'
})
export class MyMatchesComponent implements OnInit, OnDestroy {
  loading = false
  matchesLoading = true
  profiles!: User[]
  talks!: Talk[]
  loaderSubscription!: Subscription
  notifications: Notification[] = []
  notificationCount = 0
  matches!: User[]

  constructor(
    private appService: AppService,
    private http: HttpClient,
    private router: Router,
    private matchesService: MatchesService
  ){}

  ngOnInit(){
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    // gestion taille écran
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }

    this.matchesService.getNotificationsList().subscribe((res) => {
      res.map((n) => {
        n.loaded = false
        n.error = false
        n.updating = false
        n.relationship =
          n.type === 'contact'
            ? 'friend'
            : n.type === 'invitation'
            ? 'pending'
            : 'notfriend'
      })

      this.matchesService.notifications = res
      this.matchesLoading = false

      this.notifications = this.matchesService.notifications

      this.notifications.forEach(n => {
        const creationDate = new Date(n.created_at)
        n.timeExpression = this._getSeparatorBody(creationDate)
        
        if(n.user){
          if(n.readed === 0) this.notificationCount++
        }
        if(n.story && n.story.likes > 0){
          this.matchesService.getContentLikers('story', n.story.id).subscribe(res => {
            if(n.story) n.story.users = res

            if(n.readed === 0) this.notificationCount++
          })
        }
      })

      this.appService.setLoader(false)

      this.matchesService.getMatches().subscribe(res => this.matches = res)
    })

    this.getTalks().subscribe(res => this.talks = res)
  }

  private _isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getUTCFullYear() === date2.getUTCFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }

  private _getSeparatorBody(date: Date): string {
    let timeStr = ''

    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const twoDaysBeforeToday = new Date()
    twoDaysBeforeToday.setDate(twoDaysBeforeToday.getDate() - 2)

    const threeDaysBeforeToday = new Date()
    threeDaysBeforeToday.setDate(threeDaysBeforeToday.getDate() - 3)

    if (this._isSameDay(date, new Date())) {
      timeStr = 'Today'
    } else if (this._isSameDay(date, yesterday)) {
      timeStr = 'Yesterday'
    } else if (this._isSameDay(date, twoDaysBeforeToday)) {
      timeStr = '2 days ago'
    } else if (this._isSameDay(date, threeDaysBeforeToday)) {
      timeStr = '3 days ago'
    } else {
      timeStr = 'More than a week ago'
    }

    return timeStr
  }

  // récupération de fake profiles : pour les tests
  getProfiles(): Observable<User[]> {
    return this.http.get<User[]>('./assets/users.json').pipe(map((res) => {
      return res
    }))
  }

  // récupération de fake Talks : pour les tests
  getTalks(): Observable<Talk[]> {
    return this.http.get<Talk[]>('./assets/talks.json').pipe(map((res) => {
      return res
    }))
  }

  showNotifications(){
    this.router.navigate(['home/my-matches/notifications'])
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
