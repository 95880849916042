import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit, AfterViewChecked} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { ServiceService } from '../service.service';
import { StoryService } from './story.service';
import { StoriesService } from '../stories.service';
import { AuthService } from '../auth.service';
import { AppService } from '../app.service';

import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { TextInputComponent } from './text-input/text-input.component';
import { ColorPaletteComponent } from './color-palette/color-palette.component';
import { ImagesHolderComponent } from './images-holder/images-holder.component';
import { ContentsContainerComponent } from './contents-container/contents-container.component';

import { Story } from '../shared/interfaces/story';
import { Color } from '../shared/interfaces/color';
import { Visual } from '../shared/interfaces/visual';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { StoryContent } from '../shared/interfaces/story-content';
import { StoryImage } from '../shared/interfaces/story-image';

@Component({
  selector: 'app-create-story',
  standalone: true,
  imports: [
    CommonModule, 
    ReactiveFormsModule, 
    FormsModule, 
    ConfirmDialogComponent, 
    ToolbarComponent, 
    TextInputComponent,
    ColorPaletteComponent,
    ImagesHolderComponent,
    ContentsContainerComponent,
    TextEditorComponent
  ],
  templateUrl: './create-story.component.html',
  styleUrl: './create-story.component.scss',
})
export class CreateStoryComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() update: Story | null = null
  @Input() homeStoryImages: StoryImage[] = []  

  @ViewChild('mobilePhotoHolder') mobilePhotoHolder!: ElementRef
  @ViewChild('desktopPhotoHolder') desktopPhotoHolder!: ElementRef
  @ViewChild('fileUpload') fileUpload!: ElementRef
  @ViewChild('mobileSection') mobileSection!: ElementRef
  @ViewChild('desktopSection') desktopSection!: ElementRef

  //image: any

  toolbar: string | null = null

  showEmojisList = false

  // textElement: any
  // currentElement: any

  colors: Color[] = []

  newFeedSuccess = false
  published = false
  storyId!: number
  imageId!: number

  warning = false

  // textes
  textPos: {
    x: number | null,
    y: number | null
  } = {
    x: null,
    y: null
  }
  text!: {
    id: number;
    value: string | '';
    fontColorHex: string;
    pos: {
      x: number | 0;
      y: number | 0;
    }
  }
  visuals: Visual[] = []

  emojiImageElement!: HTMLDivElement

  rotateInProgress = false

  inputElement!: HTMLInputElement | null

  textToolSelected = false

  confirmDialog = { title: '', content: '' }

  textEdition = false

  fontColor: string = '#fff'
  shadowColor: string = '#000'
  textValue: string = ''
  textParagraphCreated = false

  selectedImageId: number = 0
  selectedImageSrc = ''

  displayOverlay = false
  imageToSave = false
  storyImages: StoryImage[] = []

  visualNewSize!: {width: number, height: number}

  imageProcessed = false

  desktopSectionPosition!: DOMRect
  visualToolSelected = false

  showTextEditor = false
  contents: StoryContent[] = []

  screenSize!: {height: number, width: number}

  constructor(
    private serviceService: ServiceService,
    private storiesService: StoriesService,
    private storyService: StoryService,
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.storiesService.createStory().subscribe(res => {
      this.storyService.storyId = res
    })

    //this.initElementsDefaultValue()

    this.serviceService.getColors().subscribe(res => {
      this.colors = res
    })

    if(window.innerWidth >= 1024 && window.innerHeight >= 600) this.appService.desktopDevice = true

    this.screenSize = {
      height: window.innerHeight,
      width: window.innerWidth
    }
    
    this.storyService.storyImages.subscribe(res => this.storyImages = res)    

    this.storyService.visuals.subscribe(res => this.visuals = res)
    this.storyService.textToolSelected.subscribe(res => this.textToolSelected = res)
    this.storyService.visualToolSelected.subscribe(res => this.visualToolSelected = res)

    if(!this.appService.desktopDevice) {
      this.storyService.storyImages.next(this.storiesService.tempStoryFiles)
    } else {
      this.storyService.textToolSelected.next(true)

      this.fontColor = '#000'
      this.shadowColor = '#FFF'
    }

    // à modifier !!! 
    if(this.storyImages.length === 0) {this.storyImages.push({ 
      id: 0,
      value: 'assets/rabbit-lady.PNG',
      processed: false
    })}
    // à modifier !!! 

    console.log(this.storyImages)

    this.confirmDialog = {
      title: 'Are you sure you want to delete your story?',
      content: 'Remember that once deleted, it cannot be recovered.'
    }

    this.storyService.displayOverlay.subscribe(res => this.displayOverlay = res)

    this.storyService.imageToSave.subscribe(res => {
      this.imageToSave = res
    })

    this.storyService.imageProcessed.subscribe(res => this.imageProcessed = res)
    this.appService.warningPopup.subscribe(res => this.warning = res)
    this.storyService.contents.subscribe(res => {
      this.contents = res 
    })

    this.storyService.selectedImageId.subscribe(res => this.selectedImageId = res)
  }

  ngAfterViewInit(){
    if(!this.appService.desktopDevice) {
      if(!this.mobilePhotoHolder) return
      this.storyService.mobilePhotoHolderBounds = this.mobilePhotoHolder.nativeElement.getBoundingClientRect() 
    } else {
      this.storyService.desktopPhotoHolderBounds = this.desktopPhotoHolder.nativeElement.getBoundingClientRect()
    }
  }

  ngAfterViewChecked(){
    if(!this.appService.desktopDevice) {
      console.log('mobile')
    } else {
      const bounds = this.desktopSection.nativeElement.getBoundingClientRect()
      this.desktopSectionPosition = bounds
    }
  }

  getTextEditionActivated(event: boolean){
    console.log('show text editor')
    this.showTextEditor = event
  }

  initElementsDefaultValue() {
    //console.log('initElementsDefaultValue')
    // this.textElement = {
    //   size: 10,
    //   percent: 30,
    //   colorIndex: 0,
    //   color: null,
    //   type: 'text',
    //   value: null,
    //   empty: true,
    //   x: 0,
    //   y: 0,
    //   height: 30,
    //   width: 200,
    //   start: {
    //     x: 0,
    //     y: 0,
    //   },
    // }

    //this.imagesElement = []
  }

  showConfirmDialogFromToolbar($event: boolean){
    this.warning = $event
  }

  closeNewStory() {
    console.log('closeNewStory')
    this.warning = true

    this.appService.warningPopup.next(true)
    // if((this.storyService.story.length > 0 || this.image) && !this.newFeedSuccess) {
    //   this.warning = true
    // } else {
    //   service.setNewFeedStory({ display: false, value: null })
    //   service.hideFooter(false)
    // }
  }

  confirmCloseNewStory() {
    console.log('confirmCloseNewStory')
    this.storyService.storyImages.next([])

    // if(value) {
    //   this.serviceService.setNewFeedStory({ display: false, value: null })
    // }
  }

  confirmStoryDeletion(storyDeletion: boolean){
    console.log('confirmStoryDeletion: ', storyDeletion)
    
    if(storyDeletion) {
      this.storiesService.tempStoryFiles = []
      this.storyService.storyImages.next([])
      this.storyImages = []

      this.router.navigate(['/home'])
    } 

    this.appService.warningPopup.next(false)
  }

  clickWhere() {
    console.log('clickWhere')
    this.toolbar = null
    //this.currentElement = null
  }

  getFileInputRequested(fileInputRequested: boolean){
    if(fileInputRequested) this.fileUpload.nativeElement.click()
  }

  addImage(target: EventTarget | null) {
    console.log('addImage')
    const fileInput = target as HTMLInputElement

    if(!fileInput) return
    if(!fileInput.files) return

    this.storyService.saveStoryImage()
    this.storyService.imageProcessed.next(false)
    
    const imageFile = fileInput.files[0]
    const imageFileBlob = URL.createObjectURL(imageFile) 
    this.storiesService.tempStoryFiles.push({id: this.storyImages.length, value: imageFileBlob, processed: false})

    if(this.appService.desktopDevice){
      this.storyImages.push({id: this.storyImages.length, value: imageFileBlob, processed: false})

      this.storyService.storyImages.next(this.storyImages)
    }
    
    //this.showEmojisList = false

    this.selectedImageId = this.storyImages.length - 1
    this.storyService.selectedImageId.next(this.selectedImageId)
    this.selectedImageSrc = this.storyImages[this.selectedImageId].value
  }

  getSelectedImageId(id: number | null){
    console.log('getSelectedImageId')

    if(id === null) return
    //this.storyService.selectedImageId.next(id)

    console.log(this.selectedImageId)

    console.log(this.storyImages)

    if(this.selectedImageId === this.storyImages.length){
      const newId = this.selectedImageId--
      this.selectedImageSrc = this.storyImages[newId].value

      this.storyService.selectedImageId.next(newId)
    } else {
      this.selectedImageSrc = this.storyImages[this.selectedImageId].value
    }
  }

  saveImage(){
    this.storyService.saveStoryImage()
  }

  /* checkSpace($event: any) {
    console.log('checkSpace')
    if($event.keyCode === 32) {
      return false
    }
    return
  } */

  publishStory() {
    console.log('publishStory')

    // vérifier si l'image a déjà été sauvegardée.
    // si dans this.storyImages, on a une image avec le meme id que imageId et qui a processed à true, on appelle saveStoryImage()
    const imageAlreadyProcessedArr = this.storyImages.filter(i => i.id === this.imageId && i.processed)
    if(imageAlreadyProcessedArr.length === 0){
      this.storyService.saveStoryImage()
    }

    this.storiesService.publishStory(this.storyId as number).subscribe(() => {
      this.published = true
      this.successAutoClosed()
    })
  }

  successAutoClosed() {
    console.log('successAutoClosed')

    const user = JSON.parse(this.authService.get('user'))
  
    if(!user) return

    this.authService
    .getProfile(user.id) //, 'fr', 1, 10
    .subscribe((auth) => {
      this.authService.setAuthMe(auth)
      window.location.reload()
      //this.closeNewStory(this.serviceService)
    })
  }

  // permet de savoir si l'édition de texte est en cours. Information fournie par la toolbar
  textEditionActivated($event: boolean){
    this.textEdition = $event
  }







  /* 
   getResizeValue($event: Event){
    console.log($event)
  } */
/* 
// récupère la position du drag de l'élément sélectionné dans photo-holder
  getNewPos($event: any){
    console.log('feed create getNewPos')
    if(this.appService.desktopDevice){
      if($event.x === 0 && $event.y === 0) return

      this.textPos = { x: $event.x, y: $event.y }
    } else {
      const bounds = this.mobilePhotoHolder.nativeElement.getBoundingClientRect()

      let x = $event.targetTouches[0].clientX
      let y = $event.targetTouches[0].clientY

      if(!bounds) return
      if(!$event.targetTouches[0].clientX || !$event.targetTouches[0].clientY) return

      // recalcul de x si besoin
      if($event.targetTouches[0].clientX < 0) {
        //console.log('x inférieur à 0')
        x = 0
      }
      if($event.targetTouches[0].clientX > (bounds.left + bounds.width - 200)) {
        //console.log('x supérieur à la largeur de l écran')
        x = bounds.left + bounds.width - 200 // 200 est la largeur de l'input      
      }

      // recalcul de y si besoin
      if($event.targetTouches[0].clientY < 0){
        //console.log('y inférieur à 0')
        y = 0
      }
      if($event.targetTouches[0].clientY > (bounds.top + bounds.height - 48)) {
        //console.log('y supérieur à la longueur de l écran')
        y = bounds.top + bounds.height - 48 // 48 est la hauteur de l'input      
      }

      this.textPos = { x: x, y: y }
    }
  } */

  /* 
  getInputElementFromToolbar($event: HTMLInputElement | null){
    console.log('getInputElementFromToolbar')
    if(!$event) return
    this.inputElement = $event
  }
     */


  /* 
  getNewVisualsArray($event: any){
    console.log('getNewVisualsArray')
    this.visuals = $event
  } */

  /* changeTextColor(colorCode: string, colorType: string, colorIndex: number){
    console.log('changeTextColor')

    if(this.appService.desktopDevice) {
      console.log('desktop')

      this.colors.forEach(c => c.selected = false)
    } else {
      this.colors.forEach(c => c.selected = false)
    }

    this.colors[colorIndex].selected = true
  } */

  // getTextDeleted($event: boolean){
  //   console.log('getTextDeleted')

  //   if($event) {
  //     this.storyService.textToolSelected.next(true)
  //   }
  // }

  // version desktop : création d'un paragraphe à la perte de focus sur l'input text
  //onBlur($event: any){
    // console.log('desktop onblur')

    // const str = $event.target.value.trim()

    // if(str === '') {
    //   //this.inputTextDom.nativeElement.value = ''
    //   this.storyService.textParagraphCreated.next(false)
    //   return
    // }

    // this.storyService.textInputs.next([...this.storyService.textInputs.getValue(), {
    //   id: 0, // à modifier
    //   imageId: this.imageId,
    //   value: str,
    //   fontColor: this.appService.desktopDevice ? '#fff' : '#000',
    //   shadowColor: this.appService.desktopDevice ? '#000' : '#fff',
    //   pos: {x: 0, y: 0}
    // }])

    //this.storyService.textParagraphCreated.next(true)

    // if(this.storyService.textParagraphCreated.getValue()) {
    //   // this.inputTextDom.nativeElement.value = ''
    //   // this.inputTextDom.nativeElement.disabled = true
    // }
  //}
  
  /* saveStoryImage(){
    console.log('saveStoryImage')
    const bgCanvas = document.getElementById('canvas') as HTMLCanvasElement
    const bgContext = bgCanvas.getContext('2d')

    console.log(this.selectedImageId)

    const storyImage = this.storyImages.filter(img => { 
      return img.id === this.selectedImageId
    })

    if(!bgContext) return
    const mainImage = new Image()
    
    mainImage.onload = () => {
      const fac = new FastAverageColor()
      let bgColor = ''

      fac.getColorAsync(mainImage)
        .then(color => {
          bgColor = color.hex
          bgContext.fillStyle = bgColor
          bgContext.fillRect(0, 0, bgCanvas.width, bgCanvas.height)

          const ratio = mainImage.height / mainImage.width
          bgContext.drawImage(mainImage, 0,0, mainImage.width, mainImage.height, 0, ((bgCanvas.height - bgCanvas.width * ratio) / 2), bgCanvas.width, bgCanvas.width * ratio)

          let textInput = this.contents.filter(c => { return c.type === 'text' }) 

          if(textInput.length === 0){
            textInput.push({
              id: 0,
              value: '',
              fontColor: '#fff',
              shadowColor: '#000',
              type: 'text',
              initialData: {
                size: 20,
                angle: 0,
                x: `calc(50% - 100px)`,
                y: `calc(50% - 24px)`
              },
              newData: null
            })
          } 

          if(textInput.length > 0){
            const text = textInput[0].value || ''
            bgContext.font = `bold ${textInput[0].newData?.size || textInput[0].initialData.size || 40}px Montserrat`
            bgContext.fillStyle = textInput[0].fontColor as string || '#fff'
            bgContext.shadowColor = textInput[0].shadowColor as string || '#000'
            bgContext.shadowBlur = 3
            bgContext.textBaseline = "top" as CanvasTextBaseline

            if(textInput[0].newData){
              bgContext.save()

              const textData = textInput[0].newData
              if(!textData.x) textData.x = bgCanvas.width / 2 + 'px'
              if(!textData.y) textData.y = bgCanvas.height / 2 + 'px'
              if(!textData.height) textData.height = 20
              if(!textData.width) textData.width = 100
          
              const textCenterPosY = parseInt(textData.y) + (textData.height / 2)
              const textCenterPosX = parseInt(textData.x) + (textData.width / 2)

              // déplacer le canvas tel que son coin supérieur gauche est à textCenterPos
              bgContext.translate(textCenterPosX, textCenterPosY)

              // faire pivoter le canvas selon l'angle demandé
              if(!textData.angle) textData.angle = 0
              const textAngleInRadians = (textData.angle * Math.PI) / 180
              bgContext.rotate(textAngleInRadians)

              // mettre le canvas à la position de départ du texte
              bgContext.translate(-textCenterPosX, -textCenterPosY)
              
              const x = textData.x.slice(0, textData.x.length - 2)
              const y = textData.y.slice(0, textData.y.length - 2)

              bgContext.fillText(text as string, parseInt(x as string), parseInt(y as string))
            
              bgContext.restore()
            } else {
              bgContext.save()
              bgContext.textAlign = 'center'
              const x = bgCanvas.width / 2
              const y = bgCanvas.height / 2
              bgContext.fillText(text as string, x, y)
              bgContext.restore()
            }

            const newContents = this.contents.filter(c => { return c.id !== textInput[0].id })
            this.storyService.contents.next(newContents)

            const visualInputs = this.contents.filter(c => { return c.type === 'visual' })

            if(visualInputs.length > 0){
              const visualsCanvas = document.getElementById('canvasVisuals') as HTMLCanvasElement
              const visualsContext = visualsCanvas.getContext('2d')

              visualInputs.forEach(v => {
                if(!visualsContext) return

                const visualImage = new Image()
                
                visualImage.onload = () => {
                  if(v.newData !== null){
                    visualsContext.save()

                    if(!v.newData.x) v.newData.x = visualsCanvas.width / 2 + 'px'
                    if(!v.newData.y) v.newData.y = visualsCanvas.height / 2 + 'px'
                    if(!v.newData.height) v.newData.height = 70
                    if(!v.newData.width) v.newData.width = 70
                
                    const visualCenterPosY = parseInt(v.newData.y) + (v.newData.height / 2)
                    const visualCenterPosX = parseInt(v.newData.x) + (v.newData.width / 2)

                    // déplacer le canvas tel que son coin supérieur gauche est à visualCenterPos
                    visualsContext.translate(visualCenterPosX, visualCenterPosY)

                    // faire pivoter le canvas selon l'angle demandé
                    if(!v.newData.angle) v.newData.angle = 0
                    const visualAngleInRadians = (v.newData.angle * Math.PI) / 180
                    visualsContext.rotate(visualAngleInRadians)

                    // mettre le canvas à la position de départ du visuel
                    visualsContext.translate(-visualCenterPosX, -visualCenterPosY)

                    const x = v.newData.x.slice(0, v.newData.x.length - 2)
                    const y = v.newData.y.slice(0, v.newData.y.length - 2)

                    visualsContext.drawImage(visualImage, 0, 0, visualImage.width, visualImage.height, parseInt(x), parseInt(y), v.newData.width, v.newData.height)
                  
                    visualsContext.restore()
                  } else {
                    visualsContext.save()

                    const x = visualsCanvas.width / 2
                    const y = visualsCanvas.height / 2

                    visualsContext.drawImage(visualImage, 0, 0, visualImage.width, visualImage.height, v.initialData.width ? x - v.initialData.width / 2 : x - 35, v.initialData.height ? y - v.initialData.height / 2 : y - 35, v.initialData.width || 70, v.initialData.height || 70)
                 
                    visualsContext.restore()
                  }  

                  bgContext.drawImage(visualsCanvas, 0, 0)

                  const storyImage = this.storyImages.filter(img => { return img.id === this.selectedImageId })
                  const index = this.storyImages.indexOf(storyImage[0])
                  this.storyImages[index].value = bgCanvas.toDataURL()

                  bgCanvas.style.display = 'none'
                  visualsCanvas.style.display = 'none'

                  const newContents = this.contents.filter(c => { return c.type !== 'visual' })
                  this.storyService.contents.next(newContents)
                }
                visualImage.src = v.value as string

                visualsContext.reset()
              })
            }

            storyImage[0].value = bgCanvas.toDataURL()
            storyImage[0].processed = true

            console.log(bgCanvas.toDataURL())
            

            this.storyService.storyImages.next(this.storyImages)

            this.storiesService.addImageToStory(bgCanvas.toDataURL(), this.storyId).subscribe(res => {
                this.storyService.imageProcessed.next(true)
                this.storyService.textParagraphCreated.next(true)
                bgContext.reset()
            })
          }
      })
      .catch(e => {
          console.log(e)
      })
    }

    const index = this.storyImages.indexOf(storyImage[0])
    mainImage.src = this.storyImages[index].value

    this.storyService.currentInput.next({
      id: 0,
      value: '',
      fontColor: '#fff',
      shadowColor: '#000',
      type: 'text',
      initialData: {
        size: 20,
        angle: 0,
        x: 'calc(50% - 100px)',
        y: 'calc(50% - 48px)',
      },
      newData: null
    })

    this.selectedImageId++

    if(this.storyService.storyCompleted) this.router.navigate(['/'])
  } */

  
  /* addImageToStory() {
    console.log('addImageToStory')
    // this.initElementsDefaultValue();
    // this.currentElement = null;
    // this.storyImageToDisplay = null;
  } */

    /* setTextFocus($event: Event) {
    console.log('setTextFocus')
    // if(!this.textElement.value) {
    //   this.textElement.empty = false
    //   this.textElement.value = ''
    // }

    if(!this.currentElement || this.toolbar === 'image') {
      //this.currentElement = this.textElement
      this.toolbar = 'text'
      //this.setSlider()
    }
  } */

      // récupération du texte saisi dans le composant text-input
  // getTextValue($event: string){
  //   console.log('getTextValue de create-story')

  //   // this.storyService.textInputs.next([{
  //   //   id: 0, // à modifier
  //   //   imageId: this.imageId,
  //   //   value: $event,
  //   //   fontColor:  this.appService.desktopDevice ? '#fff' : '#000',
  //   //   shadowColor:  this.appService.desktopDevice ? '#000' : '#fff',
  //   //   pos: {x: 0, y: 0}
  //   // }])
  // }

    // MOBILE : infos de la couleur sélectionnée récupérées du composant color-palette
  // getTextColorInfo($event: { colorHex: string; colorType: string; colorIndex: number; }){
  //   console.log('getTextColorInfo: ', $event)
  //   this.changeTextColor($event.colorHex, $event.colorType, $event.colorIndex)
  // }
}
