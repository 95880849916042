import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { CommonModule, DOCUMENT, Location } from '@angular/common';
import { map, Subject, takeUntil } from 'rxjs';

import { HomeService } from '../home/home.service';
import { ProfileService } from './profile.service';
import { ChatService } from '../chat/chat.service';
import { LayoutService } from '../layout.service';

import { GoBackButtonComponent } from '../shared/go-back-button/go-back-button.component';

import { User } from '../shared/interfaces/user';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [GoBackButtonComponent, RouterModule, CommonModule, RouterOutlet],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit, OnDestroy {
  private _window: (Window & typeof globalThis) | null
  private readonly _destroyed = new Subject<void>()

  profileId!: number
  profiles!: User[]
  profile!: User | null
  userAge!: number
  profileLiked$ = this.profileService.profileLiked$.pipe(
    map(res => {
      console.log(res)
      this.profileLiked = res
    })
  )
  profileLiked!: User | null
  isDesktop = this.layoutService.isDesktop

  constructor(
    private route: ActivatedRoute,
    private homeService: HomeService,
    private location: Location,
    private profileService: ProfileService,
    private router: Router,
    private chatService: ChatService,
    private layoutService: LayoutService,
    @Inject(DOCUMENT) private document: Document,
  ) { 
    this._window = this.document.defaultView

    if(this._window){
      this._window.addEventListener('popstate', (event) => {
        const popStateTarget = event.currentTarget as Window

        if(popStateTarget.location.pathname.includes('profile')) {
          this.homeService.pageDisplayed.next('profile')
        }
      }, false);
    }
  }

  ngOnInit(): void {
    if(this.router.url.includes('matches')){
      this.profileService.profile.pipe(
        takeUntil(this._destroyed)
      ).subscribe(res => {
        this.profile = res
      })
    }

    this.route.params.pipe(
      takeUntil(this._destroyed),
    ).subscribe((params) => {
      const id = params['id']
        return this.profileService.profileId.next(id)
    })

    this.homeService.pageDisplayed.next('profile')

    this.profileService.profileLiked$.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      this.profileLiked = res
    })
  }

  goBack(){
    this.location.back()

    if(this.chatService.origin === 'chat') this.chatService.toggleSidenav.next(false)
  }

  setPageDisplayed(){
    this.homeService.pageDisplayed.next('new-encounters')
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
