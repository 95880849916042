import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Location, TitleCasePipe } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';

import { AppService } from '../../app.service';
import { CoachService } from './coach.service';
import { HomeService } from '../../home/home.service';
import { StoriesService } from '../../stories.service';

import { LoaderComponent } from '../../shared/loader/loader.component';
import { TopButtonComponent } from '../../shared/top-button/top-button.component';
import { FooterComponent } from '../../footer/footer.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { User } from '../../shared/interfaces/user';
import { Story } from '../../shared/interfaces/story';

@Component({
  selector: 'app-my-coach',
  standalone: true,
  imports: [LoaderComponent, TitleCasePipe, RouterModule, FooterComponent, TopButtonComponent, GoBackButtonComponent],
  templateUrl: './my-coach.component.html',
  styleUrl: './my-coach.component.scss'
})
export class MyCoachComponent implements OnInit, OnDestroy {
  @ViewChild('top') top!: ElementRef

  serviceName = environment.serviceName
  loading = false 

  coaches: User[] = []
  coach!: User
  coachAge!: number
  user!: User
  pageDisplayed = 'space'
  loaderSubscription!: Subscription
  routeDataSubscription!: Subscription
  desktopDevice = false
  stories: Story[] = []

  constructor(
    private appService: AppService,
    private router: Router,
    private location: Location,
    private coachService: CoachService,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private storiesService: StoriesService
  ){}

  ngOnInit(){
    this.desktopDevice = this.appService.desktopDevice
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    // récupération du user du resolver
    this.routeDataSubscription = this.route.data.subscribe(data => this.user = data['user'])

    this.coachService.getCoaches().subscribe(res => {
      this.coaches = res

      this.coach = res.filter(c => c.sex === 'woman')[0]
      this.coachService.coach = this.coach

      this.coachService.coachId = this.coach.id

      if(this.coach.birthdate){
        const [y,m,d] = this.coach.birthdate.split('-')
        const date = `${y}-${m}-${d}`
        const birthDate = dayjs(date)

        this.coachAge = dayjs().diff(birthDate, 'year')
      }

      //this.coachService.getCoachStories().subscribe(res => console.log(res))
      this.storiesService.getFakeStories().subscribe(res => {
        this.stories = res
        this.coachService.stories = res
      })
    })

    if(this.location.path() === '/home/my-space/my-coach') this.router.navigate(['/home/my-space/my-coach/space'])
  }

  goBack(){
    if(this.desktopDevice) {
      this.homeService.pageDisplayed.next('')
      this.location.back()
    } else {
      this.homeService.pageDisplayed.next('')
    }
  }

  getScrollRequest(request: string){
    if(request === 'scrollToTop') this.top.nativeElement.scrollIntoView()
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
    this.routeDataSubscription.unsubscribe()
  }
}
