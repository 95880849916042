<div class="add-story-dialog">
  <div class="content">
    @if(storyImages.length === 0){
      <button type="button" class="close-btn" (click)="closeDialog()">
        <span class="material-symbols-rounded close"> close </span>
      </button>

      <img
        src="assets/icons/icon_gallery.svg"
        alt="No image"
        class="icon_gallery_pink"
      />
      <h3 i18n="Title for the dialog from where the user can upload a first photo to create a story@@ADD_NEW_STORY">Add a new story</h3>

      <div
        class="drop-zone"
        id="drop_zone"
        (drop)="drop($event)"
        (dragover)="allowDrop($event)"
      >
        <p>Drag photos here.</p>
      </div>

      <input
        type="file"
        accept="image/png, image/jpeg"
        #fileUpload
        style="opacity: 0"
        class="file-upload"
        (change)="onPhotoOrVideoInputChange($event)"
      />

      <button class="submit button" (click)="openFileInput()" i18n="Text for the button in the new story dialog to choose the first photo of your story from your computer@@SELECT_FROM_COMPUTER">
        Select from your computer
      </button>
    }
  </div>
</div>