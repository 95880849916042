
<div formGroupName="birthdate">
    <h2>Birth date</h2>
    <div class="fields">
        <fieldset class="field">
            <label for="day-select" class="sublabel">Day</label>
            <select name="day" id="day-select" formControlName="birthDayCtrl" (click)="onClick($event)"> <!--  (change)="setDay($event)" -->
                <option value="">-- Select a day --</option>
                @for(d of days; track d){
                    <option [value]="d">{{d}}</option>
                }
            </select>
        </fieldset>
        
        <fieldset class="field">
            <label for="month-select" class="sublabel">Month</label>
            <select name="month" id="month-select" formControlName="birthMonthCtrl" (click)="onClick($event)"><!--  (change)="setMonth($event)" -->
                <option value="">-- Select a month --</option>
                @for(m of months; track m.id) {
                    <option [value]="m.id" class="month">{{m.label | titlecase}}</option>
                }
            </select>
        </fieldset>
    
        <fieldset class="field">
            <label for="year-select" class="sublabel">Year</label>
            <select name="year" id="year-select" formControlName="birthYearCtrl" (click)="onClick($event)"><!--  (change)="setYear($event)" -->
                <option value="">-- Select a year --</option>
                @for(y of years; track y){
                    <option [value]="y">{{y}}</option>
                }
            </select>
        </fieldset>    
    </div>
</div>
