import { Router, CanActivateFn, UrlTree } from '@angular/router';
import { AuthService } from '../../auth.service';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { User } from '../../shared/interfaces/user';

Injectable({
  providedIn: 'root'
})
export const loginGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const authService: AuthService = inject(AuthService)
  const router = inject(Router);

  let user!: User | null 
  authService.user.subscribe(res => user = res)
  if(user && !user?.main_photo){
    router.navigate(['/upload-photo'])
    return of(false);
  }

  const tokenStoredLocally = authService.get('token')
  const userStoredLocally = authService.get('user')

  console.log('tokenStoredLocally', tokenStoredLocally)

  if(tokenStoredLocally && userStoredLocally){
    authService.getProfile(parseInt(authService.get('user_id'))).subscribe(user => {
      if(!user) {
        router.navigate(['/login'])
        return of(false);
      } else {
        return of(true)
      }
    })
    return of(true)
  }

  router.navigate(['/login'])
  return of(false);
};