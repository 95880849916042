<section class="my-dating-criteria">
    @if(loading$ | async){
      <app-loader></app-loader>
    }

    <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>  
  
    <div class="top" #top>
        <h3><span class="regular">My</span> Dating Criteria</h3>
    </div>

    <form
        class="dating-criteria-form"
        [formGroup]="datingCriteriaFormGroup"
        (ngSubmit)="onSubmit()"
    >
        <div class="fieldsets">
            <!-- <app-attraction-input [user]="user" (emitAttraction)="getAttraction($event)"></app-attraction-input> -->
            
            <fieldset>
                <h2>Age</h2>
                <app-range-input 
                    [ageRange]="ageRange"
                    (minValueSelection)="getMinValueSelection($event)"
                    (maxValueSelection)="getMaxValueSelection($event)"
                ></app-range-input>
            </fieldset>

            <app-city-input [user]="user"></app-city-input>

            <fieldset id="distance-input">
                <label for="distance">Distance</label>
                <mat-slider min="1" max="200" discrete [displayWith]="formatLabel">
                  <input [value]="distanceValue" matSliderThumb (valueChange)="getDistanceValue($event)" formControlName="distanceCtrl">
                </mat-slider>
            </fieldset>
        </div>
    </form>

    @if(successMessage){
      <p class="success-message">{{ successMessage }}</p>
    }

    <div class="submit-btn-container">
        <button 
            routerLink='/subscribe-step-two' 
            routerLinkActive="router-link-active"
            class="submit"
            type="submit"
            (click)="onSubmit()"
        >
            Validate 
        </button>
    </div>

    <app-top-button (scrollRequest)="getScrollRequest($event)"></app-top-button>
</section>