<button class="text-btn" (click)="dialogRef.close('flag')">
  <img src="assets/icons/icon_flag.svg" alt="" class="icon">
  Flag the Story
</button>
<button class="text-btn" (click)="dialogRef.close('block')">
  <img src="assets/icons/icon_block.svg" alt="" class="icon">
  Block the User
</button>
<button class="text-btn" (click)="dialogRef.close('report')">
  <img src="assets/icons/icon_report_block.svg" alt="" class="icon">
  Block & Report the User
</button>
