import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthService } from '../../auth.service';
import { inject } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ErrorReturn } from '../../shared/interfaces/errorReturn';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService)
  const appService = inject(AppService)
  const router = inject(Router)

  const authToken = authService.get('token')

  const errorToDisconnect = [21003, 21013];
  const errorSubscribing = [21012, 20013, 20014, 20015, 20016, 20017, 20018, 20019, 20020];

  // Clone the request and replace the original headers with
  // cloned headers, updated with the authorization.
  const authReq = req.clone({ setHeaders: { 
    'Authorization': authToken ? `Bearer ${authToken}` : '',
    'Content-Type':  'application/json',
    'Accept': 'application/x.nda.v6+json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0',
    } 
  })

  // send cloned request with header to the next handler.
  return next(authReq).pipe(
    tap(
      (event) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if(event instanceof HttpResponse && ((event.body as any).code === 500 || (event.body as any).code === 404)){
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          // if ((event.body as any).error_code === 1002) {
          //   console.log('1002')
          // } else {
            throw Error('error');
          //}
        }
      },
      (error) => {
        console.log(error)
        if (error instanceof HttpErrorResponse) {
          const err: ErrorReturn = {
            code: 500 | 502,
            error_code: error.error.error_code || error.status,
            level: 'danger',
            message: error.error.message || error.message,
          }

          if(error.status === 200) return 

          if (error.status === 404) {
            if (error.error.code === 404) {
              err.error_code = 21001
              return appService.setPopin(err)
            }
          }

          if (!error.error.error_code) {
            const ret = error.error
            let error21012!: number | null
              let error11001!: number | null

            if(ret.length){
              if(ret.indexOf('21012')){
                error21012 = ret.indexOf('21012') > 0 ? 21012 : null
              } else if(ret.indexOf('11001')){
                error11001 = ret.indexOf('11001') > 0 ? 11001 : null
              }
            }
            
            err.error_code = error11001 || error21012 || error.status
          }

          if (
            error.error.error_code === 1002 ||
            error.error.error_code === 32 ||
            error.error.error_code === 1101 ||
            error.error.error_code === 301 ||
            (error.error.error_code === 17 && req.url.indexOf('private') > 0)
          ) {
            return {}
          } else if (errorToDisconnect.includes(error.error.error_code)) {
            authService.authDisconnect()
            router.parseUrl('/login')
          } else if (errorSubscribing.includes(error.error.error_code)) {
            err.level = 'subscription not found'
          } else if (error.error.error_code === 20023) {
            err.level = 'subscription already exists'
          }

          //appService.setPopin(err)
        }
      }
    )
  )
}