import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location, CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { HomeService } from '../../home/home.service';
import { MatchesService } from '../matches.service';
import { AuthService } from '../../auth.service';
import { ProfileService } from '../../profile/profile.service';
import { LayoutService } from '../../layout.service';
import { LoaderService } from '../../loader.service';

import { LoaderComponent } from '../../shared/loader/loader.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { Notification } from '../../shared/interfaces/notification';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [LoaderComponent, CommonModule, GoBackButtonComponent, RouterModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit, OnDestroy {
  loading$ = this.loaderService.loading$
  timeExpression = ''
  severalLikesStoryNotifications: Notification[] = []
  notifications: Notification[] = []
  notificationCount = 0
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop

  constructor(
    private homeService: HomeService,
    private location: Location,
    private matchesService: MatchesService,
    private router: Router,
    private authService: AuthService,
    private profileService: ProfileService,
    private loaderService: LoaderService,
    private layoutService: LayoutService
  ){}
  
  ngOnInit(): void {
    this.getNotifications()
  }

  getNotifications(){
    this.homeService.getNotificationsList().pipe(
      takeUntil(this._destroyed)
    ).subscribe((res) => {
      res.map((n) => {
        n.loaded = false
        n.error = false
        n.updating = false
        n.relationship =
          n.type === 'contact'
            ? 'friend'
            : n.type === 'invitation'
            ? 'pending'
            : 'notfriend'
      })

      this.homeService.notifications = res
      this.notifications = this.homeService.notifications

      this.notifications.forEach(n => {
        const creationDate = new Date(n.created_at * 1000)
        n.timeExpression = this._getSeparatorBody(creationDate)

        if(n.user){
          if(n.readed === 0) this.notificationCount++
        }

        if(n.story && n.story.likes > 0){
          this.matchesService.getContentLikers('story', n.story.id).subscribe(res => {
            if(n.story) n.story.users = res

            if(n.readed === 0) this.notificationCount++
          })
        }
      })
    })
  }

  private _isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getUTCFullYear() === date2.getUTCFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }

  private _getSeparatorBody(date: Date): string {
    let timeStr = ''

    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const twoDaysBeforeToday = new Date()
    twoDaysBeforeToday.setDate(twoDaysBeforeToday.getDate() - 2)

    const threeDaysBeforeToday = new Date()
    threeDaysBeforeToday.setDate(threeDaysBeforeToday.getDate() - 3)

    if (this._isSameDay(date, new Date())) {
      timeStr = 'Today'
    } else if (this._isSameDay(date, yesterday)) {
      timeStr = 'Yesterday'
    } else if (this._isSameDay(date, twoDaysBeforeToday)) {
      timeStr = '2 days ago'
    } else if (this._isSameDay(date, threeDaysBeforeToday)) {
      timeStr = '3 days ago'
    } else {
      timeStr = 'More than a week ago'
    }

    return timeStr
  }

  storeUserAndNavigate(profileId: number){
    this.authService.getProfile(profileId).subscribe(res => {
      this.profileService.profile.next(res)
      this.router.navigate(['/home/my-matches/profile/' + profileId + '/space'])
    })
  }

  // récupération de fake notifs : pour les tests
  // getFakeNotifications(): Observable<Notification[]> {
  //   return this.http.get<Notification[]>('./assets/notifs.json').pipe(map((res) => {
  //     return res
  //   }))
  // }

  goBack(){
    this.homeService.pageDisplayed.next('my-matches')
    this.location.back()
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
