import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Self, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

import { AppService } from '../../app.service';
import { StoryService } from '../story.service';
import { StoryImage } from '../../shared/interfaces/story-image';

@Component({
  selector: 'app-images-holder',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './images-holder.component.html',
  styleUrl: './images-holder.component.scss'
})
export class ImagesHolderComponent implements OnInit {
  @Input() storyImages!: StoryImage[]
  //@Input() selectionId!: number

  @Output() fileInputRequested = new EventEmitter<boolean>(false)
  @Output() selectedImageId = new EventEmitter<number | null>()

  @HostListener('window:resize', ['$event'])

  public getScreenWidth!: number;
  public getScreenHeight!: number;
  
  noPhotoNumber: number | null = null 

  mobileScreenRatio!: number

  selectedImageIdFromService = 0

  maxNumberOfImagesPerStory = 5

  get height(): number {
    return this.element.nativeElement.offsetHeight;
  }
  get width(): number {
    return this.element.nativeElement.offsetWidth;
  }

  constructor(
    private appService: AppService,
    private router: Router,
    private storyService: StoryService,
    @Self() private element: ElementRef
  ){}

  ngOnInit(){
    this.noPhotoNumber = this.maxNumberOfImagesPerStory - this.storyImages.length

    if(!this.appService.desktopDevice){
      this.mobileScreenRatio = window.innerHeight / window.innerWidth
    } 

    this.storyService.selectedImageId.subscribe(res => this.selectedImageIdFromService = res)
  }

  onSubmit(){
    this.storyService.storyCompleted = true
    //console.log(this.selectionId)
    this.storyService.imageToSave.next(true)
  }

  displayImage(id: number | null){
    console.log('displayImage')
    if(id === null) return

    //this.selectionId = id
    //this.selectedImageId.emit(id)
    this.storyService.selectedImageId.next(id)
  }

  removeImageFromStory(id: number) {
    console.log('removeImage')
    const storyToRemove = this.storyImages.filter(img => { return img.id === id})
    const index = this.storyImages.indexOf(storyToRemove[0])
    this.storyImages.splice(index, 1)

    this.storyImages.forEach((i, index) => {
      if(index >= id){
        i.id = index--
      }
    })

    this.storyService.storyImages.next(this.storyImages)

    console.log(id)
    //this.selectionId = id
    this.storyService.selectedImageId.next(id)
    this.selectedImageId.emit(id)

    if(this.storyImages.length === 0 ) {
      this.router.navigate(['/'])
    }
  }

  openFileInput(fileInputIndex: number){
    console.log('openFileInput')
    console.log(fileInputIndex)
    this.fileInputRequested.emit(true) 

    // 2 scenarios : fichier ajouté ou pas
    // si fichier ajouté, sauvegarder l'image précédente (storyImages[index])
    //this.selectionId = this.storyImages.length-1
    //console.log(this.selectionId)
    // console.log(this.storyImages.length)
    // this.selectedImageId.next(this.storyImages.length)
  }
}
