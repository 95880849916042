import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Location, AsyncPipe } from '@angular/common';
import { combineLatest, map, merge, Observable, startWith, Subject, switchMap, takeUntil } from 'rxjs';

import { AuthService } from '../../auth.service';
import { MySpaceService } from '../../my-space/my-space.service';
import { LoaderService } from '../../loader.service';

import { LoaderComponent } from '../loader/loader.component';
import { GoBackButtonComponent } from '../go-back-button/go-back-button.component';
import { TopButtonComponent } from '../top-button/top-button.component';

import { Hobby } from '../interfaces/hobby';
import { User } from '../interfaces/user';

@Component({
  selector: 'app-my-hobbies',
  standalone: true,
  imports: [LoaderComponent, FormsModule, ReactiveFormsModule, GoBackButtonComponent, TopButtonComponent, AsyncPipe],
  templateUrl: './my-hobbies.component.html',
  styleUrl: './my-hobbies.component.scss'
})
export class MyHobbiesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('top') top!: ElementRef
  
  loading$ = this.loaderService.loading$
  hobbies$ = this.mySpaceService.getHobbiesList().pipe(
    map(res => {
      const hobbiesArr = [...res]

      hobbiesArr.forEach(hobby => {
        const subLabelArr = hobby.sub_label?.split(',')
        let formattedSubLabelStr = ''
        
        subLabelArr?.forEach((subLabel, index) => {
          if(index === subLabelArr.length - 1) {
            formattedSubLabelStr += subLabel
          } else {
            formattedSubLabelStr += subLabel + ', '
          }
        })
    
        hobby.sub_label = formattedSubLabelStr
      })

      return hobbiesArr
    } )
  )
  hobbiesWithSelection!: Hobby[]
  userHobbies$!: Observable<Hobby[]>
  checked = false
  checkedId!: number
  user!: User | null
  private readonly _destroyed = new Subject<void>()

  constructor(
    private location: Location,
    private authService: AuthService,
    private mySpaceService: MySpaceService,
    private loaderService: LoaderService
  ){}

  ngOnInit(){
    this.userHobbies$ = this.authService.authMe$.pipe(
      takeUntil(this._destroyed),
      map(res => {
        this.user = res
        return res
      }),
      switchMap((res: User | null) => this.mySpaceService.getHobbiesByUser(res!.id)
      )
    )
  }

  ngAfterViewInit(){
    merge(this.mySpaceService.saved),
    startWith({}),
    combineLatest([this.hobbies$, this.userHobbies$]).subscribe(
      ([hobbies, userHobbies ]) => {
        userHobbies.forEach(userHobby => {
          hobbies.map(h => {
            if(userHobby.id === h.id) {
              h.selected = true
            }
          })
        })

        this.hobbiesWithSelection = hobbies
      }
    )
    
    this.top.nativeElement.scrollIntoView()
  }

  getScrollRequest(request: string){
    if(request === 'scrollToTop') this.top.nativeElement.scrollIntoView()
  }

  clickHobby(hobby: Hobby){
    this.hobbiesWithSelection.map(h => h === hobby ? {...h, selected: h.selected = !h.selected} : h)
    this.saveHobbies()
  }

  goBack(){
    this.location.back()
  }

  saveHobbies(){
    if(!this.user) return
    
    const selectedHobbies = this.hobbiesWithSelection.filter(h => h.selected)
    const ids: number[] = []

    selectedHobbies.forEach(h => {
      ids.push(h.id)
    })

    const idsStr = ids.join(',')
    this.mySpaceService.updateHobbiesByUser(this.user.id, idsStr).pipe(
      takeUntil(this._destroyed),
    ).subscribe({
      next: console.log,
      error: err => console.error(err.message),
      complete: () => console.log("service call 1 used to create service call 2, which is complete")
    })
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
