import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, inject, Renderer2, AfterViewInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { filter, Subject, takeUntil } from 'rxjs';
import { Overlay, OverlayConfig, OverlayModule } from '@angular/cdk/overlay';
import { ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { CdkDrag, CdkDragEnd, DragDropModule, DragRef } from '@angular/cdk/drag-drop';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { Router } from '@angular/router';

import { ServiceService } from '../service.service';
import { StoryElementInterface, StoryService } from './story.service';
import { AppService } from '../app.service';
import { StoriesService } from '../stories.service';

import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { ColorPaletteComponent } from './color-palette/color-palette.component';
import { ImagesHolderComponent } from './images-holder/images-holder.component';
import { ToolbarEditStoryComponent } from './toolbar/toolbar-edit-story/toolbar-edit-story.component';
import { ResizeStoryElementComponent } from './resize-story-element/resize-story-element.component';
import { SaveImageDialogComponent } from '../dialog/save-image-dialog/save-image-dialog.component';
import { PublishStoryDialogComponent } from '../dialog/publish-story-dialog/publish-story-dialog.component';

import { Story } from '../shared/interfaces/story';
import { Color } from '../shared/interfaces/color';

import { InitialStoryPhotoArray, PhotoObject } from './images-holder/story-photos';
import { FastAverageColor } from 'fast-average-color';

@Component({
  selector: 'app-create-story',
  standalone: true,
  imports: [
    CommonModule,
    ConfirmDialogComponent,
    ColorPaletteComponent,
    ImagesHolderComponent,
    ToolbarEditStoryComponent,
    ResizeStoryElementComponent,
    OverlayModule,
    PortalModule,
    CdkDrag,
    DialogModule,
    DragDropModule
  ],
  templateUrl: './create-story.component.html',
  styleUrl: './create-story.component.scss',
})
export class CreateStoryComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() update: Story | null = null
  @Input() homeStoryImages: PhotoObject[] = []
  @ViewChild('storyPhoto') storyPhoto!: ElementRef<HTMLImageElement>
  @ViewChild('textContainer') textContainer!: ElementRef  
  @ViewChild('imageContainer') imageContainer!: ElementRef
  @ViewChild(ToolbarEditStoryComponent) toolbarEditStory!: ToolbarEditStoryComponent
  @ViewChild('mobilePhotoHolder') mobilePhotoHolder!: ElementRef
  @ViewChild('desktopPhotoHolder') desktopPhotoHolder!: ElementRef
  @ViewChild('mobileSection') mobileSection!: ElementRef
  @ViewChild(ImagesHolderComponent) imagesHolder!: ImagesHolderComponent
  @ViewChild('draggable') draggable!: DragRef

  private _destroyed = new Subject<void>()
  currentStoryElement!: StoryElementInterface
  dialog = inject(Dialog);
  private canvas! : HTMLCanvasElement
  ctx!: CanvasRenderingContext2D;
  showPortal = false
  awaitingImage = true
  lines: string[] = [];
  photoStoryBackgroundColor: string | undefined
  componentPortal!: ComponentPortal<ResizeStoryElementComponent>;
  hideMenu = false
  allstoryElements$ = this.storyService.allStoryElements$
  photos = InitialStoryPhotoArray
  storyVisuals: StoryElementInterface[] = []
  currentPhoto: StoryElementInterface = InitialStoryPhotoArray[0]
  photoIndex: number = 0
  colors: Color[] = []
  dragItemEnded = false
  desktopSectionPosition!: { top: number, left: number }
  screenSize!: { height: number, width: number }
  warning = false
  dialogInfo!: {
    title: string,
    content: string
  }
  storyId!: number
  desktopDevice = false

  constructor(
    private serviceService: ServiceService,
    private storyService: StoryService,
    private appService: AppService,
    private overlay: Overlay,
    private renderer: Renderer2,
    private location: Location,
    private storiesService: StoriesService,
    private router: Router
  ) { }

  ngOnInit() {
    this.screenSize = {
      height: window.innerHeight,
      width: window.innerWidth
    }

    // stream of emojis/text used for a single photo
    this.storyService.latestStoryElement.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      this.currentStoryElement = res
      this.open()
    })

    this.storiesService.createStory().subscribe(id => {
      this.storiesService.storyId = id
      this.storyId = this.storiesService.storyId
    })

    //color pallete for text editing
    this.serviceService.getColors().subscribe(res => {
      this.colors = res
    })

    if(this.currentPhoto) this.currentPhoto.type = "visual"

    
    // a retirer : uniquement pour les tests
      // this.currentPhoto = this.currentPhoto as StoryElementInterface
      // this.currentPhoto.src = 'assets/lapin-blanc.jpeg'
    // a retirer : uniquement pour les tests

    if (window.innerWidth >= 1024 && window.innerHeight >= 600) this.appService.desktopDevice = true
    this.desktopDevice = this.appService.desktopDevice
  }

  ngAfterViewInit(): void {
    this.canvas = <HTMLCanvasElement>document.getElementById("canvasSource")
    if(this.desktopDevice){
      this.canvas.width = window.innerHeight / 1.4
      this.canvas.height = window.innerHeight
    } else {
      this.canvas.width = this.screenSize.width
      this.canvas.height = this.screenSize.width * 1.4
    }
    

    this.ctx = this.canvas.getContext("2d") as CanvasRenderingContext2D
  }

  onImageLoad(){
    this.currentPhoto = {src: this.currentPhoto.src!, x: 0, y: 0, angle: 0, type: 'visual', width: this.storyPhoto.nativeElement.width, height: this.storyPhoto.nativeElement.height }
        
    if(!this.currentPhoto.saved){
      const canvas = <HTMLCanvasElement>document.getElementById("canvasSource");
      canvas.width = this.storyPhoto.nativeElement.width
      canvas.height = this.storyPhoto.nativeElement.width * 1.4
      const context = canvas.getContext("2d");

      if (context) {
        this.ctx = context;
      }
    }
  }

  get parentTextBoxStyles() {
    const textElement = this.storyVisuals.find(el => el.type === 'text')

    if(!textElement) return
    if(!textElement.x) textElement.x = 0
    if(!textElement.y) textElement.y = 0

    return {
      'position': 'absolute',  
      'display': 'block',
      'left': textElement.x + 'px' || '',
      'top': textElement.y + 'px' || '',
      'width': textElement.width + 'px',
      'height': textElement.height + 'px',
    }
  }

  get textBoxStyles() {
    const textElement = this.storyVisuals.find(el => el.type === 'text')

    return {
      'display': 'inline-block',
      'vertical-align': 'top',
      'background': 'transparent',
      'font-size.px': textElement?.fontSize,
      'transform': `rotate(${textElement?.angle}deg)`,
      'color': textElement?.color,
      'max-width.px': textElement?.width,
      'text-align': 'left',
    }
  }

  dragEnded(ev: CdkDragEnd, index: number) {
    console.log('dragEnded')
    this.dragItemEnded = true
    //measure the distance from previous to current location of the element to calculate x, y coordinates
    const containerDomrect = ev.source.element.nativeElement.parentElement?.getBoundingClientRect()
    const elementDomrect = ev.source.element.nativeElement.getBoundingClientRect()
    //console.log(containerDomrect, elementDomrect)
    if(!containerDomrect) return
    if(!containerDomrect.x) containerDomrect.x = 0
    if(!containerDomrect.y) containerDomrect.y = 0

    const xCoord = elementDomrect.x - containerDomrect.x
    const yCoord = elementDomrect.y - containerDomrect.y
    //console.log(ev.source.element, xCoord, yCoord, containerDomrect, elementDomrect)
    ev.source.setFreeDragPosition({ x: 0, y: 0 })

    //update coordinates of draggable item relative to the photo
    this.storyVisuals[index].x = xCoord
    this.storyVisuals[index].y = yCoord

    console.log("after",this.storyVisuals)
  }

  open(event: MouseEvent | null = null, el: StoryElementInterface | null = null, index: number | null = null) {
    console.log('open', this.dragItemEnded)

    if(this.desktopDevice){
      if (this.dragItemEnded){
        this.dragItemEnded = false
        return
      }
    }

    const textIndex = this.storyVisuals.findIndex(element => element.type === 'text')

    if (textIndex !== -1 && this.currentStoryElement?.type === 'text') {
      //only 1 text element means that the text element is removed from the array of story elements
      this.storyVisuals.splice(textIndex, 1)
      this.storyService.allStoryElements.next(this.storyVisuals)
    }

    let emoji = this.currentStoryElement
    event?.stopPropagation()

    if (el) {
      emoji = el
      //remove the element from the array of story elements
      this.storyVisuals.splice(index as number, 1)
    }

    //create portal and overlay instance to inject the resize component
    this.componentPortal = new ComponentPortal(ResizeStoryElementComponent);

    let config: OverlayConfig
    if(this.desktopDevice){
       config = new OverlayConfig({
        positionStrategy: this.overlay.position().global().centerHorizontally(),
        hasBackdrop: true,
        width: '100%',
        height: '100%',
        panelClass: 'custom-overlay-container',
        maxHeight: `${this.screenSize.height}px`,
        maxWidth: `${this.screenSize.height / 1.4}px`,
        scrollStrategy: this.overlay.scrollStrategies.block() // à revoir
      })
    } else {
       config = new OverlayConfig({
        hasBackdrop: true,
        width: '100%',
        height: '100%'
      })
    }
    
    const overlayElement = this.overlay.create(config)
    const overlayWithAttachment = overlayElement.attach(this.componentPortal)

    //set the instances in the resize component
    overlayWithAttachment.instance.currentElement = emoji;
    overlayWithAttachment.instance.componentHeight = this.screenSize.width * 1.4;
    overlayWithAttachment.instance.componentWidth = this.screenSize.width;
    emoji && emoji.width && overlayWithAttachment.instance.scale.setValue(emoji.type === 'visual' ? emoji.width! : emoji.fontSize!);
    emoji && emoji.angle && overlayWithAttachment.instance.rotate.setValue(emoji.angle);

    overlayWithAttachment.instance.addElement.subscribe((data: StoryElementInterface) => {
    //replace the thumb sized emojis zith full size emojis and centre n screen
      if(!data) return
      if(!data.width) data.width = 0
      if(!data.height) data.height = 0

      if(this.desktopDevice){
        this.storyVisuals.push({ ...data, src: data.src && data.src?.replace('thumb', 'original'), x: ((this.screenSize.height / 1.4) / 2 - data.width / 2), y: ((this.screenSize.height) / 2 - data.height / 2) })
      } else {
        this.storyVisuals.push({ ...data, src: data.src && data.src?.replace('thumb', 'original'), x: (this.screenSize.width / 2 - data.width / 2), y: ((this.screenSize.width * 1.4) / 2 - data.height / 2) })
      }

      console.log(this.storyVisuals)
      this.storyService.allStoryElements.next(this.storyVisuals)
    })

    //when finished editing the element, remove the overlay and demount the component
    overlayWithAttachment.instance.closePanel.subscribe(() => {
      overlayElement.detach();
      this.hideMenu = false
    });

    this.hideMenu = true
  }

  openResizeEl() {
    this.currentStoryElement = this.storyVisuals.find(el => el.type === 'text')!
    this.open()
  }

  openDialog(e: {type: string, imageToSave?: boolean}){
    if(e.type === 'save' && e.imageToSave) {
      this.openSaveDialog()
    } else if(e.type === 'publish' && e.imageToSave) {
      this.openSaveDialog('publish')
    } else {
      this.openPublishDialog()
    }
  }
  
  openSaveDialog(action?: string) {
    console.log('openSaveDialog')
    this.dialog.open(SaveImageDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res),
    ).subscribe(() => {
      this.awaitingImage = false
      //create canvas and draw the image and text on it then automatically trigger selecting a new photo
      this.saveImage()
      if(action === 'publish'){
        this.dialog.closeAll()
        this.openPublishDialog()
      } else {
        this.imagesHolder.fileUpload.nativeElement.click()
      }
    })
  }

  openPublishDialog(){
    console.log('openPublishDialog')
    this.dialog.open(PublishStoryDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res),
    ).subscribe(() => {
      this.storiesService.publishStory(this.storyId).subscribe(res => {
        console.log('story published: ', res)
        this.router.navigate(['/home'])
      })
    })
  }

  getImageWrapperStyle(){
    if(this.desktopDevice){
      return {
        width: `${this.screenSize.height / 1.4}px`,
        height: `${this.screenSize.height}px`
      }
    } else {
      return {
        width: `${this.screenSize.width}px`,
        height: `${this.screenSize.width * 1.4}px`
      }
    }
  }

  canvasText(element: StoryElementInterface) {
    const lineInfo = this.ctx.measureText(element.content!);
    const lineHeight = lineInfo.fontBoundingBoxAscent + lineInfo.fontBoundingBoxDescent;
    return {lineHeight, lineInfo}
  }

  wrapText(element: StoryElementInterface) {
    console.log(element)
    if(!element.content) element.content = ''
    if(!element.color) element.color = '#FFF'
    
    const words: string[] = element.content.split(' ');
    const lines: string[] = [];
    this.ctx.font = `${element?.fontSize}px Montserrat`;
    this.ctx.fillStyle = element.color
    this.ctx.textBaseline = "top";
    this.ctx.textAlign = "left";
    this.ctx.direction = "ltr";
    const {lineHeight, lineInfo} = this.canvasText(element)
    let currentLine = words![0];

    for (let i = 1; i < words.length; i++) {
      const word = words[i]
      const width = this.ctx.measureText(currentLine + " " + word).width

      if (width < element.width!) {
          currentLine += " " + word
      } else {
          lines.push(currentLine)
          currentLine = word
      }
    }

    lines.push(currentLine)

    if (element.angle ?? 0 > 0) {
      this.ctx.save()
      this.ctx.translate(element.x! + element.width! / 2, element.y! + element.height! / 2)
      this.ctx.rotate(element.angle! * Math.PI / 180)
    
      lines.forEach((line, index) => {
        this.ctx.fillText(line, -element.width! / 2, -element.height! / 2 + (index * lineHeight))
      })
    
      this.ctx.restore()
    } else {
      let ycoord = element.y! + lineInfo.fontBoundingBoxAscent
      lines.forEach((line, index) => {
        if (index===0){
          this.ctx.fillText(line, element.x!, element.y! + lineInfo.fontBoundingBoxAscent)
          ycoord += lineHeight
        }
        else {
          this.ctx.fillText(line, element.x!, ycoord)
          ycoord += lineHeight
        }
      })
    }
  }

  saveImage() {
    console.log('saveImage')
    const imagesList = [this.currentPhoto, this.storyVisuals].flat().filter(el => el?.type === 'visual');
    const textElement = this.storyVisuals.find(el => el.type === 'text') || null;

    //once all the images are loaded, draw them on the canvas finishing with the text element
    console.log(imagesList)

    const firstImagePromise = new Promise<void>((resolve) => {
      const img = new Image()
      img.src = imagesList[0]?.src || ''
      console.log(img, this.ctx)

      img.onload = () => {
        const ratio = img.width / img.height

        if(this.desktopDevice){
          const screenHeight = this.screenSize.height
          const screenWidth = this.screenSize.height / 1.4

          if(!imagesList[0].width) {

            if(imagesList[0].height === screenHeight){
              imagesList[0].width = imagesList[0].height / ratio
            } else if(img.width !== screenWidth){
              imagesList[0].width = screenWidth
            } else {
              imagesList[0].width = img.width
            }
          }
          if(!imagesList[0].height) {
            if(imagesList[0].width === screenWidth){
              imagesList[0].height = imagesList[0].width / ratio
            } else if(img.height > screenHeight){
              imagesList[0].height = screenHeight
            } else {
              imagesList[0].height = img.height
            }
          }
          if(!imagesList[0].x) {
            if(imagesList[0].width < screenWidth){
              imagesList[0].x = (screenWidth - imagesList[0].width) / 2
            } else {
              imagesList[0].x = 0
            }
          }
          if(!imagesList[0].y) {
            if(imagesList[0].height < screenHeight){
              imagesList[0].y = (screenHeight - imagesList[0].height) / 2
            } else {
              imagesList[0].y = 0
            }
          }
        } else {
          if(!imagesList[0].width) {
            if(imagesList[0].height === this.screenSize.width * 1.4){
              imagesList[0].width = imagesList[0].height / ratio
            } else if(img.width !== this.screenSize.width){
              imagesList[0].width = this.screenSize.width
            } else {
              imagesList[0].width = img.width
            }
          }
          if(!imagesList[0].height) {
            if(imagesList[0].width === this.screenSize.width){
              imagesList[0].height = imagesList[0].width / ratio
            } else if(img.height > this.screenSize.width * 1.4){
              imagesList[0].height = this.screenSize.width * 1.4
            } else {
              imagesList[0].height = img.height
            }
          }
          if(!imagesList[0].x) {
            if(imagesList[0].width < this.screenSize.width){
              imagesList[0].x = (this.screenSize.width - imagesList[0].width) / 2
            } else {
              imagesList[0].x = 0
            }
          }
          if(!imagesList[0].y) {
            if(imagesList[0].height < this.screenSize.width * 1.4){
              imagesList[0].y = (this.screenSize.width * 1.4 - imagesList[0].height) / 2
            } else {
              imagesList[0].y = 0
            }
          }
        }
        
        this.ctx.save()
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        this.ctx.drawImage(img, imagesList[0]?.x!, imagesList[0]?.y!, imagesList[0]?.width!, imagesList[0]?.height!)
        this.ctx.restore()

        // calcule une couleur sur la moyenne des teintes de l'image de fond
        let color = ''
        const fac = new FastAverageColor()
        color = fac.getColor(this.canvas).hex
        
        this.ctx.fillStyle = color
        this.ctx.fillRect(0,0,this.canvas.width, this.canvas.height)
        
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        this.ctx.drawImage(img, imagesList[0]?.x!, imagesList[0]?.y!, imagesList[0]?.width!, imagesList[0]?.height!)
        this.ctx.restore()
        
        resolve()
      }
    })
  
    const imagePromises = imagesList.map((image, index) => {
      if(index === 0) return

      return new Promise<void>((resolve) => {
        const img = new Image()
        img.src = image?.src || ''
        console.log(img, this.ctx)

        img.onload = () => {
          const ratio = img.width / img.height
          
          if(!image.angle) image.angle = 0
          if(!image.width) {
            if(image.height === this.screenSize.width * 1.4){
              image.width = image.height / ratio
            } else if(img.width !== this.screenSize.width){
              image.width = this.screenSize.width
            } else {
              image.width = img.width
            }
          }
          if(!image.height) {
            if(image.width === this.screenSize.width){
              image.height = image.width / ratio
            } else if(img.height > this.screenSize.width * 1.4){
              image.height = this.screenSize.width * 1.4
            } else {
              image.height = img.height
            }
          }
          if(!image.x) {
            if(image.width < this.screenSize.width){
              image.x = (this.screenSize.width - image.width) / 2
            } else {
              image.x = 0
            }
          }
          if(!image.y) {
            if(image.height < this.screenSize.width * 1.4){
              image.y = (this.screenSize.width * 1.4 - image.height) / 2
            } else {
              image.y = 0
            }
          }

          if(image?.angle ?? 0 > 0){
            this.ctx.save()
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            this.ctx.translate(image?.x! + image?.width! / 2, image?.y! + image?.height! / 2)
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            this.ctx.rotate(image?.angle! * Math.PI / 180)
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            this.ctx.drawImage(img, -image?.width! / 2, -image?.height! / 2, image?.width!, image?.height!)
            this.ctx.restore()
          } else {
            this.ctx.save()
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            this.ctx.drawImage(img, image?.x!, image?.y!, image?.width!, image?.height!)
            this.ctx.restore()
          }

          resolve()
        };
      });
    });

    firstImagePromise.then(() => {
      Promise.all(imagePromises).then(() => {     
        if (textElement) {
          this.wrapText(textElement)
        }
      }).then(() => {
        const dataURL = this.canvas.toDataURL("image/png")
        //update the photo object and reset all the variables
        this.photos[this.photoIndex].src = dataURL

        this.storiesService.addImageToStory(dataURL, this.storyId).subscribe(() => {
          this.photos[this.photoIndex].saved = true
          this.awaitingImage = true
          this.storyVisuals = []
          this.storyService.allStoryElements.next([])
          this.storyService.storyImages.next(this.photos)
          this.currentPhoto = {src: dataURL}
        })
      });
    })
  }

  generateImageFromCanvas(){
    console.log('generateImageFromCanvas')
    const dataURL = this.canvas.toDataURL("image/png")
    //update the photo object and reset all the variables
    this.photos[this.photoIndex].src = dataURL
    this.photos[this.photoIndex].saved = true
    this.awaitingImage = true
    this.storyVisuals = []
    this.storyService.allStoryElements.next([])
    this.storyService.storyImages.next(this.photos)
    this.currentPhoto = {src : dataURL}
  }
  
  currentImage(story: { image: PhotoObject, index: number }) {
    console.log('currentImage')

    this.currentPhoto = { src: story.image.src || null, x: 0, y: 0, angle: 0, type: 'visual' }
    this.photoIndex = story.index

    //let count = 0
    console.log(this.renderer)
    console.log(this.storyPhoto)
    // this.renderer.listen(this.storyPhoto, 'load', () => {
    //   console.log(this.storyPhoto)
    //   if(count > 1){
    //     this.currentPhoto = {src: story.image.src!, x: 0, y: 0, angle: 0, type: 'visual', width: this.storyPhoto.width, height: this.storyPhoto.height }
        
    //     if(!story.image.saved){
    //       const canvas = <HTMLCanvasElement>document.getElementById("canvasSource");
    //       canvas.width = this.storyPhoto.width
    //       canvas.height = this.storyPhoto.height
    //       const context = canvas.getContext("2d");
  
    //       if (context) {
    //         this.ctx = context;
    //       }
    //     }
  
    //     count++
    //   }
    // })
  }

  publishStory() {
    console.log('publishing story')
  }

  showCloseDialog(){
    this.warning = true
    this.dialogInfo = {
      title: `Are you sure you want to delete your story?`,
      content: `Remember that once deleted, it cannot be recovered.` 
    }
  }

  confirmStoryDeletion(close: boolean){
    if(close){  
      this.location.back()
    }

    this.warning = false
  }

  ngOnDestroy(): void {
    this._destroyed.next()
    this._destroyed.complete()
  }

  //calculate the width of the text element and set the width of the parentTextBoxStyles
  // getLineInfo(element: StoryElementInterface): number {
  //   if(!element.content) element.content = ''
  //   if(!element.color) element.color = '#FFF'
  //   console.log(this.ctx)

  //   const words: string[] = element.content.split(' ');
  //   this.ctx.font = `${element?.fontSize}px Montserrat`;
  //   this.ctx.fillStyle = element.color;
  //   this.ctx.textBaseline = "top";
  //   this.ctx.textAlign = "left";
  //   this.ctx.direction = "ltr";
  //   let currentLine = words![0];

  //   for (let i = 1; i < words.length; i++) {
  //     const word = words[i]
  //     const width = this.ctx.measureText(currentLine + " " + word).width

  //     if (width < element.width!) {
  //         currentLine += " " + word
  //     } else {
  //         this.lines.push(currentLine)
  //         currentLine = word
  //     }
  //   }

  //   this.lines.push(currentLine)
  //   return Math.max(...this.lines.map(line => this.ctx.measureText(line).width))
  // }
}
