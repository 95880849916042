<section class="profile-stories">
    <div class="profile">
      @if(profile){
        <div class="user-information mobile">
          <div class="avatar-container">
            <img [src]="profile.main_photo?.url" alt="">
          </div>
          <div class="info">
            <h3>{{profile.firstname}}</h3>
            <p>{{userAge}} years - {{profile.city?.name}}</p>
            <div class="searching-container">
                <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
                {{profile.attraction | titlecase}}
            </div>
          </div>
        </div>
      }
    </div>

    <div class="stories-container" #storiesContainer>
        @for(s of stories; track s.id){
            <div class="story-container"
              [ngStyle]="getStyles()"
            >
                <app-story [story]="s"></app-story>
            </div>
        }
    </div>
</section>