<section class="logged-in-header">
    <section class="content">
        <div class="brand-container" appClickAnywhere (clickNature)="getClickNature()">
            <img src="../../assets/logo/logo_60x60.webp" alt="{{ serviceName | titlecase }} logo">
            <h2 class="service-name">{{ serviceName | titlecase }}</h2>
        </div>
        <nav class="menu">
          <ul>
            <li>
              <button
                type="button"
                (click)="
                  toggleSidenav(false, 'home');
                  sidenavIsOpened = false;
                "
              >
                <img
                  [src]="
                    pageDisplayed === 'home'
                      ? '../../assets/icons/icon_stories_selected.png'
                      : '../../assets/icons/icon_stories.svg'
                  "
                  alt=""
                  class="icon"
                />
                <h5 class="item-title">Stories</h5>
              </button>
            </li>
            <li>
              <button
                type="button"
                [routerLink]="['my-matches/chat']"
                (click)="
                  toggleSidenav(true, 'my-matches');
                  sidenavIsOpened = true;
                "
                class="matches-btn"
              >
                <img
                  [src]="
                    pageDisplayed === 'my-matches'
                      ? '../../assets/icons/icon_mymatches_selected.svg'
                      : '../../assets/icons/icon_mymatches.svg'
                  "
                  alt=""
                  class="icon"
                />
                <h5 class="item-title">My Matches</h5>

                @if(liveNotifications$ | async){
                  <div class="notification-badge">{{liveNotifications$ | async}}</div>
                }
              </button>
            </li>
            <li>
              <button
                type="button"
                [routerLink]="[isDesktop() ? 'my-space/my-profile' : 'my-space']"
                (click)="
                  toggleSidenav(true, isDesktop() ? 'my-profile' : '');
                  sidenavIsOpened = true;
                "
              >
                <img
                  [src]="
                    pageDisplayed !== 'home' && pageDisplayed !== 'my-matches' && pageDisplayed !== 'new-encounters'
                      ? '../../assets/icons/icon_myspace_selected.svg'
                      : '../../assets/icons/icon_myspace_menu.svg'
                  "
                  alt=""
                  class="icon"
                />
                <h5 class="item-title">My Space</h5>
              </button>
            </li>
          </ul>
        </nav>
    </section>
</section>
 