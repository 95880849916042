import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { startWith, Subject } from 'rxjs';
import { TextFieldModule } from '@angular/cdk/text-field';

import { StoryService } from '../story.service';
import { ServiceService } from '../../service.service';
import { AppService } from '../../app.service';

import { ColorPaletteComponent } from '../color-palette/color-palette.component';
import { ToolbarTopActionsComponent } from '../toolbar/toolbar-top-actions/toolbar-top-actions.component';

import { Color } from '../../shared/interfaces/color';
import { StoryContent } from '../../shared/interfaces/story-content';

@Component({
  selector: 'app-text-editor',
  standalone: true,
  imports: [ColorPaletteComponent, CommonModule, FormsModule, ReactiveFormsModule, ToolbarTopActionsComponent, TextFieldModule],
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.scss'
})
export class TextEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() storyText = new EventEmitter<string>()
  @Output() closePanel = new EventEmitter<void>()
  @ViewChild('textInputBox') textInputBox!: ElementRef<HTMLTextAreaElement>;

  private _destroyed = new Subject<void>()
  colors: Color[] = []
  currentInput: StoryContent | null = null
  contents: StoryContent[] = []
  screenSize!: { height: number, width: number }
  textValue = new FormControl('', { nonNullable: true })
  color = "#FFFFFF"
  desktopDevice = false
  
  constructor(
    private storyService: StoryService,
    private serviceService: ServiceService,
    private appService: AppService
  ) { }

  ngOnInit() {
    this.serviceService.getColors().subscribe(res => {
      this.colors = res
    })

    this.textValue.valueChanges.pipe(
      startWith('')
    ).subscribe((text: string | undefined) => this.storyText.emit(text))

    this.desktopDevice = this.appService.desktopDevice 

    this.screenSize = {
      height: window.innerHeight,
      width: window.innerWidth
    }
  }

  ngAfterViewInit() {
    this.textInputBox.nativeElement.focus()
  }

  getTextColorInfo($event: { colorHex: string; colorType: string; colorIndex: number; }) {
    this.color = $event.colorHex
  }

  get textareaStyles() {
    return {
      'resize': 'none',
      'font-family': '"Montserrat", Arial, Helvetica, sans-serif',
      'overflow': 'hidden',
      'left': '50%',
      'top': '50%',
      'position': 'absolute',
      'background': 'transparent',
      'color': this.color,
      'border': 'none',
      'font-size': '40px',
      'width': this.textValue.value && this.textValue.value.length > 0 ? (this.textValue.value.length).toString() + 'em' : '1em',
      'max-width': this.desktopDevice ? ((this.screenSize.height / 1.4) * 0.7).toString() + 'px' : (this.screenSize.width * 0.7).toString() + 'px',
      'max-height': (this.screenSize.height * 0.7).toString() + 'px',
      'transform': `translate(-50%, -50%) rotate(0deg)`
    };
  }

  confirmText() {
    // update the story service with the new text element and close the panel
    this.storyService.addStoryElement.next({
      src: null,
      type: 'text',
      content: this.textValue.value,
      color: this.color
    })

    this.closePanel.emit()
  }

  ngOnDestroy(): void {
    this._destroyed.next()
    this._destroyed.complete()
  }
}
