import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../../auth.service';
import { AppService } from '../../app.service';
import { PhotoService } from '../photo.service';

import { PhotoCropComponent } from '../../shared/photo-crop/photo-crop.component';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { HeaderComponent } from '../../header/header.component';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { ProgressBarComponent } from '../../shared/progress-bar/progress-bar.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { User } from '../../shared/interfaces/user';
import { Photo } from '../../shared/interfaces/photo';

@Component({
  selector: 'app-upload-photo',
  standalone: true,
  imports: [CommonModule, PhotoCropComponent, ErrorDialogComponent, RouterModule, HeaderComponent, LoaderComponent, ProgressBarComponent, GoBackButtonComponent],
  providers: [NgxImageCompressService],
  templateUrl: './upload-photo.component.html',
  styleUrl: './upload-photo.component.scss'
})
export class UploadPhotoComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() selector = { value: 0, name: 'account' }
  @Input() flow!: string
  
  @ViewChild('fileUpload') fileUpload!: ElementRef
  @ViewChild('photoContainer') photoContainer!: ElementRef

  menu = ['account', 'photos', 'contacts', 'legal']
  fileList: FileList | null = null
  showCropper!: { 
    data: File,
    temp?: string,
    size: { height: number; width: number; }
  } | null
  croppedImage: string = ''
  cropData!: { x: number; y: number; height: number; width: number; preview?: string; }
  loading = false
  openDialog = false
  errorMessage = ''
  errorTitle = ''
  tempCropperPhotoInBase64!: string
  user!: User | null
  firstname = ''
  userId!: number

  photoUrl!: string | undefined

  showHelp = false
  helpIsOpened = false
  subscriptionInProgress = false

  hightlightedSteps: {
    first: boolean, 
    second?: boolean, 
    third?: boolean, 
    fourth?: boolean
  } = { first: true, second: true, third: true, fourth: true }

  photoContainerDimensions!: {width: number, height: number}
  loaderSubscription!: Subscription
  routeUrlSubscription!: Subscription

  constructor( 
    private authService: AuthService,
    private imageCompress: NgxImageCompressService,
    public router: Router,
    private photoService: PhotoService,
    private appService: AppService,
    private route: ActivatedRoute
  ){
    this.authService.authMe$.subscribe(res => {
      this.user = res
    })

    this.firstname = this.authService.get('firstname')
  }

  ngOnInit(){
    console.log('upload photo')
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    this.photoService.cropperState().subscribe((res) => {
      this.showCropper = res
    })

    if(this.authService.get('user')){
      const userStoredLocally = JSON.parse(this.authService.get('user'))
      if(userStoredLocally) this.userId = userStoredLocally.id
    } else {
      //this.router.navigate(['/login'])
    }

    this.routeUrlSubscription = this.route.url.subscribe(res => {
      if(res[0].path === 'subscribe-step-four'){
        this.subscriptionInProgress = true
      }
    })
  }

  ngAfterViewInit(){
    const bounds = this.photoContainer.nativeElement.getBoundingClientRect()

    this.photoContainerDimensions = {
      width: bounds.width,
      height: bounds.height
    }
  }

  openFileInput(){
    this.fileUpload.nativeElement.click()
  }

  onPhotoInputChange(event: Event){
    const element = event.currentTarget as HTMLInputElement;
    this.fileList = element.files

    if(element.files && element.files.length) {
      const file = element.files[0]
      const reader = new FileReader()

      reader.onload = () => {
        const img = new Image()
   
        img.onload = () => {
          if(img.width > 1024 || img.height > 1024){
            console.log(img.src)
            this.imageCompress.compressFile(img.src, DOC_ORIENTATION.Default, undefined, undefined, 1024, 1024).then((r)=>{
              this.tempCropperPhotoInBase64 = r

              this.showCropper = { 
                data: file,
                temp: this.tempCropperPhotoInBase64,
                size: { height: 400, width: 400 }
              }
            })
          } else {
            this.tempCropperPhotoInBase64 = img.src

            this.showCropper = { 
              data: file,
              temp: this.tempCropperPhotoInBase64,
              size: { height: 400, width: 400 }
            }
          }
        }
       
        img.src = reader.result as string
      }

      reader.onloadend = () => {}

      reader.readAsDataURL(file)
      
      // reset l'event
      element.value = ''
    }
  }

  getUserCroppedImage(imageInBase64: string){
    this.croppedImage = imageInBase64
  }
  
  getCropDetails(cropData: {height: number, width: number, x: number, y: number, preview?: string}){
    this.cropData = cropData
  }

  saveCropData() {
    console.log('saveCropData')
    const tempCropperPhotoInBase64Arr = this.tempCropperPhotoInBase64.split(',')

    // /!\ this.showCropper.temp n'est pas défini
    if(!this.showCropper?.temp){ throw new Error('Source image is missing') }

    this.appService.setLoader(true)
    this.photoService.cropper()

    // étape 2 : envoyer la photo en base64
    const data: {
      origin: string //image destination en base64
    } = {
      origin: tempCropperPhotoInBase64Arr[1]
    }

    // crop image on front
    const img = new Image()
    
    const canvas = document.getElementById('canvas') as HTMLCanvasElement
    if(!canvas) return
    const ctx = canvas.getContext('2d')

    if(img && ctx){
      img.onload = () => {
        //ctx.drawImage(img, 23, 23, canvas.width, canvas.height, 0, 0, 200, 200)
        ctx.drawImage(img,this.cropData.x,this.cropData.y,this.cropData.width,this.cropData.height,0,0,200,200)
       
        //const croppedImg = canvas.toDataURL('image/jpeg');
      }

      img.src = this.tempCropperPhotoInBase64
    }
    // crop image on front

    const token = this.authService.get('token')

    this.photoService.saveOriginalPhoto(data, token).subscribe(res => {
      this.appService.setLoader(true)
      if(res.photo.id){
        // étape 3 : cropper la photo
        const croppedImageArr = this.croppedImage.split(',')
        const photoId = res.photo.id

        this.photoService.cropPhoto(data.origin, croppedImageArr[1], this.cropData,photoId).subscribe((res: Photo | undefined) => {
          //this.photoService.croppedPhotoUrl.subscribe(res => this.photoUrl = res)

          if(!res) return
          this.photoService.croppedPhotoUrl.next(res.url)

          this.photoUrl = res.url
          this.photoService.photoCropped.next(true)
          this.authService.savedImageUrl$.next(res.url)
          
          this.appService.setLoader(false)       

          this.router.navigate(['/photo'])
        })
      } else if(res.error?.message) {
        this.errorTitle = 'Subscription failed'
        this.errorMessage = res.error?.message
        this.openDialog = true
        this.appService.setLoader(false)
      } 
    })
  }

  discardPhoto(){
    this.fileList = null
    this.photoService.cropper();
    this.showCropper = null;
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
    this.routeUrlSubscription.unsubscribe()
  }


  /* 
    onSwipeLeft() {
    this.selector.value = Math.min(this.selector.value + 1, this.menu.length - 1);
  }

  onSwipeRight() {
    this.selector.value = Math.max(this.selector.value - 1, 0);
  }

   logOut(){
    this.authService.logged$.next(false)
    this.router.navigate(['/login'])
    this.authService.authDisconnect()
  }
     */
}
