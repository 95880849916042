<div class="left-col"></div>
<div class="right-col" appClickAnywhere (clickNature)="getClickNature($event)">
    <app-progress-bar [hightlightedSteps]="hightlightedSteps"></app-progress-bar>
    <app-go-back-button [routerLink]="'/login'"></app-go-back-button>
    <app-header></app-header>

    @if(loading){
        <app-loader></app-loader>
    }

    <form
        [formGroup]="subFirstFormGroup"
        id="subFirstForm"
        [class.hide]="displayCreatingLoader"
        (ngSubmit)="onSubmit()"
    >
      <div class="inputs">
        <div class="fieldsets">
          <fieldset class="gender-selection">
              <legend for="gender" class="i-am">I am</legend>
              <div class="options">
                  <div class="option">
                      <input type="radio" id="man" name="genderCtrl" value="man" checked formControlName="genderCtrl"/>
                      <label for="man">A man</label>
                  </div>
              
                  <div class="option">
                      <input type="radio" id="woman" name="genderCtrl" value="woman" formControlName="genderCtrl"/>
                      <label for="woman">A woman</label>
                  </div>
              </div>
          </fieldset>   
        </div>

        <app-birthdate-input 
            [user]="user"
            (birthdayDialogData)="showDialog($event)"
        ></app-birthdate-input>

        @if(errorData){
          <app-error-dialog [errorTitle]="errorData.errorTitle" [errorMessage]="errorData.errorMessage"></app-error-dialog>
        }
      </div>
    </form>

    <div class="submit-btn-container">
      <button 
          routerLinkActive="router-link-active"
          class="submit"
          [disabled]="subFirstFormGroup.invalid || error"
          form="subFirstForm"
      >
          Next 
          <span class="material-symbols-rounded arrow-right">
              arrow_right_alt
          </span>
      </button>
  </div>
</div>

<router-outlet></router-outlet>

