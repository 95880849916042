import { Component, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Location, CommonModule } from '@angular/common';

import { AppService } from '../../app.service';
import { HomeService } from '../../home/home.service';
import { MatchesService } from '../matches.service';

import { LoaderComponent } from '../../shared/loader/loader.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { Notification } from '../../shared/interfaces/notification';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [LoaderComponent, CommonModule, GoBackButtonComponent],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit {
  loading = true
  desktopDevice = false
  timeExpression = ''
  severalLikesStoryNotifications: Notification[] = []
  notifications: Notification[] = []

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private homeService: HomeService,
    private location: Location,
    private matchesService: MatchesService
  ){}
  
  ngOnInit(): void {
    this.appService.loader.subscribe(res => this.loading = res)

    this.notifications = this.matchesService.notifications

    // this.getFakeNotifications().subscribe(res => { 
    //   res.map((n) => {
    //     if(n.created_at){
    //       const creationDate = new Date(n.created_at)
    //       this.timeExpression = this._getSeparatorBody(creationDate)
    //     }
    //   })

    //   this.notifications = res 

    //   const storiesNotifications = this.notifications.filter(n => n.type === "crush" && n.story)

    //   //const oneLikeStoriesNotifications = storiesNotifications.filter(n => n.story?.likes === 1)
    //   const severalLikesStoriesNotifications = storiesNotifications.filter(n => n.story?.likes !== 1)

    //   const storyIds: number[] = []
    //   severalLikesStoriesNotifications.forEach(n => {
    //     if(n.story?.id && !storyIds.includes(n.story?.id)){
    //       storyIds.push(n.story?.id)
    //     }
    //   }) 
      
    //   storiesNotifications.forEach(n => {
    //     const storyIdsDuplicate = storyIds

    //     if(n.story){
    //       if(storyIdsDuplicate.includes(n.story?.id)){
    //         this.severalLikesStoryNotifications.push(n)
    //         const index = storyIdsDuplicate.indexOf(n.story.id)
    //         storyIdsDuplicate.splice(index, 1)
    //       }
    //     }
    //   })

    //   const ids: number[] = []
    //   this.notifications.forEach(n => {
    //     if(n.story){
    //       if(!ids.includes(n.story.id)){
    //         const newContent = n.story as NotificationContent
    //         newContent.type = 'story'
    //         newContent.readed = n.readed
    //         this.contents.push(newContent)
    //       }
    //       ids.push(n.story.id)
    //     }

    //     if(n.photo){
    //       if(!ids.includes(n.photo.id)){
    //         const newContent = n.photo as NotificationContent
    //         newContent.type = 'photo'
    //         newContent.readed = n.readed
    //         this.contents.push(newContent)
    //       }
    //       ids.push(n.photo.id)
    //     }
    //   })

    //   this.contents.forEach(c => {
    //     if(c.type === 'story' && c.likes > 0){
    //       this.notificationsService.getContentLikers('story', c.id).subscribe(res => {
    //         c.users = res
    //       })
    //     } else if(c.type === 'photo' && c.likes > 0){
    //       this.notificationsService.getContentLikers('photo', c.id).subscribe(res => {
    //         c.users = res
    //       })
    //     }
    //   })

    //   console.log(this.contents)

    //   this.appService.setLoader(false)
    // })

    this.desktopDevice = this.appService.desktopDevice
  }

  // récupération de fake notifs : pour les tests
  getFakeNotifications(): Observable<Notification[]> {
    return this.http.get<Notification[]>('./assets/notifs.json').pipe(map((res) => {
      return res
    }))
  }

  goBack(){
    this.homeService.pageDisplayed.next('my-matches')
    this.location.back()
  }
}
