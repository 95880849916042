
@if(story && story.photos){
  <button 
  type="button" 
  class="story-photo-container"
  [class.clickable]="story.photos.length > 1"
  (click)="story.photos.length > 1 ? showNextPhoto() : null"
  (touchstart)="story.photos.length > 1 ? swipe($event, 'start') : null" 
  (touchend)="story.photos.length > 1 ? swipe($event, 'end') : null"
  #storyPhotoContainer
  [ngStyle]="getStoryPhotoContainerStyles()"
>
  @if(story){
    <div class="story-top" [class.userIsOwner]="storyOwnedByMe">
      <button type="button" class="close-btn" (click)="close($event)">
        <span class="material-symbols-rounded close">close</span>
      </button>
      <div class="progression-bar" #progressionBar>
        @for(p of story.photos; let i = $index; track p){
          <div class="bar" [class.selected]="currentPhotoIndex === i" [ngStyle]="getBarStyles()"></div>
        }
      </div>
      @if(!storyOwnedByMe){
        <button type="button" class="contextual-menu closed show" #contextualMenuClosed (click)="openStoryDialog($event)">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </button>
      }
    </div>

    @if(story.photos.length){
      <img [src]="story.photos[currentPhotoIndex].url" alt="" class="item">
    } @else {
      <img src="../../../assets/lapin-blanc.jpeg" alt="" class="item">
    }
    <div class="photo-overlay"></div>
    <div class="story-information">
        <div class="information-top">
            @if(!storyOwnedByMe){
              <button type="button" class="avatar" [routerLink]="['profile/',story.user?.id,'space']" (click)="stopClickPropagation($event)">
                @if(story.user?.photo){
                  <img [src]="story.user?.photo" alt="">
                } @else {
                  <div class="photo-container">
                    <img src="../../../assets/icons/icon_user_96X96.png" alt="" class="no-photo">
                  </div>
                }
              </button>
            }
            
            <div class="user-information">
              @if(!storyOwnedByMe){
                <button type="button" class="firstname-btn" [routerLink]="['profile/',story.user?.id,'space']" (click)="stopClickPropagation($event)">
                  <p class="firstname">{{story.user?.firstname}}</p>
                </button>
                
                @if(storyOwner){
                  <p class="age-city">{{storyOwnerAge}} <ng-container i18n="Age unit@@YEARS">years</ng-container> - {{storyOwner.city?.name}}</p>
                }
              }
                
                <div class="stats bottom" [class.userIsOwner]="storyOwnedByMe">
                  @if(loading$ | async){
                    <app-loader></app-loader>
                  } @else {
                    <div class="likes">
                      <button type="button" class="likes-btn" (click)="likers.getValue().length ? openLikersDialog() : null" [class.not-clickable]="!likers.getValue().length">
                        <img src="assets/icons/icon_love_32.svg" alt="" class="icon">
                        <p [class.clickable]="storyOwnedByMe && likers.getValue().length">
                          @if(story.likes && story.likes > 1){
                            {{story.likes}}&nbsp;<span>likes</span><!-- TODO : clé à prévoir -->
                          } @else {
                            {{story.likes}}&nbsp;<span>like</span><!-- TODO : clé à prévoir -->
                          }
                        </p>
                      </button>
                    </div>

                    <div class="views">
                      <img src="assets/icons/icon_view.png" alt="" class="icon">
                      {{story.views}}
                    </div>
                  }
              </div>
            </div>
            @if(!storyOwnedByMe){
              <button type="button" class="like-btn" (click)="story.liked || storyLiked ? unlikeStory($event, story) : likeStory($event, story)" [class.selected]="story.liked || storyLiked">
                <img src="assets/icons/icon_like.svg" alt="" class="like-icon">
              </button>
            }
        </div>
    </div>
  }
</button>
}

  