<section class="story-create">
    <section class="mobile" #mobileSection>
        <app-toolbar 
            [storyImages]="storyImages" 
            [selectedImageId]="selectedImageId"
            [mobilePhotoHolder]="mobilePhotoHolder" 
            (showConfirmDialogFromToolbar)="showConfirmDialogFromToolbar($event)"
            (textEditionActivated)="getTextEditionActivated($event)"
            [ngStyle]="{'z-index': textToolSelected || visualToolSelected ? '3' : '1'}"
        ></app-toolbar><!-- (inputElementFromToolbar)="getInputElementFromToolbar($event)" -->

        @if(storyImages.length !== 0){
            <div class="photo-holder" 
              #mobilePhotoHolder 
              [ngStyle]="{
                'width': screenSize.width + 'px', 
                'height': screenSize.width * 1.5 + 'px'
              }">
                <canvas id="canvas" [width]="screenSize.width" [height]="screenSize.width * 1.5"></canvas>
                <canvas id="canvasVisuals" class="canvas" [width]="screenSize.width" [height]="screenSize.width * 1.5"></canvas>

               <!--  <img 
                    [src]="selectedImageSrc !== '' ? selectedImageSrc : storyImages[storyImages.length - 1].value" 
                    class="main-image" 
                    (click)="clickWhere($event)" 
                    dropzone="move"
                /> -->

                <input 
                    type="file"
                    accept="image/*,text/plain, video/*" 
                    #fileUpload 
                    style="opacity: 0;" 
                    class="file-upload" 
                    [ngStyle]="{ 'pointerEvents': warning ? 'none' : 'initial'}"
                    (change)="addImage($event.target)"
                >
            </div>

            <app-contents-container 
                [class.show]="contents.length > 0 && !showTextEditor"
                [ngStyle]="{'z-index': visualToolSelected ? '2' : '1'}"
                (textEditionActivated)="getTextEditionActivated($event)"
            ></app-contents-container>
            
            <app-text-editor [class.show]="showTextEditor"></app-text-editor>

            <app-images-holder
                [storyImages]="storyImages"
                (fileInputRequested)="getFileInputRequested($event)"
                [ngStyle]="{'z-index': '2'}"
                (selectedImageId)="getSelectedImageId($event)"
            ></app-images-holder>
        }
    </section>

    <div class="overlay" [class.show]="displayOverlay"></div>

    <section class="desktop" #desktopSection>
       <!--  <span class="material-symbols-rounded close" (click)="closeNewStory()">
            close
        </span> -->

        <div class="story-create-step personalize">
            @if(storyImages.length !== 0){      
                <div class="photo-holder" #desktopPhotoHolder>
                    <canvas id="canvas"></canvas>
                    <canvas id="canvasVisuals" class="canvas" width="700" height="500"></canvas>

                    <app-contents-container 
                      [class.show]="contents.length > 0 && !showTextEditor"
                      [ngStyle]="{'z-index': visualToolSelected ? '2' : '1'}"
                      [bounds]="desktopSectionPosition"
                    ></app-contents-container>
            
                    @if(selectedImageId === 0) {
                        <!-- <img 
                            [src]="storyImages[0].value" 
                            class="main-image" 
                            (click)="clickWhere($event)" 
                            dropzone="move"
                        /> -->
                    } @else if(selectedImageId !== 0) {
                        @for(image of storyImages; track image.id){
                            @if(image.id === selectedImageId){
                                <!-- <img 
                                    [src]="storyImages[selectedImageId].value" 
                                    class="main-image" 
                                    (click)="clickWhere($event)" 
                                    dropzone="move"
                                /> -->
                            } 
                        }
                    }
                  
                    <app-images-holder
                        [storyImages]="storyImages"
                        (fileInputRequested)="getFileInputRequested($event)"
                        (selectedImageId)="getSelectedImageId($event)"
                       
                        [ngStyle]="{'z-index': '2'}"
                    ></app-images-holder>
                    <!--  [selectionId]="selectedImageId"  -->

                    <input 
                        type="file"
                        accept="image/*,text/plain, video/*" 
                        #fileUpload 
                        style="opacity: 0;" 
                        class="file-upload" 
                        (change)="addImage($event.target)"
                    >
                </div>
            }      
        </div>
    
        <div class="story-create-step publish">
            <h3>Make your story unforgettable</h3>

            <app-toolbar 
                [storyImages]="storyImages" 
                [desktopPhotoHolder]="desktopPhotoHolder" 
                [imageProcessed]="imageProcessed"
                [selectedImageId]="selectedImageId"
                [visuals]="visuals"
                (showConfirmDialogFromToolbar)="showConfirmDialogFromToolbar($event)"
            ></app-toolbar>
            <!-- (inputElementFromToolbar)="getInputElementFromToolbar($event)" (visualsChange)="getNewVisualsArray($event)"-->
    
            <div class="actions">
                <button class="text-btn" (click)="saveImage()" [disabled]="imageProcessed">Save for later</button>
                <button class="submit" (click)="publishStory()">Publish</button>
            </div>
        </div>
    </section>
</section>

<app-confirm-dialog 
    [class.show]="warning" 
    [title]="confirmDialog.title" 
    [content]="confirmDialog.content"
    (confirmation)="confirmStoryDeletion($event)"
></app-confirm-dialog>
  