<div class="conversation">
    <div class="online">
        <div class="photo-container">
            <img [src]="relatedUser.photo" alt="" class="avatar">
        </div>
    </div>
    <div class="information">
        
        <div class="talk">
            <h5>{{relatedUser.firstname}}</h5>

            <p class="last-message">Great!</p>
            <p class="creation-date">Yesterday 09:42 PM</p>
        </div>

        <div class="notification-badge">1</div>
    </div>
</div>
