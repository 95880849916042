<section class="profile-space">
    <div class="left">
      @if(profile){
        <div class="user-information mobile">
          <div class="avatar-container">
            <img [src]="profile.main_photo?.url" alt="">
          </div>
          <h3 [routerLink]="['/profile/' + profile.id]">{{profile.firstname}}</h3>
          <p>{{userAge}} years - {{profile.city?.name}}</p>
          <div class="searching-container">
              <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
              {{profile.attraction | titlecase}}
          </div>
        </div>

        @if(profile.photos) {
          @if(profile.photos.length > 1){
          <div class="carousel-component-container">
            <carousel
                [infinite]="true"
                [autoPlay]="true"
                [autoPlayAtStart]="true"
                [autoPlayInterval]="2500"
                [displayAutoPlayControls]="false"
                [arrows]="false"
                [counter]="false"
                [slideToShow]="1"
                [gapBetweenSlides]="6"
                [slideMaxWidth]="400"
                [responsive]="true"
            >
            @if(profile.photos){
              @for(i of profile.photos; track $index){
                <div class="carousel-slide">
                    <img [src]="i.url" alt="" class="photo">
                </div>
              }
            }
                
            </carousel>
          </div>
          } @else {
            <div class="photo-container">
              <img [src]="profile.photos[0].url" alt="">
            </div>
          }
        } 
      }
     

        <div class="actions">
            <button 
                alt="Close this profile" 
                class="close-container"
                (click)="rejectProfile()"
            >
                <span class="material-symbols-rounded close">close</span>
            </button>

            <button 
                alt="Like this profile" 
                class="like-container"
                (click)="likeProfile()"
            >
                <img src="assets/icons/icon_love_32.svg" alt="" class="like">
            </button>

            <button 
                alt="Send a message" 
                class="message-container"
                (click)="contactProfile()"
            >
                <img src="assets/icons/icon_message.png" alt="" class="message">
            </button>
        </div>    
    </div>

    @if(profile){
      <div class="right">
        <div class="user-information desktop">
          <h3 [routerLink]="['/profile/' + profile.id]">{{profile.firstname}}</h3>
          <p>{{userAge}} years - {{profile.city?.name}}</p>
          <div class="searching-container">
              <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
              {{profile.attraction | titlecase}}
          </div>
        </div>
          
          <h4>Description</h4>
          <p class="description" #description>
            @if(profile.description){
              {{profile.description}}
            } @else {
              Passport to Love: Seeking an Adventure Partner! I don't follow guidebooks; I forge my own path. Local experiences and cultural immersion are my souvenirs. Authenticity is key; I want to experience the world as it truly is. My ideal travel partner shares this insatiable curiosity, this desire to dive deep into the heart of every destination.
            }
          </p>
  
          <h4>Hobbies</h4>
          <div class="hobbies-container" #hobbiesContainer>
              @for (h of profile.topics; track $index) {
                  <div class="hobby" 
                  [ngStyle]="getStyles()"
                  >
                      {{h.name}}
                  </div>
              }
          </div>
      </div>
    }
</section>