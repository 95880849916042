import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Location, CommonModule } from '@angular/common';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime"; 
dayjs.extend(relativeTime)

import { ChatService } from '../chat.service';
import { HomeService } from '../../home/home.service';
import { LayoutService } from '../../layout.service';

import { ConversationDialogComponent } from '../../dialog/conversation-dialog/conversation-dialog.component';
import { UnmatchDialogComponent } from '../../dialog/unmatch-dialog/unmatch-dialog.component';
import { BlockUserDialogComponent } from '../../dialog/block-user-dialog/block-user-dialog.component';
import { ReportUserDialogComponent } from '../../dialog/report-user-dialog/report-user-dialog.component';
import { DeleteConversationDialogComponent } from '../../dialog/delete-conversation-dialog/delete-conversation-dialog.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { User } from '../../shared/interfaces/user';
import { UserTiny } from '../../shared/interfaces/user-tiny';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-user-tiny',
  standalone: true,
  imports: [RouterModule, GoBackButtonComponent, CommonModule],
  templateUrl: './user-tiny.component.html',
  styleUrl: './user-tiny.component.scss'
})
export class UserTinyComponent implements OnInit, OnDestroy {
  @Input() data!: UserTiny | null | undefined
  @Input() new!: boolean
  @Input() newMessages!: number
  @Input() origin!: string
  @Input() link!: string
  @Output() selectedTalkId: EventEmitter<number> = new EventEmitter
  
  user = new BehaviorSubject<User | null>(null)
  dialog = inject(Dialog)
  loggedIn = false
  matchProfile!: User | undefined
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop
  dateExpression = ''
  timeExpression = ''
  
  constructor(
    private chatService: ChatService,
    private homeService: HomeService,
    private location: Location,
    private router: Router,
    private layoutService: LayoutService,
    private authService: AuthService
  ){}

  ngOnInit(){
    console.log('ngoninit user tiny')

    if(this.data){
      this.authService.getProfile(this.data.user.id).subscribe(user => {
        const profileLastConnectionDate = dayjs(user.connected_at)
        const today = dayjs(new Date())
        const timeDiff = today.diff(profileLastConnectionDate, 'day')
  
        if(timeDiff <= 1) this.loggedIn = true
      })
    } else {
      this.chatService.relatedUser.subscribe(res => {
        this.data = res
  
        if(!res) return
        this.authService.getProfile(res.user.id).subscribe(user => {
          const profileLastConnectionDate = dayjs(user.connected_at)
          const today = dayjs(new Date())
          const timeDiff = today.diff(profileLastConnectionDate, 'day')
    
          if(timeDiff <= 1) this.loggedIn = true
        })
      })
    }

    if(!this.data) return
    const time = dayjs().to(dayjs(this.data.time))
    let dateStr = ''
    let timeStr = ''

    if(time.includes('hour') || time.includes('minute') || time.includes('second')){
      dateStr = 'Today'
    } else if(time === 'a day ago') {
      dateStr = 'Yesterday'
    } else {
      dateStr = dayjs(this.data.time).format('YYYY-MM-DD')
    }

    timeStr = dayjs(this.data.time).format('hh:mm A')

    this.dateExpression = dateStr
    this.timeExpression = timeStr
  }

  /* viewProfile(relatedUserId: number){
    console.log(relatedUserId)
  } */

  checkIfTodayOrYesterday(time: number) {
    if(!this.data) return
    const today = new Date()
    const date = new Date(time).toDateString()
    const now = today.toDateString()
    const yesterday = new Date(today.setDate(today.getDate() - 1)).toDateString()
  
    this.data.today = date === now
    this.data.yesterday = date === yesterday
  }

  openTalk(data?: UserTiny | null){
    if(this.origin === 'conversation') return
    console.log('openTalk')
    if(!data) return
    this.chatService.relatedUser.next(data)

    if(!this.isDesktop()) this.toggleSidenav(true, 'conversation')
    this.router.navigate([this.link])

    if(!data.talking) return
    this.chatService.conversationToShow$.next(data.talking)
  }

  toggleSidenav(open: boolean, componentRequested: string){
    this.chatService.toggleSidenav.next(open)
    this.chatService.componentDisplayed.next(componentRequested)
  }

  openConversationDialog(e: Event) {
    e.stopPropagation()
    this.dialog.open(ConversationDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => res !== ''),
    ).subscribe((res) => {
      switch (res) {
        case 'unmatch':
          this.unmatchDialog()
          break;
        case 'block':
          this.blockUserDialog()
          break;
        case 'report':
          this.blockAndReportUserDialog()
          break;
        case 'delete': 
          this.deleteConversationDialog()
          break;
        default:
          break;
      }
    })
  }

  unmatchDialog() {
    this.dialog.open(UnmatchDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.user.value) return
      this.homeService.deleteRelationship(this.user.value.id).pipe(
        takeUntil(this._destroyed)
      ).subscribe(res => {
        console.log(res)
      })
    })
  }

  blockUserDialog() {
    this.dialog.open(BlockUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.user.value) return
      this.homeService.updateRelationship(this.user.value.id, 'blocked').pipe(
        takeUntil(this._destroyed)
      ).subscribe(res => console.log(res))
    })
  }

  blockAndReportUserDialog() {
    this.dialog.open(ReportUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.user.value) return

      this.homeService.createReportBetweenUsers(this.user.value.id, 'user').pipe(
        takeUntil(this._destroyed)
      ).subscribe(() => {
        if(!this.user.value) return
        this.homeService.updateRelationship(this.user.value.id, 'blocked_with_report').pipe(
          takeUntil(this._destroyed)
        ).subscribe(res => console.log(res))
      })
    }) 
  }

  deleteConversationDialog() {
    this.dialog.open(DeleteConversationDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.chatService.talkingId.value) return
      this.chatService.deleteTalking(this.chatService.talkingId.value).pipe(
        takeUntil(this._destroyed)
      ).subscribe(res => console.log(res))
    }) 
  }

  viewProfile(relatedUserId: number | undefined){
    if(!relatedUserId) return
    this.router.navigate([`/home/my-matches/profile/${relatedUserId}/space`])
    this.chatService.origin = this.origin
  }

  goBack(){
    this.location.back()
    this.homeService.pageDisplayed.next('my-matches')
    this.chatService.toggleSidenav.next(false)
  }

  ngOnDestroy(){
    this._destroyed.next();
    this._destroyed.complete();    
  }
}
