<div class="left-col">
  <div class="overlay"></div>
</div>

<div class="right-col" appClickAnywhere (clickNature)="getClickNature($event)">
    <app-progress-bar [hightlightedSteps]="hightlightedSteps"></app-progress-bar>
    <app-go-back-button [routerLink]="['/subscribe-step-two']"></app-go-back-button>

    <app-header></app-header>

    @if(loading$ | async){
        <app-loader></app-loader>
    }

    <form
        [formGroup]="subThirdFormGroup"   
        (ngSubmit)="onSubmit(subThirdFormGroup.value)"
    >
        <div class="fieldsets">
            <fieldset>
                <label for="gender-select" i18n="Label for the first name input in the subscription form@@FIRSTNAME">First name</label>
                <p class="tip" i18n="Tip for the first name constraints in the subscription form@@FIRSTNAME_TIP">Your first name should contain {{firstnameMinCharNumber}} to {{firstnameMaxCharNumber}} characters, without number, space nor special characters.</p>
                <input type="text" (focus)="setFocusInput()"
                (blur)="setBlurInput()" placeholder="Enter your first name" i18n-placeholder="Placeholder for the first name input in the subscription form@@ENTER_FIRSTNAME" formControlName="firstnameCtrl" (focus)="setFocusInput()">
    
                <label for="gender-select" i18n="Label for the email input in the subscription form@@EMAIL">Email</label>
                <input type="mail" (focus)="setFocusInput()"
                (blur)="setBlurInput()" placeholder="Enter your email" i18n-placeholder="Placeholder for the email input in the subscription form@@ENTER_EMAIL" formControlName="emailCtrl" (focus)="setFocusInput()">
    
                <label for="gender-select" i18n="Label for the password input in the subscription form@@PASSWORD">Password</label>
                <p class="tip" i18n="Tip for the password constraints in the subscription form@@PASSWORD_TIP">Your password should contain {{passwordMinCharNumber}} to {{passwordMaxCharNumber}} characters.</p>
                
                <div class="input-holder">
                    <button type="button" class="password-btn" (click)="showHidePassword()" [ngStyle]="{display: showPassword ? 'flex' : 'none'}">
                        <span class="material-symbols-outlined password-icon">
                        visibility
                        </span>      
                    </button>
                    <button type="button" class="password-btn" (click)="showHidePassword()" [ngStyle]="{display: !showPassword ? 'flex' : 'none'}">
                        <span class="material-symbols-outlined password-icon">
                        visibility_off
                        </span>
                    </button>
    
                    <input 
                        [type]="showPassword ? 'text': 'password'"
                        placeholder="Enter your password" 
                        formControlName="passwordCtrl"
                        (focus)="setFocusInput()"
                        (blur)="setBlurInput()" 
                        i18n-placeholder="Placeholder for the password input in the subscription form@@ENTER_PASSWORD"
                    >
    
                    @if(showPassword){
                        <div class="show-password">{{ subThirdFormGroup.value.passwordCtrl }}</div>
                    }
                </div>
            </fieldset>
        </div>
    </form>
    
    <div class="submit-btn-container">
      @if(openDialog){
        <app-error-dialog [errorMessage]="errorMessage" [errorTitle]="errorTitle"></app-error-dialog>
      }
        <button 
            type="submit" 
            (click)="onSubmit(subThirdFormGroup.value)" 
            class="submit"
            [disabled]="subThirdFormGroup.invalid || error"
            i18n="Text for the button to finalize the subscription process in the subscription form@@CREATE_MY_PROFILE"
        >
            Create my profile
        </button>
    </div>
</div>
