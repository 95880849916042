import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { SwipeService } from '../../swipe.service';
import { AppService } from '../../app.service';

import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { Photo } from '../../shared/interfaces/photo';
import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-new-encounter-photos',
  standalone: true,
  imports: [GoBackButtonComponent, RouterModule],
  templateUrl: './new-encounter-photos.component.html',
  styleUrl: './new-encounter-photos.component.scss'
})
export class NewEncounterPhotosComponent implements OnInit, OnDestroy {
  @ViewChild('image') image!: ElementRef // spécifier le type d'élément
  
  user!: User
  photoIndex = 0
  desktopDevice = false

  private swipeCoord!: [number, number];
  private swipeTime!: number;

  photoId = 0
  photos: Photo[] = []
  routeDataSubscription!: Subscription
  routeQueryParamsSubscription!: Subscription

  constructor(
    private route: ActivatedRoute,
    private swipeService: SwipeService,
    private appService: AppService,
    private location: Location
  ){}

  ngOnInit(){
    this.routeDataSubscription = this.route.data.subscribe(data =>{ 
      this.user = data['user']
    })

    this.routeQueryParamsSubscription = this.route.queryParams.subscribe(params => {
        this.photoId = params['photoId']

        // reorder photos array to start by the clicked photo
        if(!this.user.photos) return
        let count = 0

        for(let i = this.photoId; i <= this.user.photos.length; i++){
          if(count === this.user.photos.length) return
          if(i === this.user.photos.length) i = 0
          
          this.photos.push(this.user.photos[i])
          count++
        }
      }
    )

    // gestion taille écran
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }
    this.desktopDevice = this.appService.desktopDevice
  }

  swipe(event: TouchEvent, when: string): void {
    const coord: [number, number] = [event.changedTouches[0].pageX, event.changedTouches[0].pageY]
    const time = new Date().getTime()

    if(when === 'start') {
      this.swipeCoord = coord
      this.swipeTime = time
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]]
      const duration = time - this.swipeTime
      
      if(duration < 1000
       && Math.abs(direction[0]) > 30 
       && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { 
        if (direction[0] < 0) {
          this.goToPreviousPhoto()
          this.swipeService.swipePrevious()
          
        } else {
          this.goToNextPhoto()
          this.swipeService.swipeNext()
        }
      }
    }
  }

  goBack(){
    this.location.back()
  }

  onClickBack(){
    this.goToPreviousPhoto()
  }

  onClickForward(){
    this.goToNextPhoto()
  }

  goToPreviousPhoto(){
    this.image.nativeElement.style.animation = 'slidePhotoToLeft .5s ease-in-out'

    setTimeout(() => {
      if(!this.user.photos) return

      this.image.nativeElement.style.animation = ''

      if(this.photoIndex === 0) {
        this.photoIndex = this.user.photos?.length-1
      } else {
        this.photoIndex--
      }
    },200);
  }

  goToNextPhoto(){
    this.image.nativeElement.style.animation = 'slidePhotoToRight .5s ease-in-out'

    setTimeout(() => {
      if(!this.user.photos) return

      this.image.nativeElement.style.animation = ''

      if(this.photoIndex === this.user.photos?.length-1) {
        this.photoIndex = 0
      } else {
        this.photoIndex++
      }
    }, 200);
  }

  ngOnDestroy(){
    this.routeDataSubscription.unsubscribe()
    this.routeQueryParamsSubscription.unsubscribe()
  }
}
