<canvas [ngStyle]="awaitingImage ? {'display': 'none'}: {'display': 'block'}" id="canvasSource"></canvas>

<div class="story-container">
  @if(awaitingImage){
    <div>
        <div #imageContainer [ngStyle]="getImageWrapperStyle()" class="image-wrapper">
            @if(!photos[photoIndex].saved){
              <app-toolbar-edit-story (openTextEditor)="openResizeEl()" (closeRequest)="showCloseDialog()" [hideMenu]="hideMenu"></app-toolbar-edit-story>
            }

            <div  class="images-container">
                <img class="story-photo" #storyPhoto [src]="currentPhoto.src" alt="" (load)="onImageLoad()">
        
                @for(img of allstoryElements$ | async; track img; let index = $index){
                    @if(img.type === 'visual'){
                        <div 
                          cdkDrag 
                          #draggable
                          [style.position]="'absolute'"
                          [style.top]="img.y + 'px' || ''"
                          [style.left]="img.x + 'px' || ''"
                          cdkDragBoundary=".images-container"
                          (cdkDragEnded)="dragEnded($event, index)" 
                          class="visual-container"
                        >
                          <button type="button" (click)="open($event, img, index)">
                            <img class="image-emoji" [style.transform]="'rotate(' + img.angle + 'deg)'" [src]="img.src" [height]="img.height" [width]="img.width" alt="">
                          </button>  
                        </div>
                    }
                    
                    @if (img.type === 'text') {
                        <div 
                          #textContainer 
                          [ngStyle]="parentTextBoxStyles" 
                          cdkDrag 
                          (cdkDragEnded)="dragEnded($event, index)" 
                          class="text-container"
                        >
                            <button type="button" (click)="open($event, img, index)">
                              <span [ngStyle]="textBoxStyles">{{img.content}}</span>
                            </button>
                        </div>
                    }
                }
            </div>
        </div>
    </div> 
  }

  @if(showPortal){
    <div class="example-portal-outlet">
      <ng-template [cdkPortalOutlet]="componentPortal"></ng-template>
    </div>
  }

  <app-images-holder (removeImage)="awaitingImage = $event" (openDialog)="openDialog($event)" (currentImageDisplayed)="currentImage($event)"></app-images-holder>
  @if(warning){
    <app-confirm-dialog 
      [title]="dialogInfo.title" 
      [content]="dialogInfo.content"
      (confirmation)="confirmStoryDeletion($event)"
    ></app-confirm-dialog>
  }
</div>

<!-- <section class="desktop" #desktopSection>
        <span class="material-symbols-rounded close" (click)="closeNewStory()">
            close
        </span>

        <div class="story-create-step personalize">
            @if(storyImages.length !== 0){      
                <div class="photo-holder" #desktopPhotoHolder>
                    <canvas id="canvas"></canvas>
                    <canvas id="canvasVisuals" class="canvas" width="700" height="500"></canvas>

                    <app-contents-container 
                      [class.show]="contents.length > 0 && !showTextEditor"
                      [ngStyle]="{'z-index': visualToolSelected ? '2' : '1'}"
                      [bounds]="desktopSectionPosition"
                    ></app-contents-container>
            
                    @if(selectedImageId === 0) {
                        <img 
                            [src]="storyImages[0].value" 
                            class="main-image" 
                            (click)="clickWhere($event)" 
                            dropzone="move"
                        />
                    } @else if(selectedImageId !== 0) {
                        @for(image of storyImages; track image.id){
                            @if(image.id === selectedImageId){
                                <img 
                                    [src]="storyImages[selectedImageId].value" 
                                    class="main-image" 
                                    (click)="clickWhere($event)" 
                                    dropzone="move"
                                />
                            } 
                        }
                    }
                  
                    <app-images-holder
                        [storyImages]="storyImages"
                        (fileInputRequested)="getFileInputRequested($event)"
                        (selectedImageId)="getSelectedImageId($event)"
                       
                        [ngStyle]="{'z-index': '2'}"
                    ></app-images-holder>
                     [selectionId]="selectedImageId" 

                    <input 
                        type="file"
                        accept="image/*,text/plain, video/*" 
                        #fileUpload 
                        style="opacity: 0;" 
                        class="file-upload" 
                        (change)="addImage($event.target)"
                    >
                </div>
            }      
        </div>
    
        <div class="story-create-step publish">
            <h3>Make your story unforgettable</h3>

            <app-toolbar 
                [storyImages]="storyImages" 
                [desktopPhotoHolder]="desktopPhotoHolder" 
                [imageProcessed]="imageProcessed"
                [selectedImageId]="selectedImageId"
                [visuals]="visuals"
               
            ></app-toolbar>
            (inputElementFromToolbar)="getInputElementFromToolbar($event)" (visualsChange)="getNewVisualsArray($event)"
    
            <div class="actions">
                <button class="text-btn" (click)="saveImage()" [disabled]="imageProcessed">Save for later</button>
                <button class="submit" (click)="publishStory()">Publish</button>
            </div>
        </div>
    </section> -->


<!-- <app-confirm-dialog 
    [class.show]="warning" 
    [title]="confirmDialog.title" 
    [content]="confirmDialog.content"
    (confirmation)="confirmStoryDeletion($event)"
></app-confirm-dialog> -->