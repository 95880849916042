import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, Subject, throwError } from 'rxjs';

import { AppService } from '../app.service';

import { User } from '../shared/interfaces/user';
import { Notification } from '../shared/interfaces/notification';

export interface NotificationsResponse {
  code: number,
  invitations: {
    exist: boolean,
    count: number
  },
  messages: {
    exist: boolean,
    count: number
  },
  news: {
    exist: boolean,
    count: number
  },
  new_contacts: {
    exist: boolean,
    count: number
  },
  new_advices: {
    exist: boolean,
    count: number
  }
}

@Injectable({
  providedIn: 'root'
})
export class MatchesService {
  user!: User | null
  notifications: Notification[] = []
  stop$ = new Subject<void>()
  start$ = new Subject<void>()
  matches!: User[]

  constructor(
    private http: HttpClient,
    private appService: AppService,
  ) { }

  getMatches(): Observable<User[]>{
    const now = Math.floor(Date.now() / 1000)

    return this.http.get<{users: User[]}>(`${this.appService.API}/api/relationship/my/${now}/1/10`)
    .pipe(
      map(res => {
        return res.users
      }),
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  getContentLikers(contentType: string, contentId: number): Observable<User[]> {
    return this.http
      .get<{ users: User[] }>(`${this.appService.API}/api/crush/content/${contentType}/${contentId}/1/10`)
      .pipe(
        map(res => {
          return res.users
        }),
        catchError((error) => {
          return throwError(() => error)
        })
      )
  }

  start(): void {
    this.start$.next();
  }
  stop(): void {
    this.stop$.next();
  }
}
