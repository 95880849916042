<div class="form" formGroupName="city">
    <h2>City</h2>
    <div class="input-holder" #input>
        <input 
            type="text"
            [value]="cityCtrl" 
            [formControl]="cityCtrl"
            placeholder="Type your city here..."
        >
        @if(citySelected.name){
          <button class="cancel-btn" (click)="setCity()">
            <span class="material-symbols-outlined cancel">
              cancel
            </span>
          </button>
        }
    </div>
    
    @if (citiesLoading){
        <div class="input-select-choice loading">Searching cities...</div>
    } @else if (noCityFound){
        <div class="no-cities warning"
          [ngStyle]="{
            'bottom': !desktopDevice ? '63px' : 'unset',
            'width': inputWidth + 'px'
          }"
        >
            <button class="close-btn" (click)="setCity()">
              <p>No cities found</p>
              <span class="material-symbols-rounded close">
                close
              </span>
            </button>
        </div>
    } @else if(cityChoice) {
        <div class="cities-list" 
          [ngStyle]="{
            'bottom': !desktopDevice ? '63px' : 'unset',
            'width': inputWidth + 'px'
          }"
        >
            @for(city of cityChoice; track city.id){
              <button type="button" class="city-btn" (click)="setCity(city)">
                <div class="city-choice" >
                  <img src="assets/icons/icon_location.svg" alt="" class="location-icon">

                  <div class="city-information">
                    <div class="left">
                      <div class="city-name">{{city.name}}</div>
                      <div class="city-country">{{ city.country ?? city.country }}</div>
                    </div>
                    
                    <div class="city-zipcode">{{city.location}}</div>
                  </div>
                </div>
              </button>
            }
        </div>
    }
</div>







<!-- <form [formGroup]="cityForm">
    <label>City</label>

    @if(cityChoiceDisplay){
        <div class="input-select-choices">
            @if(!cityChoice){
                <div class="input-select-choice empty">
                    <div class="empty-module" (click)="setCity(city)">
                        <div class="empty-box"></div>
                        <p>No cities found yet. Enter your city to find your location</p>
                    </div>
                </div> div à commenter
            } @else {
                <div class="input-select-choices-holder">
                    @if(citiesLoading){
                        <div class="input-select-choice loading">Searching cities...</div>
                    } @else if(noCityFound){
                        <div class="empty-module warning" (click)="setCity(city)">
                            <div class="empty-box"></div>
                            <p>No cities found</p>
                        </div>
                    } @else {
                        @for(city of cityChoice; track city.id){
                            <div class="input-select-choice" (click)="setCity(city)">
                                <div class="city-datas">
                                    {{ city.name }} ({{ city.country ?? city.country }})
                                </div>
                                <span class="zipcode">{{ city.location }}</span>
                            </div>
                        }
                    }
                </div>
            }
            <div class="cancel-inside" (click)="setCity()">Cancel</div> à commenter
        </div>
    }
    
    <div class="input-holder">
        <input
            type="text"
            placeholder="Type your city here..."
            [value]="cityForm.value.name"
            formControlName="name"
            #inputFocus
            [class.focused]="focusOnInput"
            class="city-input"
        />
        @if(cityForm.value.name){
            <span class="material-symbols-outlined" (click)="setCity()">
                cancel
            </span>
        }
    </div>
  </form> -->


  