import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule, FormControl  } from '@angular/forms';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, of, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime"; 
dayjs.extend(relativeTime)

import { ChatService } from '../chat.service';
import { AuthService } from '../../auth.service';
import { UserTinyService } from '../user-tiny/user-tiny.service';
import { WebsocketService } from '../websocket.service';
import { HomeService } from '../../home/home.service';
import { AppService } from '../../app.service';

import { UserTinyComponent } from '../user-tiny/user-tiny.component';

import { ErrorReturn } from '../../shared/interfaces/errorReturn';
import { Message } from '../../shared/interfaces/message';
import { User } from '../../shared/interfaces/user';
import { UserTiny } from '../../shared/interfaces/user-tiny';

@Component({
  selector: 'app-chat-conversation',
  standalone: true,
  imports: [UserTinyComponent, RouterModule, CommonModule, ReactiveFormsModule ],
  templateUrl: './chat-conversation.component.html',
  styleUrl: './chat-conversation.component.scss',
})
export class ChatConversationComponent implements OnInit, OnDestroy {
  @HostBinding('class.fullscreen') openConservation: boolean = false;
  @ViewChild('messagesHolder') messagesHolder!: ElementRef;
  @ViewChild('textHolder') textHolder!: ElementRef;
  @ViewChild('textareaHolder') textareaHolder!: ElementRef;
  @ViewChild('photoFileInput') photoFileInput!: ElementRef;

  userData = new BehaviorSubject<UserTiny | null | undefined>(undefined)
  talkingId!: number
  messageFormGroup!: UntypedFormGroup;
  messages: Message[] = []
  isFocused = false;
  loaded = false;
  loadingConversation = true;
  user: User | null = null;
  isNew = false;
  sendingMessage = false;
  silentTalking = false;
  silentTalkingReceived = false;
  roomToken: string | null = null;
  tinyLoader = false;
  loadingMore = false;
  noMoreToLoad = false;
  lastMessageId: number | null = null;
  messagePhoto: string | null = null;
  hasMessageSet = false;
  notSubscribe = false;
  subscribeParams: Params | null = null;
  textareaHeight = 48;
  currentUrl = window.location.href;
  //LP = environment.LP;
  relatedUser!: User | null
  private readonly _destroyed = new Subject<void>()
  me!: User | null
  messageSubscription!: Subscription

  constructor(
    private chatService: ChatService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private websocket: WebsocketService,
    private userTinyService: UserTinyService,
    private homeService: HomeService,
    private appService: AppService
  ) {
    this.messageFormGroup = this.formBuilder.group({
      text: new FormControl<string | null>(null),
      photo: new FormControl<File | null>(null)
    })
  }

  ngOnInit() {
    this.authService.authMe$.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      this.me = res
    })

    this.activatedRoute.params.subscribe((param) => {
      const id: string = param['id']

      if(id.indexOf('new') > -1) {
        //cas d'une nouvelle conversation
        this.isNew = true

        const userId = parseInt(id.split('-')[1])
        this.setNewConversation(userId)
        this.chatService.conversationToShow$.next(0)

        // this.authService.getProfile(userId).pipe(
        //   takeUntil(this._destroyed)
        // ).subscribe(res => {
        //   this.setUserDatas(res).subscribe(res => {
        //     this.userData.next(res)
        //   })
          
        //   if(res.talking_id) this.talkingId = res.talking_id
        // })
      } else {
        // cas d'une conversation existante
        console.log('conversation existante')
        this.messages = []

        this.talkingId = Number(id)

        if(this.talkingId) {
          this.loadingConversation = true
          this.messages = []
          this.lastMessageId = null
        }

        this.chatService.conversationToShow = this.talkingId
        this.getAllMessages(this.talkingId, this.lastMessageId)
      }

      this.scrollToBottom(0)

      // this.messageFormGroup = this.formBuilder.group({
      //   text: [null],
      //   photo: [null],
      // })

      this.activatedRoute.queryParams.subscribe((params) => {
        if (params['success'] === '1' || params['success'] === 1) {
          this.subscribeParams = params

          if (params['alreadysub'] && (params['alreadysub'] === 1 || params['alreadysub'] === '1')) {
            const err: ErrorReturn = {
              code: 500,
              error_code: 404,
              level: 'already subscribe',
              message: '',
            }

            this.appService.setPopin(err);
            this.notSubscribe = true
          } else {
            this.chatService.setSubscribe(params['subscription_id']).pipe(
              takeUntil(this._destroyed)
            ).subscribe((res) => {console.log(res)})
          }
        } else if (params['success'] === '0' || params['success'] === 0) {
          const err: ErrorReturn = {
            code: 500,
            error_code: 404,
            level: 'subscribe notfound',
            message: '',
          }

          this.appService.setPopin(err);
        }
      });
    });
  }
  
  setNewConversation(id: number) {
    console.log('setNewConversation')
    this.chatService.conversationToShow = Number(id) // id de conversation

    this.authService.getProfile(Number(id)).pipe(
      takeUntil(this._destroyed)
    ).subscribe((res) => {
      this.user = res
      this.setUserData(this.user).subscribe(res => this.userData.next(res))

      if (res.relationship === 'notfriend' || res.relationship === 'waiting') {
        this.silentTalking = true
        this.isNew = true
        this.messages = []
        this.loadingConversation = false
      } else {
        if (!res.talking_id) {
          this.chatService.createConversation(id).pipe(
            takeUntil(this._destroyed)
          ).subscribe((talkId) => {
            this.talkingId = talkId
            this.getAllMessages(talkId, null)
          });
        } else {
          this.talkingId = res.talking_id
          this.getAllMessages(this.talkingId, null)

          //setTimeout(() => console.log('set new conversation', this.messages), 2048)
        }
      }
    });
  }

  scrollToBottom(toScroll: number) {
    if (this.messagesHolder && this.messagesHolder.nativeElement.scrollHeight !== toScroll) {
      this.messagesHolder.nativeElement.scrollTop = this.messagesHolder.nativeElement.scrollHeight - toScroll;
    } else {
      setTimeout(() => {
        this.scrollToBottom(toScroll);
      }, 512);
    }
  }

  getAllMessages(talkId: number, lastMessageId: number | null, loadingmore: boolean = false) {
    console.log('getAllMessages')
    const toScroll = this.messagesHolder ? this.messagesHolder.nativeElement.scrollHeight : 0

    if (this.messagesHolder && this.messagesHolder.nativeElement.scrollTop < 10) {
      this.messagesHolder.nativeElement.scrollTop = 40
    }

    this.chatService.relatedUserId.pipe(
      takeUntil(this._destroyed),
      switchMap(id => {
        if(!id) return of(null)
        return this.authService.getProfile(id)
      }),
      switchMap(user => {
        if(!user) return of(null)
        return this.setUserData(user)
      })
    ).subscribe(userTiny => {
      this.userData.next(userTiny)
    })

    this.chatService.getMessages(talkId, lastMessageId).pipe(
      takeUntil(this._destroyed)
    ).subscribe((res) => {
      this.loaded = true

      if (res.messages.length === 0 && this.messages.length === 0) {
        this.isNew = true
      } else {
        this.isNew = false
        const tArray = res.messages.concat(this.messages)
        this.messages = tArray
        this.lastMessageId = this.messages[0].id

        this.messages.map(
          (m: Message) => {
            m.text = decodeURIComponent(m.text)

            const time = dayjs().to(dayjs(m.created_at))
            let dateStr = ''
            let timeStr = ''

            if(time.includes('hour') || time.includes('minute') || time.includes('second')){
              dateStr = 'Today'
            } else if(time === 'a day ago') {
              dateStr = 'Yesterday'
            } else {
              dateStr = dayjs(m.created_at).format('YYYY-MM-DD')
            }

            timeStr = dayjs(m.created_at).format('hh:mm A')

            m.date_expression = dateStr
            m.time_expression = timeStr

            if (m.photos) {
              m.photos.error = false
              m.photos.loaded = false
            }
          }
        )
      }

      this.loadingConversation = false

      if (!loadingmore) {
        this.user = res.user
        this.roomToken = res.room
        this.websocketConnection(talkId, toScroll)
        this.silentTalkingReceived = res.silent && res.messages[0] && res.messages[0].user_id === this.user.id
      } else {
        this.tinyLoader = false
        this.noMoreToLoad = res.messages.length < 10
        this.loadingMore = false
      }

      this.scrollToBottom(toScroll)
    })
  }

  websocketConnection(
    talkingId: number,
    toScroll: number,
    msg?: { userId: number; value: string; photo: string }
  ) {
    if(!this.roomToken) return

    this.websocket.connect(Number(talkingId), this.roomToken, null, msg)
    console.log('websocket connect')

    this.messageSubscription = this.websocket.message.pipe(
      takeUntil(this._destroyed)
    ).subscribe((m) => {
      const message = JSON.parse(m.data)
      const timestamp = message.timestamp * 1000

      if (message.code !== 500) {
        const tempMessages = this.messages
        tempMessages.push({
          created_at: timestamp,
          id: message.message.id,
          photos: message.photo,
          text: decodeURIComponent(message.message.text),
          user_id: message.user_id,
          // today: this.checkIfToday(timestamp),
          // yesterday: this.checkIfYesterday(timestamp),
        })

        this.messages = tempMessages
      } else {
        if (message.error_code === 32) {
          this.nonSubscriptionPopup()
        }
      }

      if (this.sendingMessage) {
        this.sendingMessage = false
        this.textHolder.nativeElement.innerHTML = ''
        this.removeMessagePhoto()
        this.isNew = false

        this.messageFormGroup.patchValue({
          text: null,
        });

        this.textHolder.nativeElement.innerHTML = ''
        this.textareaHeight = this.textHolder.nativeElement.offsetHeight
        this.textareaHolder.nativeElement.focus()
      }

      this.checkIfMessage()

      setTimeout(() => {
        this.scrollToBottom(toScroll)
      }, 512)
    });
  }

  nonSubscriptionPopup() {
    this.notSubscribe = true
  }

  checkIfToday(time: number) {
    const today = new Date().toDateString();
    const date = new Date(time).toDateString();
    return date === today;
  }

  checkIfYesterday(time: number) {
    const today = new Date();
    const date = new Date(time).toDateString();
    const yesterday = new Date(today.setDate(today.getDate() - 1)).toDateString();
    return date === yesterday;
  }

  setUserData(user: User): Observable<UserTiny> {
    return of({
      user: {
        firstname: user.firstname,
        type: 'friend',
        id: user.id,
        gendre: user.sex,
        photo: user.main_photo ? user.main_photo.url : null,
      },
      message: null,
      photos: false,
      time: null,
      talking: null,
    })
  }

  focused(value: boolean) {
    this.isFocused = value;
  }

  // imageLoaded(el) {
  //   el.loaded = true;
  // }

  // imageError(el) {
  //   el.error = true;
  // }

  typing(e: Event) {
    console.log(e)
    this.checkIfMessage();
    this.textHolder.nativeElement.innerHTML = this.messageFormGroup.value.text;
    //this.textareaHeight = this.textHolder.nativeElement.offsetHeight;
  }

  checkIfMessage() {
    this.hasMessageSet = this.messagePhoto || (this.messageFormGroup.value.text && this.messageFormGroup.value.text !== '');
  }

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPhotoInputChange($event: any) {
    const reader = new FileReader()
    const [file] = $event.target.files
    reader.readAsDataURL(file)

    reader.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
      const imageResult = reader.result
      const img = new Image()
      img.src = imageResult as string

      img.onload = () => {
        let width = 0
        let height = 0
        const landscape = img.width > img.height

        if (landscape) {
          width = Math.min(500, img.width)
          height = (width / img.width) * img.height
        } else {
          height = Math.min(500, img.height)
          width = (height / img.height) * img.width
        }

        canvas.width = width
        canvas.height = height
        ctx.drawImage(img, 0, 0, width, height)

        this.messagePhoto = canvas.toDataURL('image/jpeg', 0.5)
        this.hasMessageSet = true
      };
    };
  }

  sendMessageCheck() {
    const message = this.messageFormGroup.value.text ? encodeURIComponent(this.messageFormGroup.value.text) : ''
    this.sendingMessage = true

    if (this.silentTalking) {
      // this.profilService
      //   .askForSilentTalking(this.userDatas?.user.id)
      //   .pipe(
      //     catchError((err) => {
      //       if (err.error.error_code === 32) {
      //         this.nonSubscriptionPopup();
      //       }
      //       return of(null);
      //     })
      //   )
      //   .subscribe((res) => {
      //     console.log(res);
      //     this.roomToken = res.response ? res.response.data.room_talking : res.data.room_talking;
      //     this.talkingId = res.response ? res.response.data.talking_id : res.data.talking_id;
      //     this.silentTalking = false;
      //     // this.websocketConnection(this.talkingId, 0, {
      //     //   userId: Number(this.userDatas.user.id),
      //     //   value: message,
      //     //   photo: this.messagePhoto,
      //     // });
      //   });
    } else if (this.silentTalkingReceived) {
      if(!this.talkingId) return
      this.chatService.changeSilentTalking(this.talkingId, '0').pipe(
        takeUntil(this._destroyed)
      ).subscribe(() => {
        this.silentTalkingReceived = false;
        this.sendMessage(message);
      });
    } else {
      this.sendMessage(message);
    }
  }

  onSubmit(){
    this.sendMessageCheck()
  }

  sendMessage(message: string) {
    console.log('sendMessage: ', message)

    if (this.messagePhoto) {
      this.websocket.sendPhotoMessage(this.talkingId!, this.messagePhoto, message)
    } else {
      if(!this.userData.value?.user.id && !this.chatService.relatedUser.value?.user.id) return

      if(this.userData.value?.user.id){
        this.websocket.sendMessage(this.talkingId!.toString(), this.userData.value.user.id, message)
      } else if(this.chatService.relatedUser.value?.user.id) {
        this.websocket.sendMessage(this.talkingId!.toString(), this.chatService.relatedUser.value?.user.id, message)
      }
    }
  }

  removeMessagePhoto() {
    this.messagePhoto = null;
    this.photoFileInput.nativeElement.value = null;
    this.checkIfMessage();
  }

  onTalkScroll($event: Event) {
    const container = $event.target as HTMLElement
    const bottom = container.scrollTop < 200

    if (bottom && !this.loadingMore && !this.noMoreToLoad) {
      this.tinyLoader = true
      this.loadingMore = true

      if(!this.talkingId || !this.lastMessageId) return

      this.getAllMessages(this.talkingId, this.lastMessageId, true)
    }
  }

  redirect() {
    //window.open(this.LP + this.currentUrl, '_self');
  }

  acceptSilentTalking() {
    if(!this.talkingId) return

    this.chatService.changeSilentTalking(this.talkingId, '0').pipe(
      takeUntil(this._destroyed)
    ).subscribe((res) => {
      console.log(res)
      this.silentTalkingReceived = false;

      if(!this.userData.value?.user.id || !this.talkingId) return

      this.userTinyService.throwSuccess({
        value: null,
        type: 'accept',
        user: this.userData.value.user.id,
        talking: this.talkingId,
        feed: null,
      });
    });
  }

  deniedSilentTalking() {
    if(!this.userData.value?.user.id || !this.talkingId) return

    this.userTinyService.throwSuccess({
      value: null,
      type: 'delete',
      user: this.userData.value.user.id,
      talking: this.talkingId,
      feed: null,
    });
    // this.appService.setLoader({
    //   timer: false,
    //   display: true,
    //   value: 'talking',
    //   type: 'delete',
    // });
    this.chatService.changeSilentTalking(this.talkingId, '0').pipe(
      takeUntil(this._destroyed)
    ).subscribe(() => {
      if(!this.talkingId) return

      this.chatService.deleteTalking(this.talkingId).pipe(
        takeUntil(this._destroyed)
      ).subscribe(() => {
        if(!this.user) return
        this.homeService.deleteRelationship(this.user.id).pipe(
          takeUntil(this._destroyed)
        ).subscribe(() => {
          // this.appService.setLoader({
          //   timer: false,
          //   display: false,
          //   value: null,
          //   type: null,
          // });
          this.router.navigate(['/service/chat']);
        });
      });
    });
  }

  ngOnDestroy(){
    this.chatService.conversationToShow$.next(null);

    if(this.messageSubscription) {
      this.messageSubscription.unsubscribe();
      if(this.roomToken) this.websocket.close(this.roomToken);
    }
    
    this._destroyed.next();
    this._destroyed.complete();    
  }
}
