import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"

export function confirmedPasswordValidator() : ValidatorFn {
    return (form: AbstractControl) : ValidationErrors | null => {

      const currentPasswordCtrl = form.get('currentPasswordCtrl')
      const newPasswordCtrl = form.get('newPasswordCtrl')
      const confirmedNewPasswordCtrl = form.get('confirmedNewPasswordCtrl')

      let currentPasswordValue = ''
      let newPasswordValue = ''
      let confirmedNewPasswordValue = ''

      if(currentPasswordCtrl && newPasswordCtrl) {
        currentPasswordValue = currentPasswordCtrl.value
        newPasswordValue = newPasswordCtrl.value

        if(newPasswordValue !== '' && currentPasswordValue !== '' && newPasswordValue === currentPasswordValue){
          return { sameAsCurrent: true }
        } 
      }

      if(newPasswordCtrl && confirmedNewPasswordCtrl){
        newPasswordValue = newPasswordCtrl.value
        confirmedNewPasswordValue = confirmedNewPasswordCtrl.value

        if(confirmedNewPasswordValue !== '' && newPasswordValue !== '' && confirmedNewPasswordValue !== newPasswordValue){
          return { notConfirmed: true } 
        }  
      }

      return null
    }
  }