<section class="my-subscription">
  @if(loading$ | async){
    <app-loader></app-loader>
  }

  @if(!isDesktop()){
    <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>  
  }
  
  <div class="top" #top>
    <h3><span class="regular" i18n="Title for my subscription page in My Space section@@MY_SUB">My</span> Subscription</h3>
  </div>

  <div class="content">
    <div class="item item-subscription">
      <h4 i18n="Title for the subscription management section in my subscription page@@MANAGE_SUB">Manage your subscription</h4>

      <div class="item-content">
        <div class="content-left">
          <img class="swapImages"
            srcset="assets/logo/logo_60x60.webp 300w,
                    assets/logo/logo_100x100.webp 350w,
                    assets/logo/logo.webp 500w"
            sizes="100vw"
            src="assets/logo/logo_60x60.webp"
            width="60"
            height="60"
            alt="{{ serviceName | titlecase }} logo"
            class="service-logo"
          >
        </div>
        <div class="content-right">
          <p class="account-type" i18n="Text for the user's account type in my subscription page@@UNLIMITED_PREMIUM_ACCOUNT">Unlimited Premium Account</p>
          <div class="date-container">
            <span class="material-symbols-rounded arrow">
              arrow_forward_ios
            </span>
            <p class="date">2024-03-24</p><!-- date de début d'abonnement - prévoir un pipe pour le formattage de la date-->
          </div>
        </div>
      </div>    
      <button type="button" class="text-btn">
        <ng-container i18n="Text for the button to unsubscribe to the service in my subscription page@@UNSUB_NOW">Unsubscribe now</ng-container>
      </button>
    </div>
    
    <div class="item item-conditions">
      <h4 i18n="Title for the terms and conditions section in my subscription page@@OUR_T&C">Our Terms & Conditions</h4>

      <div class="item-content">
        <p class="description" i18n="Instruction for accessing the terms and conditions of the service in my subscrption page@@DOWNLOAD_PDF_T&C">Click below to download a PDF version of our current T&C.</p>
      </div>

      <div class="actions">
        <button type="button" class="submit">
          <ng-container i18n="Text for the button to download a pdf of the terms and conditions of the service in my subscription page@@DOWNLOAD_T&C">Download the T&C</ng-container>
        </button>
        <button type="button" class="text-btn">
          <ng-container i18n="Text for the button to access the terms and conditions archives of the service in my subscription page@@READ_T&C_ARCHIVES">Read the T&C archives</ng-container>
        </button>
      </div>
    </div>

    <div class="item item-help">
      <h4 i18n="Title for the help section in my subscription page@@NEED_HELP">Need help?</h4>

      <div class="item-content">
        <p class="description" i18n="Introduction to the customer service contact section in my subscrption page@@CONTACT_CC">For any questions or information, please contact our customer service:</p>

        <h5 i18n="Title for the instruction to contact the customer service contact by email in my subscription page@@BY_EMAIL">By Email</h5>
        <p>Send an email to <a href="mailto:customer@swipi.com" class="email">customer&#64;swipi.com</a></p>

        <h5 i18n="Title for the instruction to contact the customer service contact by phone in my subscription page@@BY_PHONE">By Phone</h5>
        <p><a href="tel:+33970340348" class="phone">+33970340348 (
          <ng-container i18n="Price mention to accompany the phone number of the customer service in my subscription page@@LOCAL_PRICE">price of a local call</ng-container>
          )</a></p>

        <h5 i18n="Title for the instruction to contact the customer service contact by mail in my subscription page@@BY_MAIL">By Mail</h5>
        <p>Service client 94119 13629 Aix-en-Provence 1 - France</p>
      </div>
    </div>
  </div>
</section>