<h3 i18n="Title for the unmatch dialog@@UNMATCH_TITLE">Are you sure you want to unmatch?</h3>

<p class="description" i18n="Description for the unmatch dialog@@UNMATCH_TEXT">This person will no longer be able to contact you</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()" i18n="Text for the button to cancel the action in progress@@CANCEL">
    Cancel
  </button>
  <button class="submit" (click)="dialogRef.close('unmatch')">
    <img src="assets/icons/icon_unmatch.svg" alt="" class="unmatch-icon">
    <ng-container i18n="Text for the button to validate the unmatch in the unmatch dialog@@UNMATCH">Unmatch</ng-container> 
  </button>
</div>
