import { Component, ViewChild, ElementRef, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormsModule, Validators, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { CommonModule, TitleCasePipe } from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import { Subscription } from 'rxjs';

import { AppService } from '../../../app.service';
import { AuthService } from '../../../auth.service';

import { HeaderComponent } from '../../../header/header.component';
import { ProgressBarComponent } from '../../../shared/progress-bar/progress-bar.component';
import { GoBackButtonComponent } from '../../../shared/go-back-button/go-back-button.component';
import { ErrorDialogComponent } from '../../../dialog/error-dialog/error-dialog.component';
import { LoaderComponent } from '../../../shared/loader/loader.component';
import { BirthdateInputComponent } from '../../../shared/birthdate-input/birthdate-input.component';

import { User } from '../../../shared/interfaces/user';
import { Gender } from '../../../shared/enum/Gender';

import { ClickAnywhereDirective } from '../../../click-anywhere.directive';

@Component({
  selector: 'app-auth-subscribe-first',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule, 
    HeaderComponent, 
    RouterOutlet, 
    RouterModule, 
    TitleCasePipe, 
    ProgressBarComponent, 
    GoBackButtonComponent,
    ErrorDialogComponent,
    LoaderComponent,
    MatIconModule,
    BirthdateInputComponent,
    ClickAnywhereDirective
  ],
  templateUrl: './auth-subscribe-first.component.html',
  styleUrls: ['./auth-subscribe-first.component.scss'],
})
export class AuthSubscribeFirstComponent implements OnInit, OnDestroy {
  @Output() emitStepOpenDialog = new EventEmitter<boolean>()

  @ViewChild('userPhoto') userPhoto!: ElementRef

  //subscriptionForm: UntypedFormGroup = new UntypedFormGroup({})
  
  //genderSet: Gender | null = null;
  //passwordRepeat = true;
  // userBilledInfos = {
  //   operationid: null,
  //   productid: null,
  //   subscription_token: null,
  //   transactionid: null,
  //   userdveid: null,
  // }
  //userBilled = false;
  //dateSet: Date | null = null
  //inputFocused: string | null = null
  //inputs = { email: null, msisdn: null, password: null }

  displayCreatingLoader = false
  hightlightedSteps: {
    first: boolean, 
    second?: boolean, 
    third?: boolean, 
    fourth?: boolean
  } = { first: true }

  error = false
  CGU = false
  
  user!: User

  subFirstFormGroup!: FormGroup

  errorTitle: string = ''
  errorMessage: string = ''
  loading = false

  errorData!: {errorTitle: string, errorMessage: string} | null
  loaderSubscription!: Subscription

  constructor(
    private authService: AuthService,
    private appService: AppService, 
    private router: Router,
    private route: ActivatedRoute
  ) {
    //this.appService.setPage('subscribe')
    /* this.inputs.email = AppInitService.config.site.signInput === 'EMAIL';
    this.inputs.msisdn = AppInitService.config.site.signInput === 'MSISDN';
    this.inputs.password = AppInitService.config.site.hasPassword; */

    this.subFirstFormGroup = new FormGroup({
      genderCtrl: new FormControl<string>(Gender.man, [Validators.required]), 
    })
  }

  ngOnInit(){
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)
  }

  getClickNature(clickNature: string){
    if(clickNature === 'anywhere'){
      this.errorData = null
    }
  }

  showDialog(dialogData: {errorTitle: string, errorMessage: string} | null){
    this.errorData = null

    if(dialogData) this.errorData = dialogData
  }

  onSubmit() {
    const data = this.subFirstFormGroup.value

    // format de birthdate : 2005-12-08
    const dateString = `${data.birthdate.birthYearCtrl}-${data.birthdate.birthMonthCtrl}-${data.birthdate.birthDayCtrl < 10 ? '0' + data.birthdate.birthDayCtrl : data.birthdate.birthDayCtrl}`
   
    this.authService.setSubscriptionFormData('sex', data.genderCtrl)
    this.authService.setSubscriptionFormData('birthdate', dateString)
    this.authService.set('sex', data.genderCtrl)
    this.authService.set('birthdate', dateString)

    this.router.navigate(['/subscribe-step-two'], { relativeTo: this.route })
  }

  changeCGU() {
    this.CGU = !this.CGU;
  }
  
  setBlurFocusInput() {}

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
