import { ResolveFn } from "@angular/router";
import { User } from "./shared/interfaces/user";

export const userResolver: ResolveFn<User> = () => {
    // const id = parseInt(route.params['id'])
    // return inject(AuthService).getProfile(id)

    const user: User = {
        id: 0,
        firstname: "Toto",
        birthdate: "05-07-1982",
        email: "toto@yopmail.com",
        photo: "assets/avatar.jpg",
        main_photo: {
          url: "assets/avatar.jpg",
        },
        photos: [
            {
                url: "assets/avatar.jpg"
            }, 
            {
                url: "assets/rabbit-500.jpg"
            }, 
            {
                url: "assets/rabbit2-500.jpg"
            }
        ],
        stories: [],
        sex: "man",
        attraction: "woman",
        country: "France",
        city: {
          id: 0,
          name: "Aix-en-Provence"
        },
        topics: [
          {
            name: "Hiking"
          },
          {
            name: "Painting"
          },
          {
            name: "Running"
          },
          {
            name: "Listening to Music"
          },
          {
            name: "Spending Time in Nature"
          },
          {
            name: "Hiking"
          },
          {
            name: "Painting"
          },
          {
            name: "Running"
          },
          {
            name: "Listening to Music"
          },
          {
            name: "Spending Time in Nature"
          }
        ],
        description: "Passport to Love: Seeking an Adventure Partner! I don't follow guidebooks; I forge my own path. Local experiences and cultural immersion are my souvenirs. Authenticity is key; I want to experience the world as it truly is. My ideal travel partner shares this insatiable curiosity, this desire to dive deep into the heart of every destination."
      }
    return user
  };