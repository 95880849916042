<div class="header">
    <img class="swapImages"
      srcset="assets/logo/logo_60x60.webp 300w,
              assets/logo/logo_100x100.webp 350w,
              assets/logo/logo.webp 500w"
      sizes="100vw"
      src="assets/logo/logo_60x60.webp"
      width="60"
      height="60"
      alt="{{ serviceName | titlecase }} logo"
      class="service-logo"
    >
    <h2 class="service-name">{{ serviceName | titlecase }}</h2>
</div>