@if(loading$ | async){
    <app-loader></app-loader>
}
<section class="my-matches">
    <section class="main">
        <div class="top">
            <h3><span class="regular">My</span> Matches</h3>

            <div class="actions">
                <div class="actions-icon-container">
                  <button type="button" class="notifications-btn" [routerLink]="['notifications']" (click)="showNotifications()">
                    <img src="assets/icons/icon_notification.svg" alt="">

                    @if(liveNotifications$ | async){
                      <div class="notification-badge">{{liveNotifications$ | async}}</div>
                    }
                  </button>
                </div>
            </div>    
        </div>
        
        <div class="content">
            <div class="matches">
                <h4>My matches</h4>
                <div class="profiles">
                  @if(matchesLoading){
                    <app-loader></app-loader>
                  } @else {
                    @for(m of matches; track m.id){
                      <app-match [profile]="m"></app-match> 
                    } @empty {
                      No matches for the moment.
                    }
                  }
                </div>
            </div>
            
            <div class="conversations">
                <h4>My conversations</h4>
                <div class="content">
                  <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </section>
</section>
