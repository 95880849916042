import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';

import { AppService } from '../app.service';

import { User } from '../shared/interfaces/user';
import { Notification } from '../shared/interfaces/notification';

export interface NotificationsResponse {
  code: number,
  invitations: {
    exist: boolean,
    count: number
  },
  messages: {
    exist: boolean,
    count: number
  },
  news: {
    exist: boolean,
    count: number
  },
  new_contacts: {
    exist: boolean,
    count: number
  },
  new_advices: {
    exist: boolean,
    count: number
  }
}

@Injectable({
  providedIn: 'root'
})
export class MatchesService {
  user!: User | null
  notifications: Notification[] = []

  constructor(
    private http: HttpClient,
    private appService: AppService,
  ) { }

  getMatches(): Observable<User[]>{
    const now = Date.now()

    return this.http.get<{users: User[]}>(`${this.appService.API}/api/relationship/my/${now}/1/10`)
    .pipe(
      map(res => {
        return res.users
      }),
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  getUserSuggestionsList(): Observable<User[]> {
    const lang = 'fr'
    //this.user = JSON.parse(this.authService.get('user'))

    //if(!this.user) return of([])

    return this.http
      .get<{ users: User[] }>(`${this.appService.API}/api/relationship/propositions_other/${lang}/1/30`)
      .pipe(
        map((res) => {
          console.log(res)
          return res.users
        }),
        catchError((error) => {
          return throwError(() => error)
        })
      )
  }

  getNotificationsList(): Observable<Notification[]> {
    return this.http
      .get<{ notifications: Notification[] }>(`${this.appService.API}/api/notifications/details/1/30`)
      .pipe(
        map((res) => {
          res.notifications.map(n => {
            if(n.type === 'invitation') {
              const index = res.notifications.indexOf(n)
              res.notifications.splice(index, 1)
            }
          })
          return res.notifications
        }),
        catchError((error) => {
          return throwError(() => error)
        })
      )
  }

  getContentLikers(contentType: string, contentId: number): Observable<User[]> {
    return this.http
      .get<{ users: User[] }>(`${this.appService.API}/api/crush/content/${contentType}/${contentId}/1/10`)
      .pipe(
        map(res => {
          return res.users
        }),
        catchError((error) => {
          return throwError(() => error)
        })
      )
  }

  // getNotificationsCount(): Observable<{
  //   messages: { exist: boolean; count: number };
  //   invitations: { exist: boolean; count: number };
  //   new_contacts: { exist: boolean; count: number };
  //   news: { exist: boolean; count: number };
  // }> {
  //   const now = Date.now();

  //   return this.http
  //     .get<NotificationsResponse>(`${this.appService.API}/api/notifications/general/${now}/0/fr`)
  //     .pipe(
  //       map((res) => {
  //         return res;
  //       }),
  //       catchError((error) => {
  //         return throwError(() => error)
  //       }),
  //     );
  // }
}
