import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';

import { AppService } from '../app.service';

import { User } from '../shared/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class EncountersService {
  constructor(
    private http: HttpClient,
    private appService: AppService,
  ) { }

  getSuggestions(page: number, offset: number): Observable<User[]>{
    const lang = 'fr'

    return this.http.get<{users: User[]}>(`${this.appService.API}/api/relationship/suggestions/${lang}/${page}/${offset}`)
    .pipe(
      map(res => {
        return res.users
      }),
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }
  
  getRelationshipStatus(relatedUserId: number): Observable<string>{
    return this.http.get<{ relationships: string }>(`${this.appService.API}/api/relationship/status/${relatedUserId}`)
      .pipe(
        map(res => {
          console.log(res)
          return res.relationships
        }),
        catchError((error) => {
          return throwError(() => error) 
        })
      )
  }

  markProfileAsSeen(relatedUserId: number): Observable<{message: string}>{
    const body = {
      related_user_id: relatedUserId.toString()
    }

    return this.http.post(`${this.appService.API}/api/relationship/seen`, body)
      .pipe(
        map(() => {
          return {message: `User ${relatedUserId} was seen`}
        }),
        catchError((error) => {
          return throwError(() => error) 
        })
      )
  }
  
  // getFakeEncounters(): Observable<User[]> {
  //   return this.http.get<User[]>('./assets/encounters.json').pipe(map((res) => {
  //     return res
  //   }))
  // }
}
