<section class="notifications">
  <app-go-back-button (click)="goBack()"></app-go-back-button>

  <div class="top">
      <h3><span class="regular" i18n="Title for the notifications drawer in My Matches section@@MY_NOTIFICATIONS">My</span> Notifications</h3>
  </div>

  @if(loading$ | async){
    <app-loader></app-loader>
  }    

  @if(notifications.length === 0){
    <p i18n="Title for the notifications drawer when no notification were received in My Matches section@@NO_NOTIFICATION">No notification</p>
  } @else {
    <section class="notifications-container">
      <hr class="separation-line">
      @for(n of notifications; track n){
        @if(n.type === 'contact'){
          @if(n.user){
            <button type="button" class="item" [ngStyle]="{borderColor: n.readed === 0 ? '#FFC46A': 'transparent'}" (click)="storeUserAndNavigate(n.user.id)"  routerLinkActive="router-link-active">
              <div class="photo-container">
                @if(n.user.photo){
                  <img [src]="n.user.photo" alt="">
                } @else {
                  <img src="../../../assets/icons/icon_user_96X96.png" alt="" class="no-photo">
                }
              </div>
              <div class="message">
                <div class="description" i18n="Text for a new match notification for the notifications drawer@@NEW_MATCH">
                  You have a new match with <span class="firstname">{{n.user.firstname}}</span>
                </div>
                <div class="time">
                  {{n.timeExpression}}
                </div>
              </div>
            </button>
            <hr class="separation-line">
          }
        } @else if (n.type === 'crush') {
            @if(n.story && n.story.likes > 0){
              <button class="item story" [ngStyle]="{borderColor: n.readed === 0 ? '#FFC46A': 'transparent'}" (click)="getShowStoryRequest(n.story)">
                <div class="left">
                  @if(n.story.users && n.story.users.length > 0){
                    <div class="photo-container crush-photo-container">
                      @if(n.story.users.length > 1){
                        <div class="overlay">+ {{n.story.users.length -1}}</div>
                      }
                      
                      <img [src]="n.story.users[0].photo" alt="">
                    </div>
                  }
      
                  <div class="message">
                    <div class="description">
                      @if(n.story.likes > 1){
                        <ng-container i18n="Text for several story crushes notification for the notifications panel@@X_PERSONS_LIKED_STORY">{{n.story.likes}} persons liked your story</ng-container>
                      } @else {
                        <ng-container i18n="Text for one story crush notification for the notifications panel@@PERSON_LIKED_STORY">1 person liked your story</ng-container>
                      }
                    </div>
                    <div class="time">
                      {{n.timeExpression}}
                    </div>
                  </div>
                </div>

                <div class="right">
                  <div class="story-photo">
                    <img [src]="n.story.photos[0]" alt="">
                  </div>
                </div>
              </button>
              <hr class="separation-line">
            }
          } @else if(n.type === 'suggestion'){
            @if(n.user){
              <button 
                type="button" 
                class="item" 
                [ngStyle]="{borderColor: n.readed === 0 ? '#FFC46A': 'transparent'}" 
                (click)="storeUserAndNavigate(n.user.id)" 
                routerLinkActive="router-link-active"
              >
                <div class="photo-container">
                  @if(n.user.photo){
                    <img [src]="n.user.photo" alt="">
                  } @else {
                    <img src="../../../assets/icons/icon_user_96X96.png" alt="" class="no-photo">
                  }
                </div>
  
                <div class="message">
                  <div class="description" i18n="Text for the invitation notification for the notifications drawer@@SEEN_YOUR_PROFILE">
                    <span class="firstname">{{n.user.firstname}}</span> has seen your profile
                  </div>
                  <div class="time">
                    {{n.timeExpression}}
                  </div>
                </div>
              </button>
              <hr class="separation-line">
            }
          }
        }
    </section>
  }
</section>
  