<div class="crop-holder">
  <div class="cancel">
    <button type="button" (click)="discardPhoto()">
      <span class="material-symbols-rounded arrow-left">
        arrow_left_alt
      </span>
    </button>
  </div>
  <app-photo-crop
      [imageSource]="uploadedPhotoSrc"
      (userCroppedImageEvent)="getUserCroppedImage($event)"
      (cropDetailsEvent)="getCropDetails($event)"
      [photoContainerDimensions]="{width: isDesktop() ? 400 : 250, height: isDesktop() ? 400 : 250}"
  ></app-photo-crop>
  <div class="submit-btn-container">
    <button type="submit" class="submit" (click)="saveCropData()">
        Crop and save
    </button>
  </div>
</div>