import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';

import { ChatService } from './chat.service';
import { UserTinyService } from './user-tiny/user-tiny.service';
import { LayoutService } from '../layout.service';
import { LoaderService } from '../loader.service';

import { UserTinyComponent } from './user-tiny/user-tiny.component';
import { LoaderComponent } from '../shared/loader/loader.component';

import { Talk } from '../shared/interfaces/talk';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [RouterModule, UserTinyComponent, MatSidenav, MatSidenavModule, CommonModule, LoaderComponent],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('conversationsContainer') conversationsContainer!: ElementRef
  @ViewChild('conversationSidenav') conversationSidenav!: MatSidenav
  @ViewChildren('conversationDiv') conversationDivs!: QueryList<ElementRef>

  loading$ = this.loaderService.loading$
  talks: Talk[] = [];
  loading = true;
  talk!: Talk | undefined
  conversationSidenavToggled = false
  isDesktop = this.layoutService.isDesktop
  currentPage = 1
  noMoreToLoad = false
  loadingMore = false
  offset = 10
  conversationToShow$ = this.chatService.conversationToShow$
  private readonly _destroyed = new Subject<void>()

  constructor(
    private chatService: ChatService,
    private userTinyService: UserTinyService,
    private layoutService: LayoutService,
    private loaderService: LoaderService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.chatService.getConversations(this.currentPage,this.offset).pipe(takeUntil(this._destroyed)).subscribe((res) => {
      this.talks = res
      console.log(this.talks)
      this.loading = false
      
      // ne garder que les conversations qui comportent au moins un message
      const talkWithMessages = this.talks.filter(t => t.last_message !== null)
      talkWithMessages.map((t) => {
        const text =
          t.last_message && t.last_message.content
            ? decodeURIComponent(t.last_message.content).slice(0, 18) + '...'
            : null

        t.myMessage = t.last_message ? t.last_message.user_id !== t.user.id : false
        t.userDatas = {
          user: {
            firstname: t.user.firstname,
            type: 'friend',
            id: t.user.id,
            gendre: t.user.sex,
            photo: t.user.photo,
          },
          message: text,
          photos: t.last_message ? !!t.last_message.photos : false,
          time: t.last_message ? t.last_message.created_at : null,
          talking: t.id,
        };
      });

      this.talks = talkWithMessages
      this.talks.reverse()

      if(this.isDesktop()) {
        this.conversationSidenav.open()
        this.chatService.toggleSidenav.next(true)
      }

      this.chatService.toggleSidenav.pipe(
        takeUntil(this._destroyed)
      ).subscribe((res: boolean) => {
        this.conversationSidenavToggled = res

        if(this.conversationSidenav) {
          if(res) this.conversationSidenav.open()
          else this.conversationSidenav.close()
        }
      })
    });
    
    this.chatService.conversationToShow$.pipe(takeUntil(this._destroyed)).subscribe((res) => {
      console.log(res)
      this.loading = !!res
      //this.showConversation = !!res
      //this.chatService.conversationToShow$.next(res)

      //if(res === 0) this.chatService.conversationToShow$.next(res)//this.showConversation = true
      //this.talkUserId = res
    });

    this.userTinyService.success.pipe(
      takeUntil(this._destroyed)
    ).subscribe((res) => {
      if(res.type === 'accept') {
        const silentTalking = this.talks.find((t) => t.user.id === res.user)

        if(!silentTalking) return
        //silentTalking.warning_silent_talking = false
      }

      if(res.type === 'delete') {
        this.talks = this.talks.filter((t) => t.user.id !== res.user)
      }
    });
  }

  ngAfterViewInit(){
    this.renderer.setAttribute(this.conversationsContainer.nativeElement, 'scrollTop', `-${this.conversationsContainer.nativeElement.scrollHeight}px`)
  }

  onScroll(e: Event){
    const scrollEvent = e.target as HTMLDivElement
    const scrollValue = (scrollEvent).scrollTop;
    const bottom = scrollValue > scrollEvent.scrollHeight - this.conversationsContainer.nativeElement.getBoundingClientRect().height;

    if(this.talks.length < this.offset) return

    if(bottom && !this.loadingMore && !this.noMoreToLoad){
      this.loadingMore = true
      this.currentPage++
      this.chatService.getConversations(this.currentPage,this.offset).subscribe(res => {
        this.talks = this.talks.reverse().concat(res)
      
        if(res.length < this.offset) this.noMoreToLoad = true

        this.loaderService.hide()
        if(!this.loadingMore) {
          window.scrollTo(0, 0)
        }
        this.loadingMore = false

        // ne garder que les conversations qui comportent au moins un message
        const talkWithMessages = this.talks.filter(t => t.last_message !== null)
        talkWithMessages.map((t) => {
          const text =
            t.last_message && t.last_message.content
              ? decodeURIComponent(t.last_message.content).slice(0, 18) + '...'
              : null

          t.myMessage = t.last_message ? t.last_message.user_id !== t.user.id : false
          t.userDatas = {
            user: {
              firstname: t.user.firstname,
              type: 'friend',
              id: t.user.id,
              gendre: t.user.sex,
              photo: t.user.photo,
            },
            message: text,
            photos: t.last_message ? !!t.last_message.photos : false,
            time: t.last_message ? t.last_message.created_at : null,
            talking: t.id,
          };
        });

        this.talks = talkWithMessages

        this.talks.reverse()
      })
    }
  }

  openTalk(talk: Talk){
    this.talk = talk
    this.setAsRead(talk)
    this.chatService.toggleSidenav.next(true)
    this.chatService.relatedUser.next(this.talk.userDatas)
    this.chatService.setConversationToShow(this.talk.id)

    console.log(this.talks)
  }

  setAsRead(talk: Talk): void {
    if(!talk.readed) {
      talk.readed = true;
      this.chatService.setAsRead(talk.id, talk.last_message.id).pipe(
        takeUntil(this._destroyed)
      ).subscribe();
    }
  }

  ngOnDestroy(){
    this._destroyed.next();
    this._destroyed.complete();    
  }

  /* getTalkSelected(e: number | null | undefined) {
    this.talkId = e

    this.talk = this.talks.find(t => t.id === this.talkId)

    this.conversationSidenav.open()
    this.showConversation = true
  } */

  // onSwipeLeft(user) {
  //   user.actions = true;
  // }

  // onSwipeRight(user) {
  //   user.actions = false;
  // }

  // showHideHeader($event) {
  //   this.hideHeader = $event;
  // }
  
  // chatShow() {
  //   this.showConversation = true;
  // }

}
