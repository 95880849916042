<app-progress-bar [hightlightedSteps]="hightlightedSteps"></app-progress-bar>

<div class="upload-photo">
    <app-go-back-button [routerLink]="['/subscribe-step-three']"></app-go-back-button>
    <app-header></app-header>

    <h3 i18n="Title for the page where the user uploads his main profile photo@@UPLOAD_BEST_PHOTO">
      Upload your best photo
      <button type="button" class="help-btn" (click)="helpIsOpened = true;">
        <span class="material-symbols-outlined help"> 
          help
        </span>
      </button>
    </h3>
  
    <!-- <div class="cancel">
      @if(showCropper){
      <button type="button" class="close-btn" (click)="discardPhoto()">
        <span class="material-symbols-rounded close">
          close
        </span>
      </button>
    }
    </div> -->
  
    <div class="photo-container" #photoContainer>
        @if(loading$ | async){
          <app-loader></app-loader>
        } @else {
          @if(!fileList){
            <button type="button" class="no-image-container" (click)="openFileInput()">
                <button type="file" class="icon-gallery-add-photo">
                    <img src="assets/icons/icon_gallery.svg" alt="">
                    <div class="plus-badge">
                        <span class="material-symbols-rounded more">
                            add
                        </span>
                    </div>
                </button>
            </button>
          }
  
          <router-outlet></router-outlet>
        }
      </div>

    <input 
        type="file"
        accept="image/png, image/jpeg" 
        #fileUpload 
        style="opacity: 0;" 
        class="file-upload" 
        (change)="onPhotoInputChange($event)"
    >
    <div id="myImage"></div>
    
    <div class="submit-btn-container" #submitBtnContainer [ngStyle]="{display: hideSaveButton ? 'none' : 'block'}">
        <button type="submit" class="submit" [disabled]="!fileList">
          <ng-container i18n="Text for the button to save the main profile photo@@SAVE">
            Save
          </ng-container>
        </button>
    </div>

    <div class="add-photo-help" [class.hide]="!helpIsOpened">
      <div class="top-container">
        <button type="button" class="close-btn" (click)="helpIsOpened = false;">
          <span class="material-symbols-rounded close">
            close
          </span>
        </button>
      </div>

      <div class="content">
          <div class="icon-container">
              <img src="assets/icons/icon_gallery.svg" alt="" class="icon_gallery_pink">
          </div>
          <h3 i18n="Title for the photo upload help popup@@ADD_PHOTO">Add a photo</h3>
          <p i18n="Instructions for the photo upload help popup@@TEXT_PHOTO">Please select a photo with good quality, lighting and one where we can clearly see you.</p>
      </div>
    </div>

    <div class="overlay" [class.show]="helpIsOpened"></div>
</div>
