import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AuthService } from '../../../auth.service';

import { GoBackButtonComponent } from '../../../shared/go-back-button/go-back-button.component';

import { User } from '../../../shared/interfaces/user';
import { emailValidator } from '../../../shared/validators/emailValidator';

@Component({
  selector: 'app-edit-email',
  standalone: true,
  imports: [GoBackButtonComponent, ReactiveFormsModule],
  templateUrl: './edit-email.component.html',
  styleUrl: './edit-email.component.scss'
})
export class EditEmailComponent implements OnInit, OnDestroy {
  emailFormGroup!: FormGroup
  user: User | null = null
  emailUpdated = false
  private readonly _destroyed = new Subject<void>()

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private location: Location
  ){
    this.emailFormGroup = new FormGroup({
      emailCtrl: new FormControl(this.user?.email || '', [Validators.required, Validators.minLength(2), Validators.maxLength(25), emailValidator()]),
    })
  }

  ngOnInit(){
    this.authService.authMe.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      this.user = res

      this.emailFormGroup.patchValue({emailCtrl: this.user?.email})
      
      if(!this.user){
        this.route.data.pipe(
          takeUntil(this._destroyed)
        ).subscribe(data => this.user = data['user'])
      }
    })
  }

  goBack(){
    this.location.back()
  }

  onSubmit(){
    this.authService.setSubscriptionFormData('email', this.emailFormGroup.value.emailCtrl)
    this.authService.authUpdate().subscribe(res => {
      this.emailUpdated = true
      this.user = res
    })
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
