import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, inject, OnInit } from '@angular/core';

import { MySpaceService } from '../../my-space/my-space.service';

@Component({
  selector: 'app-profile-photos-dialog',
  standalone: true,
  imports: [],
  templateUrl: './profile-photos-dialog.component.html',
  styleUrl: './profile-photos-dialog.component.scss'
})
export class ProfilePhotosDialogComponent implements OnInit {
  // create reference to dialog and pass the data type to pass to parent component when dialog is closed (boolean) to trigger save or cancel
  dialogRef = inject<DialogRef<'cancel' | 'main' | 'delete'>>(DialogRef<string>);
  data = inject(DIALOG_DATA);
  origin: string | null = ''

  constructor(
    private mySpaceService: MySpaceService
  ){}

  ngOnInit(){
    this.origin = this.mySpaceService.originPhoto
  }
}
