<div class="text-editor">
  {{currentInput?.value}}
    <input 
        type="text" 
        class="text-input" 
        (blur)="onBlur($event.target)"
        [attr.value]="currentInput?.type === 'text'? currentInput?.value : ''"
        [ngStyle]="{color: currentInput?.fontColor, textShadow: currentInput?.shadowColor}"
    >

    <app-color-palette
        [colors]="colors"
        (colorInfo)="getTextColorInfo($event)"
    ></app-color-palette>
</div>
