import { Component, ViewEncapsulation, OnInit, ViewChild, ElementRef, AfterViewChecked, OnDestroy } from '@angular/core';
import { CarouselModule, CarouselComponent } from 'ngx-carousel-ease';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';

import { AppService } from '../../app.service';
import { ProfileService } from '../profile.service';

import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-profile-space',
  standalone: true,
  imports: [CarouselModule, CarouselComponent, RouterModule, CommonModule],
  templateUrl: './profile-space.component.html',
  styleUrl: './profile-space.component.scss',
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    'class': 'space'  // <-- It should be unique for each component you have encapsulation off
  }
})
export class ProfileSpaceComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('hobbiesContainer') hobbiesContainer!: ElementRef

  profile!: User | null
  loading = false
  desktopDevice = false
  userAge!: number
  hobbyWidth!: number
  loaderSubscription!: Subscription
  routeDataSubscription!: Subscription
  profileSubscription!: Subscription

  constructor(
    private appService: AppService,
    private route: ActivatedRoute,
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    this.profileSubscription = this.profileService.profile.subscribe(res => {
      this.profile = res

      if(this.profile && this.profile.birthdate){
        const [y,m,d] = this.profile.birthdate.split('-')
        const date = `${y}-${m}-${d}`
        const birthDate = dayjs(date)
  
        this.userAge = dayjs().diff(birthDate, 'year')
      }
    })

    // gestion taille écran
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }

    this.desktopDevice = this.appService.desktopDevice
  }

  ngAfterViewChecked(){
    if(this.desktopDevice && this.hobbiesContainer){
      const bounds = this.hobbiesContainer.nativeElement.getBoundingClientRect()
      const hobbiesContainerWidth = bounds.right - bounds.left
      this.hobbyWidth = (hobbiesContainerWidth) / 4 - 8
    } else {
      const screenWidth = window.screen.width
      this.hobbyWidth = (screenWidth - 48) / 3 - 8
    }
  }

  getStyles() {
    return {
      'width': this.hobbyWidth + 'px',
      'height': this.hobbyWidth + 'px',
    }
  }

  rejectProfile(){

  }
  likeProfile(){

  }
  contactProfile(){

  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
    this.profileSubscription.unsubscribe()
  }
}
