import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AppService } from '../../app.service';

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {
  @Input() title!: string
  @Input() content!: string
  @Output() confirmation = new EventEmitter<boolean>()

  constructor(
    private appService: AppService
  ){}

  cancel($event: Event){
    $event.stopPropagation()
    console.log('cancel')
    this.confirmation.emit(false)
    this.appService.warningPopup.next(false)
  }

  onSubmit(){
    console.log('onsubmit')
    this.confirmation.emit(true)
  }
}
