import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

import { AppService } from '../../../app.service';
import { CoachService } from '../coach.service';

import { StoryComponent } from '../../../story/story.component';
import { LoaderComponent } from '../../../shared/loader/loader.component';

import { Story } from '../../../shared/interfaces/story';

@Component({
  selector: 'app-my-coach-stories',
  standalone: true,
  imports: [StoryComponent, CommonModule, LoaderComponent],
  templateUrl: './my-coach-stories.component.html',
  styleUrl: './my-coach-stories.component.scss'
})
export class MyCoachStoriesComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('storiesContainer') storiesContainer!: ElementRef

  stories: Story[] = []
  desktopDevice = false
  storyWidth!: number
  loading = false
  loaderSubscription!: Subscription
  count = 0

  constructor(
    private appService: AppService,
    private coachService: CoachService
  ){}

  ngOnInit(){
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)
    this.stories = this.coachService.stories

    // gestion taille écran
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }

    this.desktopDevice = this.appService.desktopDevice
  }

  ngAfterViewChecked(){
    if(this.count > 0) return

    const gapWidth = 16

    if(this.desktopDevice){
      setTimeout(() => {
        const bounds = this.storiesContainer.nativeElement.getBoundingClientRect()
        const storiesContainerWidth = Number.parseFloat((bounds.right - bounds.left).toFixed(2))
        const numberOfItemsPerRow = 3
        this.storyWidth = storiesContainerWidth / numberOfItemsPerRow - gapWidth

        this.count++
      }, 100)
    } else {
      setTimeout(() => {
        const screenWidth = window.screen.width
        const numberOfItemsPerRow = 2
        this.storyWidth = (screenWidth - 32 * 2 - gapWidth) / numberOfItemsPerRow

        this.count++
      }, 100)
    }
  }

  getStyles() {
    const storyCardRatio = 1.4

    return {
      'width': this.storyWidth + 'px',
      'height': `calc(${this.storyWidth}px * ${storyCardRatio})`
    }
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
