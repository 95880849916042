<button type="button" class="story-photo-container" (click)="viewStory(story)">
    @if(story.photos){
      <img [src]="story.photos[0]" alt="" class="item">
    }
    <div class="photo-overlay"></div>
    <div class="story-information">
        <div class="top">
            <div class="avatar">
                <img [src]="story.user?.photo" alt="">
            </div>
            <div class="firstname-container">
                <p class="firstname">{{story.user?.firstname}}</p>
            </div>
        </div>
  
        <div class="stats bottom">
            <div class="likes">
                <img src="assets/icons/icon_love_32.svg" alt="" class="icon">
                {{story.likes}}
            </div>
            <div class="views">
                <img src="assets/icons/icon_view.png" alt="" class="icon">
                {{story.views}}
            </div>
        </div>
    </div>
  </button>