import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoryService } from '../story.service';
import { ServiceService } from '../../service.service';

import { TextInputComponent } from '../text-input/text-input.component';
import { ColorPaletteComponent } from '../color-palette/color-palette.component';

import { Color } from '../../shared/interfaces/color';
import { StoryContent } from '../../shared/interfaces/story-content';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-text-editor',
  standalone: true,
  imports: [TextInputComponent, ColorPaletteComponent, CommonModule, FormsModule],
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.scss'
})
export class TextEditorComponent implements OnInit {
  colors: Color[] = []
  currentInput: StoryContent | null = null
  contents: StoryContent[] = []
  value = ''

  constructor(
    private storyService: StoryService,
    private serviceService: ServiceService
  ){}

  ngOnInit(){
    this.serviceService.getColors().subscribe(res => {
      this.colors = res
    })

    this.storyService.contents.subscribe(res => this.contents = res)
    this.storyService.currentInput.subscribe(res => {
      this.currentInput = res
    })

    this.value = this.storyService.pendingTextValue
  }

  onBlur(target: EventTarget | null){
    console.log('onblur')
    const inputElement = target as HTMLInputElement
    
    if(!this.currentInput) return
    if(this.currentInput.value === inputElement.value) return

    console.log(inputElement)
    
    this.storyService.pendingTextValue = inputElement.value
  }

  // MOBILE : infos de la couleur sélectionnée récupérées du composant color-palette
  getTextColorInfo($event: { colorHex: string; colorType: string; colorIndex: number; }){
    console.log('getTextColorInfo: ', $event)
    this.changeTextColor($event.colorHex, $event.colorType, $event.colorIndex)
  }
  
  // change la couleur de l'input
  changeTextColor(colorCode: string, colorType: string, colorIndex: number){
    console.log('changeTextColor')
    this.colors.forEach(c => c.selected = false)

    if(!this.currentInput) return

    this.storyService.currentInput.next({...this.storyService.currentInput.value, fontColor: colorCode, shadowColor: colorType === "dark" ? "#fff" : "#000" })
    this.colors[colorIndex].selected = true
  }
}
