import { Component, Output, EventEmitter, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule, FormGroup, ControlContainer, FormGroupDirective, FormControl } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { CommonModule } from '@angular/common';

import { CitiesService } from './cities.service';
import { AuthService } from '../../auth.service';
import { AppService } from '../../app.service';

import { City } from '../interfaces/city';
import { User } from '../interfaces/user';

@Component({
  selector: 'app-city-input',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './city-input.component.html',
  styleUrls: ['./city-input.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class CityInputComponent implements OnInit {
  @Input() user!: User | null
  @Output() emitCity = new EventEmitter<City | null>() 

  @ViewChild('input') input!: ElementRef

  citiesLoading = false
  noCityFound = false
  childForm!: FormGroup
  cityCtrl!: FormControl
  formCtrlSub!: Subscription
  cityChoice: City[] | null = null
  citySelected!: City
  inputBounds!: DOMRect
  inputWidth!: number
  desktopDevice = false

  constructor(
    private parentForm: FormGroupDirective,
    private citiesService: CitiesService,
    private authService: AuthService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.childForm = this.parentForm.form

    this.citySelected = {
      id: parseInt(this.authService.get('city_id')),
      name: this.authService.get('city_name'),
    }

    this.cityCtrl = new FormControl<string>(this.citySelected.name || this.user?.city?.name as string)
    this.childForm.addControl(
      'city', new FormGroup({ 
        cityCtrl: this.cityCtrl 
      })
    )

    this.formCtrlSub = this.cityCtrl.valueChanges.pipe(debounceTime(1000))
      .subscribe((val) => {
        if(!val?.trim() || this.citySelected && this.citySelected.name === val) return
        
        this.callCities(val)
      })

    if(window.innerWidth >= 1024 && window.innerHeight >= 600) this.appService.desktopDevice = true
    this.desktopDevice = this.appService.desktopDevice
  }

  callCities(value: string | null) {
    this.citiesLoading = true
    this.cityChoice = []

    this.citiesService.getCitiesByName(value?.trim() as string).subscribe({
      next: (res => {
        this.citiesLoading = false

        if(res.length === 0){
          this.noCityFound = true
          return
        }
        
        this.cityChoice = res

        this.inputBounds = this.input.nativeElement.getBoundingClientRect()
        this.inputWidth = Math.floor(this.inputBounds.right - this.inputBounds.left)

        this.noCityFound = false
      }),
      error: (err => {
        this.citiesLoading = false
        this.noCityFound = true
        return throwError(() => err)
      })
    })
  }
 
  setCity(city?: City){
    if(city){
      this.citySelected = city
      this.cityCtrl.patchValue(this.citySelected.name)
      this.emitCity.emit(this.citySelected)
    } else {
      this.cityCtrl.reset()
      this.noCityFound = false
      this.emitCity.emit(null)
    }

    this.cityChoice = null
  }
}











/* import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Validators, FormsModule, ReactiveFormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { throwError } from 'rxjs';

import { CitiesService } from './cities.service';
import { AuthService } from '../../auth.service';
import { AppService } from '../../app.service';

import { City } from '../interfaces/city';

@Component({
  selector: 'app-city-input',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './city-input.component.html',
  styleUrls: ['./city-input.component.scss'],
})
export class CityInputComponent implements OnInit, AfterViewInit {
  //@Input() city!: City | null;
  //@Output() cityFormValue = new BehaviorSubject<{ name: string, id: number } | null>(null)
  @Input() city!: {id: number | null, name: string | null}
  @Input() fromApp!: boolean
  @Output() emitCity = new EventEmitter<City | {id: number | null; name: string | null;}>()
  @ViewChild('inputFocus', { static: false }) inputFocus!: ElementRef

  cityChoiceDisplay = false; // l'encart avec le choix de villes
  cityChoice: City[] | null = null
  citiesLoading = false
  cityForm!: FormGroup
  noCityFound = false
  focusOnInput: boolean = true
  chosenCity!: City | null
  cityStoredLocally: {id: number | null, name: string | null} = {id: null, name: null}

  constructor(
    private citiesService: CitiesService,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const user = JSON.parse(this.authService.get('user'))

    this.cityStoredLocally = {
      id: parseInt(this.authService.get('city_id')) as number || user.city.id,
      name: this.authService.get('city_name') || user.city.name
    }

    this.cityChoiceDisplay = true;

    this.cityForm = this.formBuilder.group({
      name: this.formBuilder.control(this.cityStoredLocally ? this.cityStoredLocally.name : this.city ? this.city.name : null, [
        Validators.required,
        Validators.minLength(1),
      ]),
    })

    this.cityForm.get('name')?.valueChanges.pipe(debounceTime(1000))
      .subscribe((val) => {
        if (val && val.length > 0 && val !== this.chosenCity?.name) {
          this.callCities(val);
        } else {
          this.cityChoice = null;
        }
      })
  }

  ngAfterViewInit() {
    //this.inputFocus.nativeElement.focus();

    if (this.city?.name && this.city.name.length > 0) {
      this.callCities(this.city.name);
    }
  }

  callCities(val: string): void {
    this.citiesLoading = true;
    this.cityChoice = [];

    this.citiesService.getCitiesByName(val).subscribe({
      next: (res => {
        this.citiesLoading = false
        this.cityChoice = res
        this.noCityFound = false
        this.cityChoiceDisplay = true
      }),
      error: (err => {
        this.citiesLoading = false;
        this.noCityFound = true;
        return throwError(() => err)
      })
    })
  }

  setCity(city: City | null = this.city) {
    this.cityChoiceDisplay = false;

    if(city) {
      this.appService.setCityInput({city: {id: city.id, name: city.name}, display: true})
      this.emitCity.emit(city);
      this.chosenCity = city
      this.cityForm.get('name')?.setValue(city.name)
    } else {
      //this.emitCity.emit(null)
      this.resetInput()
    }
  }

  resetInput() {
    this.cityChoiceDisplay = false;
    console.log('resetInput')
    this.cityForm.get('name')?.setValue(null)
    this.inputFocus.nativeElement.focus();
    this.cityChoice = null;
    this.emitCity.emit({id: null, name: null});
  }

  closeCity() {
    this.appService.setCityInput({ display: false, city: {id: null, name: null} });
  }
}
 */