

<section class="profile-section">
    @if(profileLiked){
      <div class="like-success">
        <img src="assets/icons/icon_love_32.svg" alt="" class="like-icon">
        <p i18n="Text when the user liked the displayed profile in the profile page@@LIKED_PROFILE">You liked this profile!</p>
      </div>
    }
  
    <header class="header">
        <app-go-back-button (click)="setPageDisplayed(); goBack()"></app-go-back-button>
        <nav class="profile-menu">
            <ul>
                <li>
                    <a [routerLink]="['space']" routerLinkActive="selected" #profileSpaceLink="routerLinkActive" [routerLinkActiveOptions]="{exact: true}">
                        <img 
                            [src]="profileSpaceLink.isActive ? '../../assets/icons/icon_myspace_selected.svg' : '../../assets/icons/icon_myspace_menu.svg'" 
                            alt=""
                            class="icon"
                        >
                    </a>
                </li>
                <li>
                    <a [routerLink]="['stories']" routerLinkActive="selected" #profileStoriesLink="routerLinkActive" class="icon">
                        <img 
                            [src]="profileStoriesLink.isActive ? '../../assets/icons/icon_stories_selected.png' : '../../assets/icons/icon_stories.svg'" 
                            alt=""
                            class="icon"
                        >
                    </a>
                </li>
            </ul>
        </nav>
    </header>

    <router-outlet></router-outlet>
</section>