import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { map, Observable, Subscription } from 'rxjs';

import { AppService } from '../../app.service';
import { AuthService } from '../../auth.service';

import { LoaderComponent } from '../loader/loader.component';
import { GoBackButtonComponent } from '../go-back-button/go-back-button.component';
import { FooterComponent } from '../../footer/footer.component';
import { TopButtonComponent } from '../top-button/top-button.component';

import { Hobby } from '../interfaces/hobby';
import { User } from '../interfaces/user';
import { Topic } from '../interfaces/topic';
import { MySpaceService } from '../../my-space/my-space.service';

@Component({
  selector: 'app-my-hobbies',
  standalone: true,
  imports: [LoaderComponent, FormsModule, ReactiveFormsModule, GoBackButtonComponent, FooterComponent, TopButtonComponent],
  templateUrl: './my-hobbies.component.html',
  styleUrl: './my-hobbies.component.scss'
})
export class MyHobbiesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('top') top!: ElementRef
  
  loading = false 
  hobbies: Hobby[] = []
  checked = false
  checkedId!: number
  loaderSubscription!: Subscription
  user!: User | null

  constructor(
    private appService: AppService,
    private http: HttpClient,
    private location: Location,
    private authService: AuthService,
    private mySpaceService: MySpaceService
  ){}

  ngOnInit(){
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    this.authService.authMe$.subscribe(res => {
      this.user = res

      if(!this.user) return

      let userTopics: Topic[] = []
      if(this.user.topics && this.user.topics.length) {
        userTopics = this.user.topics
        userTopics = this.mySpaceService.selectedHobbies // à supprimer, pour les tests
      }

      if(!this.hobbies.length){
        this.getHobbies().subscribe(res => {
          this.mySpaceService.hobbies = res
          this.hobbies = this.mySpaceService.hobbies

          userTopics.forEach(t => {
            this.hobbies.forEach(h => {
              if(t.name === h.name) {
                h.selected = true
              }
            })
          })
        })
      } 
    })
  }

  ngAfterViewInit(){
    this.top.nativeElement.scrollIntoView()
  }

  getHobbies(): Observable<Hobby[]> {
    return this.http.get<Hobby[]>('./assets/hobbies.json').pipe(map((res) => {
      return res
    }))
  }

  getScrollRequest(request: string){
    if(request === 'scrollToTop') this.top.nativeElement.scrollIntoView()
  }

  clickHobby(hobby: Hobby){
    this.hobbies.forEach(h => {
      if(h === hobby){
        h.selected = !h.selected

        if(h.selected){
          this.mySpaceService.addHobby(h)
        } else {
          this.mySpaceService.removeHobby(h)
        }
      }
    })
    
    this.saveHobbies()
  }

  goBack(){
    this.location.back()
  }

  saveHobbies(){
    console.log(this.hobbies)
    // en attente de savoir comment on les met à jour côté back
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
