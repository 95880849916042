import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mergeMap, Observable, throwError } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import { AppService } from '../app.service';
import { AuthService } from '../auth.service';

import { Hobby } from '../shared/interfaces/hobby';
import { Photo } from '../shared/interfaces/photo';
import { User } from '../shared/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class MySpaceService {
  private localStorage: Storage | null = null
  hobbies: Hobby[] = []
  selectedHobbies: Hobby[] = []
  originPhoto: string | null = ''

  constructor(
    private http: HttpClient,
    private appService: AppService,
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService
  ) {
    this.localStorage = this.document.defaultView?.localStorage || null
  }

  get(key: string): string {
    return this.localStorage?.getItem(key) || ''
  }

  getHobbiesList(): Observable<Hobby[]>{
    return this.http.get<{hobbies: Hobby[]}>(`${this.appService.API}/api/hobbies/get/list`).pipe(
      map(res => {
        return res.hobbies
      })
    )
  }

  getHobbiesByUser(id: number): Observable<Hobby[]>{
    const user = JSON.parse(this.get('user'))

    return this.http.get<{hobbies: Hobby[]}>(`${this.appService.API}/api/hobbies/user/list/${id ? id : user.id}`).pipe(
      map(res => {
        return res.hobbies
      })
    )
  }

  updateHobbiesByUser(userId: number, ids: string){
    const user = JSON.parse(this.get('user'))

    const body = {
      type: 'x-www-form-urlencoded',
      hobbies: ids
    }

    return this.http.post(`${this.appService.API}/api/hobbies/user/store/${userId ? userId : user.id}`, body).pipe(
      map(res => {
        return res
      })
    )
  } 

  updateMainPhoto(photo: Photo): Observable<User> {
    const body = {
      photo_id: photo.id
    }

    return this.http.put<{code: 200}>(`${this.appService.API}/api/photo/profile`, body).pipe(
      mergeMap(() => {
        return this.authService.getProfile(Number(this.get('user_id'))).pipe(
          map((user) => {
            this.authService.setAuthMe(user)
            return user
          }),
          catchError((error) => {
            return throwError(() => error)
          }),
        );
      })
    );
  }
}
