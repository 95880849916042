@if(loading){
    <app-loader></app-loader>
}

<section class="my-profile" id="my-profile" appClickAnywhere (clickNature)="getClickNature($event)">
    <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>

    <div class="top" #top>
      <h3><span class="regular">My</span> Profile</h3>
    </div>

    @if(updateMainPhotoError){
        <app-error-dialog [errorMessage]="updateMainPhotoError.message" [errorTitle]="updateMainPhotoError.title"></app-error-dialog>
    }

    <div class="photos-container">
      <div class="main">
        <img [src]="user.main_photo?.url || photos[0]" alt="" class="photo">
        @if(deleting && user.main_photo?.id === photoToDiscardId){
          <app-loader></app-loader>
        }
        <button type="button" class="contextual-menu closed show" #contextualMenuClosed (click)="openProfilePhotosDialog(user.main_photo, 'main')">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </button>
      </div>

      @for(photo of photos | slice:1; track photo; let index = $index){
          @if(!photo) {
            <div class="secondary">
              <button type="file" class="icon-gallery-add-photo" (click)="openFileInput(index)">
                  <img src="assets/icons/icon_gallery.svg" alt="No image">
                  <div class="plus-badge">
                      <span class="material-symbols-rounded more">
                          add
                      </span>
                  </div>
              </button>
            </div>
          } @else {
            <div class="secondary">
              <img [src]="photo?.url" alt="">
              @if(deleting && photo.id === photoToDiscardId){
                <app-loader></app-loader>
              }
              <button type="button" class="contextual-menu closed show" #contextualMenuClosed (click)="openProfilePhotosDialog(photo, null, index)">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </button>
            </div>
          }
      }
    </div>

    <input 
        type="file"
        accept=".jpg, .jpeg, .png" 
        #fileUpload 
        style="opacity: 0;" 
        class="file-upload" 
        (change)="onPhotoInputChange($event)"
    >

    <div #cropper [ngStyle]="{'height': '1px','width': '100%'}"></div><!-- repère pour repositionnement uniquement -->
    <div class="cropper" #cropperContainer>  
      <!-- @if(showCropper){
          <div class="crop-holder">
            <div class="cancel">
              <button type="button" (click)="discardPhoto()">
                <span class="material-symbols-rounded close">
                  close
                </span>
              </button>
            </div>
              <app-photo-crop
                  [imageSource]="tempCropperPhotoInBase64"
                  (userCroppedImageEvent)="getUserCroppedImage($event)"
                  (cropDetailsEvent)="getCropDetails($event)"
                  [photoContainerDimensions]="photoContainerDimensions"
              ></app-photo-crop>
          
              <div class="submit-btn-container">
                <button type="submit" class="submit" (click)="saveCropData()">
                    Crop and save
                </button>
              </div>
          </div>
      } -->
    </div>

    @if(!editInformationMode){
        <div class="information">
            <button type="button" class="edit-btn" (click)="editProfile()">
              <img src="../../../assets/icons/icon_edit.svg" alt="" class="icon-edit">
            </button>
            <h3 class="firstname">{{user.firstname}}</h3>
            <p class="age-location">{{userAge}} years old - {{user.city?.name}}</p>
            <div class="searching-container">
                <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
                {{user.attraction === 'woman' ? 'Women' : 'Men'}}
            </div>
            
            <p class="description">
              @if(user.description){
                {{user.description}}
              } @else {
                Passport to Love: Seeking an Adventure Partner! I don't follow guidebooks; I forge my own path. Local experiences and cultural immersion are my souvenirs. Authenticity is key; I want to experience the world as it truly is. My ideal travel partner shares this insatiable curiosity, this desire to dive deep into the heart of every destination.
              }
            </p>
        </div>
    } @else {
        <div class="information-edit" appClickAnywhere (clickNature)="getClickNature($event)">
            <button type="button" class="close-btn" (click)="cancel($event)">
              <span class="material-symbols-rounded close">
                close
              </span>
            </button>
            <h3>Edit your information</h3>
            <form
                [formGroup]="editProfileInformationFormGroup"
                [class.hide]="displayCreatingLoader"
                (ngSubmit)="onSubmit()"
            >
                <div class="fieldsets">
                    <fieldset>
                        <label for="firstname">Firstname</label>
                        <p class="tip">Your first name should contain 2 to 25 characters, without number, space nor special characters.</p>
                        <input type="text" [value]="user.firstname" id="firstname" formControlName="firstnameCtrl" minlength="2" maxlength="25">

                        @if(firstnameError){
                          <app-error-dialog [errorTitle]="errorTitle" [errorMessage]="errorMessage"></app-error-dialog>
                        }
                    </fieldset>
    
                    <app-birthdate-input [user]="user" (birthdayDialogData)="getBirthdateError($event)"></app-birthdate-input>
                    
                    @if(dateError){
                      <app-error-dialog [errorTitle]="errorTitle" [errorMessage]="errorMessage"></app-error-dialog>
                    }
                    
                    <app-city-input [user]="user" (emitCity)="getCity($event)"></app-city-input>
    
                    <app-attraction-input [user]="user" [parentName]="'myProfileComponent'" (emitAttraction)="getAttraction($event)"></app-attraction-input>
                    
                    <fieldset>
                        <label for="description">Description</label>
                        <textarea id="description" name="description" rows="5" cols="33" formControlName="descriptionCtrl" maxlength="500" class="description">{{user.description}}</textarea>
                    </fieldset>

                    <!-- <p class="success" [ngStyle]="{'display': informationUpdated ? 'block' : 'none'}">Your information has been successfully updated.</p> -->
                </div>
    
                <div class="actions">
                    <button class="text-btn" (click)="cancel($event)">Cancel</button>
                    <button class="submit">
                        Save
                    </button>
                </div>
            </form>
        </div>
    }

    @if(!userHobbies.length){
      <div class="hobbies">
        <div class="icon-container">
            <img src="../../../assets/icons/icon_hobbies_white.svg" alt="" class="icon-hobbies">
        </div>
        
        <h4>Hobbies</h4>
        <p class="description">Here you can show your hobbies</p>

        <button 
            class="submit" 
            [routerLink]="['my-hobbies']" 
            routerLinkActive="selected" 
            #myProfileLink="routerLinkActive"
        >
            <img src="../../../assets/icons/icon_hobbies_white.svg" alt="" class="icon-hobbies">
            Select your hobbies
        </button>
      </div>
    } @else {
      <section class="hobbies-section">
        <button type="button" class="edit-btn" (click)="editHobbies()">
          <img src="../../../assets/icons/icon_edit.svg" alt="" class="icon-edit">
        </button>

        <h3>Hobbies</h3>
        <div class="hobbies-list" #hobbiesListContainer>
          @for(h of userHobbies; track h){
            <div class="hobby-item" [ngStyle]="getStyles()">
              <p class="hobby-title">{{h.label}}</p>
            </div>
          }
        </div>
      </section>
    }

    <app-top-button (scrollRequest)="getScrollRequest($event)"></app-top-button>
</section>
