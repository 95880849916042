<div class="cropped-photo">
    <div class="greeting" i18n="Title to welcome the user on the home page@@HELLO">
        Hello <div class="firstname">{{user?.firstname}}!</div>
    </div>

    <div class="photo-container">
      @if(loading$ | async){
        <app-loader></app-loader>
      }
      @if(user?.main_photo?.url){
        <img [src]="user?.main_photo?.url" alt="Image">
      }
    </div>

    <div class="submit-btn-container" #submitBtnContainer>
        <div class="check" #check>
            <span class="material-symbols-rounded">
                done
            </span>
        </div>
            
        <div class="discover" #discover [routerLink]="['/home']" routerLinkActive="router-link-active" i18n="Text for the button to go to the home page when a main photo was uploaded@@DISCOVER_PERFECT_MATCH">
            Discover your perfect match
        </div>
    </div>
</div>
<router-outlet></router-outlet> 
