import { Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AsyncPipe } from '@angular/common';

import { AuthService } from '../../auth.service';
import { AppService } from '../../app.service';
import { LoaderService } from '../../loader.service';

import { LoaderComponent } from '../../shared/loader/loader.component';

import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-cropped-photo',
  standalone: true,
  imports: [RouterModule, LoaderComponent, AsyncPipe],
  templateUrl: './cropped-photo.component.html',
  styleUrl: './cropped-photo.component.scss'
})
export class CroppedPhotoComponent implements OnInit, OnDestroy {
  @ViewChild('submitBtnContainer') submitBtnContainer!: ElementRef
  @ViewChild('check') check!: ElementRef
  @ViewChild('discover') discover!: ElementRef

  loading$ = this.loaderService.loading$
  user!: User | null
  photoUrl: string | undefined = ''

  userId!: number
  private readonly _destroyed = new Subject<void>()

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private loaderService: LoaderService
  ){}
  
  ngOnInit(){
    console.log('cropped-photo')
    this.userId = parseInt(this.authService.get('user_id'))

    this.authService.getProfile(this.userId).pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      this.user = res
      this.authService.setAuthMe(this.user)
    })

    // animation du bouton
    setTimeout(()=>{
      this.submitBtnContainer.nativeElement.style.width = '100%'
      this.check.nativeElement.style.opacity = '0'
      this.discover.nativeElement.style.opacity = '1'
      this.discover.nativeElement.style.width = '100%'
    }, 1000)
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
