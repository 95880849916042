
<div class="left-col"></div>

<div class="right-col" appClickAnywhere (clickNature)="getClickNature($event)">
    <app-go-back-button [routerLink]="['/login']"></app-go-back-button>
    <app-header></app-header>
    @if(loading$ | async){
        <app-loader></app-loader>
    }
    
    <form [formGroup]="resetPasswordFormGroup" (ngSubmit)="onSubmit()">
        <h2 i18n="Label for email or phone number input in the reset password form@@EMAIL_OR_PHONE">Email or phone number</h2>
        <div class="input-holder">
            <input
                id="email"
                type="text"
                formControlName="emailOrPhoneCtrl"
                placeholder="Enter your email or phone number"
                i18n="Placeholder for email or phone number input in the reset password form@@EMAIL_OR_PHONE"
                (click)="closeDialog()"
                [ngClass]="openDialog ? 'invalid': ''"
            />
            
            @if(openDialog){
                <app-error-dialog [errorMessage]="errorMessage" [errorTitle]="errorTitle"></app-error-dialog>
            }
        </div>
        <p class="info" i18n="Instruction for the email or phone number input in the reset password form@@FORGOT_PASSWORD_TEXT">
            Please enter the email address or the phone number of your account to generate a new password.
        </p>
        
        @if(passwordReset){
            <div class="success-message">
                <!-- TODO : Une seule clé au lieu de deux. Mettre à jour la clé  -->
                <h4 i18n="Text to inform that the new password has been sent to the user at his request in the reset password form@@NEW_PASSWORD_SENT">Your new password has been sent.</h4>
                <p i18n="Text to invite user to check his emails or phone messages to retrieve his password@@CHECK_EMAILS_OR_SMS">Please check your emails or phone messages.</p>
            </div>
        }
    </form>

    <div class="submit-btn-container-pwd" #submitBtnContainer>
        <button 
            class="submit"
            type="submit" 
            [disabled]="resetPasswordFormGroup.invalid"
            #btn
            (click)="onSubmit()"
            i18n="text for the button to get a new password in the reset password form@@GENERATE_NEW_PASSWORD"
        >
            Generate a new password
        </button>
        <div class="check-pwd" #check>
            <span class="material-symbols-rounded">done</span>
        </div>
    </div>
</div>

<router-outlet></router-outlet>
