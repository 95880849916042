import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appClickAnywhere]',
  standalone: true
})
export class ClickAnywhereDirective {
  @Output() clickNature: EventEmitter<string> = new EventEmitter()
  
  @HostListener('click', ['$event']) onClick(){
    this.clickNature.emit('anywhere')
  }

  constructor() { }
}
