import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
//import { ErrorReturn } from './shared/interfaces/errorReturn';
//import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  //readonly API = 'https://api.dating.dve-dev.com/'
  readonly API = 'https://api.staging.dating.dv-content.io'
  //readonly API = environment.API;

  readonly serviceId = '69'; // base woozgo
  //readonly serviceId = '/777'; // base swipi

  //private popin$ = new BehaviorSubject<ErrorReturn>(null);
  private page$ = new BehaviorSubject<string>('home');
  private cityInput$ = new Subject<{ city: {id: number | null, name: string | null}; display: boolean }>();
  private focusInput$ = new BehaviorSubject<{
    value: string | null;
    display: boolean;
    type: string | null;
    name: string | null;
  }>({ value: null, display: false, type: null, name: null });
  private loader$ = new BehaviorSubject<boolean>(false);
  private keyboardup$ = new BehaviorSubject<{ resize: boolean; delta: number }>({
    resize: false,
    delta: 0,
  });

  desktopDevice = false

  warningPopup = new BehaviorSubject<boolean>(false)
  scrollValue = new BehaviorSubject<Event | null>(null)

  get page(): Observable<string> {
    return this.page$.asObservable();
  }
  setPage(value: string) {
    this.page$.next(value);
  }

  get loader(): Observable<boolean>{
    return this.loader$.asObservable()
  }
  setLoader(value: boolean){
    this.loader$.next(value)
  }

  /* get popin(): Observable<ErrorReturn> {
    return this.popin$.asObservable();
  } */
  /* setPopin(value: ErrorReturn) {
    this.popin$.next(value);
  } */

  get cityInput(): Observable<{ city: {id: number | null, name: string | null} | null; display: boolean }> {
    return this.cityInput$.asObservable();
  }

  setCityInput(value: { city: {id: number | null, name: string | null}; display: boolean }) {
    this.cityInput$.next(value);
  }

  get focusInput(): Observable<{
    value: string | null;
    display: boolean;
    type: string | null;
    name: string | null;
  }> {
    return this.focusInput$.asObservable();
  }

  setFocusInput(value: { value: string; display: boolean; type: string; name: string }) {
    this.focusInput$.next(value);
  }

  get keyboardstate(): Observable<{ resize: boolean; delta: number }> {
    return this.keyboardup$.asObservable();
  }

  setKeyboardstate(value: { resize: boolean; delta: number }) {
    this.keyboardup$.next(value);
  }
}

