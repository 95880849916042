@if(loading){
    <app-loader></app-loader>
}

<section class="my-dating-criteria">
    <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>  
  
    <div class="top" #top>
        <h3><span class="regular">My</span> Dating Criteria</h3>
    </div>

    <form
        class="dating-criteria-form"
        [formGroup]="datingCriteriaFormGroup"
        (ngSubmit)="onSubmit()"
    >
        <div class="fieldsets">
            <app-attraction-input [user]="user" (emitAttraction)="getAttraction($event)"></app-attraction-input>
            
            <fieldset>
                <h2>Age</h2>
                <app-range-input 
                    [ageRange]="ageRange"
                    (minValueSelection)="getMinValueSelection($event)"
                    (maxValueSelection)="getMaxValueSelection($event)"
                ></app-range-input>
            </fieldset>

            <app-city-input [user]="user"></app-city-input>

            <fieldset id="distance-input">
                <label for="distance">Distance</label>
                <input 
                    name="distance" 
                    id="distance" 
                    type="range" 
                    (input)="getDistanceValue($event)" 
                    [value]="distanceValue" 
                    min="0" 
                    max="200" 
                    formControlName="distanceCtrl"
                    #distanceInput
                >
                <div class="distance-text" [ngStyle]="getDistanceStyle()" #distanceText>{{distanceValue}} km</div>
            </fieldset>
        </div>
    </form>

    <div class="submit-btn-container">
        <button 
            routerLink='/subscribe-step-two' 
            routerLinkActive="router-link-active"
            class="submit"
            type="submit"
            (click)="onSubmit()"
        >
            Validate 
        </button>
    </div>

    <app-top-button (scrollRequest)="getScrollRequest($event)"></app-top-button>
</section>