@if(loading){
    <app-loader></app-loader>
}

<section class="my-stories" #myStoriesHolder>
  <app-go-back-button class="go-back-btn" (click)="goBack()"></app-go-back-button>  
  
  <div class="top" #top>
      <h3><span class="regular">My</span> Stories</h3>
  </div>

  @if(!stories.length && !storiesLoading){
    <div class="no-stories-container" [style.height.px]="(viewportHeight * 70 / 100)">
      <p class="no-stories">No stories for the moment.</p>
    </div>
  } @else {
    <div class="stories-container" #storiesContainer>
      @for(s of stories; track s){
          <div class="photo-container" [ngStyle]="getStyles()">
            <img [src]="s.photos[0]" alt="" class="cover">
            <button type="button" (click)="openDeleteDialog(s.id)"  class="delete-story-btn">
              <img src="assets/icons/icon_delete.svg" alt="" class="delete-icon"> 
            </button>
            <div class="overlay" [class.show]="s.state === 'pending'"></div>
            <button type="button" (click)="openPublishDialog(s.id)" class="edit-story-btn" [class.show]="s.state === 'pending'">
              <img src="assets/icons/icon_edit_story.svg" alt="" class="edit-icon">
            </button>
          </div>
      }
    </div>

    @if(storiesLoading){
      <app-loader class="stories-loader"></app-loader>
    }
  }

  @if(stories.length){
    <app-top-button (scrollRequest)="getScrollRequest($event)"></app-top-button>
  }
</section>
