import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { TitleCasePipe, AsyncPipe } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { RouterModule } from '@angular/router';

import { HomeService } from '../home/home.service';
import { LayoutService } from '../layout.service';

import { ClickAnywhereDirective } from '../click-anywhere.directive';

@Component({
  selector: 'app-logged-in-header',
  standalone: true,
  imports: [TitleCasePipe, RouterModule, ClickAnywhereDirective, AsyncPipe],
  templateUrl: './logged-in-header.component.html',
  styleUrl: './logged-in-header.component.scss'
})
export class LoggedInHeaderComponent implements OnInit, OnDestroy {
  serviceName = environment.serviceName
  pageDisplayed = ''
  sidenavIsOpened = false
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop
  liveNotifications$ = this.homeService.liveNotifications

  constructor(
    private homeService: HomeService,
    private layoutService: LayoutService
  ){}

  ngOnInit(){
    this.homeService.pageDisplayed.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => {
      this.pageDisplayed = res
    })
  }

  toggleSidenav(open: boolean, pageRequested: string){
    this.homeService.toggleSidenav.next(open)
    this.homeService.pageDisplayed.next(pageRequested)
  }

  getClickNature(){
    this.toggleSidenav(false, 'home')
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
