import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location, CommonModule } from '@angular/common';
import { RouterOutlet, RouterModule, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { MatSidenavModule } from '@angular/material/sidenav';

import { AuthService } from '../auth.service';
import { HomeService } from '../home/home.service';
import { LayoutService } from '../layout.service';

//import { MyCoachComponent } from './my-coach/my-coach.component';

@Component({
  selector: 'app-my-space',
  standalone: true,
  imports: [RouterOutlet, RouterModule, MatSidenavModule, CommonModule],
  templateUrl: './my-space.component.html',
  styleUrl: './my-space.component.scss'
})
export class MySpaceComponent implements OnInit, OnDestroy {
  pageDisplayed = ''
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private homeService: HomeService,
    private layoutService: LayoutService
  ){}

  ngOnInit(){
    this.homeService.pageDisplayed.pipe(
      takeUntil(this._destroyed)
    ).subscribe(res => this.pageDisplayed = res)

    if(this.location.path() === '/home/my-space' && this.isDesktop()) {
      this.router.navigate(['/home/my-space/my-profile'])
    }
  }

  displayPage(page: string){
    this.homeService.pageDisplayed.next(page)
  }

  logOut(){
    this.authService.logged$.next(false)
    this.router.navigate(['/login'])
    this.authService.authDisconnect()
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
