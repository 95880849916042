<h3>Are you sure you want to delete this conversation?</h3>

<p class="description">The conversation will no longer be recoverable.</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()">Cancel</button>
  <button class="submit" (click)="dialogRef.close(true)">
    <img src="assets/icons/icon_chat_delete.svg" alt="" class="delete-icon">
    Delete
  </button>
</div>
