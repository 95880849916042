import { HttpEvent, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';

export const networkInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next): Observable<HttpEvent<unknown>> => {
  let totalRequests = 0
  let completedRequests = 0

  totalRequests++

  return next(req).pipe(
    finalize(() => {
      completedRequests++

      if(completedRequests === totalRequests) {
        completedRequests = 0
        totalRequests = 0
      }
    })
  );
};
