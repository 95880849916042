import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import dayjs from 'dayjs';

import { MatchesService } from './matches.service';
import { LoaderService } from '../loader.service';
import { LayoutService } from '../layout.service';
import { HomeService } from '../home/home.service';

import { LoaderComponent } from '../shared/loader/loader.component';
import { MatchComponent } from './match/match.component';

import { User } from '../shared/interfaces/user';
import { Talk } from '../shared/interfaces/talk';
import { Notification } from '../shared/interfaces/notification';

@Component({
  selector: 'app-my-matches',
  standalone: true,
  imports: [LoaderComponent, MatchComponent, RouterModule, CommonModule],
  templateUrl: './my-matches.component.html',
  styleUrl: './my-matches.component.scss'
})
export class MyMatchesComponent implements OnInit, OnDestroy {
  loading$ = this.loaderService.loading$
  matchesLoading = true
  profiles!: User[]
  talks!: Talk[]
  notifications: Notification[] = []
  notificationCount = 0
  matches!: User[]
  notificationObservable$!: Observable<unknown>
  notificationsCount = new BehaviorSubject(0)
  liveNotifications$ = this.homeService.liveNotifications
  private readonly _destroyed = new Subject<void>()
  isDesktop = this.layoutService.isDesktop

  constructor(
    private router: Router,
    private matchesService: MatchesService,
    private loaderService: LoaderService,
    private layoutService: LayoutService,
    private homeService: HomeService
  ){}

  ngOnInit(){
    this.loaderService.show()

    this.matchesService.getMatches().pipe(
      takeUntil(this._destroyed),
      map(matches => {
        this.matchesLoading = false
        const tempMatches = matches

        tempMatches.map(m => {
          const profileLastConnectionDate = dayjs(m.connected_at)
          const today = dayjs(new Date())
          const timeDiff = today.diff(profileLastConnectionDate, 'day')

          m.loggedIn = timeDiff <= 1
        })

        this.matches = tempMatches

        this.matchesService.matches = this.matches
        this.loaderService.hide()
      })
    ).subscribe()
  }

  private _isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getUTCFullYear() === date2.getUTCFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }

  private _getSeparatorBody(date: Date): string {
    let timeStr = ''

    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const twoDaysBeforeToday = new Date()
    twoDaysBeforeToday.setDate(twoDaysBeforeToday.getDate() - 2)

    const threeDaysBeforeToday = new Date()
    threeDaysBeforeToday.setDate(threeDaysBeforeToday.getDate() - 3)

    if (this._isSameDay(date, new Date())) {
      timeStr = $localize `:@@TODAY:Today`
    } else if (this._isSameDay(date, yesterday)) {
      timeStr = $localize `:@@YESTERDAY:Yesterday`
    } else if (this._isSameDay(date, twoDaysBeforeToday)) {
      timeStr = '2' + $localize `:@@DAYS_AGO:days ago`
    } else if (this._isSameDay(date, threeDaysBeforeToday)) {
      timeStr = '3' + $localize `:@@DAYS_AGO:days ago`
    } else {
      timeStr = $localize `:@@MORE_THAN_WEEK_AGO:More than a week ago`
    }

    return timeStr
  }

  // récupération de fake profiles : pour les tests
  // getProfiles(): Observable<User[]> {
  //   return this.http.get<User[]>('./assets/users.json').pipe(map((res) => {
  //     return res
  //   }))
  // }

  // récupération de fake Talks : pour les tests
  // getTalks(): Observable<Talk[]> {
  //   return this.http.get<Talk[]>('./assets/talks.json').pipe(map((res) => {
  //     return res
  //   }))
  // }

  showNotifications(){
    this.router.navigate(['home/my-matches/notifications'])
  }

  ngOnDestroy(){
    this._destroyed.next()
    this._destroyed.complete()
  }
}
