
<button 
  type="button" 
  class="story-photo-container" 
  (click)="showNextPhoto()"
  (touchstart)="swipe($event, 'start')" 
  (touchend)="swipe($event, 'end')"
  #storyPhotoContainer
  [ngStyle]="getStoryPhotoContainerStyles()"
>
  @if(story){
    <div class="story-top">
      <button type="button" class="close-btn" (click)="closeSidenav($event)">
        <span class="material-symbols-rounded close">close</span>
      </button>
      <div class="progression-bar" #progressionBar>
        @for(p of story.photos; let i = $index; track p){
          <div class="bar" [class.selected]="currentPhotoIndex === i" [ngStyle]="getBarStyles()"></div>
        }
      </div>
      <button type="button" class="contextual-menu closed show" #contextualMenuClosed (click)="openStoryDialog($event)">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </button>
    </div>

    <img [src]="story.photos[currentPhotoIndex]" alt="" class="item">
    <div class="photo-overlay"></div>
    <div class="story-information">
        <div class="information-top">
            <button type="button" class="avatar" [routerLink]="['profile/' + story.user?.id + '/space']" (click)="stopClickPropagation($event)">
                <img [src]="story.user?.photo" alt="">
            </button>
            <div class="user-information">
                <button type="button" class="firstname-btn" [routerLink]="['profile/' + story.user?.id + '/space']" (click)="stopClickPropagation($event)">
                  <p class="firstname">{{story.user?.firstname}}</p>
                </button>
                
                @if(storyOwner){
                  <p class="age-city">{{storyOwnerAge}} years - {{storyOwner.city?.name}}</p>
                }

                <div class="stats bottom">
                  <div class="likes">
                      <img src="assets/icons/icon_love_32.svg" alt="" class="icon">
                      {{story.likes}}
                  </div>
                  <div class="views">
                      <img src="assets/icons/icon_view.png" alt="" class="icon">
                      {{story.views}}
                  </div>
              </div>
            </div>
            <button type="button" class="like-btn" (click)="story.liked ? unlikeStory($event, story) : likeStory($event, story)" [class.selected]="story.liked">
              <img src="assets/icons/icon_like.svg" alt="" class="like-icon">
            </button>
        </div>
    </div>
  }
</button>
  