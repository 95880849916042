<div class="auth-login">
  <div class="left-col">
    <div class="overlay"></div>
    <div class="content">
      <h1>Share your 
        <br/>Stories!</h1>
      <p class="hook">For the first time, there is a free online dating service that allows you to introduce yourself to others through stories!</p>
      <p class="hook">{{ serviceName | uppercase }} has flipped the rules of online dating upside down with its</p>
      <div class="users-info">
        <div class="users-number">
          <span class="number">2.5</span> million
        </div>
        <p>active users</p>
      </div>
      <div class="users-info">
        <div class="users-number">
          <span class="number">200K</span>
        </div>
        <p>messages per month</p>
      </div>
      <div class="users-info">
        <div class="users-number">
          <span class="number">5K</span>
        </div>
        <p>new stories per month</p>
      </div>
    </div>
  </div>

  <div class="right-col" appClickAnywhere (clickNature)="getClickNature($event)">
    <div class="overlay"></div>
    <div class="content">
      <app-header></app-header>

      <form 
        [formGroup]="authFormGroup" 
        (ngSubmit)="onSubmit()"
      >
        <div class="field">
          <h2>Log in</h2>
          @if(inputs.login){
            <div class="input-holder">
              <input
                id="login"
                type="text"
                formControlName="loginCtrl"
                (focus)="setFocusInput()"
                (blur)="setBlurInput()"
                placeholder="Login"
                autocapitalize="none"
                #inputLogin
                (click)="closeDialog()"
              />
            </div>
          } 
          @if(inputs.password) {
            <div class="input-holder">
              <button type="button" class="password-btn" (click)="showHidePassword()" [class.showHide]="!showPassword">
                <span class="material-symbols-outlined password-icon">
                  visibility
                </span>
              </button>
              <button type="button" class="password-btn" (click)="showHidePassword()" [class.showHide]="showPassword">
                <span class="material-symbols-outlined password-icon">
                  visibility_off
                </span>
              </button>

              <input
                id="password"
                [type]="showPassword ? 'text': 'password'"
                formControlName="passwordCtrl"
                (focus)="setFocusInput()"
                (blur)="setBlurInput()"
                placeholder="Password"
                autocapitalize="none"
                #inputPassword
                (click)="closeDialog()"
              />

              @if(showPassword){
                <div class="show-password">{{ authFormGroup.value.password }}</div>
              }
            </div>
          }
        
          @if(openDialog){
            <app-error-dialog [errorMessage]="errorMessage" [errorTitle]="errorTitle"></app-error-dialog>
          }

          <button
            class="submit"
            [class.wrong]="wrongCredential"
            type="submit"
            [disabled]="authFormGroup.invalid || error"
          >
            Log in
          </button>
          
          @if(inputs.password) {
            <button type="button" class="text-link" routerLink="/forgotten-password" routerLinkActive="active">
              Forgot your password?
            </button>
          }
        </div>

        @if(loading){
          <app-loader></app-loader>
        }
        
        <div class="field">
          <h3>No account?</h3>
          <button
            class="text-btn"
            routerLink="/subscribe-step-one"
            queryParamsHandling="merge"
          >
            Join {{ serviceName | titlecase }}
          </button>
        </div>
      </form>
    </div>
    
    <app-footer></app-footer>
  </div>
</div>
  